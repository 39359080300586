import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import {
  ChartDataItem,
  ChartDataQuery,
  ChartDataState,
  MinAvgMax,
  Reference,
} from '@/store/chart-data/chart-data.types';
import { debugLog } from '@/store/chart-data/chart-data.extensions';

export const getters: GetterTree<ChartDataState, RootState> = {
  stateData(state): [ChartDataItem[], ChartDataItem[]] | undefined {
    const { data } = state;
    return data;
  },
  query(state): ChartDataQuery | undefined {
    const { query } = state;
    return query;
  },
  phase(state): number | undefined {
    const { selectedPhase } = state;
    return selectedPhase;
  },

  getRefs(state): Reference[] | undefined {
    debugLog(state);
    if (!state?.refs) return undefined;
    const { refs } = state;
    return refs;
  },
  getMam(state): MinAvgMax[] | undefined {
    const { mam } = state;
    return mam;
  },
};
