import Socket = SocketIOClient.Socket;
import { addSubject, removeSubject } from '@/socket';
import { Subject } from 'rxjs';

export const stateSubjectByOrder: any = {
  1: 'at_state', // Силовой (авто)трансформатор
  2: 'reactor_general_state', // Шунтирующий реактор
  3: 'breaker_state', //Выключатель
  4: 'transformer_state', //Трансформатор напряжения
  5: 'tt_general_state_result', // Трансформатор тока
  6: 'opn_state', //  Ограничитель перенапряжения -- еще нет
  7: 'dc_general_state_result', // Разъединитель
  8: 'wavetrap_state', // Высокочастотный заградитель,
  // 9: 'capacitor_state', // Конденсатор связи
  9: 'ccvt_state', // Конденсатор связи
  10: 'rpn_state', // Регулятор напряжения
};

export class StateSubscriber {
  public static stop(socket: Socket, subject?: string) {
    try {
      if (subject) {
        removeSubject(subject);
        socket.off(subject);
      }
    } catch (e) {
      console.log(e.message);
    }
  }
  public static start(subject: string, keys: string[], socket: Socket) {
    const subscriber = new Subject();
    addSubject({
      theme: subject,
      keys,
    });
    socket.on(subject, (data: any) => {
      subscriber.next(data);
    });

    return subscriber;
  }
}
