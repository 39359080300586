const prefix = 'historyCharts/';

//info
export const HISTORY_CHARTS = `${prefix}`;
export const HISTORY_CHARTS_DATA = `${prefix}data`;

// set
export const SET_HISTORY_DATA = `${prefix}setHistoryData`;
export const SET_HISTORY_DATA_SUCCESS = `${prefix}setHistoryDataSuccess`;
export const SET_HISTORY_DATA_FAIL = `${prefix}setHistoryDataFail`;

// scheme
export const HISTORY_CHARTS_BUILD_SCHEME = `${prefix}BuildScheme`;
export const HISTORY_CHARTS_BUILD_SCHEME_SUCCESS = `${prefix}BuildSchemeSuccess`;
export const HISTORY_CHARTS_BUILD_SCHEME_ERROR = `${prefix}BuildSchemeError`;

// request data
export const REQUEST_DATA = `${prefix}RequestData`;
export const REQUEST_DATA_SUCCESS = `${prefix}RequestDataSuccess`;
export const REQUEST_DATA_FAIL = `${prefix}RequestDataFail`;

// extra
export const EXTRA_CALC_DATA = `${prefix}ExtraCalcData`;
export const EXTRA_CALCULATED_SUCCESS = `${prefix}ExtraCalculatedSuccess`;
export const EXTRA_CALCULATED_FAIL = `${prefix}ExtraCalculatedFail`;

// reset extra
export const RESET_EXTRA_DATA = `${prefix}ResetExtraData`;
export const RESET_EXTRA_DATA_SUCCESS = `${prefix}ResetExtraDataSuccess`;
export const RESET_EXTRA_DATA_FAIL = `${prefix}ResetExtraDataFail`;

// empty areas
export const AREAS_DATA_STATE = `${prefix}AreasDataState`;
export const AREAS_DATA_STATE_FAIL = `${prefix}AreasDataStateFail`;

export const CLEAR_HISTORY_DATA = `${prefix}ClearHistoryData`;
