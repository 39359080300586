
export interface Event {
  name?: string;
  payload?: any;
}

export enum EventStateEnum {
  initial = 0,
  started = 1,
  stopped = 2
}

export interface EventState {
  error?: string | boolean | undefined;
  state?: EventStateEnum | undefined;
  event?: Event | undefined

}
