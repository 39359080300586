export enum DirtyEnum {
  AssetUid = 'assetUid',
  Uid = 'uid',
  Value = 'value'
}

export type DirtyTypes = DirtyEnum.AssetUid | DirtyEnum.Uid | DirtyEnum.Value;

export interface IDirtyState {
  changes: object | null;
  dirtyItems: Map<string, number | string>;
  isDirty: boolean;
  error: Error | string | boolean;
  clearItems: Map<string, number | string>;
}
