import { Module } from 'vuex';
import { UsageState } from '@/store/usage-info/types';
import { RootState } from '@/store/types';
import {
  GET_USAGE_INFO,
  GET_USAGE_INFO_CONFIG,
  GET_USAGE_INFO_ERROR,
  GET_USAGE_INFO_SUCCESS,
} from '@/store/usage-info/constants';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { RestApiService } from '@/services';
const path = getApiUrl(TR_TYPE.HTTP, '/usage-info');
const rest = new RestApiService();

export const usageInfo: Module<UsageState, RootState> = {
  namespaced: true,
  state: {
    error: false,
    data: [],
    config: [],
  },
  actions: {
    [GET_USAGE_INFO_CONFIG]: async (context) => {
      try {
        const response = await rest.get(path + '/config/sys_monitor');
        context.commit(GET_USAGE_INFO_CONFIG, response?.data);
      } catch (e) {
        context.commit(GET_USAGE_INFO_CONFIG, []);
      }
    },
    [GET_USAGE_INFO]: async (context, payload) => {
      try {
        const response = await rest.get(path);
        context.commit(
          GET_USAGE_INFO_SUCCESS,
          response?.data.sort((a: any, b: any) => a.server - b.server)
        );
      } catch (e) {
        context.commit(GET_USAGE_INFO_ERROR, e.message);
      }
    },
  },
  mutations: {
    [GET_USAGE_INFO_CONFIG]: (state, payload) => {
      state.config = payload;
    },
    [GET_USAGE_INFO_SUCCESS]: (state, payload) => {
      state.data = payload;
      state.error = false;
    },
    [GET_USAGE_INFO_ERROR]: (state, payload) => {
      state.error = payload;
      state.data = [];
    },
  },
  getters: {
    [GET_USAGE_INFO]: ({ data }) => data,
    [GET_USAGE_INFO_ERROR]: ({ error }) => error,
    [GET_USAGE_INFO_CONFIG]: ({ config }) => config,
  },
};
