import { ActionTree } from 'vuex';
import axios from 'axios';

import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { RootState } from '@/store/types';

import { AuthState } from './types';
import { CONST } from './constants';

const rest = new RestApiService();

export const actions: ActionTree<AuthState, RootState> = {
  [CONST.LOGIN]: async (context, payload: { login: string; password: string }) => {
    const response = await rest.post(getApiUrl(TR_TYPE.HTTP, '/auth/login'), {
      user: {
        login: payload.login,
        password: payload.password,
      },
    });

    if (response.status === 201) {
      const token = response.data.access_token;
      const refreshToken = response.data.refresh_token;

      context.commit(CONST.SET_TOKEN, token);
      context.commit(CONST.SET_REFRESH_TOKEN, refreshToken);

      const user = await rest.get(getApiUrl(TR_TYPE.HTTP, '/users/personal'));

      if (user.status === 200) {
        context.commit(CONST.SET_USER, user.data);
        return { ok: true };
      } else {
        await context.dispatch(CONST.LOGOUT);
        return { ok: false };
      }
    } else {
      return { ok: false };
    }
  },
  [CONST.LOGOUT]: async (context) => {
    const u = {...context.state.user};
    const ses = await rest.post(getApiUrl(TR_TYPE.HTTP, '/auth/logoff'), u);
    context.commit(CONST.SET_TOKEN, null);
    context.commit(CONST.SET_REFRESH_TOKEN, null);
    context.commit(CONST.SET_USER, null);
  },
  [CONST.UPDATE_USER_INFO]: async (context) => {
    const token = context.getters[CONST.GET_TOKEN];

    if (token) {
      const user = await rest.get(getApiUrl(TR_TYPE.HTTP, '/users/personal'));

      if (user.status === 200) {
        context.commit(CONST.SET_USER, user.data);
        return { ok: true };
      } else {
        await context.dispatch(CONST.LOGOUT);
        return { ok: false };
      }
    } else {
      return { ok: true };
    }
  },
  [CONST.UPDATE_TOKEN]: async (context) => {
    const token = context.getters[CONST.GET_REFRESH_TOKEN];

    if (token) {
      const ax = axios.create({ headers: { Authorization: `bearer ${token}` } });
      const response = await ax.post(getApiUrl(TR_TYPE.HTTP, '/auth/refresh')).catch((e) => e);

      if (response.status === 201) {
        const token = response.data.access_token;
        const refreshToken = response.data.refresh_token;

        context.commit(CONST.SET_TOKEN, token);
        context.commit(CONST.SET_REFRESH_TOKEN, refreshToken);

        const ax = axios.create({ headers: { Authorization: `bearer ${token}` } });
        const user = await ax.get(getApiUrl(TR_TYPE.HTTP, '/users/personal')).catch((e) => e);

        if (user.status === 200) {
          context.commit(CONST.SET_USER, user.data);
          return { ok: true };
        } else {
          await context.dispatch(CONST.LOGOUT);
          return { ok: false };
        }
      } else {
        await context.dispatch(CONST.LOGOUT);
        return { ok: false };
      }
    } else {
      return { ok: false };
    }
  },
};
