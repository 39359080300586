








import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AlgorithmScheme } from '@/store/algorithms/algorithms.types';
import EquipmentDynamicStateItem from '@/components/items-tab/panes/equip-state/EquipmentDynamicStateItem.vue';
import { TableCol } from '@/components/basics/table/basic-table.types';
import { Action, Getter } from 'vuex-class';
import { EXACT_EQUIP_STATE, REPORT_DATA } from '@/store/report-data/constants';
import { IExactStateQuery } from '@/store/report-data/types';

@Component({
  components: { EquipmentDynamicStateItem },
})
export default class EquipmentDynamicState extends Vue {
  @Prop() scheme?: AlgorithmScheme;
  @Action(EXACT_EQUIP_STATE, { namespace: REPORT_DATA })
  getExactEquipStateAction!: (payload: IExactStateQuery) => void;
  @Getter(EXACT_EQUIP_STATE, { namespace: REPORT_DATA })
  stateGetter!: any;

  get equipStateValue() {
    return this.stateGetter;
  }

  @Watch('scheme', { immediate: true }) onScheme(val?: AlgorithmScheme, old?: AlgorithmScheme) {
    if (val === old) return;
    const { moduleUid, dbTableName } = val as AlgorithmScheme;
    this.getExactEquipStateAction({ algorithm: dbTableName, calculator_uuid: moduleUid as string });
  }

  get props(): TableCol[] {
    const propsData = this.scheme?.columns
      ?.filter((i) => i.mandatory)
      .filter((i) => i?.order && i.order > 0)
      .map((i) => i);
    return propsData?.length ? propsData : [];
  }
}
