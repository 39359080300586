export enum BookmarkItemType {
  Link = 'link',
  Folder = 'folder',
}

export interface IUuid {
  uuid: string;
}

export interface IBookmark extends IUuid {
  url?: string | null;
  urlWithQuery?: string | null;
  alias?: string;
  type: BookmarkItemType;
  mPath?: string;
}

export interface IBookmarksQuery {
  userId?: number;
}

export interface IBookmarkNode extends IBookmark {
  children?: IBookmarkNode[];
}

export interface IBookmarksState {
  error: boolean | Error;
  bookmarksQuery: IBookmarksQuery;
  bookmarksTree: IBookmarkNode[];
}
