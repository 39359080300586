


















import Vue from 'vue';
import echarts from 'echarts';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Builder } from '@/components/basics/charts/echart/builder';
import halloween from '@/assets/themes/echarts/halloween.json';
import phaseA from '@/assets/themes/echarts/phase_a.json';

import {
  AREAS_DATA_STATE,
  HISTORY_CHARTS,
  HISTORY_CHARTS_BUILD_SCHEME,
  HISTORY_CHARTS_DATA,
  SET_HISTORY_DATA,
} from '@/store/history-charts/constants';
import {
  IAreasDataState,
  IHistoryChartsQuery,
  IHistoryDataSet,
  ISeries,
} from '@/store/history-charts/types';
import { ChartOptions } from '@/components/basics/charts/echart/types';
import { EChartManager } from '@/components/basics/charts/echart/manager';
import { getLabelFormat } from '@/store/data/data.types';

echarts.registerTheme('halloween', halloween);
echarts.registerTheme('phase_a', phaseA);

@Component
export default class HistoryChart extends Vue {
  emptyMessage = 'Нет данных!';
  @Prop() query!: any;
  @Prop() autoRefresh?: boolean;

  @Watch('query') onQueryChanged(val: any, old: any) {
    if (val === old || !val) return;
    EChartManager.rebuildSchemeIfShould(val, old, this.buildScheme);
    this.renderChart(val);
  }

  @Ref('chart') chartDiv: any;
  builder: Builder = new Builder();

  @Action(HISTORY_CHARTS_BUILD_SCHEME, { namespace: HISTORY_CHARTS })
  buildScheme!: (query: IHistoryChartsQuery) => void;

  renderChart(query: any) {
    // console.log('!renderChart disposeChart:::!!!!!!');
    // this.builder.disposeChart();
    if (query) {
      this.buildScheme(this.query);
      this.builder.initChart(this.chartDiv, 'phase_a', this.chartOptions);
    }
  }

  chartOptions?: ChartOptions;
  mounted(): void {
    this.chartOptions = {
      toolbox: true,
      dataZoom: true,
      tooltip: true,
    };
    this.builder.disposeChart();
    this.renderChart(this.query);
  }
  beforeDestroy(): void {
    this.builder.disposeChart();
    this.setChartData(new Map<ISeries, IHistoryDataSet>());
  }

  @Getter(AREAS_DATA_STATE, { namespace: HISTORY_CHARTS })
  areasDataState!: IAreasDataState;
  @Action(AREAS_DATA_STATE, { namespace: HISTORY_CHARTS })
  checkAreasDataState!: (data: Map<ISeries, IHistoryDataSet>) => void;
  @Getter(HISTORY_CHARTS_DATA, { namespace: HISTORY_CHARTS })
  chartData?: Map<ISeries, IHistoryDataSet>;
  @Action(SET_HISTORY_DATA, { namespace: HISTORY_CHARTS })
  setChartData!: (data: Map<ISeries, IHistoryDataSet>) => void;

  @Watch('chartData') async onChartData(
    val?: Map<ISeries, IHistoryDataSet>,
    old?: Map<ISeries, IHistoryDataSet>
  ) {
    if (val === old || !val) return;

    if (!this.autoRefresh) {
      // check areas with empty data
      await this.checkAreasDataState(val);
      this.builder.disposeChart();
      this.builder.initChart(this.chartDiv, 'phase_a', this.chartOptions);
      const format = getLabelFormat(this.query.interval);
      await this.builder.updateOptions(val, this.query?.phase, format);
    } else {
      await this.builder.refreshData(val);
    }
  }
}
