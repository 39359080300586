import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { EventStateEnum, Event, EventState } from '@/store/events/types';
import { RootState } from '@/store/types';

const initialState: EventState = {
  state: EventStateEnum.initial,
};

export enum EVENT_CONST {
  ON_PUBLISH = 'onPublish',
  ON_ERROR = 'onError',
}

export const events: Module<EventState, RootState> = {
  namespaced: true,

  //state
  state: initialState,

  // mutations
  mutations: {
    onPublish(state: EventState, payload: Event) {
      state.event = payload;
      state.error = undefined;
    },
    onError(state: EventState, err: Error) {
      state.error = err.message;
      state.event = undefined;
    },
  } as MutationTree<EventState>,

  // actions
  actions: {
    publish(context, payload) {
      context.commit(EVENT_CONST.ON_PUBLISH, payload);
    },
  } as ActionTree<EventState, RootState>,

  // getters
  getters: {
    event(state): Event | undefined {
      const { event } = state;
      return event;
    },
  } as GetterTree<EventState, RootState>,
};
