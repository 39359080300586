


























































import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import Vue from 'vue';
import { Getter } from 'vuex-class';
import { TableCol } from '@/components/basics/table/basic-table.types';
import RowPopupContainer from '@/components/row-popup-container/index.vue';
import { AlgorithmScheme } from '@/store/algorithms/algorithms.types';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';
import { RestApiService } from '@/services';
import { ALGORITHM_CONST } from '@/store/algorithms';
import { PRELOADER_STATE } from '@/store/abstract/preloader';
import { IPreloader } from '@/store/abstract/preloader/types';
import TableSorter from './TableSorter.vue';
import TablePagination from './TablePagination.vue';
import TableFilter from './TableFilter.vue';
import { getColumnFilter } from './utils';
import { FiltersState, FiltersMap } from './filter.types';
import { RouterService } from '@/services/router/RouterService';
import { REPORT_DATA, SUBSTATION_EQUIPMENT } from '@/store/report-data/constants';
import { PhaseConverter } from '@/utils/PhaseConverter';
import { tableFilters } from '@/components/basics/table/filters';

const { TAB_REFS, DICTIONARIES } = DICTIONARIES_CONST;

@Component({
  filters: {
    ...tableFilters,
  },
  components: {
    'row-popup-container': RowPopupContainer,
    'table-sorter': TableSorter,
    'table-filter': TableFilter,
    'table-pagination': TablePagination,
  },
})
export default class BasicTable extends Vue {
  @Ref('table') table: any;

  @Prop() total?: number;
  @Prop() columns?: TableCol[];
  @Prop() page?: any[];
  @Prop() showHeader?: boolean;
  @Prop() showPagination?: boolean;
  @Prop() scheme?: AlgorithmScheme;
  @Prop() isState?: boolean;

  hasComment = false;
  stateComment = ({ commentsMap }: any, { property }: any) => {
    if (!commentsMap || !property) {
      this.hasComment = false;
      return null;
    }
    const comment = commentsMap.get(property);
    if (!comment) {
      this.hasComment = false;
      return null;
    }
    this.hasComment = true;
    return comment;
  };

  isEmptyOrSpaces = (str: string): boolean => {
    return !str || str.trim() === '';
  };
  getIcon(column: any, scope: any) {
    const cMap = scope?.row?.colorMap;
    const prop = column?.property;
    let base = 'icon-base ';

    // todo kill hardcode, tmpl hack
    if (column.Type === 'StateEnum') {
      const val = scope?.row[prop];
      if (val === 0) {
        return `${base} las la-check-double`;
      } else if (val === 1) {
        return `${base} las las la-times`;
      } else if (val > 0 && val < 1) {
        return `${base} las la-exclamation-triangle`;
      }
    }

    if (!cMap || this.isEmptyOrSpaces(prop) || column.Type !== 'Enum') return base;
    const color = cMap.get(prop);
    switch (color) {
      case 'Error':
        return (base += 'las la-times');
      case 'Warning0':
        return (base += 'las la-exclamation-triangle');
      case 'Warning1':
        return (base += 'las la-exclamation-triangle');
      case 'Normal':
        return (base += 'las la-check-double');
      default:
        return '';
    }
  }

  colorStyler(column: any, scope: any) {
    const cMap = scope?.row?.colorMap;
    const prop = column?.property;

    // todo kill hardcode, tmpl hack
    if (column.Type === 'StateEnum') {
      const val = scope?.row[prop];
      if (val === 0) {
        return 'cell-normal';
      } else if (val === 1) {
        return 'cell-error';
      } else if (val > 0 && val < 1) {
        return 'cell-warning1';
      }
    }

    if (!cMap || this.isEmptyOrSpaces(prop)) return '';
    const color = cMap.get(prop);
    switch (color) {
      case 'Error':
        return 'cell-error';
      case 'Warning0':
        return 'cell-warning1';
      case 'Warning1':
        return 'cell-warning2';
      case 'Normal':
        return 'cell-normal';
      default:
        return '';
    }
  }

  formatCell(column: any, scope: any) {
    if (!scope?.row[column?.property] || scope?.row[column?.property] === 'NaN') return;
    return column?.formatter
      ? column.formatter(scope?.row[column?.property])
      : scope?.row[column?.property];
  }

  rest = new RestApiService();

  selectedItem: any = {};
  selectedRow: any = {};

  filtersMap: FiltersMap = {};
  filtersState: FiltersState = {
    filterMin: {},
    filterMax: {},
    filterOptions: [],
    filterQueryOptions: [],
    numberFilterOptions: [],
    stringFilterOptions: [],
    filterGuidOptions: [],
  };

  @Getter(PRELOADER_STATE, { namespace: ALGORITHM_CONST.ALGORITHMS })
  preloader?: IPreloader;

  get preloaderState(): boolean {
    return this.preloader?.isBusy ?? false;
  }
  get preloaderMessage(): string {
    return this.preloader?.message ?? '';
  }

  @Getter(TAB_REFS, { namespace: DICTIONARIES })
  tabRefs!: any[];

  get needShowHeader(): boolean {
    return this.showHeader ?? true;
  }

  get needShowPagination(): boolean {
    return this.showPagination ?? true;
  }

  get refsWithColumnsAndQuery() {
    return (
      this.tabRefs &&
      this.columns && { refs: this.tabRefs, columns: this.columns, query: this.$route.query.filter }
    );
  }

  get toolTooltipMsg() {
    return this.isState ? 'Переход к устройству' : 'Детали';
  }

  @Watch('refsWithColumnsAndQuery', { immediate: true }) onRefsColumnsChange(val: any) {
    if (val) {
      const queryFilters =
        val.query &&
        Object.fromEntries(JSON.parse(decodeURIComponent(val.query)).map((x: any) => [x.name, x]));

      val.columns.forEach((column: any) => {
        if (column.mandatory && !Array.isArray(column.dbProps)) {
          const currentFilter = queryFilters && queryFilters[column.dbProps];
          const oldFilter: any = this.filtersMap[column.dbProps];

          Vue.set(
            this.filtersMap,
            column.dbProps,
            getColumnFilter(val, column, oldFilter, currentFilter)
          );
        }
      });
    }
  }

  @Getter(SUBSTATION_EQUIPMENT, { namespace: REPORT_DATA })
  equipList?: any[];

  async onRowClickHandler(rowData: any) {
    if (this.isState) {
      const { phase, true_asset_uuid } = rowData;
      const equip = this.equipList?.find((e) => e.asset_uuid === true_asset_uuid);
      if (!equip) return;
      const { equip_id } = equip;
      await RouterService.Route(`${equip_id}?phase=${PhaseConverter.getLabel(phase)}`);
      return;
    }

    this.page
      ?.filter((x) => x !== rowData)
      .forEach((row: any) => {
        this.table.toggleRowExpansion(row, false);
      });
    this.table.toggleRowExpansion(rowData);
  }
}
