import { RestApiService } from '@/services';
import { splitChunks } from '@/store/helpers';
import { buildDataSet, mapToArray } from '@/store/history-data/history-data.extensions';
import { MaxAvgMin, TR } from '@/store/history-data/history-data.types';
import _ from 'lodash';

export interface IDataItem {
  key: string;
  data: any[];
}

export interface IHistoryDataManager {
  add: (values: string[], url: string, interval: string, scale?: number, format?: any) => void;
  legendData: Map<string, MaxAvgMin>;
  resetDataMap: () => void;
}

export class HistoryDataManager implements IHistoryDataManager {
  private _rest = new RestApiService();
  private _items: IDataItem[] = [];
  private _legendData = new Map<string, MaxAvgMin>();
  private _dataMap = new Map<string, object>();

  public resetDataMap() {
    this._dataMap = new Map<string, object>();
  }

  private static killExist(items: IDataItem[], key: string) {
    const exist = items.find((i) => i.key === key);
    if (exist) {
      const index = items.indexOf(exist);
      items.splice(index, 1);
    }
  }

  get legendData() {
    return this._legendData ?? [];
  }

  private getNumbs(data: any[]): number[] {
    return data.map((i) => Number(i));
  }
  public updLegendItem(interval: string, data: any[], key: string, values: string[]) {
    if (!values?.length) return;

    if (this._legendData.has(key)) {
      this._legendData.delete(key);
    }

    if (interval.toString() === 'ms') {
      const ext = data.map((d) => d[values[0]]);
      this._legendData.set(key, {
        max: _.max(ext),
        avg: _.mean(this.getNumbs(ext)),
        min: _.min(ext),
      });
    } else {
      const _max = data.map((d) => d[values[2]]);
      const _avg = data.map((d) => d[values[1]]);
      const _min = data.map((d) => d[values[0]]);
      this._legendData.set(key, {
        max: _.max(_max),
        avg: _.mean(this.getNumbs(_avg)),
        min: _.min(_min),
      });
    }
  }

  public async add(values: string[], url: string, interval: string, scale?: number, format?: any) {
    try {
      const key = values.join('-');
      let response;
      try {
        response = await this._rest.get(url);
      } catch (e) {
        HistoryDataManager.killExist(this._items, key);
        return await mapToArray(this._dataMap);
      }
      const split = splitChunks(response.data);
      const dataSet = buildDataSet(split, interval as TR, values, this._dataMap, scale, format);
      HistoryDataManager.killExist(this._items, key);
      this._items.push({ key, data: dataSet ?? [] });
      if (dataSet) {
        this.updLegendItem(interval, dataSet, key, values);
      }
      const d2 = await mapToArray(this._dataMap);
      return d2;
    } catch (e) {
      return e;
    }
  }
}
