import { IPropertyScheme } from '@/store/history-data/history-data.types';
import { IHistoryChartsQuery } from '@/store/history-charts/types';
import { getHistoryChartValue, getSeriesProps } from '@/store/history-charts/extensions';
import moment from 'moment';
import _ from 'lodash';

export const transformValues = (values: any) => {
  return _.orderBy([...values], ['time'], ['desc']).map((i) => ({
    ...i,
    ['time']: moment(i['time']).format('DD/MM/YYYY HH:mm:ss.S'),
  }));
};

const defineKey = (
  key: string,
  { name }: IPropertyScheme,
  { interval }: IHistoryChartsQuery
): string => {
  if (key === 'ts') return 'time';
  if (interval === 'ms') {
    return name;
  }
  return name + '_' + key;
};

export const transformDataset = (
  dataset: any[],
  query: IHistoryChartsQuery,
  prop: IPropertyScheme,
  map: Map<string, any>
) => {
  return dataset.map((d) => {
    let line = {};
    let datetimeKey;
    getSeriesProps(query).forEach((p) => {
      const key = defineKey(p, prop, query);
      if (p === 'ts') {
        datetimeKey = moment(getHistoryChartValue(query, p, d, prop)).format(
          'DD/MM/YYYY HH:mm:ss.SSS'
        );
      }
      line = { ...line, [key]: getHistoryChartValue(query, p, d, prop) };
    });
    if (datetimeKey) {
      const v = map.get(datetimeKey);
      if (v) {
        line = { ...v, ...line };
      }
      map.set(datetimeKey, line);
    }
    return line;
  });
};

const getField = ({ name }: IPropertyScheme, { interval }: IHistoryChartsQuery) => {
  if (interval === 'ms') {
    return [name];
  }
  return [`${name}_max`, `${name}_avg`, `${name}_min`];
};

const getHeader = ({ name, unit }: IPropertyScheme, { interval }: IHistoryChartsQuery) => {
  if (interval === 'ms') {
    return [`${name}${unit ? ', ' + unit : ''}`];
  }
  return [
    `${name}_Мин${unit ? ', ' + unit : ''}`,
    `${name}_Срд${unit ? ', ' + unit : ''}`,
    `${name}_Макс${unit ? ', ' + unit : ''}`,
  ];
};

export const getFieldsAndHeaders = ({
  query,
  props,
}: {
  query: IHistoryChartsQuery;
  props: IPropertyScheme[];
}) => {
  const clProps = props.filter((i: any) => i.order > 0);
  const fields = ['time', ...clProps.map((i) => getField(i, query))].flat();
  const headers = ['Время', ...clProps.map((i) => getHeader(i, query))].flat();
  return { fields, headers };
};
