import router from '@/router';

export class RouterService {
  public static async Route(route: string) {
    try {
      await router.push(route);
    } catch (e) {
      console.log(e.message);
    }
  }

  public static async Query<T>(query: T) {
    try {
      await router.push({ query: query as any });
    } catch (e) {
      console.log(e.message);
    }
  }
}
