import { MutationTree } from 'vuex';
import {
  PRELOADER_CHANGE_STATE_ERROR,
  PRELOADER_CHANGE_STATE_SUCCESS,
} from '@/store/abstract/preloader/constants';
import { IPreloader, IPreloaderState } from '@/store/abstract/preloader/types';

const mutations: MutationTree<IPreloaderState> = {
  [PRELOADER_CHANGE_STATE_SUCCESS]: (state, payload: IPreloader) => {
    state.preloader = payload;
    state.preloaderError = false;
  },
  [PRELOADER_CHANGE_STATE_ERROR]: (state, payload) => {
    state.preloaderError = payload;
    state.preloader = {
      isBusy: false,
      message: '',
    };
  },
};

export default mutations;
