import { MutationTree } from 'vuex';

import { SensorsState } from './types';
import { SENSORS_STORE_CONST } from './constants';

export const mutations: MutationTree<SensorsState> = {
  [SENSORS_STORE_CONST.TREE_ITEMS_EXPAND](state: SensorsState, payload: Set<number | string>) {
    state.treeExpanded = new Set([...state.treeExpanded, ...payload]);
    localStorage.setItem('sensorsTree.expanded', JSON.stringify([...state.treeExpanded]));
  },
  [SENSORS_STORE_CONST.TREE_ITEMS_COLLAPSE](state: SensorsState, payload: Set<number | string>) {
    state.treeExpanded = new Set([...state.treeExpanded].filter((x) => !payload.has(x)));
    localStorage.setItem('sensorsTree.expanded', JSON.stringify([...state.treeExpanded]));
  },
  [SENSORS_STORE_CONST.TREE_ITEMS_COLLAPSE_ALL](state: SensorsState) {
    state.treeExpanded = new Set();
    localStorage.setItem('sensorsTree.expanded', JSON.stringify([...state.treeExpanded]));
  },
  [SENSORS_STORE_CONST.UPDATE_SENSORS](state: SensorsState, payload: SensorsState['sensors']) {
    state.sensors = payload;
  },
  [SENSORS_STORE_CONST.UPDATES_REFERENCES](
    state: SensorsState,
    payload: SensorsState['references']
  ) {
    state.references = payload;
  },
  [SENSORS_STORE_CONST.UPDATE_EQUIPMENTS](
    state: SensorsState,
    payload: SensorsState['equipments']
  ) {
    state.equipments = payload;
  },
  [SENSORS_STORE_CONST.UPDATE_SENSORS_STATES](
    state: SensorsState,
    payload: SensorsState['sensorsStates']
  ) {
    state.sensorsStates = payload;
  },
};
