import { RegInfoItem, RegInfoTab } from '@/store/reg-info/reg-info.types';

export class RegInfoMapper {

  private static getScaledValue({ Value, Type, Scale}: any): string {
    try {
      if(!Scale){
        return Value;
      }
      if( Type !== 'Float'){
        return Value;
      }
      const sVal = Number(Value);
      if(isNaN(sVal)){
        return Value;
      }
      const scale = Number(Scale);
      if(isNaN(scale)){
        return Value;
      }
      return `${sVal * scale}`;
    } catch (e) {
      console.log(e.message);
    }
    return Value;
  }

  private static getItems(props: any): RegInfoItem[] {
    if (!props?.length) return [];

    return props.map((p: any) => {
      return {
        order: p.Value ? Number(p.Value) : undefined,
        key: p.Uid,
        name: p.Name,
        note: p.Notes,
        type: p.Type,
        value: this.getScaledValue(p),
        unit: p.Unit,
        uid: p.Uid,
        max: p.Max !== undefined && p.Max !== null ? Number(p.Max) : undefined,
        min: p.Min !== undefined && p.Min !== null ? Number(p.Min) : undefined,
      };
    });
  }

  public static transform(data: any): RegInfoTab[] {
    if (!data?.length) return [];
    return data.map((d: any) => {
      return {
        key: d.name,
        title: d.name,
        phase: d.phase,
        typeUid: d.type_uid,
        equipId: d.equip_id,
        assetId: d.asset_id,
        assetUid: d.asset_uid,
        items: RegInfoMapper.getItems(d.props),
      } as RegInfoTab;
    });
  }
}
