import { ActionTree } from 'vuex';
import moment from 'moment';

import { RootState } from '@/store/types';
import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { splitChunks } from '@/store/helpers';

import { SensorsState, Sensor, Reference, Equipment, Source } from './types';
import { SENSORS_STORE_CONST } from './constants';

const sensorsPath = getApiUrl(TR_TYPE.HTTP, '/sensors');
const sourcesPath = getApiUrl(TR_TYPE.HTTP, '/sources');
const equipmentsPath = getApiUrl(TR_TYPE.HTTP, '/equipment');
const referencesPath = getApiUrl(TR_TYPE.HTTP, '/references');
const assetsPath = getApiUrl(TR_TYPE.HTTP, '/assets');
const sensorsStatePath = getApiUrl(TR_TYPE.HTTP, '/click-house/sensors-state');
const rest = new RestApiService();

const protocolReferencesNames = [
  'protocol_error_package_104',
  'protocol_error_package',
  'protocol_error_package_sv',
  'protocol_error_package_modbus',
  'protocol_error_package_light_104',
  'protocol_error_package_light',
  'protocol_error_package_light_sv',
  'protocol_error_package_light_modbus',
  'protocol_error_package_ntp',
];

function getStateStatus(sensorState: any) {
  if (sensorState) {
    if (sensorState.protocol === 'МЭК 61850-8-1') {
      return sensorState.connection_8_1 === 1
        ? 'off'
        : sensorState.data_exchange_8_1 === 1
        ? 'warn'
        : 'on';
    } else if (sensorState.protocol === 'МЭК 61850-9-2') {
      return sensorState.connection_9_2 === 1 ? 'off' : 'on';
    } else if (sensorState.protocol === 'МЭК 60870-5-104') {
      return sensorState.connection_104 === 1 ? 'off' : 'on';
    } else if (sensorState.protocol === 'Modbus') {
      return sensorState.connection_modbus === 1 ? 'off' : 'on';
    } else {
      return 'on';
    }
  } else {
    return 'on';
  }
}

export const actions: ActionTree<SensorsState, RootState> = {
  [SENSORS_STORE_CONST.GET_ALL_DATA]: async (context) => {
    const [sensors, sources, equipments, journalsReferences] = await Promise.all([
      rest.get<Sensor[]>(`${sensorsPath}/all`),
      rest.get<Source[]>(`${sourcesPath}/all`),
      rest.get<Equipment[]>(`${equipmentsPath}/all`),
      rest.put<Reference[]>(`${referencesPath}/by-names`, protocolReferencesNames),
    ]);

    if (
      sensors.status === 200 &&
      sources.status === 200 &&
      equipments.status === 200 &&
      journalsReferences.status === 200
    ) {
      const sourcesTypes = [...new Set(sources.data.map((x) => x.typeId))];

      const sourcesReferences = await rest.put<Reference[]>(
        `${referencesPath}/by-ids`,
        sourcesTypes
      );

      if (sourcesReferences.status === 200) {
        sources.data.forEach((x) => {
          x.equipment = equipments.data.find((y) => x.equipId === y.id) as any;
        });

        sensors.data.forEach((x) => {
          x.sources = sources.data.filter((y) => x.id === y.sensorId);
        });

        context.commit(
          SENSORS_STORE_CONST.UPDATE_SENSORS,
          sensors.data.filter((x) => x.protocol?.ied)
        );
        context.commit(SENSORS_STORE_CONST.UPDATE_EQUIPMENTS, equipments.data);
        context.commit(SENSORS_STORE_CONST.UPDATES_REFERENCES, [
          ...journalsReferences.data,
          ...sourcesReferences.data,
        ]);
      }
    }
  },

  [SENSORS_STORE_CONST.GET_SENSORS]: async (context) => {
    const [sensors, sources] = await Promise.all([
      rest.get<Sensor[]>(`${sensorsPath}/all`),
      rest.get<Source[]>(`${sourcesPath}/all`),
    ]);

    if (sensors.status === 200 && sources.status === 200) {
      sources.data.forEach((x) => {
        x.equipment = (context.state.equipments || []).find((y) => x.equipId === y.id) as any;
      });

      sensors.data.forEach((x) => {
        x.sources = sources.data.filter((y) => x.id === y.sensorId);
      });

      context.commit(
        SENSORS_STORE_CONST.UPDATE_SENSORS,
        sensors.data.filter((x) => x.protocol?.ied)
      );
    }
  },

  [SENSORS_STORE_CONST.GET_SENSORS_STATES]: async (context) => {
    const states = await rest.get(`${sensorsStatePath}`);

    if (states.status === 200) {
      const sensorsStates = Object.fromEntries(
        splitChunks(states.data).map((x) => [
          `${x.ied_ip}${x.ied_port !== -1 ? `:${x.ied_port}` : ''}`,
          { status: getStateStatus(x), time: moment(x.connection_ts_nano / 1000000) },
        ])
      );

      context.commit(SENSORS_STORE_CONST.UPDATE_SENSORS_STATES, sensorsStates);
    }
  },

  [SENSORS_STORE_CONST.SAVE_SENSOR]: async (
    context,
    payload: { sensor: Sensor; currentSensors: Sensor[] | null; comTradeSensors: Sensor[] | null }
  ) => {
    const { sensor, currentSensors, comTradeSensors } = payload;

    const comTradeSensor = comTradeSensors?.find((x) =>
      currentSensors?.some((y) => y.protocol.ied === x.protocol.ied)
    );

    const toEditSensors = [...new Set(sensor.sources.map((x) => x.sensor?.uuid))]
      .map((x) => context.state.sensors?.find((y) => y.uuid === x))
      .map((x) => {
        const sources = sensor.sources.filter((y) => y.sensor?.id === x?.id);

        return {
          ...x,
          protocol: {
            ...sensor.protocol,
            appid: sources?.[0].sensor?.protocol.appid,
          },
          sources: sources.map((y) => ({ ...y, sensor: undefined })),
        };
      });

    const toAllEdit = [
      ...toEditSensors,
      ...(comTradeSensor
        ? [
            {
              ...comTradeSensor,
              protocol: {
                ...comTradeSensor?.protocol,
                ied: sensor.protocol.ied,
                ip: sensor.protocol.ip,
                port: sensor.protocol.port,
              },
            },
          ]
        : []),
    ];

    return await Promise.all(
      toAllEdit.map((x) => rest.put<Sensor>(`${sensorsPath}`, { ...x }))
    );
  },

  [SENSORS_STORE_CONST.SAVE_SENSORS]: async (context, payload: { sensors: Sensor[] }) => {
    const { sensors } = payload;

    return await Promise.all(
      sensors.map((x) => rest.put<Sensor>(`${sensorsPath}`, { ...x }))
    );
  },

  [SENSORS_STORE_CONST.SAVE_SENSOR_AND_ASSETS]: async (
    context,
    payload: { sensor: Sensor; assets: any[] }
  ) => {
    const { sensor, assets } = payload;

    return await Promise.all([
      rest.put<Sensor>(`${sensorsPath}`, { ...sensor }),
      rest.put<any[]>(`${assetsPath}/all`, assets),
    ]);
  },
};
