import { Module } from 'vuex';

import { RootState } from '@/store/types';

import { LayoutState } from './types';
import { mutations } from './mutations';
import { getters } from './getters';

const initialState: LayoutState = {
  drawerVisible: JSON.parse(localStorage.getItem('layout.drawerVisible') ?? 'true'),
  drawerWidth: JSON.parse(localStorage.getItem('layout.drawerWidth') ?? '300'),
};

export const layout: Module<LayoutState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  getters,
};
