import './register-hooks'; // must be before other imports

import Vue from 'vue';
// locale
import lang from 'element-ui/lib/locale/lang/ru-RU';
import locale from 'element-ui/lib/locale';

import VueSocketIOExt from 'vue-socket.io-extended';
import Element from 'element-ui';
import * as Sentry from '@sentry/browser';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';

import { SubjectSingleton } from '@/socket/subject/subject.manager';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './services/interceptors';
import { addSubject, socketClient } from './socket';
import './style/element-variables.scss';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import { ENV_CONST, EnvManager } from '@/env';

Vue.use(PerfectScrollbar);

pdfMake.vfs = pdfFonts.pdfMake.vfs;
Sentry.init({
  dsn: EnvManager.getValue(ENV_CONST.SENTRY_DSN),
});
Vue.use(VueSocketIOExt, socketClient);
locale.use(lang);
Vue.use(Element);
Vue.config.productionTip = false;

new Vue({
  sockets: {
    connect() {
      console.log('socket connected!');
      const sbj = SubjectSingleton?.instance()?.currentSubject;
      if (sbj) {
        console.log('Socket subject:', sbj);
        addSubject(sbj);
        console.log('Re-subscribed successfully!');
      }
    },
  },
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
