






import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ArrowButton extends Vue {
  @Prop() direction!: string;
  @Prop() disabled!: boolean;
  get dirPostfix(): string {
    if (this.direction === 'left') {
      return 'prev';
    }
    if (this.direction === 'right') {
      return 'next';
    }
    return '';
  }
  get arrowClass(): string {
    return `el-icon-arrow-${this.direction} arrow-btn`;
  }

  handleClick() {
    this.$emit('arrowClick', this.direction);
  }
}
