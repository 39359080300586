import { TR } from '@/store/history-data/history-data.types';
import { buildDate, updateTimeOffset } from '@/utils/datetime/DateTimeCorrector';
import _ from 'lodash';
import moment from 'moment';
import { MOMENT_QUERY_FORMAT } from '@/utils/datetime/DateTimeRangePresets';

export const cleanUpDataMap = (values: string[], dataMap: Map<string, object>) => {
  for (const v of values) {
    for (const value of dataMap.values()) {
      const exist = Object.keys(value).find((k) => k === v);
      if (exist) {
        delete (value as any)[v];
      }
    }
  }
};

export const updateDataMap = (ts: Date, data: object, dataMap: Map<string, object>) => {
  const key = moment(ts).format(MOMENT_QUERY_FORMAT.dateToMs);
  const itemData = dataMap.get(key);
  if (!itemData) {
    dataMap.set(key, { ...data });
  } else {
    dataMap.set(key, { ...itemData, ...data });
  }
};

export const mapToArray = async (dataMap: Map<string, object>): Promise<any[]> => {
  return await new Promise((resolve, reject) => {
    try {
      const data = [];
      for (const [key, value] of dataMap.entries()) {
        data.push({ time: new Date(key), ...value });
      }
      const sorted = _.orderBy(data, 'time', ['asc']);
      resolve(sorted);
    } catch (e) {
      reject(e);
    }
  });
};
const withScale = (value?: any, scale?: number, format?: any) => {
  if (!scale || isNaN(scale) || !value || isNaN(value)) return value;
  const v = value * scale;
  return format && !isNaN(format) && format > 0 ? v.toFixed(format) : v;
};
export const buildBunch = (
  point: any,
  int: TR,
  values: string[],
  dataMap: Map<string, object>,
  scale?: number,
  format?: any
) => {
  if (int === 'ms') {
    const { ts, v } = point;
    const r = { time: updateTimeOffset(new Date(ts)), [values[0]]: withScale(v, scale, format) };
    const { time, ...rest } = r;
    updateDataMap(time, rest, dataMap);
    return r;
  }
  const { d, h, m, s, ...rest } = point;
  const ts = updateTimeOffset(buildDate(d, h, m, s));
  let res = {};
  let index = -1;
  for (const value of values) {
    const ws = withScale(Object.values(rest)[++index], scale, format);
    res = { ...res, [value]: ws };
  }
  const r2 = { time: ts, ...res };
  const { time, ...rest2 } = r2;
  updateDataMap(time, rest2, dataMap);
  return r2;
};

export const buildDataSet = (
  dataSet: any[],
  interval: TR,
  values: string[],
  dataMap: Map<string, object>,
  scale?: number,
  format?: any
) => {
  if (!dataSet?.length) return;
  cleanUpDataMap(values, dataMap);
  const data = dataSet.map((d) => buildBunch(d, interval, values, dataMap, scale, format));
  return _.orderBy(data, 'time', ['asc']);
};
