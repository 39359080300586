import { Module } from 'vuex';
import { IDataGetterState } from '@/store/abstract/data-getter/types';
import { RootState } from '@/store/types';
import {
  DATA,
  DATASET,
  GET_DATA,
  GET_DATA_FAIL,
  GET_DATA_SUCCESS,
  GET_DATASET,
  GET_DATASET_FAIL,
  GET_DATASET_SUCCESS,
} from '@/store/abstract/data-getter/constants';
import { RestApiService } from '@/services';
import { splitChunks } from '@/store/helpers';
import {
  Preloader,
  PRELOADER_DICT,
  preloaderActions,
  preloaderGetters,
  preloaderInitialState,
  preloaderMutations,
} from '@/store/abstract/preloader';
import { QueryBuilder } from '@/utils/QueryBuilder';
import { IPropertyScheme } from '@/store/history-data/history-data.types';
import moment from 'moment';
import { resetTimeOffset, updateTimeOffset } from '@/utils/datetime/DateTimeCorrector';
const rest = new RestApiService();

export const dataGetterInitialState: IDataGetterState = {
  data: [],
  dataError: false,
  dataset: new Map<string, unknown[]>(),
  datasetError: false,
  ...preloaderInitialState,
};

export const dataGetter: Module<IDataGetterState, RootState> = {
  actions: {
    ...preloaderActions,
    [GET_DATASET]: async (context, payload: IPropertyScheme[]) => {
      try {
        await Preloader.start(context, PRELOADER_DICT.DATA_LOADING);
        const dataMap = new Map<string, unknown[]>();
        const dataGetter = async (scheme: IPropertyScheme) => {
          const subQuery = {
            select: scheme.select,
            algorithm: scheme.algorithm,
            calculator_uuid: scheme.calculatorUid,
            interval: 'ms',
            seconds: 20,
            [`qd_${scheme.select}`]: 0,
          };
          const url = context.state.path;
          const response = await rest.post(url ?? '', subQuery);
          const data = splitChunks(response.data);
          if (data?.length) {
            // dataMap.set(s.calculatorName ?? 'avg_freq', data);
            dataMap.set(`${scheme.select},${scheme.calculatorUid}`, data);
          }
        };
        await Promise.all(payload.map(dataGetter));
        context.commit(GET_DATASET_SUCCESS, dataMap);
      } catch (e) {
        context.commit(GET_DATASET_FAIL, e);
      } finally {
        await Preloader.stop(context);
      }
    },
    [GET_DATA]: async (context, payload) => {
      try {
        await Preloader.start(context, PRELOADER_DICT.DATA_LOADING);
        const { start, end, select, phase, ...props } = payload;
        let pl = {
          ...props,
          select,
          // phase,
          start: moment(resetTimeOffset(new Date(start))).format('YYYY-MM-DD HH:mm:ss'),
          end: moment(resetTimeOffset(new Date(end))).format('YYYY-MM-DD HH:mm:ss'),
        };
        // TODO KILL HARD CODE
        if (select === 'value_f') {
          pl = { ...pl, [`qd_${select}`]: 0 };
        }
        const url = context.state.path;
        const response = await rest.post(url ?? '', pl);
        const data = splitChunks(response.data);
        context.commit(GET_DATA_SUCCESS, data);
        return data;
      } catch (e) {
        context.commit(GET_DATA_FAIL, e);
      } finally {
        await Preloader.stop(context);
      }
    },
  },
  mutations: {
    ...preloaderMutations,
    [GET_DATASET_SUCCESS]: (state, payload) => {
      state.dataset = payload;
      state.datasetError = false;
    },
    [GET_DATASET_FAIL]: (state, payload) => {
      state.dataset = new Map<string, unknown[]>();
      state.dataError = payload;
      console.log(payload.message);
    },
    [GET_DATA_SUCCESS]: (state, payload) => {
      state.data = payload;
      state.dataError = false;
    },
    [GET_DATA_FAIL]: (state, payload) => {
      state.data = [];
      state.dataError = payload;
    },
  },
  getters: {
    ...preloaderGetters,
    [DATA]: ({ data }) => data,
    [DATASET]: ({ dataset }) => dataset,
  },
};
