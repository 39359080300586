import moment from 'moment';

import { ITab } from '@/store/tabs/tabs.types';
import AlgorithmView from '@/components/items-tab/panes/algorithms/algorithm.view.vue';
import EquipStateTNView from '@/components/items-tab/panes/equip-state/EquipStateTN.vue';
import EquipStateTTView from '@/components/items-tab/panes/equip-state/EquipStateTT.vue';
import EquipStateRPNView from '@/components/items-tab/panes/equip-state/EquipStateRPN.vue';
import EquipStateReactorView from '@/components/items-tab/panes/equip-state/EquipStateReactor.vue';
import EquipStateATView from '@/components/items-tab/panes/equip-state/EquipStateAT.vue';
import EquipStateInputHVView from '@/components/items-tab/panes/equip-state/EquipStateInputHV.vue';
import EquipStateInputMVView from '@/components/items-tab/panes/equip-state/EquipStateInputMV.vue';
import EquipStateInputLVView from '@/components/items-tab/panes/equip-state/EquipStateInputLV.vue';
import EquipStateBreakerView from '@/components/items-tab/panes/equip-state/EquipStateBreaker.vue';
import EquipStateBreakerV2View from '@/components/items-tab/panes/equip-state/EquipStateBreakerV2.vue';
import EquipStateWTView from '@/components/items-tab/panes/equip-state/EquipStateWT.vue';
import EquipStateCouplingCapacitor from '@/components/items-tab/panes/equip-state/EquipStateCouplingCapacitor.vue';
import EquipStateDisconnectorView from '@/components/items-tab/panes/equip-state/EquipStateDisconnector.vue';
import EquipStateDisconnectorV2View from '@/components/items-tab/panes/equip-state/EquipStateDisconnectorV2.vue';
import EquipStateOPN from '@/components/items-tab/panes/equip-state/EquipStateOPN.vue';
import { AlgorithmScheme, commonQueryProps } from '@/store/algorithms/algorithms.types';
import { LevelType, TableCol } from '@/components/basics/table/basic-table.types';
import { equipmentTypes } from '@/utils/equipmentTypes';
import { PropertyType } from '@/store/algorithms/algorithms.types';

const dateTimeFormatter = (col: any) => {
  if (col.Type !== 'DateTime') return;
  return function(value: string) {
    return moment(value).format('HH:mm:ss.S DD-MM-YYYY');
  };
};

export const buildColumns = (cols: any[]): TableCol[] => {
  return cols?.map((c) => {
    const { Name, Unit, Notes, Chart, Order, Mandatory, Sort, ...rest } = c;
    const hAdder = Unit ? `, ${Unit}` : '';
    return {
      header: Notes + hAdder,
      key: Name,
      dbProps: Name,
      property: Name,
      mandatory: Mandatory,
      tabLink: Sort,
      sort: Sort,
      order: Order ?? -1,
      level: Chart ? (Chart as LevelType) : undefined,
      unit: Unit,
      notes: Notes,
      formatter: dateTimeFormatter(c),
      ...rest,
    };
  });
};

const findMax = (words: string[]): number => {
  let max = 0;
  for (const w of words) {
    if (w.length > max) max = w.length;
  }
  return max;
};

const setColumnWidth = (col: TableCol) => {
  const words = col?.header?.split(' ');
  if (!words?.length) return;
  col.width = Math.max(findMax(words) * 10 + 70, 100);
};

export const setColumnsWidth = (scheme: AlgorithmScheme) => {
  scheme?.columns?.forEach((c) => setColumnWidth(c));
};

export const buildScheme = (data: any): AlgorithmScheme => {
  const scheme: AlgorithmScheme = {
    moduleUid: data.module_uid,
    assetUid: data.asset_uid,
    phaseLabel: data.phase,
    phaseValue: data.flags,
    columns: buildColumns(data.props),
    dbTableName: data.table_name,
    name: data.page_name,
    socket: '',
    sorter: {
      dir: data?.Sort?.toString()?.toUpperCase(),
      property: 'datetime',
      type: 'date' as PropertyType,
    },
    queryProps: [...commonQueryProps],
    inputProps: data.module_props?.Input,
  };
  setColumnsWidth(scheme);
  return scheme;
};

export const extractTabs = (data: any[], equipmentType: string): ITab[] => {
  try {
    return data?.map((d: any, i) => {
      return {
        key: d.order.toString(),
        name: d.page_name,
        component:
          d.order >= 400 && d.order < 422
            ? EquipStateTNView
            : d.order >= 430 && d.order < 432
            ? EquipStateReactorView
            : d.order == 441
            ? EquipStateATView
            : d.order > 440 && equipmentType === equipmentTypes.ATrWindingHV
            ? EquipStateInputHVView
            : d.order > 440 && equipmentType === equipmentTypes.ATrWindingMV
            ? EquipStateInputMVView
            : d.order > 440 && equipmentType === equipmentTypes.ATrWindingLV
            ? EquipStateInputLVView
            : d.order > 440 && equipmentType === equipmentTypes.Breaker
            ? EquipStateBreakerView
            : d.order > 440 && equipmentType === equipmentTypes.BreakerV2
            ? EquipStateBreakerV2View
            : d.order > 440 && equipmentType === equipmentTypes.WaveTrap
            ? EquipStateWTView
            : d.order > 440 && equipmentType === equipmentTypes.RatioTapChanger
            ? EquipStateRPNView
            : d.order > 440 && equipmentType === equipmentTypes.Disconnector
            ? EquipStateDisconnectorView
            : d.order > 440 && equipmentType === equipmentTypes.DisconnectorV2
            ? EquipStateDisconnectorV2View
            : d.order >= 440 && equipmentType === equipmentTypes.OPN //state module order always higher than 440
            ? EquipStateOPN
            : d.order >= 440 && equipmentType === equipmentTypes.CouplingCapacitor //state module order always higher than 440
            ? EquipStateCouplingCapacitor
            : d.order < 400
            ? AlgorithmView
            : EquipStateTTView,
        type:
          d.order >= 400 && d.order < 422
            ? 'pt_state'
            : d.order >= 430 && d.order < 432
            ? 'reactor_state'
            : d.order == 441
            ? 'at_state'
            : d.order > 440 && equipmentType === equipmentTypes.ATrWindingHV
            ? 'input_hv_state'
            : d.order > 440 && equipmentType === equipmentTypes.ATrWindingMV
            ? 'input_mv_state'
            : d.order > 440 && equipmentType === equipmentTypes.ATrWindingLV
            ? 'input_lv_state'
            : d.order > 440 && equipmentType === equipmentTypes.Breaker
            ? 'breaker_state'
            : d.order > 440 && equipmentType === equipmentTypes.BreakerV2
            ? 'breaker_state'
            : d.order > 440 && equipmentType === equipmentTypes.WaveTrap
            ? 'wavetrap_state'
            : d.order > 440 && equipmentType === equipmentTypes.RatioTapChanger
            ? 'rpn_state'
            : d.order > 440 && equipmentType === equipmentTypes.Disconnector
            ? 'dc_general_state_result'
            : d.order > 440 && equipmentType === equipmentTypes.DisconnectorV2
            ? 'dc_general_state_result'
            : d.order > 440 && equipmentType === equipmentTypes.CouplingCapacitor
            ? 'ccvt_state'
            : d.order > 440 && equipmentType === equipmentTypes.OPN
            ? 'opn_state'
            : d.order < 400
            ? 'datatable'
            : 'tt_state',
        scheme: buildScheme(d),
      };
    });
  } catch (e) {
    console.log(e);
    throw e;
  }
};
