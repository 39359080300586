import Vue from 'vue';
import VueRouter from 'vue-router';
import { ROUTES } from '@/router/router.constants';
import EmptyData from '@/components/basics/EmptyData.vue';

Vue.use(VueRouter);

const { EQUIPMENT, HOME, ADMIN, LOGIN, SENSORS, UPDATE_JOURNALS, SYSTEM_LOGS, USAGE_INFO, EVENT_JOURNAL } = ROUTES;
const routes = [
  {
    path: LOGIN,
    name: 'Login',
    component: () => import(/* webpackChunkName:'Login'*/ '@/components/Login.vue'),
  },
  {
    path: HOME,
    name: 'MainView',
    component: async () => import(/* webpackChunkName:'MainView'*/ '@/components/MainView.vue'),
    children: [
      {
        path: EQUIPMENT,
        name: 'Empty',
        component: async () =>
          import(
            /* webpackChunkName:'DetailsContent'*/ '@/components/equipment-details/DetailsContent.vue'
          ),
      },
      {
        path: `${EQUIPMENT}/:id`,
        name: 'Equipment',
        component: async () =>
          import(
            /* webpackChunkName:'DetailsContent'*/ '@/components/equipment-details/DetailsContent.vue'
          ),
      },
      {
        path: '${EQUIPMENT}/*',
        component: EmptyData,
      },
    ],
  },
  {
    path: ADMIN,
    name: 'Admin',
    component: async () =>
      import(/* webpackChunkName:'DetailsContent'*/ '@/components/admin/AdminView.vue'),
  },
  {
    path: `${SENSORS}/:stationId/:id?`,
    name: 'Sensors',
    component: async () =>
      import(/* webpackChunkName:'DetailsContent'*/ '@/components/sensors/SensorsView.vue'),
  },
  {
    path: SENSORS,
    name: 'Sensors',
    component: async () =>
      import(/* webpackChunkName:'DetailsContent'*/ '@/components/sensors/SensorsView.vue'),
  },
  {
    path: `${SYSTEM_LOGS}/:id`,
    name: 'SystemLogs',
    component: async () =>
      import(/* webpackChunkName:'DetailsContent'*/ '@/components/system-logs/SystemLogsView.vue'),
  },
  {
    path: SYSTEM_LOGS,
    name: 'SystemLogs',
    component: async () =>
      import(/* webpackChunkName:'DetailsContent'*/ '@/components/system-logs/SystemLogsView.vue'),
  },
  {
    path: UPDATE_JOURNALS,
    name: 'UpdateJournals',
    component: async () =>
      import(
        /* webpackChunkName:'DetailsContent'*/ '@/components/update-journals/UpdateJournalsView.vue'
      ),
  },
  {
    path: USAGE_INFO,
    name: 'UsageInfo',
    component: async () =>
      import(
        /* webpackChunkName:'DetailsContent'*/ '@/components/usage-dashboard/UsageDashboard.vue'
      ),
  },
  {
    path: EVENT_JOURNAL,
    name: 'EventJournal',
    component: async () =>
      import(
        /* webpackChunkName:'DetailsContent'*/ '@/components/event-journal/EventJournalView.vue'
      ),
  },
  {
    path: '*',
    component: EmptyData,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !localStorage.getItem('auth.token')) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
