import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { PRELOADER_STATE } from '@/store/abstract/preloader/constants';
import {IPreloaderState } from '@/store/abstract/preloader/types';

const getters: GetterTree<IPreloaderState, RootState> = {
  [PRELOADER_STATE]: ({ preloader }) => preloader,
};

export default getters;
