import { MutationTree } from 'vuex';
import { INode, TreeState } from '@/store/equipment/types';
import { EQUIP_STORE_CONST } from './constants';

export const mutations: MutationTree<TreeState> = {
  [EQUIP_STORE_CONST.SELECTED_TREE_NODE]: (state, payload) => {
    state.selectedTreeNode = payload;
  },
  [EQUIP_STORE_CONST.SUBSTATION_CALC_UIDS]: (state, payload) => {
    state.substationCalcUids = payload;
  },
  [EQUIP_STORE_CONST.TREE_STATE_MAP]: (state, payload) => {
    state.treeStateMap = payload;
  },
  [EQUIP_STORE_CONST.TREE_STATE_MAP_ERR]: (state) => {
    state.treeStateMap = null;
  },
  [EQUIP_STORE_CONST.TREE_LOADED](state: TreeState, payload: INode[]) {
    state.tree = payload;
    state.error = false;
  },
  [EQUIP_STORE_CONST.TREE_LOADING_SET](state: TreeState, payload: boolean) {
    state.isLoading = payload;
  },
  [EQUIP_STORE_CONST.TREE_ERROR](state: TreeState, payload) {
    state.tree = [];
    state.error = payload;
  },
  [EQUIP_STORE_CONST.TREE_ITEMS_EXPAND](state: TreeState, payload: Set<number>) {
    state.expanded = new Set([...state.expanded, ...payload]);
    localStorage.setItem('tree.expanded', JSON.stringify([...state.expanded]));
  },
  [EQUIP_STORE_CONST.TREE_ITEMS_COLLAPSE](state: TreeState, payload: Set<number>) {
    state.expanded = new Set([...state.expanded].filter((x) => !payload.has(x)));
    localStorage.setItem('tree.expanded', JSON.stringify([...state.expanded]));
  },
  [EQUIP_STORE_CONST.TREE_ITEMS_COLLAPSE_ALL](state: TreeState) {
    state.expanded = new Set();
    localStorage.setItem('tree.expanded', JSON.stringify([...state.expanded]));
  },
  [EQUIP_STORE_CONST.SET_TYPES](state: TreeState, payload: any) {
    state.equipmentTypes = payload;
  },
};
