import { ENV_CONST, EnvManager } from '@/env';

export const apiIP = process.env.VUE_APP_SERVER_IP;
export const apiPort = process.env.VUE_APP_SERVER_PORT;
export const sentryDsn = process.env.VUE_APP_SENTRY_DSN;

export const TR_TYPE = {
  WS: 'ws://',
  HTTP: 'http://',
  TCP: 'tcp://',
  FTP: 'ftp://',
};

export const getApiUrl = (prefix = '', postfix = ''): string => {
  const ip = EnvManager.getValue(ENV_CONST.SERVER_IP);
  const port = EnvManager.getValue(ENV_CONST.SERVER_PORT);
  const fPort = port ? `:${port}` : '';
  const fPrefix = !ip ? '' : prefix;

  return `${fPrefix}${ip}${fPort}/api${postfix}`;
};
