import { IDataConverter } from '@/components/basics/table/converters/types';

export class WindingConverter implements IDataConverter {
  constructor(private readonly assetProps: any) {}
  convert(value: any, opts: any): any {
    const props = this.assetProps[value];
    if (!props) return value;
    const find = (props as [])?.find((i: any) => i.Name === 'Name');
    return (find as any)?.Value ? (find as any)?.Value : 'Первичная обмотка';
  }
}
