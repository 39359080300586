import { MutationTree } from 'vuex';
import { DetailsState, IEquipDetails } from '@/store/details/types';

export const mutations: MutationTree<DetailsState> = {
  detailsLoaded: (state: DetailsState, payload: IEquipDetails) => {
    state.equipmentDetails = { ...payload };
    state.error = false;
  },

  detailsError: (state: DetailsState) => {
    state.equipmentDetails = null;
    state.error = true;
  },

  clearDetails: (state) => {
    state.equipmentDetails = null;
  },

  // phase
  selectedPhaseChanged(state, payload) {
    state.selectedPhase = payload;
  },

  selectedPhaseError(state, error) {
    state.selectedPhase = null;
  },
};
