import {
  IHistoryChartsQuery,
  IHistoryDataSet,
  IMaxAvgMin,
  ISeries,
  ISeriesInfo,
} from '@/store/history-charts/types';
import _ from 'lodash';
import { updateTimeOffset } from '@/utils/datetime/DateTimeCorrector';
import { IPropertyScheme } from '@/store/history-data/history-data.types';
import { ScaleManager } from '@/utils/scale/ScaleManager';

export const getAreas = (series: ISeries[]): ISeries[] => {
  return [..._.uniqBy(series, 'areaKey')];
};

export const getSeries = (scheme: Map<ISeries, number[]>): ISeries[] => {
  return [...scheme.keys()];
};

export const getSeriesProps = ({ interval }: IHistoryChartsQuery) => {
  if (interval === 'ms') return ['ts', 'v'];
  return ['ts', 'max', 'avg', 'min'];
};

export const getHistoryChartValue = (
  { interval }: IHistoryChartsQuery,
  key: string,
  data: any,
  scheme: IPropertyScheme
) => {
  if (interval === 'hour' && key === 'ts') {
    const date = new Date(data['d']).setHours(data['h'], 0, 0);
    return updateTimeOffset(new Date(date));
  }
  if (interval === 'minute' && key === 'ts') {
    const date = new Date(data['d']).setHours(data['h'], data['m'], 0);
    return updateTimeOffset(new Date(date));
  }
  if (interval === 'second' && key === 'ts') {
    const date = new Date(data['d']).setHours(data['h'], data['m'], data['s']);
    return updateTimeOffset(new Date(date));
  }

  if (interval === 'ms' && key === 'ts') {
    return updateTimeOffset(new Date(data[key])).getTime();
  }

  const val = data[key];
  const scaleRes = ScaleManager.scale(val, {
    key: 'scale',
    scheme,
  });
  return ScaleManager.format(scaleRes, {
    key: 'axisScale',
    scheme,
  });
};

export const extractSeries = (
  query: IHistoryChartsQuery,
  areaKey: string,
  data: any[],
  scheme: IPropertyScheme
) => {
  const result = new Map<ISeriesInfo, any[]>();
  const props = getSeriesProps(query);
  if (!props?.length) return;
  props.forEach((p) => {
    result.set(
      { valueKey: p, areaKey },
      data.map((d) => getHistoryChartValue(query, p, d, scheme))
    );
  });
  return result;
};

const hasAvgSeries = (extractKeys: ISeriesInfo[]): boolean => {
  const x = extractKeys.find((e) => e.valueKey === 'avg');
  return !!x;
};

export const updateHistoryDataMap = (
  query: IHistoryChartsQuery,
  areaKey: string,
  scheme: IPropertyScheme,
  data: any[],
  historyDataMap: Map<ISeries, IHistoryDataSet>,
  extraMap?: Map<string, IMaxAvgMin>
) => {
  const extract = extractSeries(query, areaKey, data, scheme);
  const eMap = new Map(extraMap ?? new Map<string, IMaxAvgMin>());
  if (!extract || !historyDataMap) return;
  const dataMap = new Map(historyDataMap);
  const seriesKeys = [...dataMap.keys()].filter((i) => i.areaKey === areaKey);
  const extractKeys = [...extract.keys()];
  if (!seriesKeys?.length || !extractKeys?.length) {
    return;
  }
  const hasAvg = hasAvgSeries(extractKeys);
  const timeSet = extract.get(extractKeys[0]);
  let mam: IMaxAvgMin = {},
    max = undefined,
    avg = undefined,
    min = undefined;

  for (let i = 1; i <= seriesKeys.length; i++) {
    const sKey = seriesKeys[i - 1];
    const eKey = extractKeys[i];
    const dataSet = dataMap.get(sKey);
    if (dataSet) {
      dataSet.time = timeSet ?? [];
      dataSet.data = extract.get(eKey) ?? [];

      if(dataSet.data?.length > 0) {
        if (hasAvg) {
          if (eKey.valueKey === 'max') {
            max = _.max(dataSet.data.map((i) => Number(i))) ?? 0;
          }
          if (eKey.valueKey === 'avg') {
            avg = _.mean(dataSet.data.map((i) => Number(i))) ?? 0;
          }
          if (eKey.valueKey === 'min') {
            min = _.min(dataSet.data.map((i) => Number(i))) ?? 0;
          }
        } else {
          if (dataSet.data?.length > 0) {
            max = _.max(dataSet.data.map((i) => Number(i))) ?? 0;
            avg = _.mean(dataSet.data.map((i) => Number(i))) ?? 0;
            min = _.min(dataSet.data.map((i) => Number(i))) ?? 0;
          }
        }
      }
    }
    mam = { max, avg, min };
  }

  const m = eMap.get(areaKey);
  // console.log('m:::',m);
  if (m) {
    eMap.delete(areaKey);
  }
  eMap.set(areaKey, mam);
  // console.log('extra:::', eMap);
  return [dataMap, eMap];
};

const seriesPerArea = ({ interval }: IHistoryChartsQuery) => {
  if ((interval as any).value === 'ms') {
    return 1;
  }
  return 3;
};

export const getSchemeBySketch = async (
  payload: IHistoryChartsQuery
): Promise<Map<ISeries, IHistoryDataSet | null>> => {
  const scheme = new Map<ISeries, IHistoryDataSet | null>();
  const seriesCount = seriesPerArea(payload);

  for (let j = 1; j < 4; j++) {
    for (let i = 1; i <= seriesCount; i++) {
      const seriesKey = `Area_${j}*Series_${i}`;
      scheme.set(
        {
          areaKey: `Area_${j}`,
          name: `Series_${i}`,
          uid: seriesKey,
          uniqueKey: `${seriesKey}`,
        },
        { time: [], data: [] }
      );
    }
  }
  return scheme;
};
