import { IQueryPopupChartParams } from '@/store/popup-charts/popup.charts.types';
import moment from 'moment';
import { ShockPoint } from '@/components/basics/charts/short-circuit-chart/types';
import _ from 'lodash';

const formatPoint = (num: number) => {
  //
};

export const buildDataPoints = (data: number[], step: number, initialPoint: number) => {
  return data.map((p, i) => {
    return [initialPoint + i * step, p];
  });
};

export const calculateShownPoints = (start: number, end: number, totalPoints: number) => {
  const prc = Math.abs(start - end);
  return (totalPoints / 100) * prc;
};

const timeNumber = (nanoTime: number, k = 1): number => {
  const d = new Date(nanoTime / k);
  // const sp = Number(moment(d).format('ss.SSS'));
  // return Number.isNaN(sp) ? 0 : sp;
  return d.getTime();
};

const MILL = 1_000_000;

const getShockPoint = (start: number, end: number, stamp: number): Record<ShockPoint, number> => {
  const sPoint = timeNumber(start, MILL);
  const ePoint = timeNumber(end, MILL);
  const scPoint = timeNumber(Number(stamp));
  const duration = Math.abs(scPoint - sPoint);
  const totalDuration = Math.abs(ePoint - sPoint);
  const prc = (duration / totalDuration) * 100;
  return { prcShock: prc, xShock: scPoint };
};

const tryTransformYs = (
  dataPoints: number[][],
  limit = 10_000,
  scale = 1000
): [boolean, number[][]] => {
  const max = _.max(dataPoints.map((i) => Math.abs(i[1])));
  if (!max || max < limit) return [false, dataPoints];
  return [true, dataPoints.map((i) => [i[0], i[1] / scale])];
};

export const transformPopupChartData = (dataArray: any[], params: IQueryPopupChartParams[]) => {
  try {
    const result = [];
    for (const p of params) {
      const points = dataArray.find((i) => i.select === p.data_field);
      const { data, t_step, t_array_start, t_array_stop, stamp } = points;
      const dataPoints = buildDataPoints(data, t_step / MILL, timeNumber(t_array_start, MILL));
      const shockPoint = getShockPoint(t_array_start, t_array_stop, Number(stamp));

      const scaleMaker = tryTransformYs(dataPoints);
      result.push({
        name: p.graph_name + ', ' + moment(Number(stamp)).format('DD.MM.YYYY HH:mm:ss.S'),
        scale: scaleMaker[0] ? 1000 : 0,
        data: scaleMaker[1],
        shockPoint,
      });
    }
    return result;
  } catch (e) {
    //
  }
};

export const transformPopupChartDataAsync = async (
  data: any[],
  params: IQueryPopupChartParams[]
): Promise<any[]> => {
  return await new Promise<any[]>((resolve, reject) => {
    try {
      const r = transformPopupChartData(data, params);
      resolve(r);
    } catch (e) {
      reject(e);
    }
  });
};
