import { GetterTree } from 'vuex';

import { RootState } from '@/store/types';

import { LAYOUT } from './constants';
import { LayoutState } from './types';

export const getters: GetterTree<LayoutState, RootState> = {
  [LAYOUT.GET_DRAWER_VISIBLE](state): boolean {
    return state.drawerVisible;
  },
  [LAYOUT.GET_DRAWER_WIDTH](state): number {
    return state.drawerWidth;
  },
};
