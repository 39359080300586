import { IDatetimeRangeQuery } from '@/store/abstract/range-getter/types';
import { getSecondsCount } from '@/components/basics/selectors/extensions';

export const buildDatetimeRangeQuery = (query: any): IDatetimeRangeQuery | null => {
  try {
    const { algorithm, uuids, interval, isTopology, count } = query;
    return {
      algorithm,
      asset_uuid: [...uuids].join(';'),
      count: count ?? getSecondsCount(interval),
      interval,
      isTopology,
    };
  } catch {
    return null;
  }
};
