import { Module } from 'vuex';
import {
  IHistoryDataQueryState,
  IZoomHistory,
  IZoomQuery,
} from '@/store/history-data-query/history-data-query.types';
import { RootState } from '@/store/types';
import { HISTORY_DATA_QUERY_CONST } from '@/store/history-data-query/history-data-query.const';
const {
  POP_ZOOM_HISTORY,
  ZOOM_OUT_QUERY,
  ZOOM_HISTORY,
  PUSH_ZOOM_HISTORY,
  POP_ZOOM_HISTORY_ERROR,
  POP_ZOOM_HISTORY_SUCCESS,
  PUSH_ZOOM_HISTORY_ERROR,
  PUSH_ZOOM_HISTORY_SUCCESS,
  RESET_ZOOM_HISTORY,
  RESET_ZOOM_HISTORY_ERROR,
  RESET_ZOOM_HISTORY_SUCCESS,
  UPD_ZOOM_IN_QUERY,
  UPD_ZOOM_IN_QUERY_ERROR,
  UPD_ZOOM_IN_QUERY_SUCCESS,
  HOME_QUERY,
  UPD_HOME_QUERY,
  UPD_HOME_QUERY_SUCCESS,
  UPD_HOME_QUERY_ERROR,
  ZOOM_IN_QUERY,
} = HISTORY_DATA_QUERY_CONST;

export const initial = (): IHistoryDataQueryState => {
  return {
    zoomHistory: [],
    zoomOutQuery: null,
    zoomInQuery: null,
    homeQuery: null,
    error: false,
  };
};

export const historyDataQuery: Module<IHistoryDataQueryState, RootState> = {
  namespaced: true,
  state: initial,
  actions: {
    [UPD_HOME_QUERY]: (context, payload) => {
      try {
        context.commit(UPD_HOME_QUERY_SUCCESS, payload);
      }catch (e) {
        context.commit(UPD_HOME_QUERY_ERROR, payload);
      }
    },
    [UPD_ZOOM_IN_QUERY]: (context, payload: IZoomHistory) => {
      try {
        context.commit(UPD_ZOOM_IN_QUERY_SUCCESS, payload);
      } catch (e) {
        context.commit(UPD_ZOOM_IN_QUERY_ERROR, e);
      }
    },
    [RESET_ZOOM_HISTORY]: (context) => {
      try {
        context.commit(RESET_ZOOM_HISTORY_SUCCESS, []);
        context.commit(POP_ZOOM_HISTORY_SUCCESS, null);
      } catch (e) {
        context.commit(RESET_ZOOM_HISTORY_ERROR, e);
      }
    },
    [POP_ZOOM_HISTORY]: (context) => {
      try {
        const history = [...(context?.state?.zoomHistory ?? [])];
        const pop = history?.pop();
        context.commit(PUSH_ZOOM_HISTORY_SUCCESS, history);
        if (history.length >= 2) {
          context.commit(POP_ZOOM_HISTORY_SUCCESS, history[history.length - 2]);
        } else {
          context.commit(POP_ZOOM_HISTORY_SUCCESS, null);
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    [PUSH_ZOOM_HISTORY]: (context, payload: IZoomHistory) => {
      try {
        const history = [...(context?.state?.zoomHistory ?? [])];
        history.push(payload);
        context.commit(PUSH_ZOOM_HISTORY_SUCCESS, history);
        if (history.length >= 2) {
          context.commit(POP_ZOOM_HISTORY_SUCCESS, history[history.length - 2]);
        } else {
          context.commit(POP_ZOOM_HISTORY_SUCCESS, null);
        }
      } catch (e) {
        console.log(e.message);
      }
    },
  },
  mutations: {
    [UPD_HOME_QUERY_SUCCESS]: (state, payload) => {
      state.homeQuery = payload;
    },
    [UPD_HOME_QUERY_ERROR]: (state, payload) => {
      state.error = payload;
    },
    [UPD_ZOOM_IN_QUERY_SUCCESS]: (state, payload) => {
      state.zoomInQuery = payload;
      state.error = false;
    },
    [UPD_ZOOM_IN_QUERY_ERROR]: (state, payload) => {
      state.zoomInQuery = null;
      state.error = payload;
    },
    [RESET_ZOOM_HISTORY_ERROR]: (state, payload) => {
      state.error = payload;
    },
    [RESET_ZOOM_HISTORY_SUCCESS]: (state, payload) => {
      state.zoomHistory = payload;
    },
    [POP_ZOOM_HISTORY_SUCCESS]: (state, payload) => {
      state.zoomOutQuery = payload;
    },
    [POP_ZOOM_HISTORY_ERROR]: (state, payload) => {
      state.error = payload;
    },
    [PUSH_ZOOM_HISTORY_SUCCESS]: (state, payload) => {
      state.zoomHistory = payload;
    },
    [PUSH_ZOOM_HISTORY_ERROR]: (state, payload) => {
      state.error = payload;
    },
  },
  getters: {
    [ZOOM_HISTORY]: ({ zoomHistory }) => zoomHistory,
    [ZOOM_OUT_QUERY]: ({ zoomOutQuery }) => zoomOutQuery,
    [ZOOM_IN_QUERY]: ({ zoomInQuery }) => zoomInQuery,
    [HOME_QUERY]: ({homeQuery}) => homeQuery
  },
};
