import { ENV_PREFIX } from './env.const';

const { VUE_APP, __ } = ENV_PREFIX;
const env = process.env.TARGET_ENV;
console.log(env);

const stageConfig: Record<string, string> = {
  VUE_APP_SERVER_IP__: "192.168.10.120",
  VUE_APP_SERVER_PORT__: "4201",
  VUE_APP_SOCKET_PORT__: "4407",
  VUE_APP_SOCKET_HOST__: "192.168.10.120",
  VUE_APP_SENTRY_DSN__: "http://9e0a3e7f43db4122b7c229bbcfe1beab@kso-preprod/2"
};

export class EnvManager {

  public static isProd(){
    return process.env.NODE_ENV !== 'development';
  }

  public static isDev(){
    return process.env.NODE_ENV === 'development';
  }

  public static getValue(key: string, postfix = __): string | undefined {
    const _key = `${VUE_APP}_${key}`;
    //console.log('MODE::', process.env.NODE_ENV);

    if (env === 'stage') 
      return stageConfig[_key] ?? stageConfig[`${_key}${postfix}`];
    else
      return process.env[_key] ?? process.env[`${_key}${postfix}`];
  }

  public static getMode(){
    return process.env['MODE'];
  }

}
