  import { ActionTree } from 'vuex';
import { RestApiService } from '@/services';
import { RootState } from '@/store/types';
import { ChartDataQuery, ChartDataState } from '@/store/chart-data/chart-data.types';
import { CHART_DATA_CONST, END_POINTS } from '@/store/chart-data/chart-data.constants';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import {
  buildUrlQueries,
  checkCritical,
  debugLog,
  getMam,
  getMamMS,
  mergeDataArrays,
  transformData,
} from '@/store/chart-data/chart-data.extensions';
import store from '@/store';
import { ROOT_STORE_CONST } from '@/store/root.constants';

const rest = new RestApiService();

const harmonicPath = getApiUrl(TR_TYPE.HTTP, `/click-house/${END_POINTS.HISTORY_DATA}?`);

export const actions: ActionTree<ChartDataState, RootState> = {
  getData: async (context, payload: ChartDataQuery) => {
    try {
      debugLog('start', payload, context);

      await store.dispatch(ROOT_STORE_CONST.START_BUSY_INDICATOR, { chart: true });

      if (!checkCritical(payload as any)) {
        debugLog('The chosen range is not possible!');
        context.commit(CHART_DATA_CONST.DATA_LOADED, context.state.data);
        await store.dispatch(ROOT_STORE_CONST.STOP_BUSY_INDICATOR, { chart: true });
        return;
      }

      const harmonic = await rest.fetchData(await buildUrlQueries(payload as any, harmonicPath));
      debugLog('harmonic', harmonic);
      const data = mergeDataArrays(
        transformData(harmonic, payload.interval.value),
        payload.interval.value
      );
      debugLog('data', data);
      context.commit(CHART_DATA_CONST.DATA_LOADED, data);
      const maxAveMin = [];
      if (payload.interval.value === 'ms') {
        maxAveMin.push(getMamMS(harmonic));
      } else {
        maxAveMin.push(getMam(harmonic));
      }
      debugLog('mam', maxAveMin);
      await context.dispatch(CHART_DATA_CONST.UPDATE_MAM, maxAveMin);
      await store.dispatch(ROOT_STORE_CONST.STOP_BUSY_INDICATOR, { chart: true });
    } catch (e) {
      context.commit(CHART_DATA_CONST.DATA_ERROR, e);
      await store.dispatch(ROOT_STORE_CONST.STOP_BUSY_INDICATOR, { chart: true });
    }
  },
  updateQuery: async (context, payload: ChartDataQuery) => {
    try {
      await context.commit(CHART_DATA_CONST.QUERY_UPDATED, payload);
      await context.dispatch(CHART_DATA_CONST.GET_DATA, context.state.query);
    } catch (e) {
      await context.commit(CHART_DATA_CONST.QUERY_ERROR);
    }
  },
  updateRefs: async (context, payload) => {
    try {
      await context.commit(CHART_DATA_CONST.REFS_UPDATED, payload);
    } catch (e) {
      await context.commit(CHART_DATA_CONST.UPDATE_REFS_ERROR, e);
    }
  },
  changePhase: async (context, payload) => {
    try {
      await context.commit(CHART_DATA_CONST.PHASE_CHANGED, payload);
    } catch (e) {
      await context.commit(CHART_DATA_CONST.PHASE_ERROR);
    }
  },
  updateMam: async (context, payload) => {
    try {
      await context.commit(CHART_DATA_CONST.MAM_UPDATED, payload);
    } catch (e) {
      await context.commit(CHART_DATA_CONST.MAM_ERROR, e);
    }
  },
};
