import {
  IQueryPopupChartParams,
  IHistoryClickHouseQuery,
  getPopupFlag,
  PopupFlagsType,
} from '@/store/popup-charts/popup.charts.types';
import moment from 'moment';
import { MOMENT_QUERY_FORMAT } from '@/utils/datetime/DateTimeRangePresets';

export const getEnumValue = (row: any, param: IQueryPopupChartParams): any => {
  // console.log('row:::',row);
  // console.log('param:::',param);
  const { base_field } = param;
  const { enums } = row;
  const value = row[base_field as string];
  const find = enums?.find((e: any) => e.notes === value);
  if (!find) return row[base_field as string];
  return find;
};

export const baseValueFilter = (
  row: any,
  params: IQueryPopupChartParams[]
): IQueryPopupChartParams[] => {
  const result = [];
  for (const param of params) {
    const { base_field, base_value } = param;
    const x = row[base_field as string];
    if (!x || `${x}` !== `${base_value}`) continue;
    result.push(param);
  }
  return result.length > 0 ? result : params.slice(0, 3);
};

const buildTimeRange = (row: any, param: IQueryPopupChartParams) => {
  const { data_time, ts } = row;
  const dt = data_time ? new Date(data_time / 1_000_000) : new Date(ts / 1_000_000);
  const { graph_shift, graph_len } = param;
  const start = new Date(dt.setSeconds(dt.getSeconds() - Number(graph_shift)));
  const end = new Date(dt.setSeconds(dt.getSeconds() + Number(graph_len) - Number(graph_shift)));
  return { start, end };
};

const buildTimeStamp = (row: any): string => {
  // ->>
  const { data_time, ts } = row;
  if (!data_time || !ts) return '';
  const dt = data_time ? new Date(data_time / 1_000_000) : new Date(ts / 1_000_000);
  return dt ? moment(dt).format(MOMENT_QUERY_FORMAT.dateToSec) : '';
};

export const buildClickHouseQuery = (
  row: any,
  params: IQueryPopupChartParams[]
): IHistoryClickHouseQuery[] | null => {
  // console.log('row::', row);
  // console.log('params::', params);
  if (!params?.length) return null;
  const typeFlag = getPopupFlag(params[0]?.flags ?? 0);
  const filtered = typeFlag === 'graph' ? baseValueFilter(row, params) : params;
  if (!filtered?.length) return null;
  const queries: IHistoryClickHouseQuery[] = [];
  const { phase, type_prm, assetUid, i_wind_uuid,iedname } = row;
  for (const param of filtered) {
    const { start, end } = buildTimeRange(row, param);
    const { data_type, data_field, base_field } = param;
    if (!data_type || !data_field) continue;
    switch (getPopupFlag(param?.flags ?? 0)) {
      case 'graph':
        queries.push({
          start,
          end,
          algorithm: data_type,
          select: data_field,
          type: 'graph',
          asset_uid: assetUid,
          phase,
        });
        break;
      case 'atgraph':
          queries.push({
            start,
            end,
            algorithm: data_type,
            select: data_field,
            type: 'graph',
            asset_uid: row[base_field as string],
            phase,
          });
          break;  
      case 'osc':
        queries.push({
          ts: new Date(row[base_field as string] / 1_000_000).getTime(),
          algorithm: data_type,
          select: data_field,
          type: 'osc',
          phase,
          i_wind_uuid,
          asset_uid: assetUid,
          ied_name: iedname,
        });
        break;
      case 'matrix2':
        queries.push({
          start: buildTimeStamp(row),
          algorithm: data_type,
          select: data_field,
          type: 'matrix2',
          type_prm: row[base_field as string],
          // type_prm: getEnumValue(row, param).order as string,
          phase,
          asset_uid: assetUid,
        });
        break;
      case 'matrix4':
        queries.push({
          start: buildTimeStamp(row),
          algorithm: data_type,
          select: data_field,
          type: 'matrix4',
          type_prm: row[base_field as string],
          // type_prm: getEnumValue(row, param).order as string,
          phase,
          asset_uid: assetUid,
        });
        break;
      case 'array':
        queries.push({
          start: moment(new Date(row.ts_nano)).format(MOMENT_QUERY_FORMAT.dateToSec),
          algorithm: data_type,
          select: data_field,
          type: 'array',
          type_prm: row[base_field as string],
          phase,
          asset_uid: assetUid,
        });
        break;
      default:
        break;
    }
  }
  return !queries?.length ? null : queries;
};

export const buildClickHouseQueryAsync = async (
  row: any,
  params: IQueryPopupChartParams[]
): Promise<IHistoryClickHouseQuery[] | null> => {
  // console.log('row:::', row);
  // console.log('params:::', params);
  return await new Promise((resolve, reject) => {
    try {
      const result = buildClickHouseQuery(row, params);
      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const findUniqueTypesWithCount = (types: any[]): Map<PopupFlagsType, number> => {
  const all = types.map((f) => f as PopupFlagsType);
  const map = new Map<PopupFlagsType, number>();
  if (!all?.length) map.set('none', 0);
  if (all.length === 1) map.set(all[0], 1);
  if (all.length > 1) {
    for (const u of all) {
      let value = map.get(u);
      if (!value) map.set(u, 1);
      else map.set(u, ++value);
    }
  }
  return map;
};

export const getWinnerType = (typesMap: Map<PopupFlagsType, number>): PopupFlagsType => {
  let type: PopupFlagsType = 'none';
  if (typesMap.size <= 0) return type;
  if (typesMap.size === 1) {
    const x = [...typesMap.keys()];
    return x[0];
  }
  let score = 0;
  for (const [key, value] of typesMap) {
    if (value > score) {
      type = key;
      score = value;
    }
  }
  return type;
};

export const definePopupType = (params: IHistoryClickHouseQuery[]) => {
  return getWinnerType(findUniqueTypesWithCount(params.map((p) => p.type)));
};

export const definePopupTypeAsync = async (
  params: IHistoryClickHouseQuery[]
): Promise<PopupFlagsType> => {
  return await new Promise<PopupFlagsType>((resolve, reject) => {
    try {
      resolve(definePopupType(params));
    } catch (e) {
      reject(e);
    }
  });
};
