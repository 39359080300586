






import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { Subscription } from 'rxjs';
import { sampleTime } from 'rxjs/operators';

import { TIMER_EVENTS } from '@/services/timer/timer.constants';
import { socket$, get, addSubject, removeSubject } from '@/socket';

@Component
export default class AutoQuerySwitch extends Vue {
  active?: boolean = false;
  eventSubscription?: Subscription;

  @Prop() on?: string;
  @Prop() off?: string;
  @Prop() tick?: number;
  @Prop() subject!: string;
  @Prop() keys!: string[];

  @Watch('active') onActiveChanged(val?: boolean, old?: boolean) {
    if (val === old) {
      return;
    }
    if (val) {
      this.eventSubscription = socket$
        .pipe(get(this.subject), sampleTime(this.tick !== undefined ? this.tick : 10000))
        .subscribe((message) => this.reQuery(message));
      addSubject({ theme: this.subject, keys: this.keys });
    } else {
      if (this.eventSubscription) {
         removeSubject(this.subject);
        this.eventSubscription.unsubscribe();
        this.eventSubscription = undefined;
      }
    }
  }

  reQuery(payload: any) {
    this.$emit(TIMER_EVENTS.REQUERY, payload);
  }

  beforeDestroy() {
    this.active = false;
    removeSubject(this.subject);
  }
}
