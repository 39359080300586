export const ROUTES = {
  NOT_FOUND: '/notFound',
  EQUIPMENT: '/equipment',
  SENSORS: '/sensors',
  SYSTEM_LOGS: '/system-logs',
  HOME: '/',
  INFO: '/info',
  STATE: '/state',
  CHARTS: '/charts',
  ANALYZER: '/analyzer',
  DETECTOR: '/detector',
  ADMIN: '/admin',
  ROLES: '/roles',
  LOGIN: '/login',
  UPDATE_JOURNALS: '/update-journals',
  USAGE_INFO: '/usage-info',
  EVENT_JOURNAL: '/event-journal',
};
