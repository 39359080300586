










































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { tableFilters } from '@/components/basics/table/filters';

@Component({
  filters:{
    ...tableFilters
  }
})
export default class RowPopupArray extends Vue {
  @Prop() data!: any[];
  @Prop() title?: string;
  @Prop() selectedRow?: any;

  baseCellStyle = { padding: '0', height: '20px', textAlign: 'center' };
  valueCellStyle({ row, column, rowIndex, columnIndex }: any) {
    const cellValue = row[column?.label];
    if (columnIndex > 0 && cellValue && cellValue !== '-')
      return { background: '#f6f8ff', color: '#2E5BFF', ...this.baseCellStyle };
    return { ...this.baseCellStyle };
  }
  get columns() {
    if (!this.data?.length) return [];
    const { n_prm } = this.data[0];
    return n_prm?.length ? (n_prm as []).map((i, j) => ({ key: j, value: `${i}` })) : [];
  }

  get tableData() {
    if (!this.data?.length) return [];
    const { prm_nom_out, n_prm, mark} = this.data[0];
    let row = {};
    (n_prm as [])?.forEach((v, i) => {
      row = { ...row, [`${v}`]: mark[i] === 0 ? '-' : prm_nom_out[i] };
    });
    return row ? [row] : [];
  }
}
