import { Module } from 'vuex';
import { TextEncoderState } from '@/store/text-decoder/types';
import { RootState } from '@/store/types';
import { DECODE_FAIL, DECODE_TEXT, DECODED_TEXT } from '@/store/text-decoder/constants';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { RestApiService } from '@/services';
const path = getApiUrl(TR_TYPE.HTTP, '/text-encoding');
const rest = new RestApiService();

const initial: TextEncoderState = {
  error: false,
  encoded: '',
};

export const textDecoder: Module<TextEncoderState, RootState> = {
  namespaced: true,
  state: initial,
  actions: {
    [DECODE_TEXT]: async (context, payload) => {
      try {
        const resp = await rest.post(path, payload);
        context.commit(DECODE_TEXT, resp.data);
      } catch (e) {
        console.log(e.message);
      }
    },
  },
  mutations: {
    [DECODE_TEXT]: (state, payload: string) => {
      state.encoded = payload;
      state.error = false;
    },
    [DECODE_FAIL]: (state, payload: Error) => {
      state.error = payload;
      state.encoded = '';
    },
  },
  getters: {
    [DECODED_TEXT]: ({ encoded }) => encoded,
  },
};
