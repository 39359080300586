import { Module } from 'vuex';
import { IChartDataExportState } from '@/store/chart-data-export/types';
import { RootState } from '@/store/types';
import { dataGetter, dataGetterInitialState } from '@/store/abstract/data-getter';
import { ALL_CHART_DATA, GET_ALL_CHART_DATA } from '@/store/chart-data-export/constants';
import { IHistoryChartsQuery } from '@/store/history-charts/types';
import { IPropertyScheme } from '@/store/history-data/history-data.types';
import { GET_DATA } from '@/store/abstract/data-getter/constants';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import {
  getFieldsAndHeaders,
  transformDataset,
  transformValues,
} from '@/store/chart-data-export/extensions';
const path = getApiUrl(TR_TYPE.HTTP, '/click-house/history-data?');

const initialState: IChartDataExportState = {
  allChartData: [],
  allChartDataError: false,
  ...dataGetterInitialState,
  path,
};

export const chartDataExport: Module<IChartDataExportState, RootState> = {
  namespaced: true,
  state: initialState,
  actions: {
    ...dataGetter.actions,
    [GET_ALL_CHART_DATA]: async (
      ctx,
      { query, props }: { query: IHistoryChartsQuery; props: IPropertyScheme[] }
    ) => {
      const clProps = props.filter((i: any) => i.order > 0);
      const data = await Promise.all(
        clProps.map((p) => {
          const { select, algorithm } = p;
          return ctx.dispatch(GET_DATA, { ...query, select, algorithm } as IHistoryChartsQuery);
        })
      );
      const { interval } = query;
      const map = new Map<string, any>();
      clProps.forEach((prop, i) => transformDataset(data[i], query, prop, map));

      const fah = getFieldsAndHeaders({ query, props });
      return { data: transformValues(map.values()), ...fah, interval };
    },
  },
  mutations: {
    ...dataGetter.mutations,
    [GET_ALL_CHART_DATA]: () => {
      //
    },
  },
  getters: {
    ...dataGetter.getters,
    [ALL_CHART_DATA]: ({ allChartData }) => allChartData,
  },
};
