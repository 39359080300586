















































































































































































































































import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { TAB_PARAMS_CONST } from '@/store/tab-params/tab-params.constants';
import {
  TabParamsItem,
  TabParamsQuery,
  TabParamsResponse,
} from '@/store/tab-params/tab-params.types';
import {
  convertEnum,
  parseJson,
  prepareElTableData,
  prepareTableData,
} from '@/components/ram-info/ram-info.ext';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';
import { CONST } from '@/store/auth/constants';
import { User } from '@/store/auth/types';
import { EventAggregator } from '@/events';
import { EVENTS_RAM_DATA_STATE } from '@/components/ram-info/constants';
import { MOMENT_FORMAT } from '@/utils/datetime/DateTimeRangePresets';
import moment from 'moment';

const { DICTIONARIES, TAB_REFS } = DICTIONARIES_CONST;

@Component({
  filters: {
    transform: (val: any, row: any) => {
      const { type } = row;
      if (type === 'DateTime') return moment(val / 1_000_000).format(MOMENT_FORMAT.dateToSec);
      return val;
    },
  },
})
export default class RamInfo extends Vue {
  @Ref() scroll?: any;

  @Prop() equipId?: number;
  @Prop() phase?: string | number;
  @Prop() tableName: any;
  @Prop() visible!: boolean;

  isEdit = false;
  ramEditModel: Record<string, any> = {};
  hasDetails = true;
  objectsTableFields = [
    { key: 'Deviation', label: 'Отклонение' },
    { key: 'Interval', label: 'Интервал усреднения' },
  ];

  @Watch('tableName', { immediate: true }) onDataChanged(val: any, old: any) {
    if (!val || val === old) return;
    this.getDetailsItems({ equip: this.equipId, phase: this.phase });
  }

  @Getter(CONST.GET_USER, { namespace: CONST.AUTH })
  user!: User | null;

  @Getter(TAB_REFS, { namespace: DICTIONARIES })
  tabRefs?: ITabRef[];

  @Getter(TAB_PARAMS_CONST.EQUIPMENT_DETAILS, { namespace: TAB_PARAMS_CONST.NAMESPACE })
  tabsParams!: TabParamsResponse;

  @Watch('tabsParams', { immediate: true }) onTabParamsChanged(val: any, old: any) {
    if (!val || val === old) return;
    this.hasDetails = true;
    return;
  }

  @Action(TAB_PARAMS_CONST.GET_DETAILS, { namespace: TAB_PARAMS_CONST.NAMESPACE })
  getDetailsItems!: (query: TabParamsQuery) => TabParamsResponse;

  @Action(TAB_PARAMS_CONST.UPDATE_DETAILS, { namespace: TAB_PARAMS_CONST.NAMESPACE })
  updateDetails!: (query: any) => Promise<void>;

  @Mutation(TAB_PARAMS_CONST.CLEAR_DETAILS, { namespace: TAB_PARAMS_CONST.NAMESPACE })
  clearDetails!: () => void;

  get isEditable() {
    const rights = (this.user?.roles ?? []).map((x) => x.role.rights).reduce((x, y) => x | y, 0);
    return rights & 0b10000000;
  }

  get currentParams(): TabParamsItem | null {
    if (!Array.isArray(this.tabsParams?.items)) return null;
    return this.tabsParams?.items?.find((tp: any) => tp.table_name == this.tableName) ?? null;
  }

  get details(): any[] {
    const item = this.currentParams;
    if (!item) return [];

    const props = item?.module_props;

    const list = props?.Input?.map((p: any) => {
      if (p.Order === 0) return null;

      if (p.Type == 'Fault') return null;

      const min = p.Min === undefined || p.Min === null ? null : Number(p.Min);
      const max = p.Max === undefined || p.Max === null ? null : Number(p.Max);

      let value = parseJson(p.Value);
      const tablesData =
        p.Type === 'NormTable' && !Array.isArray(value) ? prepareTableData(value) : undefined;
      const objectsData = p.Type === 'NormTable' ? value : undefined;

      if (tablesData) {
        const elTableData = prepareElTableData(tablesData);

        return {
          name: p.Notes,
          tableData: elTableData,
          code: p.Name,
          type: p.Type,
          min: min,
          max: max,
        };
      }

      if (objectsData) {
        return {
          name: p.Notes,
          objectsData: objectsData,
          code: p.Name,
          type: 'ObjectsTable',
        };
      }

      value = p.Value ?? '';
      if (this.tabRefs?.length && p.Type == 'Enum') {
        value = convertEnum(p, this.tabRefs);
      }
      const measure = (value ? p.Unit : null) ?? '';

      // eslint-disable-next-line
      if (measure && value.match(/(?:[^-]{4,}\-)+/)) {
        return null;
      }
      return {
        uid: p.Uid,
        name: p.Notes,
        value: value,
        code: p.Name,
        measure: p.Unit,
        type: p.Type,
        min: min,
        max: max,
      };
    }).filter(
      (el: any) =>
        el != null &&
        (el.tableData ||
          el.objectsData ||
          (el.value !== null && el.value !== undefined && el.value != ' '))
    );

    this.hasDetails = !!list;

    EventAggregator.publish({
      message: Array.isArray(list) && list?.length > 0,
      theme: EVENTS_RAM_DATA_STATE,
    });

    return list ?? [];
  }

  get originalRamModel() {
    return this.details && this.getRamModel(this.details);
  }

  @Watch('details', { immediate: true }) onDetailsChanged(val: any, prev: any) {
    if (val && val !== prev) {
      this.resetRamModel(val);
    }
  }

  spanMethod(data: any): any {
    if (data.row.tableData || data.row.objectsData) {
      return [1, 3];
    }
    return [1, 1];
  }

  onDateTimeChange(val: any, key: string) {
    if (!val) {
      this.ramEditModel[key].value = this.originalRamModel[key].value;
      return;
    }
  }

  onNumberChange([cur, prev]: [number?, number?], key: string) {
    if (cur === undefined) {
      this.ramEditModel[key].value = prev?.toString();
    }
  }

  hasBoundaries(key: string) {
    const item = this.ramEditModel[key];

    return !isNaN(item.min) && !isNaN(item.max);
  }

  numberValidate(key: string) {
    const item = this.ramEditModel[key];

    if (isNaN(item.value)) {
      return false;
    } else if (this.hasBoundaries(key)) {
      return Number(item.min) <= Number(item.value) && Number(item.max) >= Number(item.value);
    } else return true;
  }

  onNumberBlur([e]: [any], key: string) {
    if (!this.numberValidate(key)) {
      this.ramEditModel[key].value = this.originalRamModel[key].value;
    }
  }

  tableValidate(key: string, tableIndex: number, rowIndex: number, columnKey: string) {
    const item = this.ramEditModel[key].value[tableIndex][rowIndex][columnKey];

    return !isNaN(item);
  }

  objectsValidate(key: string, rowIndex: number, columnKey: string) {
    const item = this.ramEditModel[key].value[rowIndex][columnKey];

    return !isNaN(item);
  }

  onTableBlur([e]: [any], key: string, tableIndex: number, rowIndex: number, columnKey: string) {
    if (!this.tableValidate(key, tableIndex, rowIndex, columnKey)) {
      const originalValue = this.originalRamModel[key].value[tableIndex][rowIndex][columnKey];
      this.ramEditModel[key].value[tableIndex][rowIndex][columnKey] = originalValue;
    }
  }

  onObjectsBlur([e]: [any], key: string, rowIndex: number, columnKey: string) {
    if (!this.objectsValidate(key, rowIndex, columnKey)) {
      const originalValue = this.originalRamModel[key].value[rowIndex][columnKey];
      this.ramEditModel[key].value[rowIndex][columnKey] = originalValue;
    }
  }

  onTableNumberChange(
    [cur, prev]: [number?, number?],
    key: string,
    tableIndex: number,
    rowIndex: number,
    columnKey: string
  ) {
    if (cur === undefined) {
      this.ramEditModel[key].value[tableIndex][rowIndex][columnKey] = prev?.toString();
    }
  }

  onObjectsNumberChange(
    [cur, prev]: [number?, number?],
    key: string,
    rowIndex: number,
    columnKey: string
  ) {
    if (cur === undefined) {
      this.ramEditModel[key].value[rowIndex][columnKey] = prev?.toString();
    }
  }

  getRamModel(val: any) {
    const values = val.filter(
      (x: any) =>
        x.min !== x.max ||
        ((x.min === null || x.min === undefined) && (x.max === null || x.max === undefined))
    );

    const numberValues = values.filter((x: any) => x.type === 'Float' || x.type === 'Integer');
    const enumValues = values.filter((x: any) => x.type === 'Enum');
    const dateTimeValues = values.filter((x: any) => x.type === 'DateTime');
    const normTableValues = values.filter((x: any) => x.type === 'NormTable');
    const objectsTableValues = values.filter((x: any) => x.type === 'ObjectsTable');

    return Object.fromEntries([
      ...dateTimeValues.map((i: any) => [
        i.code,
        { value: new Date(i.value / 1_000_000), type: i.type },
      ]),
      ...numberValues.map((x: any) => [
        x.code,
        {
          value: x.value,
          min: x.min,
          max: x.max,
          type: x.type,
        },
      ]),
      ...enumValues.map((x: any) => [
        x.code,
        {
          value: x.value,
          type: x.type,
          options: this.getEnumValues(x.uid),
        },
      ]),
      ...normTableValues.map((x: any) => [
        x.code,
        {
          value: x.tableData.map((item: any) =>
            item.data.slice(1, item.data.length).map((x: any) => ({ ...x }))
          ),
          type: x.type,
        },
      ]),
      ...objectsTableValues.map((x: any) => [
        x.code,
        {
          value: x.objectsData.map((item: any) => ({ ...item })),
          type: x.type,
        },
      ]),
    ]);
  }

  resetRamModel(val: any) {
    this.ramEditModel = this.getRamModel(val);
  }

  getEnumValues(uid: string): { id: number; note: string }[] {
    return (this.tabRefs ?? [])
      .filter((v) => `${v.enumUid}` === `${uid}`)
      .map((v) => ({ id: v.order, note: v.notes }));
  }

  startEdit() {
    this.isEdit = true;
  }

  cancelEdit() {
    this.isEdit = false;
    this.resetRamModel(this.details);
  }

  mergeTableRow(origObj: any, editRow: any) {
    const editList = Object.entries(editRow)
      .slice(1)
      .map((x) => x[1]);

    const origValues = Object.entries(origObj).sort((x, y) =>
      Number(x[0]) > Number(y[0]) ? 1 : -1
    );

    return Object.fromEntries(origValues.map(([key], i) => [key, editList[i]]));
  }

  getTableData(key: string, table: any) {
    const stringValue: any = this.currentParams?.module_props.Input.find((x) => x.Name === key)
      ?.Value;
    const value = JSON.parse(stringValue);
    if (!value) {
      return;
    }
    if(table.length === 0) return;

    if (table[0].length === 1) {
      return JSON.stringify(this.mergeTableRow(value, table[0][0]));
    } else if (table[0].length === 2) {
      const newValues = {
        YearMax: undefined as any, YearWarn: undefined as any,
        TotalMax: undefined as any, TotalWarn: undefined as any,
      }
      if(value.YearMax) {
        const YearMax = this.mergeTableRow(value.YearMax, table[0][0])
        const YearWarn = this.mergeTableRow(value.YearWarn, table[0][1])
        newValues.YearMax = YearMax
        newValues.YearWarn = YearWarn
      }
      if(value.TotalMax) {
        const TotalMax = this.mergeTableRow(value.TotalMax, table[0][0])
        const TotalWarn = this.mergeTableRow(value.TotalWarn, table[0][1])
        newValues.TotalMax = TotalMax
        newValues.TotalWarn = TotalWarn
      }
      return JSON.stringify(newValues);
    } else {
      return;
    }
  }

  validateModel() {
    return Object.entries(this.ramEditModel).every(([key, value]) =>
      value.type === 'Float' || value.type === 'Integer'
        ? this.numberValidate(key)
        : value.type === 'NormTable'
        ? this.ramEditModel[key].value.every((tables: any[], i: number) =>
            tables.every((rows: any[], j) =>
              Object.entries(rows).every(
                ([valKey, value], k) => k === 0 || this.tableValidate(key, i, j, valKey)
              )
            )
          )
        : value.type === 'ObjectsTable'
        ? this.ramEditModel[key].value.every((row: any, j: number) =>
            this.objectsTableFields.every((item) => this.objectsValidate(key, j, item.key))
          )
        : true
    );
  }

  hasChanges() {
    return Object.entries(this.ramEditModel).some(([key, value]) =>
      value.type === 'Float' ||
      value.type === 'Integer' ||
      value.type === 'Enum' ||
      value.type === 'DateTime'
        ? value.value !== this.originalRamModel[key].value
        : value.type === 'NormTable'
        ? this.ramEditModel[key].value.some((tables: any[], i: number) =>
            tables.some((rows: any[], j) =>
              Object.entries(rows).some(
                ([valKey, value]) => value !== this.originalRamModel[key].value[i][j][valKey]
              )
            )
          )
        : value.type === 'ObjectsTable'
        ? this.ramEditModel[key].value.some((row: any, j: number) =>
            this.objectsTableFields.some(
              (item) => row[item.key] !== this.originalRamModel[key].value[j][item.key]
            )
          )
        : false
    );
  }

  async save() {
    const model = Object.entries(this.ramEditModel);

    const numberFields = model.filter(
      ([key, value]) => value.type === 'Float' || value.type === 'Integer'
    );
    const datetimeFields = model.filter(([key, value]) => value.type === 'DateTime');
    const enumFields = model.filter(([key, value]) => value.type === 'Enum');
    const tableFields = model.filter(([key, value]) => value.type === 'NormTable');
    const objectsFields = model.filter(([key, value]) => value.type === 'ObjectsTable');

    const numberData = numberFields.map(([key, value]) => [key, value.value]);
    const enumData = enumFields.map(([key, value]) => [
      key,
      value.options.find((x: any) => x.note === value.value)?.id,
    ]);
    const tableData = tableFields
      .map(([key, value]) => [key, this.getTableData(key, value.value)])
      .filter(([key, value]) => !!value);
    const objectsData = objectsFields.map(([key, value]) => [key, JSON.stringify(value.value)]);

    const datetimeData = datetimeFields.map(([key, item]) => [
      key,
      (item.value as Date).getTime() * 1_000_000,
    ]);

    const query = {
      module: this.currentParams?.module_uid,
      data: Object.fromEntries([
        ...numberData,
        ...enumData,
        ...tableData,
        ...objectsData,
        ...datetimeData,
      ]),
    };

    await this.updateDetails(query);
    await this.getDetailsItems({ equip: this.equipId, phase: this.phase });

    this.isEdit = false;
  }

  closeDialog() {
    this.$emit('close-dialog');
  }

  beforeDestroy() {
    this.clearDetails();
  }
}
