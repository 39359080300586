














































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import moment from 'moment';

import { BusyIndicator } from '@/store/types';
import { ROOT_STORE_CONST } from '@/store/root.constants';
import { AlgorithmScheme } from '@/store/algorithms/algorithms.types';
import { MOMENT_QUERY_FORMAT } from '@/utils/datetime/DateTimeRangePresets';
import {
  CLEAN_POPUP_DATA,
  GET_POPUP_CHART_DATA,
  POPUP_CHART_CONST,
  POPUP_CHART_DATA,
  POPUP_CHART_QUERY_PARAMS,
  POPUP_FLAG,
  POPUP_SCALE_CONFIG,
} from '@/store/popup-charts/popup.charts.const';
import {
  IDataPoint,
  IQueryPopupChartParams,
  PopupFlagsType,
} from '@/store/popup-charts/popup.charts.types';
import AlgorithmChartsExt from '@/components/items-tab/panes/algorithms/algorithmChartsExt';
import { REG_INFO_CONST } from '@/store/reg-info/reg-info.const';

import { ILevel } from '../basics/TableRowChartManager';
import LevelsEChart from '@/components/basics/charts/levels/LevelsEChart.vue';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';
import { PhaseConverter } from '@/utils/PhaseConverter';
import ShortCircuitChart from '@/components/basics/charts/short-circuit-chart/index.vue';
import RowPopupMatrix4 from '@/components/row-popup-matrix4/index.vue';
import RowPopupMatrix2 from '@/components/row-popup-matrix2/index.vue';
import { DETAILS_CONST } from '@/store/details';
import RowPopupArray from '@/components/row-popup-array/index.vue';

const { POPUP_CHART, DATA, GET_DATA } = POPUP_CHART_CONST;
const { REG_INFO_TABS, REG_INFO } = REG_INFO_CONST;
const { TAB_REFS, DICTIONARIES } = DICTIONARIES_CONST;

@Component({
  components: {
    'levels-echart': LevelsEChart,
    'sc-chart': ShortCircuitChart,
    'matrix-4': RowPopupMatrix4,
    'matrix-2': RowPopupMatrix2,
    'array-1': RowPopupArray,
  },
})
export default class RowPopupContainer extends Vue {
  @Prop() selectedItem: any;
  @Prop() scheme?: AlgorithmScheme;

  public chartVisible = false;
  public legend = '';
  public levels?: ILevel[] = [];

  @Getter(ROOT_STORE_CONST.BUSY_INDICATOR)
  busyIndicator?: BusyIndicator;

  @Getter(REG_INFO_TABS, { namespace: REG_INFO })
  regInfoTabs!: ITabRef[];

  @Getter(TAB_REFS, { namespace: DICTIONARIES })
  tabRefs!: any[];

  @Getter(DATA, { namespace: POPUP_CHART })
  data!: IDataPoint[];

  @Getter(POPUP_SCALE_CONFIG, { namespace: POPUP_CHART })
  scaleConfig!: object | null;

  @Getter(POPUP_CHART_DATA, { namespace: POPUP_CHART })
  popupData?: any[];
  @Mutation(CLEAN_POPUP_DATA, { namespace: POPUP_CHART })
  cleanPopupData!: () => void;

  @Getter(POPUP_CHART_QUERY_PARAMS, { namespace: POPUP_CHART })
  popupQueryParams!: IQueryPopupChartParams[];

  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  details?: any;

  get graphTitle() {
    return this.popupQueryParams?.length <= 0 ? '' : this.popupQueryParams[0].graph_name;
  }
  get phase(): number | null {
    const { phase } = this.$route?.query;
    if (!phase) return null;
    return PhaseConverter.getPhase(phase as string);
  }

  get assetUid(): string | null {
    if (!this.details?.assetUids || !this.phase) return null;
    return this.details.assetUids[this.phase];
  }

  get queryParams() {
    return this.popupQueryParams ?? [];
  }
  get isRangeChart(): boolean {
    if (!this.popupQueryParams?.length) return true;
    const params = this.popupQueryParams?.filter((q) => q?.graph_len === 0);
    return params?.length <= 0;
  }

  get chartData() {
    return this.data?.length ? this.data : [];
  }

  get regInfo() {
    return this.regInfoTabs ?? [];
  }

  // todo kill hard code
  refEnum = 'D8C744EB-300E-4DD7-BF0A-DFB550104B4A';
  get namesEnum(): any[] | null {
    return this.tabRefs?.filter((r) => r.enumUid === this.refEnum.toLowerCase()) ?? null;
  }

  get selectedRow() {
    return this.selectedItem;
  }
  @Watch('selectedItem', { immediate: true }) onSelectRow($row: any, old: any) {
    if (!$row || $row === old) return;
    this.cleanPopupData();
    if (this.phase)
      this.getPopupData({
        ...$row,
        phase: this.phase,
        enums: this.tabRefs,
        assetUid: this.assetUid,
      });
    this.chartVisible = true;
  }

  @Action(GET_DATA, { namespace: POPUP_CHART })
  getData!: (query: any) => void;
  @Action(GET_POPUP_CHART_DATA, { namespace: POPUP_CHART })
  getPopupData!: (query: any) => void;
  @Getter(POPUP_FLAG, { namespace: POPUP_CHART })
  popupFlag!: PopupFlagsType;
  get isPopupShown(): boolean {
    return this.popupFlag !== 'none';
  }

  buildQuery($row: any) {
    const queryBase = AlgorithmChartsExt.chartBaseQuery(this.scheme);
    this.legend = AlgorithmChartsExt.seriesName;
    if (!queryBase?.length) return;
    return {
      ...queryBase[0],
      start: moment($row.ts_nano)
        .subtract(5, 'seconds')
        .format(MOMENT_QUERY_FORMAT.dateToMs),
      end: moment($row.ts_nano)
        .add(5, 'seconds')
        .format(MOMENT_QUERY_FORMAT.dateToMs),
    };
  }
}
