


















import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { IKeyValue } from '@/types/IKeyValue';
@Component
export default class PropertiesGroup extends Vue {
  @Prop() topic?: string;
  @Prop() items?: IKeyValue[];
}
