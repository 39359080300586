import fetch from 'node-fetch';

export class IpManager {
  private static _ip: string | null;
  public static get ip(): string | null {
    if(!this._ip){
        fetch('https://api.ipify.org?format=json')
          .then((x) => x.json())
          .then(({ ip }) => {
            this._ip = ip;
          })
          .catch((err) => {
            this._ip = null;
          });
    }
    return this._ip;
  }
}
