export const HISTORY_DATA_QUERY_CONST = {
  HISTORY_DATA_QUERY: 'historyDataQuery',

  ZOOM_HISTORY: 'zoomHistory',
  ZOOM_OUT_QUERY: 'zoomOutQuery',
  ZOOM_IN_QUERY: 'zoomInQuery',

  UPD_ZOOM_IN_QUERY: 'updZoomInQuery',
  UPD_ZOOM_IN_QUERY_SUCCESS: 'updZoomInQuerySuccess',
  UPD_ZOOM_IN_QUERY_ERROR: 'updZoomInQueryError',

  HOME_QUERY: 'homeQuery',
  UPD_HOME_QUERY: 'updHomeQuery',
  UPD_HOME_QUERY_SUCCESS: 'updHomeQuerySuccess',
  UPD_HOME_QUERY_ERROR: 'updHomeQueryError',

  POP_ZOOM_HISTORY: 'popZoomHistory',
  POP_ZOOM_HISTORY_SUCCESS: 'popZoomHistorySuccess',
  POP_ZOOM_HISTORY_ERROR: 'popZoomHistoryError',

  RESET_ZOOM_HISTORY: 'resetZoomHistory',
  RESET_ZOOM_HISTORY_SUCCESS: 'resetZoomHistorySuccess',
  RESET_ZOOM_HISTORY_ERROR: 'resetZoomHistoryError',

  PUSH_ZOOM_HISTORY: 'pushZoomHistory',
  PUSH_ZOOM_HISTORY_SUCCESS: 'pushZoomHistorySuccess',
  PUSH_ZOOM_HISTORY_ERROR: 'pushZoomHistoryError',
};
