import { IPreloaderState } from '@/store/abstract/preloader/types';

export type IDataQuery = {
  select: string;
  algorithm: string;
  start: string;
  end: string;
  interval: string;
  phase?: number;
};

export interface IDataPoint {
  x: Date | number | string;
  y: number;
}

export const getPopupFlag = (num: number | string): PopupFlagsType => {
  const n = Number(num);
  return Number.isNaN(n) || n <= 0
    ? 'none'
    : n === 1
    ? 'graph'
    : n === 2
    ? 'osc'
    : n === 3
    ? 'matrix2'
    : n === 4
    ? 'matrix4'
    : n === 5
    ? 'array'
    : n === 11
    ? 'atgraph'
    : 'none';
};

export type PopupFlagsType = 'none' | 'graph' | 'osc' | 'matrix2' | 'matrix4' | 'array'| 'atgraph';

export interface IHistoryClickHouseQuery {
  phase: number;
  ts?: number | string | Date;
  start?: number | string | Date;
  end?: number | string | Date;
  select: string;
  algorithm: string;
  type: PopupFlagsType;
  type_prm?: string;
  asset_uid?: string;
  i_wind_uuid?: string;
  ied_name?: string;
}

export interface IPopupHistoryQuery {
  equipId: number;
  tsNano: string;
  algorithm: string;
  phase: number;
  statePrm?: number | null;
}

export interface IQueryPopupChartParams {
  base_type?: string;
  base_field?: string;
  base_value?: number | string;
  data_type?: string;
  data_field?: string;
  data_limit?: string;
  graph_len?: number;
  graph_shift?: number;
  graph_name?: string;
  notes?: string;
  order?: number | string;
  flags?: number | string;
}

export interface IPopupChartsState extends IPreloaderState {
  scaleConfig: object | null;
  popupChartQueryParams: IQueryPopupChartParams[];
  popupData?: any[];
  mockData?: any[];
  data?: IDataPoint[];
  dataError: boolean | Error;
  popupFlag: PopupFlagsType;
}
