import moment from 'moment';
import { MOMENT_QUERY_FORMAT } from '@/utils/datetime/DateTimeRangePresets';
import { updateTimeOffset } from '@/utils/datetime/DateTimeCorrector';

const getDate = (nano: number) => {
  return moment(new Date(nano / 1_000_000)).format(MOMENT_QUERY_FORMAT.dateToSec);
};

const getFromString = (dt: string) =>
  moment(updateTimeOffset(new Date(dt))).format(MOMENT_QUERY_FORMAT.dateToSec);

export const transformTimeRange = (data: any) => {
  if (!data) return;
  const { start, end } = data;
  if (!start || !end || Number.isNaN(start) || Number.isNaN(end)) return;
  return { start: getDate(start), end: getDate(end) };
};

export const transformTimeRange2 = (data: any) => {
  if (!data) return;
  const { start, end } = data;
  return {
    start: getFromString(start),
    end: getFromString(end),
  };
};
