import axios, { AxiosRequestConfig } from 'axios';

import router from '@/router';
import store from '@/store';
import { CONST } from '@/store/auth/constants';
import { IpManager } from '@/utils/IpManager';
import { StatusBarManager } from '@/store/status-bar/StatusBarManager';

let refreshTokenPromise: any = null;

export async function refreshToken() {
  return await store.dispatch(`${CONST.AUTH}/${CONST.UPDATE_TOKEN}`);
}

function requestInterceptor(req: any) {
  StatusBarManager.change(req, 'request');
  return req;
}
function responseInterceptor(resp: any) {
  StatusBarManager.change(resp, 'response');
  return resp;
}

function authInterceptor(req: AxiosRequestConfig) {
  requestInterceptor(req);
  const token = localStorage.getItem('auth.token');

  if (token && req?.headers) {
    req.headers.Authorization = `bearer ${JSON.parse(token)}`;
  }

  return req;
}

function ipInterceptor(req: AxiosRequestConfig) {
  req.headers = {
    ...req.headers,
    ip: IpManager.ip as string,
  };

  return req;
}

const axiosExpiredUnintercepted = axios.create();
axiosExpiredUnintercepted.interceptors.request.use(authInterceptor, ipInterceptor);

async function expiredInterceptor(error: any) {
  StatusBarManager.change(error, 'error');
  if (error && error.response && error.response.status === 401) {
    if (!refreshTokenPromise) {
      refreshTokenPromise = refreshToken();
    }

    const result = await refreshTokenPromise;
    refreshTokenPromise = null;
    if (result && result.ok) {
      //todo strange return
      return await axios(error.config);
    } else {
      router.push('/login').catch(() => void 0);
      return new Error('Token refresh failed');
    }
  } else {
    return error;
  }
}

axios.interceptors.request.use(authInterceptor);
axios.interceptors.response.use(responseInterceptor, expiredInterceptor);
