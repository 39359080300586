import { buildRange, updateTimeOffset } from '@/utils/datetime/DateTimeCorrector';
import moment from 'moment';

export const getLabelFormat = ({value}:TimeInterval) => {
  switch (value){
    case 'ms': return 'HH:mm:ss.S';
    case 'second': return 'HH:mm:ss';
    case 'minute': return 'HH:mm:ss';
    case 'hour': return 'DD-MM-YYYY HH:mm:ss';
    default: return 'HH:mm:ss';
  }
}

export type TimeFrameType = 'ms' | 'second' | 'minute' | 'hour' | 'day';

export interface TimeInterval {
  duration: number;
  value: TimeFrameType;
  label: string;
}
export const INTERVAL_CONST = {
  ms: {
    value: 'ms',
    duration: 1,
    label: '0.1 cек',
  } as TimeInterval,
  second: {
    value: 'second',
    duration: 1000,
    label: '1 сек',
  } as TimeInterval,

  minute: {
    value: 'minute',
    duration: 1000 * 60,
    label: '1 мин',
  } as TimeInterval,
  hour: {
    value: 'hour',
    duration: 1000 * 60 * 60,
    label: '1 час',
  } as TimeInterval
};

export const timeFrames: TimeInterval[] = [
  INTERVAL_CONST.ms,
  INTERVAL_CONST.second,
  INTERVAL_CONST.minute,
  INTERVAL_CONST.hour,
];


export enum AlgorithmType {
  stkPowerMeasurementCalculator = 'stk_power_measurement_calculator'
}

const range = buildRange(
  updateTimeOffset(new Date('2020-04-27 00:00:00')),
  60 * 60 * 24 * 3
);

export interface Reference {
  id: number;
  uuid: string;
  note: string;
}

export interface DataState {
  error: boolean | Error;
  selectedPhase?: number;
  data?: [DataItem[], DataItem[]];
  query?: DataQuery;
  refs: Reference[];
  mam?: MinAvgMax[];
}

export interface MinAvgMax {
  min: number;
  avg: number;
  max: number;
}

export interface PhasesData {
  u_a: MinAvgMax;
  u_b: MinAvgMax;
  u_c: MinAvgMax;
}

export interface DataItem {
  ts: string;
  data: PhasesData;
}

export interface DataQuery {
  interval: TimeInterval;
  start: string;
  end: string;
  algorithm: string;
  calculatorName: string;
  phase: string;
}
