import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { BUSY_INDICATOR_MSG, RootState } from '@/store/types';
import { tree } from '@/store/equipment';
import { events } from '@/store/events';
import { details } from '@/store/details';
import { data } from '@/store/data';
import { ROOT_STORE_CONST } from '@/store/root.constants';
import { chartData } from '@/store/chart-data';
import { tabParams } from '@/store/tab-params';
import { historyData } from '@/store/history-data';
import { algorithms } from '@/store/algorithms';
import { tabs } from '@/store/tabs';
import { dictionaries } from '@/store/dictionaries';
import { regInfo } from '@/store/reg-info';
import { popupChart } from '@/store/popup-charts';
import { historyDataQuery } from '@/store/history-data-query';
import { layout } from '@/store/layout';
import { historyCharts } from '@/store/history-charts';
import { auth } from '@/store/auth';
import { textDecoder } from '@/store/text-decoder';
import { sensors } from '@/store/sensors';
import { systemLogs } from '@/store/system-logs';
import { breadcrumbs } from '@/store/breadcrumbs';
import { reportData } from '@/store/report-data';
import { statusBar } from '@/store/status-bar';
import { chartDataExport } from '@/store/chart-data-export';
import { bookmarks } from '@/store/bookmarks';
import { usageInfo } from '@/store/usage-info';

Vue.use(Vuex);

const initialRootState: RootState = {
  version: '1.0.0',
  busyIndicator: {
    isBusy: false,
    message: 'Загрузка...',
  },
};

const {
  START_BUSY_INDICATOR,
  STOP_BUSY_INDICATOR,
  INDICATOR_START,
  INDICATOR_STOP,
  BUSY_INDICATOR,
} = ROOT_STORE_CONST;

const store: StoreOptions<RootState> = {
  getters: {
    [BUSY_INDICATOR]: (state) => {
      const { busyIndicator } = state;
      return busyIndicator;
    },
  },
  actions: {
    [START_BUSY_INDICATOR]: (context, data) => context.commit(INDICATOR_START, data),
    [STOP_BUSY_INDICATOR]: (context, data) => context.commit(INDICATOR_STOP, data),
  },
  mutations: {
    [INDICATOR_START]: (state, data) => {
      state.busyIndicator = {
        isBusy: true,
        message: BUSY_INDICATOR_MSG.GETTING_SERVER_DATA,
        data,
      };
    },
    [INDICATOR_STOP](state, data) {
      state.busyIndicator = { isBusy: false, message: '', data };
    },
  },
  state: initialRootState,
  modules: {
    bookmarks,
    chartDataExport,
    statusBar,
    reportData,
    sensors,
    textDecoder,
    historyCharts,
    historyDataQuery,
    popupChart,
    regInfo,
    historyData,
    tabParams,
    dictionaries,
    tree,
    events,
    details,
    tabs,
    algorithms,
    data,
    chartData,
    layout,
    auth,
    breadcrumbs,
    systemLogs,
    usageInfo,
  },
};

export default new Vuex.Store(store);
