import { IHistoryChartsQuery, ISeries } from '@/store/history-charts/types';
import { IChartProperty } from '@/store/abstract/props-scheme/types';

export class EChartManager {
  public static async getPropsBySeries(
    props: IChartProperty[],
    series: ISeries[]
  ): Promise<IChartProperty[] | null> {
    try {
      const r: IChartProperty[] = [];
      for await (const s of series) {
        const find = props.find((p) => p.name === s.name);
        if (!find) continue;
        r.push(find);
      }
      return r;
    } catch (e) {
      return null;
    }
  }

  public static rebuildSchemeIfShould(
    val: any,
    old: any,
    build: (query: IHistoryChartsQuery) => void
  ) {
    if (
      (val?.interval?.value === 'ms' && old?.interval?.value !== 'ms') ||
      (val?.interval?.value !== 'ms' && old?.interval?.value === 'ms')
    ) {
      // different series count on area
      build(val);
    }
  }

  private static filterByIndex(props: IChartProperty[], keys?: number[]): IChartProperty[] {
    if (!keys) return props;
    const filter = new Set<IChartProperty>();
    for (const key of keys) {
      if (key >= props.length) continue;
      filter.add(props[key]);
    }
    return [...filter];
  }

  private static filterByName(
    props: IChartProperty[],
    before?: IChartProperty[],
    keys?: string[]
  ): IChartProperty[] {
    if (!keys) return before ?? [];
    const filter = new Set<IChartProperty>();
    for (const key of keys) {
      const prop = props.find((i) => i.name === key);
      if (!prop) continue;
      filter.add(prop);
    }
    return [...(before ?? []), ...filter];
  }

  public static async buildLiveDataScheme(
    props: IChartProperty[],
    indexFilter?: number[],
    nameFilter?: string[]
  ): Promise<ISeries[] | null> {
    try {
      if (!props?.length) return null;
      let result: IChartProperty[];
      result = EChartManager.filterByIndex(props, indexFilter);
      result = EChartManager.filterByName(props, result, nameFilter);

      /* костыль для реакторов, надо переделать: */
      if (result.length === 2) {
        const us = props
          .filter((x) => x.name[0] === 'U')
          .sort((x: any, y: any) => (x.order < y.order ? -1 : 1));

        const rs = props
          .filter((x) => x.name[0] === 'R')
          .sort((x: any, y: any) => (x.order < y.order ? -1 : 1));

        const xs = props
          .filter((x) => x.name[0] === 'X')
          .sort((x: any, y: any) => (x.order < y.order ? -1 : 1));

        result = [us[0], rs[0], xs[0]].filter((x) => !!x);
      }

      const items = new Set<IChartProperty>(result);
      const series: ISeries[] = [];
      for await (const p of items) {
        series.push({
          name: p.name,
          // TODO KILL p.select === 'f' ? 'avg_freq'
          // seriesKey: p.select === 'f' ? 'avg_freq' : p.calculatorUid,
          uid: p.calculatorUid,
          areaKey: `${p.name}, ${p.unit}`,
          select: p.select,
          scale: p.scale,
          uniqueKey: `${p.name},${p.calculatorUid}`,
        });
      }
      return series;
    } catch (e) {
      console.log(e.message);
      return null;
    }
  }
}
