export const POPUP_CHART_CONST = {
  GET_DATA: 'popupChart/getData',
  GET_DATA_SUCCESS: 'popupChart/getDataSuccess',
  GET_DATA_ERROR: 'popupChart/getDataError',
  DATA: 'popupChart/data',
  POPUP_CHART: 'popupChart',
};
const prefix = 'popupCharts/';
export const GET_POPUP_CHART_DATA = `${prefix}GetPopupChartData`;
export const CLEAN_POPUP_DATA = `${prefix}CleanPopupData`;
export const GET_POPUP_CHART_DATA_FAIL = `${prefix}GetPopupChartDataFail`;
export const POPUP_CHART_DATA = `${prefix}PopupChartData`;
export const POPUP_CHART_MOCK_DATA = `${prefix}PopupChartMockData`;

export const GET_QUERY_PARAMS = `${prefix}GetQueryParams`;
export const GET_QUERY_PARAMS_OK = `${prefix}GetQueryParamsOk`;
export const GET_QUERY_PARAMS_FAIL = `${prefix}GetQueryParamsFail`;
export const POPUP_CHART_QUERY_PARAMS = `${prefix}PopupChartQueryParams`;

export const T_ARRAY_START = 't_array_start';
export const T_ARRAY_STOP = 't_array_stop';

export const RANGE_QUERY_TYPE = 'range';
export const TIME_STAMP_QUERY_TYPE = 'timeStamp';

export const POPUP_SCALE_CONFIG = 'popupScaleConfig';

export const POPUP_FLAG = `${prefix}PopupFlag`;
