import axios, { AxiosInstance, AxiosResponse } from 'axios';
import fetch from 'node-fetch';

import { splitChunks } from '@/store/helpers';

export class RestApiService {
  service: AxiosInstance;
  constructor() {
    this.service = axios;
  }

  getFile(url: string, fileName: string, fileExtension: string) {
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      // const data = splitChunks(response.data);
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${fileName}.${fileExtension}`);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  }

  get<T = any>(url: string): Promise<AxiosResponse<T>> {
    return this.service.get<T>(url);
  }
  getSome(url: string) {
    return this.service.get(url);
  }

  delete<T = any>(url: string): Promise<AxiosResponse<T>> {
    return this.service.delete<T>(url).catch((error) => error);
  }

  put<T = any>(url: string, body?: any): Promise<AxiosResponse<T>> {
    return this.service.put<T>(url, body).catch((error) => error);
  }

  post<T = any>(url: string, body?: any): Promise<AxiosResponse<T>> {
    return this.service.post<T>(url, body).catch((error) => error);
  }

  async getParse<T = any>(url: string): Promise<T[] | null> {
    const response = await this.service.get<T>(url);
    return response.status === 200 ? splitChunks(response.data) : null;
  }

  // public async fetchAsStream(url: string, subject: Subject<any>) {
  //   const eventAgregator = new EventAggregator();
  //   const utf8Decoder = new TextDecoder('utf-8');
  //   const response = await fetch(url);
  //   const reader = response.body.getReader();
  //   async function go() {
  //     const { value: chunk, done: readerDone } = await reader.read();
  //     if (!readerDone) {
  //       const ch = chunk ? utf8Decoder.decode(chunk) : '';
  //       const d = ch.split('*');
  //       const data: any[] = [];
  //       if (d?.length) {
  //         d?.map(i => {
  //           try {
  //             const v = JSON.parse(i);
  //             if (v) {
  //               data.push(v);
  //             }
  //           } catch (e) {
  //             // ignore
  //           }
  //         });
  //         subject.next(data);
  //       }
  //       await go();
  //     }
  //   }
  //   await go();
  // }

  public async fetchData(url: string): Promise<any[]> {
    const response = await fetch(url);
    const arr = await response.text();
    return await new Promise((resolve, reject) => {
      if (response) {
        resolve(this.extract(arr));
      } else {
        reject(new Error('Response is undefined'));
      }
    });
  }

  public async getData(url: string) {
    return await new Promise((resolve, reject) => {
      this.service
        .get(url)
        .then((response) => {
          const r = this.extract(response.data);
          resolve(r);
        })
        .catch((err) => reject(err));
    });
  }

  extract(data: any) {
    const arr = data.split('*');
    const result: any[] = [];
    if (!arr?.length) {
      return;
    }
    (arr as []).map((i) => {
      if (i) {
        try {
          const parse = JSON.parse(i);
          if (parse) {
            result.push({ ...parse });
          }
        } catch (e) {
          // ignore
        }
      }
    });
    return result;
  }

  public async getStream(url: string): Promise<any[]> {
    return await new Promise<any[]>((resolve, reject) => {
      let target: any = undefined;
      return axios({
        url,
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain',
          'Transfer-Encoding': 'chunked',
        },
        responseType: 'stream',
        onDownloadProgress: (progressEvent) => {
          if (target === progressEvent.currentTarget.response) {
            return;
          }
          target = progressEvent.currentTarget.response;
          const arr = target.split('*');
          const result: any[] = [];
          if (!arr?.length) {
            reject('Empty data array!');
          }
          (arr as []).map((i) => {
            if (i) {
              try {
                const parse = JSON.parse(i);
                if (parse?.length) {
                  result.push({ ...parse });
                }
              } catch (e) {
                //
              }
            }
          });
          resolve(result);
        },
      });
    });
  }
}
