import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';

import { SensorsState } from './types';
import { SENSORS_STORE_CONST } from './constants';

export const getters: GetterTree<SensorsState, RootState> = {
  [SENSORS_STORE_CONST.EXPANDED_ITEMS](state): SensorsState['treeExpanded'] {
    const { treeExpanded } = state;
    return treeExpanded;
  },
  [SENSORS_STORE_CONST.SENSORS_DATA](state): SensorsState['sensors'] {
    const { sensors } = state;
    return sensors;
  },
  [SENSORS_STORE_CONST.SENSORS_STATES_DATA](state): SensorsState['sensorsStates'] {
    const { sensorsStates } = state;
    return sensorsStates;
  },
  [SENSORS_STORE_CONST.REFERENCES_DATA](state): SensorsState['references'] {
    const { references } = state;
    return references;
  },
  [SENSORS_STORE_CONST.EQUIPMENTS_DATA](state): SensorsState['equipments'] {
    const { equipments } = state;
    return equipments;
  },
};
