import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import {
  DataItem,
  DataQuery,
  DataState,
  MinAvgMax,
  Reference
} from '@/store/data/data.types';

export const getters: GetterTree<DataState, RootState> = {
  stateData(state): [DataItem[], DataItem[]] | undefined {
    const { data } = state;
    return data;
  },
  query(state): DataQuery | undefined {
    const { query } = state;
    return query;
  },
  phase(state): number | undefined {
    const { selectedPhase } = state;
    return selectedPhase;
  },

  getRefs(state): Reference[] | undefined {
    if (!state?.refs) return undefined;
    const { refs } = state;
    return refs;
  },
  getMam(state): MinAvgMax[] | undefined {
    const { mam } = state;
    return mam;
  }
};
