export type FilterType = 'name' | 'category' | 'value';
export interface IColumnFilter {
  type: FilterType;
  property: string;
}

export enum LevelType {
  Green = 1,
  Red = 2,
}

export interface IChartFlags {
  order?: number;
  level?: LevelType;
}

export interface TableCol extends IChartFlags {
  header?: string;
  props?: any;
  key?: string | number;
  property?: string;
  dbProps?: string[] | string;
  iconField?: string;
  mandatory: boolean;
  width?: number;
  tabLink?: number;
  sort?: string;
  transformer?: string;
  filter?: IColumnFilter;
  unit?: string;
  notes?: string;
  formatter?: any;
}

export interface TableRow {
  value?: any;
  property?: string;
}

export interface TableRowData<T> {
  item: T;
  $row: any;
}

export enum ColType {
  DateTime = 'DateTime',
  DateTimes = 'Datetime[]',
  Enum = 'Enum',
  Integer = 'Integer',
  Float = 'Float',
  String = 'String',
  string = 'string',
  Guid = 'Guid',
  Asset = 'Asset',
  Winding = 'Winding',
  StateEnum = 'StateEnum',
  StateEnums = 'StateEnum[]',
}

// export type ColType =
//   | 'DateTime'
//   | 'Enum'
//   | 'Integer'
//   | 'Float'
//   | 'String'
//   | 'string'
//   | 'Guid'
//   | 'Asset';
