









import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class EmptyData extends Vue {
  @Prop() message?: string;
  @Prop() icon?: string;
  get messageInfo(): string {
    return this.message ?? 'Нет данных!';
  }
  get iconClass(): string {
    return [this.icon ?? 'lab la-twitch', 'empty-data-icon'].join(' ');
  }
}
