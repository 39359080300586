import { Subject } from 'rxjs';
import { IBaseEvent } from '@/events/types';

export class EventAggregator {
  // events buffer
  private static __eventsMap: Map<string, Subject<any>>;

  // find or create new subject
  private static __useOrCreate(theme: string): Subject<any> | null {
    if (!EventAggregator.__eventsMap) EventAggregator.__eventsMap = new Map<string, Subject<any>>();
    if (!EventAggregator.__eventsMap.has(theme))
      EventAggregator.__eventsMap.set(theme, new Subject<any>());
    return EventAggregator.__eventsMap.get(theme) ?? null;
  }

  // subscribe
  public static register(theme: string): Subject<any> | null {
    return EventAggregator.__useOrCreate(theme);
  }

  // send message to all clients
  public static publish({ message, theme }: IBaseEvent) {
    try {
      EventAggregator.__useOrCreate(theme)?.next(message);
    } catch (e) {
      console.log(e.message);
    }
  }
}
