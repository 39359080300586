import { BookmarkItemType, IBookmark } from '@/store/bookmarks/types';
import { generateFolderName } from '@/store/bookmarks/bookmarks-dto/extensions';
import { v4 } from 'uuid';

export class BookmarksDto {
  public static GenerateFolder({ url, urlWithQuery }: IBookmark): IBookmark {
    return {
      uuid: v4(),
      type: BookmarkItemType.Folder,
      alias: generateFolderName(url ?? ''),
    };
  }

  public static GenerateLink({ url, urlWithQuery }: IBookmark): IBookmark {
    return {
      uuid: v4(),
      url,
      urlWithQuery,
      type: BookmarkItemType.Link,
      alias: url as string,
    };
  }
}
