import { Module } from 'vuex';

import { RootState } from '@/store/types';

import { BreadcrumbsState } from './types';
import { mutations } from './mutations';
import { getters } from './getters';

const initialState: BreadcrumbsState = {
  breadcrumbs: [],
};

export const breadcrumbs: Module<BreadcrumbsState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  getters,
};
