





































import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import { resetTimeOffset, slideRange } from '@/utils/datetime/DateTimeCorrector';
import { ROOT_STORE_CONST } from '@/store/root.constants';
import { BusyIndicator } from '@/store/types';
import { QueryParamsService } from '@/utils';
import { timeFrames, TimeFrameType } from '@/store/data/data.types';
import ArrowButton from '@/components/basics/charts/dynamic-history-chart/arrow-btn/ArrowButton.vue';
import LiveUpdate from '@/components/basics/charts/dynamic-history-chart/live-update/LiveUpdate.vue';
import { TimeFrameInterval } from '@/store/history-data-query/history-data-query.types';
import { buildSharpRange } from '@/utils/datetime/range-builder';
import {
  DATETIME_RANGE,
  GET_DATETIME_RANGE,
  UPDATE_DATETIME_RANGE,
} from '@/store/abstract/range-getter/constants';
import { HISTORY_CHARTS } from '@/store/history-charts/constants';
import { IDatetimeRange, IDatetimeRangeQuery } from '@/store/abstract/range-getter/types';
import {
  setDefaultRange,
  durationPreset,
  durationToTimeFrameAsync,
  getRangeFilter,
  getSecFromPresetAsync,
  getSecondsCount,
  protectFromFool,
  durationKeyFinder,
  getMaxEndDate,
  DurationPresetType,
durationToTimeFrame,
} from '@/components/basics/selectors/extensions';
import PresetSelector from '@/components/basics/selectors/preset-selector.vue';
import TimeFrameSelector from '@/components/basics/selectors/time-frame-selector.vue';
import { DETAILS_CONST } from '@/store/details';
import { IEquipDetails } from '@/store/details/types';
import { PhaseConverter } from '@/utils/PhaseConverter';
import { EventAggregator } from '@/events';
import { MAKE_RANGE_REQUEST } from '@/components/bookmarks/bookmarks-constants';

type QueryType = {
  start?: string;
  end?: string;
  interval?: string;
  offset?: string;
};

@Component({
  components: {
    'arrow-btn': ArrowButton,
    'live-update': LiveUpdate,
    'preset-selector': PresetSelector,
    'time-frame-selector': TimeFrameSelector,
  },
})
export default class DateTimeSelector extends Vue {
  queryParams!: QueryParamsService<QueryType>;
  timeFrames = timeFrames;

  // for initial range query
  @Prop() algorithm?: string;
  @Prop() query?: object;

  @Prop() timeFrame?: boolean;
  @Prop() denyFuture?: boolean;
  @Prop() autoRefresh?: boolean;
  @Prop() defaultPreset?: DurationPresetType;

  // todo kill topology ??
  @Prop() isTopology?: boolean;

  get isActionsBlocked(): boolean {
    return this.refreshState || (this.busyIndicator ? this.busyIndicator.isBusy : false);
  }

  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  equipmentDetails?: IEquipDetails;
  get assetUid(): string | null {
    const { phase } = this.$route?.query;
    if (!phase) return null;
    const ph = PhaseConverter.getPhase(phase as string);
    return (this.equipmentDetails as any)?.assetUids[ph as number] ?? null;
  }
  onTimeFrameChange(interval: string) {
    // need compare intervals
    this.$emit('interval', interval);
    // if can change time-frame means auto === false
    this.$emit('refresh', false);
    // should update start + end also
    if (!this.datetimeRangeQuery) return null;
    // set max available end date according to points limit for interval (and not in future)
    const q = this.queryParams.get();
    const start = resetTimeOffset(new Date(q.start))
    let end = resetTimeOffset(new Date(q.end))
    end = getMaxEndDate(start, end, interval as TimeFrameType)
    this.updateDatetimeRange({start: start.toISOString(), end: end.toISOString()})
    this.selectedTimeFrame.set(interval as TimeFrameType);
    //this.queryParams.update({ ...this.queryParams?.get(), interval });
  }
  async onPresetChange(val: any) {
    // if can change presets means auto === false
    this.$emit('refresh', false);

    const [interval, count] = await Promise.all([
      durationToTimeFrameAsync(val),
      getSecFromPresetAsync(val),
    ]);

    if (this.isTopology) {
      this.$emit('on-preset-changed', {
        interval,
        count,
      });
    } else {
      if (interval && count && this.datetimeRangeQuery) {
        this.getDatetimeRange({
          ...this.datetimeRangeQuery,
          interval,
          count,
        });
      }
    }

    if (interval) {
      this.selectedTimeFrame.set(interval);
    }

    // simple component behavior
    if (!this.datetimeRangeQuery) {
      const presetKey = durationKeyFinder(val);
      this.updateDatetimeRange(setDefaultRange(presetKey ?? 'hour'));
    }
  }

  private __interval: TimeFrameType = 'minute';
  selectedTimeFrame = {
    get: () => this.__interval,
    set: (payload: TimeFrameType) => {
      this.__interval = payload;
    },
  };
  get presets(): string[] {
    return Object.values(durationPreset);
  }

  refreshState = false;
  onActiveStateChanged(state: boolean) {
    this.refreshState = state;
    if (state) this.$emit('refresh', state);
  }
  onTick(datetime: Date) {
    const range = buildSharpRange(datetime, -this.refreshLag);
    const { start, end } = range;
    this.queryParams.update({
      start: start.toISOString(),
      end: end.toISOString(),
    });
  }
  get refreshLag(): number {
    switch (this.interval?.value) {
      case TimeFrameInterval.HOUR:
        return 1000 * 60 * 60 * 24;
      default:
      case TimeFrameInterval.MINUTE:
        return 1000 * 60 * 60 * 3;
      case TimeFrameInterval.SECOND:
        return 1000 * 60 * 10;
      case TimeFrameInterval.MS:
        return 1000 * 60;
    }
  }
  @Getter(ROOT_STORE_CONST.BUSY_INDICATOR)
  busyIndicator?: BusyIndicator;
  slideTimeRange(direction: string) {
    const { start, end } = this.queryParams.get();
    const range = slideRange(direction, start, end);
    this.queryParams.update({
      start: range[0],
      end: range[1],
    });
  }
  get pickerOptions() {
    const denyFuture = !!this.denyFuture;
    return {
      disabledDate(time: Date) {
        return denyFuture && time.getTime() >= Date.now() + 60 * 60 * 1000;
      },
    };
  }
  get intervalValue(): string | null {
    const { interval } = this.$route.query;
    if (!interval) return null;
    return interval as string;
  }
  get interval() {
    const { interval } = this.$route.query;
    return timeFrames.find((x) => x.value === interval);
  }

  @Getter(DATETIME_RANGE, { namespace: HISTORY_CHARTS })
  datetimeRange!: IDatetimeRange;
  // this is range getter
  get selectedRange() {
    const { start, end } = this.$route.query;
    return [start, end];
  }

  onRangeChange(range: [Date | null, Date | null] | null) {
    if (!range) return;
    const [start, end] = range;
    const i = protectFromFool(range);
    if (!i) return;
    this.selectedTimeFrame.set(i);
    this.updateDatetimeRange({
      start: resetTimeOffset(start as Date),
      end: resetTimeOffset(end as Date),
    });
  }

  onPeriodChange(val: string) {
    this.onRangeChange(getRangeFilter(val));
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      start: this.$route?.query?.start,
      end: this.$route?.query?.end,
      interval: this.$route?.query?.interval,
    }));
  }

  initFlag = false;
  @Watch('datetimeRange') onDatetimeRange(val?: IDatetimeRange, old?: IDatetimeRange) {
    if (val === old) return;
    if (!this.initFlag) {
      if (!val) {
        this.updateDatetimeRange(setDefaultRange());
        return;
      }
      this.queryParams.initialise({
        start: val?.start as string,
        end: val?.end as string,
        interval: this.selectedTimeFrame.get(),
      });
      this.initFlag = true;
    } else {
      const offset = this.$route.query.offset;
      this.queryParams.update({
        start: val?.start as string,
        end: val?.end as string,
        interval: this.selectedTimeFrame.get(),
        offset: !offset ? undefined : '0',
      });
    }
  }
  beforeDestroy() {
    this.initFlag = false;
  }
  // this is initial range query
  get datetimeRangeQuery(): any {
    if (!this.algorithm) {
      return null;
    } else if (this.query) {
      if (this.$route?.query?.interval || !this.defaultPreset) {
        const tf = this.$route?.query?.interval || this.selectedTimeFrame.get();
        return {
          ...this.query,
          interval: tf,
          count: getSecondsCount(tf as TimeFrameType),
          algorithm: this.algorithm,
        };
      } else {
        const tf = durationToTimeFrame(durationPreset[this.defaultPreset] || durationPreset['hour']) || this.selectedTimeFrame.get();
        this.selectedTimeFrame.set(tf);
        return {
          ...this.query,
          interval: tf,
          count: getSecondsCount(tf),
          algorithm: this.algorithm,
        };
      }
    } else if (this.assetUid) {
      const tf = this.$route?.query?.interval || this.selectedTimeFrame.get();
      return {
        asset_uuid: this.assetUid as string,
        interval: tf,
        count: getSecondsCount(tf as TimeFrameType),
        algorithm: this.algorithm,
      };
    } else {
      return null;
    }
  }

  makeRangeQuery() {
    if (this.datetimeRangeQuery) {
      this.getDatetimeRange(this.datetimeRangeQuery);
    } else {
      // no query
      this.updateDatetimeRange(setDefaultRange(this.defaultPreset));
    }
  }

  // request datetime range for selected period
  @Action(GET_DATETIME_RANGE, { namespace: HISTORY_CHARTS })
  getDatetimeRange!: (query: IDatetimeRangeQuery) => void;
  @Action(UPDATE_DATETIME_RANGE, { namespace: HISTORY_CHARTS })
  updateDatetimeRange!: (payload: IDatetimeRange) => void;
  mounted() {
    this.makeRangeQuery();
    EventAggregator.register(MAKE_RANGE_REQUEST)?.subscribe((msg) => {
      this.makeRangeQuery();
    });
    
  }
}
