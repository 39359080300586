

export const HIST_DATA_CONST = {
  REFRESH_HISTORY_DATA: 'refreshHistoryData',
  GET_HISTORY_DATA: 'getHistoryData',
  GET_HISTORY_DATA_SUCCESS: 'getHistoryDataSuccess',
  GET_HISTORY_DATA_ERROR: 'getHistoryDataError',

  GET_LIVE_DATA_BUFFER: 'getLiveDataBuffer',
  GET_LIVE_DATA_BUFFER_SUCCESS: 'getLiveDataBufferSuccess',
  GET_LIVE_DATA_BUFFER_ERROR: 'getLiveDataBufferError',
  LIVE_DATA_BUFFER: 'liveDataBuffer',

  UPDATE_PROPERTIES_SCHEME: 'updatePropertiesScheme',
  UPDATE_PROPERTIES_SCHEME_SUCCESS: 'updatePropertiesSchemeSuccess',
  UPDATE_PROPERTIES_SCHEME_ERROR: 'updatePropertiesSchemeError',

  UPD_COLORS: 'updColors',
  UPD_COLORS_SUCCESS: 'updColorsSuccess',
  UPD_COLORS_ERROR: 'updColorsError',
  COLOR_SCHEME: 'colorScheme',

  UPD_LEGEND_DATA: 'updLegendData',
  UPD_LEGEND_DATA_SUCCESS: 'updLegendDataSuccess',
  UPD_LEGEND_DATA_ERROR: 'updLegendDataError',
  LEGEND_DATA: 'legendData',

  HISTORY_DATA: 'historyData',

  CHANGE_CHART_SCHEME: 'changeChartScheme',
  CHANGE_CHART_SCHEME_SUCCESS: 'changeChartSchemeSuccess',
  CHANGE_CHART_SCHEME_ERROR: 'changeChartSchemeError',
  HISTORY_DATA_SET: 'historyDataSet',

  PROPS_SCHEME: 'propsScheme'
};
