import { ActivityType, IStatus, IStatusRecord, statusBarTypeConst } from '@/store/status-bar/types';
import { Subject } from 'rxjs';
import { getStatusMessage } from '@/store/status-bar/ru-translater';
import moment from 'moment';
import { parseUrlPart } from '@/store/status-bar/extensions';

const initialStatus: IStatus = {
  statusType: 'undefined',
  message: statusBarTypeConst.undefined,
  dateTime: new Date(),
  activeTasks: 0,
};

export class StatusBarManager {
  private static __activeRequests: Map<string, any>;
  private static __status?: IStatus;
  public static status = (): IStatus => {
    if (!StatusBarManager.__status) StatusBarManager.__status = initialStatus;
    return StatusBarManager.__status;
  };
  private static set() {
    if (StatusBarManager.__activeRequests.size <= 0) {
      StatusBarManager.__status = {
        statusType: 'done',
        message: getStatusMessage(StatusBarManager.__activeRequests.size),
        activeTasks: 0,
        dateTime: new Date(),
      };
    } else {
      StatusBarManager.__status = {
        statusType: 'progress',
        message: getStatusMessage(StatusBarManager.__activeRequests.size),
        activeTasks: StatusBarManager.__activeRequests.size,
        dateTime: new Date(),
      };
    }
  }

  // history map
  private static __historyMap = new Map<string, IStatusRecord>();
  private static updateHistoryBuffer(record: IStatusRecord) {
    StatusBarManager.__historyMap.set(record.key, record);
  }
  public static resetHistoryBuffer() {
    StatusBarManager.__historyMap.clear();
  }
  public static getHistory(): IStatusRecord[] {
    return [...this.__historyMap.values()];
  }

  private static __subject = new Subject();
  public static onChange(): Subject<any> {
    return StatusBarManager.__subject;
  }

  public static change(payload: any, activity: ActivityType) {
    this.__subject.next({ payload, activity });
  }

  public static request(req: any) {
    if (!StatusBarManager.__activeRequests) {
      StatusBarManager.__activeRequests = new Map<string, any>();
    }
    const { url } = req;
    StatusBarManager.__activeRequests.set(url, req);
    StatusBarManager.set();
    StatusBarManager.updateHistoryBuffer({
      key: url,
      note: parseUrlPart(url),
      dateTime: moment(new Date()).format('HH:mm:ss DD-MM-YYYY'),
      type: 'request',
      start: new Date(),
    });
  }
  public static response(resp: any) {
    if (!StatusBarManager.__activeRequests) {
      StatusBarManager.__activeRequests = new Map<string, any>();
    }
    const { url } = resp?.config;
    StatusBarManager.__activeRequests.delete(url);
    StatusBarManager.set();
    const req = StatusBarManager.__historyMap.get(url);
    if (!req) return;
    StatusBarManager.updateHistoryBuffer({
      key: url,
      note: parseUrlPart(url),
      dateTime: moment(new Date()).format('HH:mm:ss DD-MM-YYYY'),
      type: 'response',
      status: resp?.statusText,
      end: new Date(),
      start: req?.start,
    });
  }
}
