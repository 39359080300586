























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { IChartProperty } from '@/store/abstract/props-scheme/types';
import { Action } from 'vuex-class';
import { HISTORY_CHARTS, REQUEST_DATA, RESET_EXTRA_DATA } from '@/store/history-charts/constants';
import { IHistoryChartsQuery, IMaxAvgMin } from '@/store/history-charts/types';
import { IPropertyScheme } from '@/store/history-data/history-data.types';
import { Subject } from 'rxjs';
import {
  getSelectedProperty,
  saveSelectedProperty,
} from '@/components/basics/charts/history-chart/legend-item/extensions';
import { IEquipDetails } from '@/store/details/types';
import { DataConverter } from '@/components/basics/table/converters/DataConverter';
import { ColType } from '@/components/basics/table/basic-table.types';
type QueryType = {
  start?: string;
  end?: string;
};

@Component
export default class LegendItem extends Vue {
  @Prop() areaKey!: string;
  @Prop() query?: any;
  @Prop() properties?: IChartProperty[];
  @Prop() index!: number;
  @Prop() mam?: IMaxAvgMin;
  @Prop() refresh!: Subject<any>;
  @Prop() equipDetails!: IEquipDetails | null;

  mounted() {
    this.refresh.subscribe((query) => {
      // this one update on refresh with future time query
      this.reQuery(this.historyChartsQuery, this.areaKey, this.selectedProp);
    });
  }

  get fullProperty(): IChartProperty | null {
    return this.properties?.find((i) => i.name === this.selectedProperty) ?? null;
  }

  get props() {
    const fProps = this.properties?.filter((i) => (i.order ? i.order > 0 : false));
    if (Number(this.index) >= 0 && fProps?.length && fProps?.length - 1 >= Number(this.index)) {
      const key = `${this.equipDetails?.typeUid},${this.areaKey}`;
      getSelectedProperty(key, fProps)
        .then((p: string | null) => {
          if (p) {
            this.selectedProperty = p;
          } else {
            this.selectedProperty = fProps[Number(this.index)].name;
          }
        })
        .catch((e) => {
          console.log(e.message);
          this.selectedProperty = fProps[Number(this.index)].name;
        });
    }

    return fProps ?? [];
  }
  selectedProperty = '';

  get selectedProp(): IChartProperty | null {
    if (!this.properties?.length) {
      return null;
    }
    const prop = this.properties?.find((i) => i.name === this.selectedProperty);
    return prop ?? null;
  }

  get historyChartsQuery(): IHistoryChartsQuery | null {
    if (!this.query) return null;
    const { start, end, interval, phase } = this.query;
    if (!start || !end || !interval) return null;
    const prop = this.selectedProp;
    if (!prop) return null;
    const { select, calculatorName, algorithm, calculatorUid, uuid } = prop;
    if (!select || !algorithm) return null;

    return {
      phase,
      start,
      end,
      interval: interval.value,
      select: select,
      algorithm,
      calculator_name: calculatorName,
      calculator_uuid: calculatorUid,
      asset_uuid: uuid,
    };
  }

  // reaction on query change
  @Watch('query') onQuery(val?: any, old?: any) {
    if (val === old) return;
    if (old.start === val.start && old.end === val.end && val.interval === old.interval) return;
    const query = this.historyChartsQuery;
    this.reQuery(query, this.areaKey, this.selectedProp);
  }
  // reaction on property change
  @Watch('selectedProperty') async OnSelectedProperty(val?: string, old?: string) {
    if (val === old || !this.historyChartsQuery) return;
    if (new Date(this.historyChartsQuery?.end as string)?.getTime() >= new Date().getTime()) {
      // trigger to upd all
      this.$emit('refreshAllSelectors', this.historyChartsQuery);
      return;
    }
    this.reQuery(this.historyChartsQuery, this.areaKey, this.selectedProp);
    if (this.fullProperty && this.equipDetails) {
      const { typeUid } = this.equipDetails;
      await saveSelectedProperty(this.fullProperty, `${typeUid},${this.areaKey}`);
    }
  }

  @Action(REQUEST_DATA, { namespace: HISTORY_CHARTS })
  getData!: (query: [IHistoryChartsQuery, string, IPropertyScheme]) => void;

  // @Action(RESET_EXTRA_DATA, { namespace: HISTORY_CHARTS })
  // resetData!: (query: string) => void;

  reQuery(query?: IHistoryChartsQuery | null, areaKey?: string, selected?: IPropertyScheme | null) {
    if (query && areaKey && selected) {
      // reset max avg min for area
      // this.resetData(areaKey);
      this.getData([query, this.areaKey, selected]);
    }
  }

  format = (num: number | undefined, format: number | undefined) => {
    if ( !format ) return '';
    if ( num === null || num === undefined ) return '';
    return  DataConverter.convert(num , {Type: ColType.Float, Format: format});
  };

  get max(): string {
    if(this.mam?.max === undefined) return '-'
    return this.format(this.mam?.max, this.fullProperty?.axisScale);
  }
  get avg(): string {
    if(this.mam?.avg === undefined) return '-'
    return this.format(this.mam?.avg, this.fullProperty?.axisScale);
  }
  get min(): string {
    if(this.mam?.min === undefined) return '-'
    return this.format(this.mam?.min, this.fullProperty?.axisScale);
  }
  get unit() {
    if (!this.properties?.length) {
      return '';
    }
    const p = this.properties?.find((i) => i.name === this.selectedProperty);
    return p ? p.unit : '';
  }
}
