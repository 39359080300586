import { Module } from 'vuex';
import {
  IDatetimeRange,
  IDatetimeRangeQuery,
  IRangeGetterState,
} from '@/store/abstract/range-getter/types';
import { RootState } from '@/store/types';
import moment from 'moment';
import { MOMENT_QUERY_FORMAT } from '@/utils/datetime/DateTimeRangePresets';
import {
  DATETIME_RANGE,
  DATETIME_RANGE_ERROR,
  GET_DATETIME_RANGE,
  GET_DATETIME_RANGE_FAIL,
  UPDATE_DATETIME_RANGE,
  UPDATE_DATETIME_RANGE_FAIL,
} from '@/store/abstract/range-getter/constants';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { RestApiService } from '@/services';
import { QueryBuilder } from '@/utils/QueryBuilder';
import { transformTimeRange2 } from '@/store/abstract/range-getter/extensions';
import {
  Preloader,
  PRELOADER_DICT,
  preloaderActions,
  preloaderGetters,
  preloaderInitialState,
  preloaderMutations,
} from '@/store/abstract/preloader';
const rest = new RestApiService();
const path = getApiUrl(TR_TYPE.HTTP, '/click-house/paginator/dateRange');

const defaultRange: IDatetimeRange = {
  start: moment(new Date()).format(MOMENT_QUERY_FORMAT.dateToSec),
  end: moment(new Date()).format(MOMENT_QUERY_FORMAT.dateToSec)
};
const initialState: IRangeGetterState = {
  range: { ...defaultRange, default: true },
  rangeError: false,
  ...preloaderInitialState,
};

export const dateTimeRange: Module<IRangeGetterState, RootState> = {
  state: initialState,
  actions: {
    ...preloaderActions,
    [GET_DATETIME_RANGE]: async (ctx, payload: IDatetimeRangeQuery) => {
      try {
        await Preloader.start(ctx, PRELOADER_DICT.DATA_SEARCH);
        const query = QueryBuilder.build(payload);
        const resp = await rest.get(`${path}?${query}`);
        ctx.commit(GET_DATETIME_RANGE, transformTimeRange2(resp.data[0]));
      } catch (e) {
        ctx.commit(GET_DATETIME_RANGE_FAIL, e);
      } finally {
        await Preloader.stop(ctx);
      }
    },
    [UPDATE_DATETIME_RANGE]: async (ctx, payload) => {
      try {
        await Preloader.start(ctx, PRELOADER_DICT.DATA_SEARCH);
        ctx.commit(UPDATE_DATETIME_RANGE, transformTimeRange2(payload));
      } catch (e) {
        ctx.commit(UPDATE_DATETIME_RANGE_FAIL, e);
      } finally {
        await Preloader.stop(ctx);
      }
    },
  },
  mutations: {
    ...preloaderMutations,
    [UPDATE_DATETIME_RANGE]: (state, payload) => {
      state.rangeError = false;
      state.range = payload;
    },
    [GET_DATETIME_RANGE]: (state, payload) => {
      state.rangeError = false;
      state.range = payload;
    },
    [GET_DATETIME_RANGE_FAIL]: (state, payload) => {
      state.rangeError = payload;
      state.range = defaultRange;
    },
    [UPDATE_DATETIME_RANGE_FAIL]: (state, payload) => {
      state.rangeError = payload;
      state.range = defaultRange;
    },
  },
  getters: {
    ...preloaderGetters,
    [DATETIME_RANGE]: ({ range }) => range,
    [DATETIME_RANGE_ERROR]: ({ rangeError }) => rangeError,
  },
};
