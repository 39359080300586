import { Module } from 'vuex';
import { IStatusBarState, statusBarTypeConst } from '@/store/status-bar/types';
import { RootState } from '@/store/types';
import { StatusBarManager } from '@/store/status-bar/StatusBarManager';
import {
  STATUS_ON_REQUEST,
  STATUS,
  STATUS_ON_RESPONSE,
  UPDATE_STATUS,
  STATUS_BAR_HISTORY,
  RESET_STATUS_BAR_HISTORY,
} from '@/store/status-bar/constants';

const initial: IStatusBarState = {
  error: false,
  status: {
    dateTime: new Date(),
    message: statusBarTypeConst.undefined,
    statusType: 'undefined',
    activeTasks: 0,
  },
  history: [],
};

export const statusBar: Module<IStatusBarState, RootState> = {
  namespaced: true,
  state: initial,
  actions: {
    [RESET_STATUS_BAR_HISTORY]: (context) => {
      try {
        StatusBarManager.resetHistoryBuffer();
        context.commit(STATUS_BAR_HISTORY, []);
      } catch (e) {
        context.commit(STATUS_BAR_HISTORY, []);
      }
    },
    [STATUS_BAR_HISTORY]: (context, payload) => {
      try {
        context.commit(STATUS_BAR_HISTORY, payload);
      } catch (e) {
        context.commit(STATUS_BAR_HISTORY, []);
      }
    },
    [STATUS_ON_REQUEST]: (context, payload) => {
      try {
        StatusBarManager.request(payload);
        context.commit(UPDATE_STATUS, StatusBarManager.status());
        context.commit(STATUS_BAR_HISTORY, StatusBarManager.getHistory());
      } catch (e) {
        console.log(e.message);
      }
    },
    [STATUS_ON_RESPONSE]: (context, payload) => {
      try {
        StatusBarManager.response(payload);
        context.commit(UPDATE_STATUS, StatusBarManager.status());
        context.commit(STATUS_BAR_HISTORY, StatusBarManager.getHistory());
      } catch (e) {
        console.log(e.message);
      }
    },
  },
  mutations: {
    [STATUS_BAR_HISTORY]: (state, payload) => {
      state.history = payload;
    },
    [UPDATE_STATUS]: (state, payload) => {
      state.status = { ...payload };
    },
  },
  getters: {
    [STATUS]: ({ status }) => status,
    [STATUS_BAR_HISTORY]: ({ history }) => history,
  },
};
