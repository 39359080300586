import { DataConverter } from '@/components/basics/table/converters/DataConverter';

export const tableFilters = {
  transformMatrixCell: (row: any, { value, key }: any, initialRow: any) => {
    if (Number.isNaN(Number(value))) return row[value];
    if (row[value] === '-') return '-';
    if (initialRow?.enumScale) {
      const values = Object.values(initialRow?.enumScale);
      if (Array.isArray(values) && values.length) {
        const scale = values[0].scale ?? 1;
        const scaled = row[value] * scale;
        return scaled.toFixed(3);
      }
    }
    return row[value];
  },
  transform: (row: any, col: any) => {
    if (!col?.property || !row) return '';
    const val = row[col.property];
    if (val === null || val === undefined) return '';
    const postFormat = row.enumFormatMap?.get(col.property);
    if (postFormat) col.Format = postFormat;
    return DataConverter.convert(val, col);
  },
  // todo kill this
  simpleTransform: (row: any, col: any) => {
    if (!col?.property || !row) return '';
    const val = row[col.property];
    if (val === null || val === undefined) return '';
    return val;
  },
  qualityConverter: (val: any) => (Number(val) >= 1 ? 'не полные' : 'полные'),
  stateConverter: (val: any) => {
    return (Math.round(Number(`${val}`.replace('%', '')) * 100) / 100).toFixed(1) + ' %';
  },
};
