import { Module } from 'vuex';
import { DirtyEnum, DirtyTypes, IDirtyState } from '@/store/abstract/dirty/types';
import { RootState } from '@/store/types';
import {
  CLEAR_ITEMS,
  DIRTY_FAIL,
  DIRTY_ITEMS,
  DIRTY_SUCCESS,
  IS_DIRTY,
  RESET_DIRTY,
  SET_CLEAR_ITEMS,
  UPDATE_DIRTY,
} from '@/store/abstract/dirty/constants';

export const initialDirtyState: IDirtyState = {
  isDirty: false,
  dirtyItems: new Map<string, number | string>(),
  error: false,
  clearItems: new Map<string, number | string>(),
  changes: null,
};

export const dirty: Module<IDirtyState, RootState> = {
  actions: {
    [SET_CLEAR_ITEMS]: (context, payload: Record<string, any>) => {
      try {
        const clear = new Map<string, number | string>();
        for (const [key, value] of Object.entries(payload)) {
          if (value.type === 'Enum') {
            // get Enum order
            const find = value.options.find((i: any) => i === value.value);
            clear.set(key, value.options.indexOf(find));
          } else {
            clear.set(
              key,
              Array.isArray(value['value'])
                ? value['value'].map((x: any) => ({ ...x }))
                : value['value']
            );
          }
        }
        context.commit(SET_CLEAR_ITEMS, clear);
      } catch (e) {
        context.commit(DIRTY_FAIL, e);
      }
    },

    [RESET_DIRTY]: (context) => {
      context.commit(RESET_DIRTY);
    },
    [UPDATE_DIRTY]: (context, payload: Record<DirtyTypes, string | number>) => {
      try {
        // new values set
        const assetUid = payload[DirtyEnum.AssetUid] as string;
        const uid = payload[DirtyEnum.Uid] as string;
        const value = payload[DirtyEnum.Value];

        if (!assetUid || !uid || !value) return;

        const { state } = context;
        const { changes, clearItems } = state;
        let _changes = { ...changes };

        // initial pre-set value
        const initialValue = clearItems.get(uid);

        let group = (_changes as any)[assetUid];
        if (group) {
          let item = group[uid];
          if (item) {
            if (JSON.stringify(value) === JSON.stringify(initialValue)) {
              // kill the same value
              delete group[uid];
            } else {
              item = value;
              group[uid] = item;
            }
          } else {
            group = { ...group, [uid]: value };
          }
          if (!Object.values(group)?.length) {
            // kill empty group
            delete (_changes as any)[assetUid];
          } else {
            (_changes as any)[assetUid] = group;
          }
        } else {
          _changes = { ..._changes, [assetUid]: { [uid]: value } };
        }
        context.commit(UPDATE_DIRTY, { changes: _changes, isDirty: Object.keys(_changes)?.length });
      } catch (e) {
        context.commit(DIRTY_FAIL, e);
      }
    },
  },
  mutations: {
    [UPDATE_DIRTY]: (state, payload: Partial<IDirtyState>) => {
      state.changes = { ...payload?.changes };
      state.isDirty = payload?.isDirty ?? false;
    },
    [RESET_DIRTY]: (state) => {
      state.changes = null;
      state.dirtyItems.clear();
      state.isDirty = false;
      state.error = false;
    },
    [SET_CLEAR_ITEMS]: (state, payload) => {
      state.clearItems = payload;
      state.error = false;
    },
    [DIRTY_SUCCESS]: (state, payload: IDirtyState) => {
      const { isDirty, dirtyItems } = payload;
      state.isDirty = isDirty;
      state.dirtyItems = dirtyItems;
      state.error = false;
    },
    [DIRTY_FAIL]: (state, payload) => {
      state.error = payload;
    },
  },
  getters: {
    [CLEAR_ITEMS]: ({ clearItems }) => clearItems,
    [DIRTY_ITEMS]: ({ dirtyItems }) => dirtyItems,
    [IS_DIRTY]: ({ isDirty }) => isDirty,
  },
};
