import { MutationTree } from 'vuex';
import { DataState, MinAvgMax } from '@/store/data/data.types';

export const mutations: MutationTree<DataState> = {
  dataLoaded(state: DataState, payload: any) {
    state.error = false;
    state.data = payload;
  },
  dataError(state: DataState, error: Error) {
    state.data = undefined;
    state.error = true;
  },

  queryError(state: DataState) {
    state.query = undefined;
  },

  queryUpdated(state: DataState, payload) {
    state.query = payload;
  },

  phaseError(state: DataState) {
    state.selectedPhase = undefined;
  },
  phaseChanged(state: DataState, payload: number) {
    state.selectedPhase = payload;
  },

  updateRefsError(state: DataState, error: any) {
    state.refs = [];
  },
  refsUpdated(state: DataState, payload?: any) {
    state.refs = payload;
  },
  mamUpdated(state: DataState, payload: MinAvgMax[]) {
    state.mam = payload;
  },
  mamError(state, payload) {
    state.mam = undefined;
  }
};
