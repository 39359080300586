
const prefix = 'abstract/dataGetter/';

// data
export const GET_DATA = `${prefix}getData`;
export const GET_DATA_SUCCESS = `${prefix}getDataSuccess`;
export const GET_DATA_FAIL = `${prefix}getDataFail`;
export const DATA = `${prefix}data`;

// dataset
export const GET_DATASET = `${prefix}getDataset`;
export const GET_DATASET_SUCCESS = `${prefix}getDatasetSuccess`;
export const GET_DATASET_FAIL = `${prefix}getDatasetFail`;
export const DATASET = `${prefix}dataset`;
