import { EChartOption } from 'echarts';
import XAxis = echarts.EChartOption.XAxis;
import Type = echarts.EChartOption.BasicComponents.CartesianAxis.Type;
import moment from 'moment';
import YAxis = echarts.EChartOption.YAxis;

import { DataConverter } from '@/components/basics/table/converters/DataConverter';
import { ColType } from '@/components/basics/table/basic-table.types';

export const xAxisBasic = (
  gridIndex = 0,
  type: Type = 'category',
  hasLabels = false,
  xAxisFormat?: string
): XAxis => {
  return {
    gridIndex,
    type,
    boundaryGap: false,
    // splitLine: { show: hasLabels },
    axisLabel: {
      show: hasLabels,
      formatter: (value: Date) => moment(value).format(xAxisFormat),
    },
    axisTick: { show: hasLabels },
  };
};
const yAxisFormatterScale = (value: any, format: string|undefined) => {
  return  DataConverter.convert(value , {Type: ColType.Float, Format: format});
};

export const yAxisBasic = (gridIndex = 0, type: Type, yAxisFormat: string|undefined, scale: boolean ): YAxis => {
  return {
    nameGap: 10,
    scale,
    type,
    gridIndex,
    axisLabel: {
      formatter: yAxisFormat 
        ? (value: any) => yAxisFormatterScale(value, yAxisFormat)
        : (value: any) => Number(value)
    }
  };
};
