import {
  ICpuUsage,
  IDiskUsage,
  IMemoryUsage,
  IUsageConfigItem,
  IUsageMessage,
} from '@/store/usage-info';
import {
  IBarDataItem,
  IUsageChartSettings,
  IUsageServerGroup,
  UsageItemType,
} from '@/services/usage/types';
import { buildBasicChartMap, getScale } from '@/services';

const servers = {
  core: 'Нижний уровень',
  upper: 'Верхний уровень',
};

export class DataManager {
  public static buildServerGroups(
    config: IUsageConfigItem[],
    data: IUsageMessage[]
  ): IUsageServerGroup[] {
    return data?.map((d) => ({
      title: (servers as any)[d.server],
      data: d,
      settings: buildBasicChartMap(config, d),
    }));
  }

  public static extract({ type, scale }: IUsageChartSettings, data: IUsageMessage): IBarDataItem[] {
    if (!type || !data) return [];
    switch (type) {
      case UsageItemType.CPU:
        return this.lastCpus(data);
      case UsageItemType.HARD_DRIVE:
        return this.lastHardDrive(data, scale);
      case UsageItemType.MEMORY:
        return this.lastMemory(data, scale);
      default:
        return [];
    }
  }

  public static lastHardDrive(data: IDiskUsage, scale: number): IBarDataItem[] {
    const { diskUsed } = data;
    return [{ value: diskUsed * scale, name: 'HD' }];
  }

  public static lastMemory(data: IMemoryUsage, scale: number): IBarDataItem[] {
    const { memUsed } = data;
    return [{ value: memUsed * scale, name: 'RAM' }];
  }

  public static lastCpus(data: ICpuUsage): IBarDataItem[] {
    const { cpuPercents } = data;
    return [...cpuPercents?.map((p, j) => ({ name: '' + (j + 1), value: p }))];
  }
}
