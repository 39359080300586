import { IDataConverter, ValueFormat } from '@/components/basics/table/converters/types';
import { MOMENT_FORMAT } from '@/utils/datetime/DateTimeRangePresets';
import moment from 'moment';

export class DateTimeConverter implements IDataConverter {
  private static _roundTime(dt: Date): Date {
    const ms = dt.getMilliseconds();
    if (ms >= 950) {
      return new Date(dt.getTime() + (1000 - ms));
    }
    return dt;
  }

  private static addZeros(ms: number): string {
    if (ms < 10) return `00${ms}`;
    if (ms < 100) return `0${ms}`;
    return `${ms}`;
  }

  // we need anti ms .X format (don't ask why)
  private static _roundMs(dt: Date): string {
    const ms = DateTimeConverter.addZeros(dt.getMilliseconds())
      .split('')
      .map((i) => Number(i));
    if (!ms?.length) return '0';
    if (ms.length === 1) return `${ms[0]}`;
    const str = [`${ms[0]}.${ms[1]}${ms[2] ?? ''}`].join('');
    const n = Number(str);
    return Math.round(n).toString();
  }

  convert(value: any, opts: any): any {
    const { Format } = opts;

    if ((Format as ValueFormat) === '.ms') {
      if (typeof value === 'string') {
        const d = moment(value).format('DD/MM/YYYY HH:mm:ss.S');
        return d === 'Invalid date' ? value : d;
      } 
      const dt = new Date(value / 1_000_000);
      const rndTime = DateTimeConverter._roundTime(dt);
      const ms = DateTimeConverter._roundMs(rndTime);

      const r0 = moment(rndTime).format('YYYY-MM-DD HH:mm:ss');

      return moment(r0 + '.' + ms).format('DD/MM/YYYY HH:mm:ss.S');
    } else if ((Format as ValueFormat) === 'mm:ss.ms') {
      const dt = new Date(value / 1_000_000);
      return moment(dt).format(MOMENT_FORMAT.minSecMss);
    } else if ((Format as ValueFormat) === 'hh:mm:ss.ms') {
      const dt = new Date(value / 1_000_000);
      return moment(dt).format(MOMENT_FORMAT.hourMinSecMss);
    }

    return value;
  }
}
