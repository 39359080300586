import { MutationTree } from 'vuex';
import { ChartDataState, MinAvgMax } from '@/store/chart-data/chart-data.types';
import { debugLog } from '@/store/chart-data/chart-data.extensions';

export const mutations: MutationTree<ChartDataState> = {
  dataLoaded(state: ChartDataState, payload: any) {
    state.error = false;
    state.data = payload;
  },

  dataError(state: ChartDataState, error: Error) {
    state.data = undefined;
    state.error = true;
    debugLog('chartDataError: ' + error);
  },

  queryError(state: ChartDataState) {
    state.query = undefined;
  },

  queryUpdated(state: ChartDataState, payload) {
    state.query = payload;
    debugLog(`ChartData.mutations query: `, state.query);
  },

  phaseError(state: ChartDataState) {
    state.selectedPhase = undefined;
  },
  phaseChanged(state: ChartDataState, payload: number) {
    state.selectedPhase = payload;
  },

  updateRefsError(state: ChartDataState, error: any) {
    state.refs = [];
    debugLog(`ChartData.updateRefsError() -> err: ${error.message}`);
  },
  refsUpdated(state: ChartDataState, payload?: any) {
    state.refs = payload;
    debugLog(`ChartData.refsUpdated() -> payload: ${payload}`);
  },
  mamUpdated(state: ChartDataState, payload: MinAvgMax[]) {
    state.mam = payload;
  },
  mamError(state, payload) {
    state.mam = undefined;
    debugLog(payload.message);
  },
};
