export enum SENSORS_STORE_CONST {
  TREE_ITEMS_EXPAND = 'sensorsTreeItemExpand',
  TREE_ITEMS_COLLAPSE = 'sensorsTreeItemsCollapse',
  TREE_ITEMS_COLLAPSE_ALL = 'sensorsTreeItemsCollapseAll',
  GET_ALL_DATA = 'sensorsGetAllData',
  GET_SENSORS = 'sensorsGetSensors',
  GET_SENSORS_STATES = 'sensorsGetSensorsStates',
  SAVE_SENSOR = 'sensorsSaveSensor',
  SAVE_SENSORS = 'sensorsSaveSensors',
  SAVE_SENSOR_AND_ASSETS = 'sensorsSaveSensorAndAssets',

  UPDATE_SENSORS = 'sensorsUpdateSensorsData',
  UPDATES_REFERENCES = 'sensorsUpdateReferencesData',
  UPDATE_SENSORS_STATES = 'sensorsUpdateSensorsStatesData',
  UPDATE_EQUIPMENTS = 'sensorsUpdateEquipmentsData',

  SENSORS_DATA = 'sensorsSensorsData',
  EQUIPMENTS_DATA = 'sensorsEquipmentsData',
  REFERENCES_DATA = 'sensorsReferencesData',
  SENSORS_STATES_DATA = 'sensorsStatesData',
  EXPANDED_ITEMS = 'sensorsTreeExpanded',

  SENSORS = 'sensors',
}
