export enum CHART_DATA_CONST {
  // data
  GET_DATA = 'getData',
  DATA_LOADED = 'dataLoaded',
  DATA_ERROR = 'dataError',
  DATA = 'chartData',
  STATE_DATA = 'stateData',

  // query
  QUERY = 'query',
  QUERY_UPDATED = 'queryUpdated',
  QUERY_ERROR = 'queryError',
  UPDATE_QUERY = 'updateQuery',

  // phase
  CHANGE_PHASE = 'changePhase',
  PHASE_CHANGED = 'phaseChanged',
  PHASE_ERROR = 'phaseError',
  PHASE = 'phase',

  // refs
  UPDATE_REFS = 'updateRefs',
  REFS_UPDATED = 'refsUpdated',
  UPDATE_REFS_ERROR = 'updateRefsError',
  GET_REFS = 'getRefs',

  // mmm
  UPDATE_MAM = 'updateMam',
  MAM_UPDATED = 'mamUpdated',
  MAM_ERROR = 'mamError',
  GET_MAM = 'getMam',
}

export const CALC_NAMES: { [id: string]: string } = {
  'vi_ova_1:': 'voltageparams1',
  'vi_ova_1b:': 'voltageparams1',
  'vi_ova_1c:': 'voltageparams1',
  'vi_ova_2:': 'voltageparams2',
  'vi_ova_2b:': 'voltageparams2',
  'vi_ova_2c:': 'voltageparams2',
  'vi_ova_3:': 'voltageparams3',
  'vi_ova_3b:': 'voltageparams3',
  'vi_ova_3c:': 'voltageparams3',
};

export const CALC_PHASES: { [id: string]: string } = {
  'vi_ova_1:': 'A',
  'vi_ova_1b:': 'B',
  'vi_ova_1c:': 'C',
  'vi_ova_2:': 'A',
  'vi_ova_2b:': 'B',
  'vi_ova_2c:': 'C',
  'vi_ova_3:': 'A',
  'vi_ova_3b:': 'B',
  'vi_ova_3c:': 'C',
};

export const END_POINTS = {
  HARMONIC: 'harmonic',
  HISTORY_DATA: 'history-data',
};

export const DEBUG_LOGS = false;
