import { EChartOption, init } from 'echarts';
import Grid = echarts.EChartOption.Grid;
import XAxis = echarts.EChartOption.XAxis;
import Series = echarts.EChartOption.Series;
import YAxis = echarts.EChartOption.YAxis;
import {
  createMap,
  setData,
  updateMap,
} from '@/components/basics/charts/history-chart/e-charts.extensions';
import {
  xAxisBasic,
  yAxisBasic,
} from '@/components/basics/charts/history-chart/e-charts.constants';
import DataZoom = echarts.EChartOption.DataZoom;
import Tooltip = echarts.EChartOption.Tooltip;
import { IHistoryDataSet, ISeries } from '@/store/history-charts/types';
import { getAreas } from '@/store/history-charts/extensions';
import ECharts = echarts.ECharts;
import {
  buildColors,
  colorByPhase,
  defaultColors,
} from '@/components/basics/charts/history-chart/e-charts.color-manager';
import { ChartOptions } from '@/components/basics/charts/echart/types';
import 'echarts/lib/component/legend';
import Legend = echarts.EChartOption.Legend;
import EChartTitleOption = echarts.EChartTitleOption;
import { getOption } from '@/components/basics/charts/echart/extensions';
import Dataset = echarts.EChartOption.Dataset;
import { ILevel } from '@/components/basics/TableRowChartManager';
import _ from 'lodash';
import Type = echarts.EChartOption.BasicComponents.CartesianAxis.Type;
import { Subject } from 'rxjs';
import { EventAggregator } from '@/events';
import { EVN__EQUIP_TREE_HIDDEN, EVN__MAIN_VIEW_SIZE_CHANGED } from '@/events/constants';
import { tryCatch } from 'rxjs/internal-compatibility';
import moment from 'moment';

export class Builder {
  // x
  private _xAxis: Map<string, XAxis>;
  get xAxis(): XAxis[] {
    return [...this._xAxis.values()];
  }

  // y
  private _yAxis: Map<string, YAxis>;
  get yAxis(): YAxis[] {
    return [...this._yAxis?.values()];
  }

  // series
  private _series: Map<string, Series>;
  get series(): Series[] {
    return [...this._series?.values()];
  }

  // colorSet
  private _colorSet: Map<string, string[]>;

  private _chart: ECharts | unknown;
  constructor() {
    this._xAxis = createMap<XAxis>();
    this._yAxis = createMap<YAxis>();
    this._series = createMap<Series>();
    this._colorSet = new Map<string, string[]>();
  }

  subscribeOnZoomEvents(): Subject<any> {
    const subject = new Subject<any>();
    (this._chart as ECharts)?.on('dataZoom', (evn: any) => {
      if (evn?.batch && Array.isArray(evn.batch) && evn.batch.length) {
        const lPoint = this.endXPointValue();
        if (!lPoint || !Array.isArray(lPoint) || !lPoint.length) return;
        const eX = lPoint[0];
        if (isNaN(eX)) return;
        const start = (eX / evn.batch[0].startValue) * 100;
        const end = (eX / evn.batch[0].endValue) * 100;
        subject.next({ start, end });
        return;
      }
      if (!evn?.start || !evn.end) return;
      subject.next(evn);
    });
    return subject;
  }

  endXPointValue(): any | null {
    const chart = this._chart as ECharts;
    if (!chart?.getOption()?.series?.length) return 0;
    const opt = chart?.getOption() as EChartOption;
    if (!opt) return null;
    const { series } = opt;
    if (!series?.length || !Array.isArray(series)) return null;
    const data = series[0]?.data;
    if (!data?.length || !Array.isArray(data)) return null;
    const index = data.length - 1;
    return data[index];
  }

  totalPoints(): number {
    const chart = this._chart as ECharts;
    if (!chart?.getOption()?.series?.length) return 0;
    const opt = chart?.getOption() as EChartOption;
    if (!opt?.series?.length) return 0;
    return opt?.series[0].data?.length ?? 0;
  }

  async showHidePoints(flag: boolean): Promise<boolean> {
    return await new Promise<boolean>((resolve, reject) => {
      try {
        const chart = this._chart as ECharts;
        const opts = chart?.getOption();
        const series = opts?.series as any[];
        if (!series?.length) {
          return;
        }
        for (const s of series) {
          s.showSymbol = flag;
          s.showAllSymbol = flag;
        }
        this.setChartOptions({ ...opts, series });
      } catch (e) {
        reject(e);
      }
    });
  }

  setSeriesData(key: string, data: any) {
    setData<Series>(key, this._series, data);
  }
  setXAxisData(key: string, data: string[]) {
    setData<XAxis>(key, this._xAxis, data);
  }
  buildGrid(areas: string[], options: Grid, mrg = 30, sps = 30): Grid[] {
    if (!areas?.length) return [];
    const grid: Grid[] = [];
    let space = mrg;
    areas.forEach((i) => {
      const { top, ...props } = options;
      const index = areas.indexOf(i);
      space = index * Number(props.height) + mrg + sps * index;
      grid.push({
        id: i,
        top: space,
        left: 55,
        right: 30,
        // containLabel: true,
        ...props,
      });
    });
    return grid;
  }
  buildSeries(series: ISeries[], ds?: Dataset[]): Series[] {
    const areas = getAreas(series);
    if (!series?.length) return [];
    series.forEach((a, i) => {
      const area = areas.find((i) => i.areaKey === a.areaKey);
      if (area) {
        const index = areas.indexOf(area);
        updateMap<Series>(a.uniqueKey ?? '', this._series, {
          // datasetIndex: ds ? ds.length > i ? i : undefined : undefined,
          xAxisIndex: index,
          yAxisIndex: index,
          type: 'line',
          smooth: false,
          sampling: 'average',
          symbol: a.uniqueKey,
          animation: false,
          showSymbol: false,
          lineStyle: {
            width: 0.75,
          },
        });
      }
    });
    return this.series;
  }

  titleOptions(title?: string | null): {} | null {
    return title
      ? {
          name: title,
          nameLocation: 'end',
          nameTextStyle: {
            color: '#aaa',
            align: 'center',
          },
        }
      : null;
  }

  buildXAxis(areas: string[], type: Type = 'time', xAxisFormat?: string): XAxis[] {
    if (!areas?.length) return [];
    areas.forEach((s, i) => {
      updateMap<XAxis>(s, this._xAxis, {
        ...xAxisBasic(i, type, i >= areas.length - 1, xAxisFormat),
      });
    });
    return this.xAxis;
  }
  buildYAxis(areas: string[], type: Type = 'value', yAxisFormat: string[], scale = false, titles?: any[]): YAxis[] {
    if (!areas?.length) return [];

    areas.forEach((s, i) => {
      const t = titles ? titles[i] : null;
      updateMap<YAxis>(s, this._yAxis, {
        ...yAxisBasic(i, type, yAxisFormat[i], scale),
        ...this.titleOptions(t),
      });
    });
    return this.yAxis;
  }

  buildToolBox() {
    return {
      show: true,
      feature: {
        dataZoom: {
          title: {
            zoom: '+/-',
            back: 'Назад',
          },
          yAxisIndex: 'none',
        },
        // dataView: { readOnly: false },
        // magicType: { type: ['line', 'bar'] },
        // restore: {},
        saveAsImage: {
          title: '.png',
          name: `${new Date().toISOString()}`,
        },
      },
    };
  }

  buildToolTip(): Tooltip {
    return {
      trigger: 'axis',
      formatter: (params: any) => {
        const r = params.map((p: any) => `${p.marker}${p.value[1]}`);
        let ts;
        if (params?.length === 1) {
          ts = moment(params[0].value[0]).format('HH:mm:ss.S');
        } else if (params?.length > 1) {
          ts = moment(params[0].value[0]).format('DD/MM HH:mm:ss');
        }
        return [`<i class="las la-clock"></i> ${ts}`, ...r].join(`<br/>`);
      },
      renderMode: 'html',
    };
  }

  buildZoom(
    areas: string[],
    options: DataZoom = { show: true, realtime: true, start: 0, end: 100 }
  ): DataZoom[] {
    const indexes = areas.map((v, i) => i);
    return [
      {
        ...options,
        xAxisIndex: indexes,
      },
      {
        ...options,
        xAxisIndex: indexes,
        type: 'inside',
      },
    ];
  }
  buildLegend(keys: string[]): Legend {
    return {
      show: true,
      data: [...keys],
      orient: 'horizontal',
      top: 5,
    };
  }

  buildTitles(names: string[], colors?: string[]): EChartTitleOption[] {
    const titles: EChartTitleOption[] = [];
    let space = 0;
    names.forEach((n, i) => {
      space = (120 + 35) * i + 9;
      titles.push({
        top: space,
        left: '50%',
        textAlign: 'center',
        text: n,
        textStyle: {
          fontSize: 13,
          fontWeight: 'normal',
          color: colors
            ? colors?.length > i
              ? colors[i]
              : defaultColors.BLUE
            : defaultColors.BLUE,
        },
      });
    });
    return titles;
  }

  private _options?: ChartOptions;
  get options(): ChartOptions {
    return this._options ?? {};
  }

  public initChart = (div: any, theme: string, options?: ChartOptions): void => {
    this._chart = init(div, theme);
    if (this._chart) {
      const chart = this._chart as ECharts;
      EventAggregator?.register(EVN__MAIN_VIEW_SIZE_CHANGED)?.subscribe(() =>
        setTimeout(() => chart.resize(), 200)
      );
      window.onresize = () => setTimeout(chart.resize, 200);
    }
    this._options = options ?? {};
  };
  setChartOptions(opt: EChartOption) {
    if (!this._chart) {
      return;
    }
    // try {
      (this._chart as ECharts).setOption(opt);
    // } catch (e) {
    //   console.log({
    //     err: e.message,
    //   });
    // }
  }

  public disposeChart = (): void => {
    if (!this._chart) return;
    try {
      (this._chart as ECharts).dispose();
    } catch (e) {
      console.log(e.message);
    }
  };

  public async refreshData(dataMap: Map<ISeries, IHistoryDataSet>): Promise<boolean> {
    return await new Promise<boolean>((resolve, reject) => {
      try {
        const opt = (this._chart as ECharts).getOption();
        if (!opt) return;
        const seriesColl = opt.series as EChartOption.SeriesLine[];
        if (!seriesColl?.length) {
          return;
        }
        for (const key of dataMap.keys()) {
          const series = seriesColl.find((i) => i.symbol === key.uniqueKey);
          if (!series) continue;
          const val = dataMap.get(key);
          if (!val?.time?.length || !val?.data?.length) continue;
          const dp = val?.data.map((d: number, i: number) => [val?.time[i], d]);
          series.data = [...(dp as any)];
        }
        (this._chart as ECharts).setOption({ series: opt.series });
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  public async updateData(keys: ISeries[], dataMap: Map<string, IHistoryDataSet>): Promise<void> {
    try {
      const opt = (this._chart as ECharts).getOption();
      for await (const sr of keys) {
        const s = opt.series?.find((s: any) => s.symbol === sr.uniqueKey);
        if (!s || !sr?.uniqueKey) continue;
        const ds = dataMap.get(sr.uniqueKey);
        if (!ds) continue;
        s.data = ds.data;
        const i = (s as any).xAxisIndex;
        if (!Array.isArray(opt.xAxis) || !i) continue;
        if (opt.xAxis.length <= i) continue;
        opt.xAxis[i].data = ds.time as string[];
      }
      (this._chart as ECharts).setOption({ series: opt.series, xAxis: opt.xAxis });
    } catch (e) {
      console.log(e.message);
    }
  }

  transformData(data: [number[], Date[]]) {
    return data[0].map((v, i) => [data[i], v]);
  }

  public async updateSeriesData(keys: [string, string], data: [number[], string[]]) {
    const opt = (this._chart as ECharts).getOption();
    const s = opt.series?.find((s: any) => s.symbol === keys[0]);
    if (s) {
      s.data = data[0];
      const i = (s as any).xAxisIndex;
      if (Array.isArray(opt.xAxis) && i) {
        if (opt.xAxis.length > i) opt.xAxis[i].data = data[1];
      }
    }
    (this._chart as ECharts).setOption({ series: opt.series, xAxis: opt.xAxis });
  }

  public async updateOptions(
    data: Map<ISeries, IHistoryDataSet>,
    phase: number,
    xAxisFormat = 'HH:mm:ss'
  ): Promise<boolean> {
    try {
      const keys = [...data.keys()];
      this.resetCollections();
      (this._chart as ECharts).setOption({
        series: undefined,
      });
      const options = this.getOptions(keys, phase, xAxisFormat);
      // fill chart options with data
      for await (const [key, value] of data) {
        const sKey = key?.uniqueKey;
        const aKey = key?.areaKey;
        if (!sKey || !aKey) continue;
        this.setSeriesData(
          sKey,
          (value.time as []).map((t: Date, i: number) => [t, value.data[i]])
        );
      }
      (this._chart as ECharts).setOption(options);
      return true;
    } catch (e) {
      return false;
    }
  }

  public resetCollections(): void {
    this._series = new Map<string, Series>();
    this._xAxis = new Map<string, XAxis>();
    this._yAxis = new Map<string, YAxis>();
  }

  public getOptions(series: ISeries[], phase: number, xAxisFormat: string): EChartOption {
    const areas = getAreas(series)?.map((i) => i.areaKey);
    const colors = buildColors(colorByPhase(phase), series.length, areas.length, series.length > 3);
    const ds = getOption<Dataset[]>(this.options?.dataset, []);

    return {
      legend: this.options.legend ? this.buildLegend(series.map((i) => i.name)) : undefined,
      color: colors,
      xAxis: this.buildXAxis(areas, 'time', xAxisFormat),
      yAxis: this.buildYAxis(areas, 'value', []), // FIXME не разбирался откуда можно взять формат для значений, должно не сломаться
      grid: this.buildGrid(areas, { height: 120 }, 40, 35),
      series: this.buildSeries(series, ds),
      tooltip: this.options.tooltip ? this.buildToolTip() : undefined,
      toolbox: this.options.toolbox ? this.buildToolBox() : undefined,
      dataZoom: this.options.dataZoom ? this.buildZoom(areas) : undefined,
      title: getOption<EChartTitleOption[]>(this.options.titles, this.buildTitles(areas, colors)),
      dataset: ds,
    };
  }

  buildLevels(levels: ILevel[]) {
    return levels.map((l) => {
      return {
        yAxis: l.value,
        color: l.color,
      };
    });
  }

  minmax(data: number[]): [number, number] {
    const max = _.max(data);
    const min = _.min(data);
    return [min ?? 0, max ?? 0];
  }

  public buildCustomOptions(
    title: string,
    xData: string[],
    yData: number[],
    levels: ILevel[],
    colors = [defaultColors.BLUE]
  ): EChartOption {
    return {
      // legend: { top: 15, data: [title] },
      grid: {
        left: 60,
        right: 60,
      },
      xAxis: {
        type: 'category',
        data: xData,
      },
      yAxis: { type: 'value', position: 'left', scale: true },
      series: [
        {
          type: 'line',
          showSymbol: false,
          animation: false,
          smooth: false,
          data: yData,
          // lineStyle: {
          //   width: 1,
          // },
          markLine: {
            silent: true,
            label: {
              emphasis: {},
            },
            lineStyle: {
              color: defaultColors.RED,
            },
            data: this.buildLevels(levels) as any,
          },
        },
      ],
      color: ['#c3c3c3'],
      tooltip: this.buildToolTip(),
      toolbox: this.buildToolBox(),
    };
  }
}
