import { IDataConverter, ValueFormat } from '@/components/basics/table/converters/types';
import { MOMENT_FORMAT } from '@/utils/datetime/DateTimeRangePresets';
import moment from 'moment';

export class FloatConverter implements IDataConverter {
  getSecFormat = (value: number) => {
    if (value === 0) {
      return '0';
    }
    if (value <= 60.99) {
      return value.toFixed(2);
    }
    return value.toFixed(0);
  };
  format(col: any, value: number): string {
    if (!value) return `${value}`;
    switch (col.Format as ValueFormat) {
      case '.':
      case '*':
        return `${value.toFixed(0)}`;
      case '.*':
        return value.toFixed(1);
      case '.**':
        return value.toFixed(2);
      case '.***':
        return value.toFixed(3);
      case '.ms':
        return `${value}`;
      case 'mm:ss.ms':
        return `${value}`;
      case 'hh:mm:ss.ms':
        return `${value}`;
      case 'text':
        return `${value}`;
      default:
        return `${value}`;
    }
  }
  convert(value: any, opts: any): any {
    if (value <= opts.Min) return '0';
    if (opts.Format === '.ms') {
      return this.getSecFormat(moment.duration(value / 1_000_000).asSeconds());
    } else if (opts.Format === 'mm:ss.ms') {
      const dt = new Date(value / 1_000_000);
      return moment(dt).format(MOMENT_FORMAT.minSecMss);
    } else if (opts.Format === 'hh:mm:ss.ms') {
      const dt = new Date(value / 1_000_000);
      return moment(dt).format(MOMENT_FORMAT.hourMinSecMss);
    }
    const scale = opts?.Scale;
    if (scale) value = value * scale;
    let r = this.format(opts, value);
    if (r === '-0.00') r = '0.00';
    if (r === '-0.000') r = '0.000';
    if (r === '-0.0') r = '0.0';
    return r;
  }
}
