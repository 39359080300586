import { DataQuery, INTERVAL_CONST, MinAvgMax } from '@/store/data/data.types';
import moment, { Moment } from 'moment';
import { resetTimeOffset, updateTimeOffset } from '@/utils/datetime/DateTimeCorrector';
import _ from 'lodash';
import { MOMENT_QUERY_FORMAT } from '@/utils/datetime/DateTimeRangePresets';
import { CALC_NAMES, CALC_PHASES, DEBUG_LOGS } from '@/store/chart-data/chart-data.constants';

export const debugLog = (...params: any[]) => {
  if (DEBUG_LOGS) {
    console.debug('STORE.CHART-DATA:', ...params);
  }
};

export const getMam = (data: any[]): MinAvgMax => {
  const min = _.minBy(data, 'min').min;
  const avg = _.meanBy(data, 'avg');
  const max = _.maxBy(data, 'max').max;
  return {
    min,
    avg,
    max,
  };
};
export const getMamMS = (data: any[]): MinAvgMax => {
  const min = _.minBy(data, (o) => o.v).v;
  const avg = _.meanBy(data, (o) => o.v);
  const max = _.maxBy(data, (o) => o.v).v;
  return {
    min,
    avg,
    max,
  };
};

export const buildDate = (date: string, hour = 0, minute = 0, second = 0, ms = 0) => {
  const d = new Date(date);
  const h = new Date(d.setHours(d.getHours() + hour));
  const m = new Date(h.setMinutes(h.getMinutes() + minute));
  const s = new Date(m.setSeconds(m.getSeconds() + second));
  return resetTimeOffset(new Date(s.setMilliseconds(m.getMilliseconds() + ms)));
};

const { ms, second, minute, hour } = INTERVAL_CONST;

const getSecDuration = (range: string[]) => {
  const s = new Date(range[0]);
  const e = new Date(range[1]);
  const dif = Math.abs(e.getTime() - s.getTime());
  return moment.duration(dif).asSeconds();
};

export const checkCritical = (query: DataQuery): boolean => {
  const duration = getSecDuration([query.start, query.end]);
  debugLog('Duration:', duration, query.interval);
  if (query.interval.value === ms.value) {
    return duration <= 300;
  }
  if (query.interval.value === second.value) {
    return duration <= 60 * 60 * 3;
  }
  if (query.interval.value === minute.value) {
    return duration <= 60 * 60 * 24 * 7;
  }
  if (query.interval.value === hour.value) {
    return duration <= 60 * 60 * 24 * 30 * 3;
  }
  return false;
};

export const buildNano = (nano: number) => {
  return new Date(nano / 1000000);
};
export const transformData = (data: any[], interval: string) => {
  let r;
  if (interval === 'ms') {
    r = data.map((i) => {
      const { ts, v } = i;
      return {
        datetime: buildNano(ts),
        value: v,
      };
    });
  } else {
    r = data.map((i) => {
      const { d, h, m, s, ms, ...rest } = i;
      const datetime = updateTimeOffset(buildDate(d, h ?? 0, m ?? 0, s ?? 0, ms ?? 0));
      return {
        datetime,
        ...rest,
      };
    });
  }
  return _.sortBy(r, 'datetime');
};
export const buildMap = (arr: any[], props: string[]) => {
  const map = new Map<string, any>();
  arr.forEach((i) => {
    const { datetime, max, min, avg } = i;
    map.set(moment(datetime).format(MOMENT_QUERY_FORMAT.dateToSec), {
      [props[0]]: datetime,
      [props[1]]: max,
      [props[2]]: avg,
      [props[3]]: min,
    });
  });
  return map;
};
export const buildMapMS = (arr: any[], props: string[]) => {
  const map = new Map<string, any>();
  arr.forEach((i) => {
    const { datetime, value } = i;
    map.set(moment(datetime).format('YYYY-MM-DD HH:mm:ss:SSS'), {
      [props[0]]: datetime,
      [props[1]]: value,
    });
  });
  return map;
};

export const mergeDataArrays = (har1: any[], interval: string) => {
  if (interval !== 'ms') {
    const map = buildMap(har1, ['time', 'value1']);
    debugLog('CD.Extensions: map1', map);
    return [...map.values()];
  }

  const mapMS = buildMapMS(har1, ['time', 'value1']);
  debugLog('CD.Extensions: mapMS', mapMS);
  return [...mapMS.values()];
};

export function getPhase(rawCalculatorName: string, defaultPhase: string): string {
  return CALC_PHASES[`${rawCalculatorName}:`] ?? defaultPhase;
}

export function getWinding(rawCalculatorName: string, defaultPhase: number): number {
  const symbol = rawCalculatorName.replace(/^[^\d]+(\d+)[abc]?$/, '$1');
  return parseInt(symbol, 10);
}

export const buildUrlQueries = async (dataQuery: DataQuery, path: string): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const start = resetTimeOffset(new Date(dataQuery.start));
    const end = resetTimeOffset(new Date(dataQuery.end));
    const phase =
      dataQuery.phase === 'A'
        ? 'a'
        : dataQuery.phase === 'B'
        ? 'b'
        : dataQuery.phase === 'C'
        ? 'c'
        : '';

    try {
      let url = '';
      url = path;
      url += 'select=u_' + phase;
      url += '&algorithm-data=' + dataQuery.algorithm;
      url += '&start=' + moment(start).format('YYYY-MM-DD HH:mm:ss');
      url += '&end=' + moment(end).format('YYYY-MM-DD HH:mm:ss');
      url += '&interval=' + dataQuery.interval.value;
      url += '&calculator_name=' + dataQuery.calculatorName;
      url += '&qd_u_' + phase + '=0';
      resolve(url);
    } catch (e) {
      reject(e);
    }
  });
};
