import { Module } from 'vuex';
import { IChartPropsQuery, IChartPropsState } from './types';
import { RootState } from '@/store/types';
import {
  GET_CHARTS_PROPS,
  GET_CHART_PROPS_FAIL,
  GET_CHART_PROPS_SUCCESS,
  CHART_PROPS,
} from './constants';
import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '../../tools';
import {
  Preloader,
  PRELOADER_DICT,
  preloaderActions,
  preloaderGetters,
  preloaderInitialState,
  preloaderMutations,
} from '../preloader';
import { QueryBuilder } from '@/utils/QueryBuilder';
const rest = new RestApiService();
const path = getApiUrl(TR_TYPE.HTTP, '/chart-props');

export const chartPropsInitialState: IChartPropsState = {
  ...preloaderInitialState,
  chartPropsError: false,
  chartProps: [],
};

export const chartProps: Module<IChartPropsState, RootState> = {
  actions: {
    ...preloaderActions,
    [GET_CHARTS_PROPS]: async (context, payload: IChartPropsQuery) => {
      try {
        const { phase, typeUid, equipId } = payload;
        if (!phase || !typeUid || typeUid.length <= 0 || phase < 32) {
          context.commit(
            GET_CHART_PROPS_FAIL,
            new Error(`GET_CHARTS_PROPS::: Empty or NULL => phase: ${phase} typeUid: ${typeUid}`)
          );
          return;
        }
        await Preloader.start(context, PRELOADER_DICT.SCHEME_LOADING);
        const query = QueryBuilder.build({ phase, typeUid, equipId });
        const url = `${path}?${query}`;
        const response = await rest.get(url);
        context.commit(GET_CHART_PROPS_SUCCESS, response?.data ?? []);
      } catch (e) {
        context.commit(GET_CHART_PROPS_FAIL, e);
      } finally {
        await Preloader.stop(context);
      }
    },
  },
  mutations: {
    ...preloaderMutations,
    [GET_CHART_PROPS_SUCCESS]: (state, payload) => {
      state.chartProps = payload;
      state.chartPropsError = false;
    },
    [GET_CHART_PROPS_FAIL]: (state, payload) => {
      state.chartProps = [];
      state.chartPropsError = payload;
    },
  },
  getters: {
    ...preloaderGetters,
    [CHART_PROPS]: ({ chartProps }) => chartProps,
  },
};
