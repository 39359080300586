






import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import { CONST } from '@/store/auth/constants';
import fetch from 'node-fetch';

@Component
export default class App extends Vue {
  @Action(CONST.UPDATE_USER_INFO, { namespace: CONST.AUTH }) updateUser!: Function;

  userUpdated = false;

  async mounted() {
    try {
      await this.updateUser();
    } catch (e) {
      void 0;
    }
    this.userUpdated = true;
  }
}
