
const prefix = 'textDecoder';
export const DECODE_TEXT = `${prefix}DecodeText`;
export const DECODE_FAIL = `${prefix}DecodeText`;
export const DECODED_TEXT = `${prefix}DecodedText`;
export const TEXT_DECODER = `${prefix}`;


export enum Encoding{
  IBM866= 'IBM866',
  CP1251= 'CP1251',
  CP1252= 'CP1252',
  UTF8 = 'UTF-8',
  ASCII = 'ASCII'
}
