










import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import echarts from 'echarts';
import phaseA from '@/assets/themes/echarts/phase_a.json';
import { Builder } from '@/components/basics/charts/echart/builder';
import {
  buildShortCircuitChartOptions,
  buildSimpleSeries,
} from '@/components/basics/charts/echart/extensions';
import { IQueryPopupChartParams } from '@/store/popup-charts/popup.charts.types';
import {
  calculateShownPoints,
  transformPopupChartDataAsync,
} from '@/components/basics/charts/short-circuit-chart/extensions';
import { Getter } from 'vuex-class';
import { PRELOADER_STATE } from '@/store/abstract/preloader';
import { IPreloader } from '@/store/abstract/preloader/types';
import { POPUP_CHART_CONST } from '@/store/popup-charts/popup.charts.const';
import { Subject } from 'rxjs';
import { PhaseConverter } from '@/utils/PhaseConverter';
import { EventAggregator } from '@/events';
import { EVN__MAIN_VIEW_SIZE_CHANGED } from '@/events/constants';
echarts.registerTheme('phase_a', phaseA);
const { POPUP_CHART } = POPUP_CHART_CONST;
@Component
export default class ShortCircuitChart extends Vue {
  @Prop() chartData?: any[];
  @Prop() chartParams?: IQueryPopupChartParams[];
  @Ref('sc-ui-chart') chartDiv: any;
  chartBuilder = new Builder();
  @Ref('right-content-ref') contentDiv: any;

  @Getter(PRELOADER_STATE, { namespace: POPUP_CHART })
  preloader!: IPreloader;

  @Watch('chartData') async onChartData(
    val: IQueryPopupChartParams[],
    old: IQueryPopupChartParams[]
  ) {
    if (val === old || !this.chartParams) return;
    const data = await transformPopupChartDataAsync(val, this.chartParams);
    if (data?.length) {
      await this.renderChart(data);
    }
  }

  async renderChart(data: any[]) {
    this.chartBuilder.disposeChart();
    this.chartBuilder.initChart(this.chartDiv, 'phase_a');
    const opt = await buildShortCircuitChartOptions(data, this.chartBuilder, this.getPhase);
    if (!opt) return;
    this.chartBuilder.setChartOptions(opt);
    this.pointsVisual();
   //  window.addEventListener('resize', this.chartBuilder.resize);
  }
  get getPhase(): number | null {
    const { phase } = this.$route?.query;
    if (!phase) return null;
    return PhaseConverter.getPhase(phase as string);
  }

  zoomEvents?: Subject<any> | null;
  beforeDestroy() {
    if (this.zoomEvents) this.zoomEvents = null;
   //  window.removeEventListener('resize', () => this.chartBuilder.resize);
  }

  private static __POINTS_LIMIT = 300;
  private static __POINTS_FLAG = false;
  pointsVisual() {
    // shit for show/hide points on line
    const totalPoints = this.chartBuilder.totalPoints();
    let pointsCount = 0;
    this.zoomEvents = this.chartBuilder.subscribeOnZoomEvents() as Subject<any>;
    this.zoomEvents.subscribe(async (evn) => {
      const { start, end } = evn;
      pointsCount = calculateShownPoints(start, end, totalPoints);
      if (pointsCount !== 0 && pointsCount <= ShortCircuitChart.__POINTS_LIMIT) {
        //show
        if (!ShortCircuitChart.__POINTS_FLAG) {
          ShortCircuitChart.__POINTS_FLAG = true;
          await this.chartBuilder.showHidePoints(true);
        }
      } else {
        //hide
        if (ShortCircuitChart.__POINTS_FLAG) {
          ShortCircuitChart.__POINTS_FLAG = false;
          await this.chartBuilder.showHidePoints(false);
        }
      }
    });
  }
}
