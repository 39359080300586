import { IDataConverter } from '@/components/basics/table/converters/types';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';

export class EnumConverter implements IDataConverter {
  private __enums!: ITabRef[];
  constructor(private readonly enums: ITabRef[]) {
    this.__enums = enums;
  }
  convert(value: any, opts: any): any {
    const ref = this.__enums.find(
      (i) => `${i.enumUid}` === `${opts.Uid}` && `${i.order}` === `${value}`
    );
    if (!Number.isNaN(opts?.Max) && value > opts.Max) return '';
    if (opts?.Format === 'text' && ref) {
      return ref?.notes;
    }
    return !ref && (value === 0 || value === '0') ? '' : value;
  }
}
