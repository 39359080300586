import { Module } from 'vuex';

import { RootState } from '@/store/types';

import { SystemLogsState } from './types';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';

const initialState: SystemLogsState = {
  treeExpanded: new Set(JSON.parse(localStorage.getItem('systemLogsTree.expanded') ?? '[]')),
};

export const systemLogs: Module<SystemLogsState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
