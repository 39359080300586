export enum CONST {
  GET_USER = 'getUser',
  GET_ROLES = 'getRoles',
  GET_TOKEN = 'getToken',
  GET_REFRESH_TOKEN = 'getRefreshToken',

  SET_USER = 'setUser',
  SET_TOKEN = 'setToken',
  SET_REFRESH_TOKEN = 'setRefreshToken',

  LOGIN = 'login',
  LOGOUT = 'logout',
  UPDATE_USER_INFO = 'updateUserInfo',
  UPDATE_TOKEN = 'updateToken',

  AUTH = 'auth',
}
