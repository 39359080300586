import { IMatrixColumn } from '@/components/row-popup-matrix2/types';

const scaleValueX4 = (v: any, scale: any) => {
  if (v === '-' || v === '?') return v;
  const result = v * scale;
  if (Math.abs(result) >= 1000) return result.toFixed(0);
  return result.toFixed(3);
};

export const buildValuesMatrix = (arr: number[][], scale: number): any[] => {
  return arr.map((i) => ({ ...i.map((j) => scaleValueX4(j, scale)) }));
};

export const buildMatrixColumns = (data: number[], n1: string, n2: string): IMatrixColumn[] => {
  const initial: IMatrixColumn = { key: 0, value: `${n1 ?? ''}/${n2 ?? ''}`, fixed: 'left' };
  const arr: IMatrixColumn[] = [...data.map((p, i) => ({ key: i + 1, value: `${p}` }))];
  return [initial, ...arr];
};
// that's roll back
export const buildMatrix4Rows = (m2X?: any[], t2X?: number[], columns?: IMatrixColumn[]) => {
  if (!m2X?.length || !t2X?.length || !columns?.length) return [];
  const mx2Arr = m2X.map((i) => [...Object.values(i)]);
  const rows: any = [];
  let j = -1;
  // rows and
  for (const row of mx2Arr) {
    let item: any = { [columns[0].value]: `${t2X[++j]}` };
    // columns
    for (let i = 1; i < columns.length; i++) {
      item = { ...item, [columns[i].value]: row[i - 1] };
    }
    rows.push({ ...item });
  }
  return rows;
};
