import { TableData, TableRow, TotalData, YearData } from './ram-info.types';
import {
  RATIO_STRING,
  TOTAL_MAX_STRING,
  TOTAL_STRING,
  TOTAL_WARN_STRING,
  YEAR_STRING,
} from './ram-info.consts';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';

export function parseJson(code: string): any {
  try {
    const parse = JSON.parse(code);
    if (parse) {
      return parse;
    }
  } catch (e) {
    // ignore
  }

  return code;
}

function sortByIntHeader(data: [string, number][]): [string, number][] {
  return data
    .map((v) => { return [v[0], v[1]] as [string, number]}) // специальный обьект для сортировки
    .sort((a,b) => parseInt(a[0])-parseInt(b[0])) // сортировка
}

function createTableData(
  data: any,
  header: string,
  firstRowDataHeader: string
): TableData {
  const tableData = {
    header,
    keys: [] as string[],
    rows: [{ name: '', values: [] as string[] }] as TableRow[],
  };
  sortByIntHeader(Object.entries(data)).map(([k, v]) => {
    tableData.keys.push(k);
    tableData.rows[0].values.push(String(v));
  });
  tableData.rows[0].name = firstRowDataHeader;

  return tableData;
}

export function prepareTableData(data: any): TableData[] {
  const result = [];
  const total = data as TotalData;
  const yearData = data as YearData;

  if (yearData.YearMax) {
    const tableData = createTableData(yearData.YearMax, YEAR_STRING, TOTAL_MAX_STRING);
    const secondRow = createTableData(yearData.YearWarn, YEAR_STRING, TOTAL_WARN_STRING);
    tableData.rows.push(secondRow.rows[0])
    result.push(tableData);
  }

  if (total.TotalMax) {
    const tableData = createTableData(total.TotalMax, TOTAL_STRING, TOTAL_MAX_STRING);
    const secondRow = createTableData(total.TotalWarn, TOTAL_STRING, TOTAL_WARN_STRING);
    tableData.rows.push(secondRow.rows[0])
    result.push(tableData);
  }
  if (!total.TotalMax && !yearData.YearMax) {
    result.push(createTableData(data, '', RATIO_STRING));
  }

  return result;
}

export function prepareElTableData(tablesData: TableData[]) {
  const elTableData: any[] = [];
  for (const tableIndex in tablesData) {
    const tableData = tablesData[tableIndex];
    elTableData.push({
      header: tableData.header,
      columns: ['col1'],
      data: tableData.rows.map((r) => {
        return { col1: r.name };
      }),
    });
    elTableData[tableIndex].data.unshift({ col1: 'Длительность, с' });
    for (let i = 0; i < tableData.keys.length; i++) {
      const colName = 'col' + (i + 2);
      elTableData[tableIndex].columns.push(colName);
      elTableData[tableIndex].data[0][colName] = tableData.keys[i];
      for (let j = 0; j < tableData.rows.length; j++) {
        elTableData[tableIndex].data[j + 1][colName] = tableData.rows[j].values[i];
      }
    }
  }

  return elTableData;
}

export const convertEnum = (value: any, refs: ITabRef[]): string => {
  const ref = refs.find((i) => i.enumUid === value.Uid && i.order === value.Value);
  return ref?.notes ?? '';
};
