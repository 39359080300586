import { IPresetItem, IPresetQuery } from '@/store/abstract/presets-service/types';

export class LocalStorageManager {
  public static async save(preset: IPresetItem): Promise<IPresetItem | null | any> {
      let group: Map<string, IPresetItem>;
      const { groupKey, key } = preset;
      const load = localStorage.getItem(groupKey);
      if (!load) {
        group = new Map<string, IPresetItem>();
        group.set(key, preset);
      } else {
        group = new Map<string, IPresetItem>(JSON.parse(load));
        group?.delete(key);
        group?.set(key, preset);
      }
      localStorage.setItem(groupKey, JSON.stringify(Array.from(group.entries())));
      return await LocalStorageManager.load(preset);

  }

  public static async load({ groupKey, key }: IPresetQuery): Promise<IPresetItem | null> {
      const load = localStorage.getItem(groupKey);
      if (!load) {
        return null;
      }
      const group = new Map<string, IPresetItem>(JSON.parse(load));
      return group?.get(key) ?? null;
  }

  public static async deleteItem({ groupKey, key }: IPresetQuery): Promise<boolean> {
      const load = localStorage.getItem(groupKey);
      if(!load){
        return true;
      }
      const group = new Map<string, IPresetItem>(JSON.parse(load));
      group.delete(key);
      localStorage.setItem(groupKey, JSON.stringify(Array.from(group.entries())));
      return true;
  }

  public static async getGroup(groupKey: string): Promise<Map<string, IPresetItem> | null> {
      const load = localStorage.getItem(groupKey);
      if(!load){
        return null;
      }
      return new Map<string, IPresetItem>(JSON.parse(load));
  }

  public static async deleteGroup(key: string): Promise<boolean> {
      localStorage.removeItem(key);
      return true;
  }

  public static async clearStorage(): Promise<boolean> {
      localStorage.clear();
      return true;
  }
}
