export type StatusType = 'progress' | 'done' | 'fail' | 'undefined';

export const statusBarTypeConst = {
  progress: 'в работе',
  done: 'завершено',
  fail: 'ошибка',
  undefined: 'состояние не определено',
};

export type ActivityType = 'request' | 'response' | 'error';

export interface IStatusRecord {
  key: string;
  note?: string;
  type: ActivityType;
  dateTime?: string;
  status?: number | string;
  end?: Date;
  start?: Date;
}

export interface IStatus {
  statusType: StatusType;
  message: string;
  dateTime: Date | string;
  activeTasks: number;
}

export interface IStatusBarState {
  history: IStatusRecord[];
  status: IStatus;
  error?: Error | string | boolean;
}
