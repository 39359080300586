export const ENV_PREFIX = {
  __: '__',
  VUE_APP: 'VUE_APP'
};

export const ENV_CONST = {
  // api
  SERVER_IP: 'SERVER_IP',
  SERVER_PORT: 'SERVER_PORT',
  // socket
  SOCKET_PORT: 'SOCKET_PORT',
  SOCKET_HOST: 'SOCKET_HOST',
  // sentry
  SENTRY_DSN: 'SENTRY_DSN',
};
