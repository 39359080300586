export class ScaleManager {
  public static scale(value: any, opts: { key: string; scheme: any }) {
    // is not number
    const val = Number(value);
    if (Number.isNaN(val)) return value;
    const { key, scheme } = opts;
    if (!key || !scheme) return value;
    const scale = scheme[key];
    const scaleValue = Number(scale);
    if (!scale || Number.isNaN(scaleValue)) return value;
    return val * scaleValue;
  }

  private static __getFormat(payload: any): number | null {
    try {
      if (payload === null || payload === undefined) return null;
      const val = Number(payload);
      if (!Number.isNaN(val)) return val;
      if (!(payload as string).includes('*')) return null;
      const stars = (payload as string).split('').filter((i) => i === '*');
      if (!Array.isArray(stars) || stars.length <= 0) return null;
      return stars.length;
    } catch {
      return null;
    }
  }

  public static round(value: number, precision: number): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  public static format(value: any, opts: { key: string; scheme: any }) {
    // is not number
    if (Number.isNaN(value)) return value;
    const { key, scheme } = opts;
    if (!key || !scheme) return value;
    const format = ScaleManager.__getFormat(scheme[key]);
    if (format === null) return value;
    const round = ScaleManager.round(Number(value), format);
    return round.toFixed(format);
  }
}
