import { Module } from 'vuex';

import { RootState } from '@/store/types';

import { TreeState } from './types';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';

const initialState: TreeState = {
  substationCalcUids: {},
  selectedTreeNode: null,
  isLoading: false,
  error: false,
  tree: [],
  expanded: new Set(JSON.parse(localStorage.getItem('tree.expanded') ?? '[]')),
  equipmentTypes: {},
};

export const tree: Module<TreeState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
