export function getColumnFilter(
  value: any,
  column: any,
  oldFilter: any,
  currentFilter: any,
  guidTablesHash?: Record<string, any>
) {
  return (column.Type === 'Integer' || column.Type === 'Float') && column.Filter === 'enum'
    ? {
        type: 'NumberEnum',
        name: column.dbProps,
        visible: false,
        active: !!currentFilter,
        scale: column.Scale,
        options: [
          ...(oldFilter?.options ?? []).filter(
            (x: any) => !(currentFilter?.options ?? []).some((y: number) => x.value === y)
          ),
          ...(currentFilter?.options ?? []).map((x: any) => ({
            value: x,
            active: true,
          })),
        ].sort((x: any, y: any) => x.value - y.value),
        optionsLoaded: oldFilter?.optionsLoaded ?? false,
      }
    : column.Type === 'String' && column.Filter === 'enum'
    ? {
        type: 'StringEnum',
        name: column.dbProps,
        visible: false,
        active: !!currentFilter,
        options: [
          ...(oldFilter?.options ?? []).filter(
            (x: any) => !(currentFilter?.options ?? []).some((y: number) => x.value === y)
          ),
          ...(currentFilter?.options ?? []).map((x: any) => ({
            value: x,
            active: true,
          })),
        ].sort((x: any, y: any) => x.value - y.value),
        optionsLoaded: oldFilter?.optionsLoaded ?? false,
      }
    : column.Type === 'Integer' || column.Type === 'Float'
    ? {
        type: 'Number',
        name: column.dbProps,
        visible: false,
        active: !!currentFilter,
        scale: column.Scale,
        min: currentFilter
          ? currentFilter.min * (column.Scale || 1)
          : column.Min
          ? Number(column.Min) * (column.Scale || 1)
          : null,
        max: currentFilter
          ? currentFilter.max * (column.Scale || 1)
          : column.Max
          ? Number(column.Max) * (column.Scale || 1)
          : null,
      }
    : column.Type === 'Enum' && column.Filter === 'query'
    ? {
        type: 'EnumQuery',
        name: column.dbProps,
        visible: false,
        active: !!currentFilter,
        options: (() => {
          const refs = value.refs.filter(
            (x: any) => x.enumUid === column.Uid || x.enum_uid === column.Uid
          );

          return [
            ...(oldFilter?.options ?? []).filter(
              (x: any) => !(currentFilter?.options ?? []).some((y: number) => x.value === y)
            ),
            ...(currentFilter?.options ?? []).map((x: any) => ({
              value: x,
              notes: refs.find((y: any) => y.order === x)?.notes,
              active: true,
            })),
          ].sort((x: any, y: any) => (x.notes < y.notes ? -1 : 1));
        })(),
        optionsLoaded: oldFilter?.optionsLoaded ?? false,
      }
    : column.Type === 'Guid'
    ? {
        type: 'Guid',
        name: column.dbProps,
        visible: false,
        active: !!currentFilter,
        options: (() => {
          const data = guidTablesHash?.[column.Name];

          return [
            ...(oldFilter?.options ?? []).filter(
              (x: any) => !(currentFilter?.options ?? []).some((y: number) => x.value === y)
            ),
            ...(currentFilter?.options ?? []).map((x: any) => ({
              value: x,
              notes: data?.items.find((y: any) => y.uuid === x)?.[data.property],
              active: true,
            })),
          ].sort((x: any, y: any) => (x.notes < y.notes ? -1 : 1));
        })(),
        optionsLoaded: oldFilter?.optionsLoaded ?? false,
      }
    : column.Type === 'Enum'
    ? {
        type: 'Enum',
        name: column.dbProps,
        visible: false,
        active: !!currentFilter,
        options: value.refs
          .filter((x: any) => x.enumUid === column.Uid || x.enum_uid === column.Uid)
          .map((x: any) => ({
            value: x.order,
            notes: x.notes,
            active: currentFilter
              ? currentFilter.options.some((option: any) => option === x.order)
              : false,
          })),
      }
    : undefined;
}
