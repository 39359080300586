import { DataQuery, INTERVAL_CONST, MinAvgMax } from '@/store/data/data.types';
import moment, { Moment } from 'moment';
import { resetTimeOffset, updateTimeOffset } from '@/utils/datetime/DateTimeCorrector';
import _ from 'lodash';
import { MOMENT_FORMAT } from '@/utils/datetime/DateTimeRangePresets';

export enum Duration {
  Day = 1,
  Week = 7,
  Month = 30,
}
export const getMam = (data: any[]): MinAvgMax => {
  const min = _.minBy(data, 'min').min;
  const avg = _.meanBy(data, 'avg');
  const max = _.maxBy(data, 'max').max;
  return {
    min,
    avg,
    max,
  };
};
export const getMamMS = (data: any[]): MinAvgMax => {
  const min = _.minBy(data, (o) => o.v).v;
  const avg = _.meanBy(data, (o) => o.v);
  const max = _.maxBy(data, (o) => o.v).v;
  return {
    min,
    avg,
    max,
  };
};
export const buildDate = (date: string, hour = 0, minute = 0, second = 0, ms = 0) => {
  const d = new Date(date);
  const h = new Date(d.setHours(d.getHours() + hour));
  const m = new Date(h.setMinutes(h.getMinutes() + minute));
  const s = new Date(m.setSeconds(m.getSeconds() + second));
  return resetTimeOffset(new Date(s.setMilliseconds(m.getMilliseconds() + ms)));
};

const { ms, second, minute, hour } = INTERVAL_CONST;

const getSecDuration = (range: string[]) => {
  const s = new Date(range[0]);
  const e = new Date(range[1]);
  const dif = Math.abs(e.getTime() - s.getTime());
  return moment.duration(dif).asSeconds();
};

export const checkCritical = (query: DataQuery): boolean => {
  const duration = getSecDuration([query.start, query.end]);
  if (query.interval === ms) {
    return duration <= 300;
  }
  if (query.interval === second) {
    return duration <= 60 * 60 * 3;
  }
  if (query.interval === minute) {
    return duration <= 60 * 60 * 24 * 7;
  }
  if (query.interval === hour) {
    return duration <= 60 * 60 * 24 * 30 * 3;
  }
  return false;
};

export const buildNano = (nano: number) => {
  return new Date(nano / 1000000);
};
export const transformData = (data: any[], interval: string) => {
  let r;
  if (interval === 'ms') {
    r = data.map((i) => {
      const { ts, v } = i;
      return {
        datetime: buildNano(ts),
        value: v,
      };
    });
  } else {
    r = data.map((i) => {
      const { d, h, m, s, ms, ...rest } = i;
      const datetime = updateTimeOffset(buildDate(d, h ?? 0, m ?? 0, s ?? 0, ms ?? 0));
      return {
        datetime,
        ...rest,
      };
    });
  }
  return _.sortBy(r, 'datetime');
};
export const buildMap = (arr: any[], props: string[]) => {
  const map = new Map<string, any>();
  arr.forEach((i) => {
    const { datetime, max, min, avg } = i;
    map.set(moment(datetime).format(MOMENT_FORMAT.dateToSec), {
      [props[0]]: datetime,
      [props[1]]: max,
      [props[2]]: avg,
      [props[3]]: min,
    });
  });
  return map;
};
export const buildMapMS = (arr: any[], props: string[]) => {
  const map = new Map<string, any>();
  arr.forEach((i) => {
    const { datetime, value } = i;
    map.set(moment(datetime).format('YYYY-MM-DD HH:mm:ss:SSS'), {
      [props[0]]: datetime,
      [props[1]]: value,
    });
  });
  return map;
};
export const attachToMap = (map: Map<string, any>, arr: any[], props: string[]) => {
  arr.forEach((i) => {
    const { datetime, max, min, avg } = i;
    const key = moment(datetime).format(MOMENT_FORMAT.dateToSec);
    let r = map.get(key);
    if (!r) {
      map.set(datetime, {
        [props[0]]: datetime,
        [props[1]]: max,
        [props[2]]: avg,
        [props[3]]: min,
      });
    } else {
      r = Object.assign(r, {
        [props[1]]: max,
        [props[2]]: avg,
        [props[3]]: min,
      });
    }
  });
  return map;
};

export const attachToMapMS = (map: Map<string, any>, arr: any[], props: string[]) => {
  arr.forEach((i) => {
    const { datetime, value } = i;
    const key = moment(datetime).format('YYYY-MM-DD HH:mm:ss:SSS');
    let r = map.get(key);
    if (!r) {
      map.set(datetime, {
        [props[0]]: datetime,
        [props[1]]: value,
      });
    } else {
      r = Object.assign(r, {
        [props[1]]: value,
      });
    }
  });
  return map;
};

export const mergeDataArrays = (har1: any[], har2: any[], freq: any[], interval: string) => {
  if (interval !== 'ms') {
    const map1 = buildMap(har1, ['time', 'value1', 'value2', 'value3']);
    const map2 = attachToMap(map1, har2, ['time', 'value4', 'value5', 'value6']);
    const map3 = attachToMap(map2, freq, ['time', 'value7', 'value8', 'value9']);
    return [...map3.values()];
  }
  const map1MS = buildMapMS(har1, ['time', 'value1']);
  const map2MS = attachToMapMS(map1MS, har2, ['time', 'value4']);
  const map3MS = attachToMapMS(map2MS, freq, ['time', 'value7']);
  return [...map3MS.values()];
};

export const getLast = (range: Duration = 1): Moment[] => {
  const end = new Date();
  const start = moment(end).subtract(range, 'days');
  return [moment(start), moment(end)];
};

export const buildUrlQueries = async (
  dataQuery: DataQuery,
  path: string,
  calculatorName?: string
): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const start = resetTimeOffset(new Date(dataQuery.start));
    const end = resetTimeOffset(new Date(dataQuery.end));
    try {
      let url = '';
      url = path;
      url += 'start=' + moment(start).format('YYYY-MM-DD HH:mm:ss');
      url += '&end=' + moment(end).format('YYYY-MM-DD HH:mm:ss');
      url += '&interval=' + dataQuery.interval.value;
      url += calculatorName ? '&calculatorName=' + calculatorName : '';
      url += calculatorName ? '&phase=' + dataQuery.phase : '';
      resolve(url);
    } catch (e) {
      reject(e);
    }
  });
};

export const getRange = (dates: Date[]): string[] => {
  if (!dates || dates.length !== 2) throw new Error('Wrong dates input!');
  return [moment(dates[0]).format(), moment(dates[1]).format()];
};
