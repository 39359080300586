import { Module } from 'vuex';
import { DetailsState } from '@/store/details/types';
import { RootState } from '@/store/types';
import { getters } from '@/store/details/getters';
import { mutations } from '@/store/details/mutations';
import { actions } from '@/store/details/actions';


export const  UPDATE_EQUIPMENT_PHASES = 'updateEquipmentPhases';

export enum DETAILS_CONST {
  GET_DETAILS = 'getDetails',
  DETAILS_LOADED = 'detailsLoaded',
  DETAILS_ERROR = 'detailsError',
  DETAILS = 'details',
  EQUIPMENT_DETAILS = 'equipmentDetails',

  // Phase
  SELECTED_PHASE = 'selectedPhase',

  SELECTED_PHASE_CHANGED = 'selectedPhaseChanged',
  SELECTED_PHASE_ERROR = 'selectedPhaseError',
  CLEAR_DETAILS = 'clearDetails',
}

const initialState: DetailsState = {
  error: false,
  equipmentDetails: {
    header: undefined,
    statesAndPhases: undefined,
    make: [],
    refs: [],
    equipInfo: null,
  },
  selectedPhase: null,
};

export const details: Module<DetailsState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
};
