import { IUsageMessage } from '@/store/usage-info';

export interface IBarDataItem {
  name: string;
  value: number;
}

export enum UsageItemType {
  CPU = 'cpu',
  HARD_DRIVE = 'hardDrive',
  MEMORY = 'memory',
}

export interface IUsageChartSettings {
  type?: UsageItemType;
  title?: string;
  limit?: number;
  unit?: string;
  scale: number;
}

export interface IUsageServerGroup {
  title: string;
  // one server filtered data
  data: IUsageMessage;
  settings: IUsageChartSettings[];
}
