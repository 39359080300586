var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    (_vm.column.Type === 'Integer' || _vm.column.Type === 'Float' || _vm.column.Type === 'DateTime' ||
    _vm.column.type === 'Integer' || _vm.column.type === 'Float' || _vm.column.type === 'DateTime') &&
      !Array.isArray(_vm.column.dbProps)
  )?_c('div',{staticClass:"sorter",class:{
    ascending: _vm.sorting.field === _vm.column.dbProps && _vm.sorting.direction === 'ASC',
    descending: _vm.sorting.field === _vm.column.dbProps && _vm.sorting.direction === 'DESC',
  },on:{"click":function($event){return _vm.onChangeSort(_vm.column.dbProps)}}},[_vm._m(0)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"caret-wrapper-1"},[_c('i',{staticClass:"sort-caret ascending"}),_c('i',{staticClass:"sort-caret descending"})])}]

export { render, staticRenderFns }