import moment from 'moment';

export const MOMENT_FORMAT = {
  secAlter: 'DDMMMYY_HH_mm_ss',
  hourMinSecMs: 'HH:mm:ss.S',
  hourMinSecMss: 'HH:mm:ss.SSS',
  minSecMss: 'mm:ss.SSS',
  dateToMs: 'HH:mm:ss.S DD-MM-YYYY',
  dateToMsss: 'HH:mm:ss.SSS DD-MM-YYYY',
  dateToSec: 'HH:mm:ss DD-MM-YYYY',
  dateToMin: 'DD-MM-YYYY HH:mm',
  dateToHour: 'DD-MM-YYYY HH',
};

export const MOMENT_QUERY_FORMAT = {
  secAlter: 'DDMMMYY_HH_mm_ss',
  hourMinSecMs: 'HH:mm:ss.S',
  dateToMs: 'YYYY-MM-DD HH:mm:ss.S',
  dateToSec: 'YYYY-MM-DD HH:mm:ss',
  dateToMin: 'YYYY-MM-DD HH:mm',
  dateToHour: 'YYYY-MM-DD HH',
};

export const TIME_RANGE_PRESETS = {
  hour: {
    label: 'Час',
    value: 'hour',
  },
  day: {
    label: 'День',
    value: 'day',
  },
  week: {
    label: 'Неделя',
    value: 'week',
  },
};

export const rebuildQueryTimeRange = (start?: string, end?: string) => {
  if (!start || !end) {
    return undefined;
  }
  const s = new Date(start);
  const e = new Date(end);
  const dif = e.getTime() - e.getTime();
  const newEnd = e.setTime(e.getTime() + dif);
  return [
    moment(s).format(MOMENT_QUERY_FORMAT.dateToSec),
    moment(newEnd).format(MOMENT_QUERY_FORMAT.dateToSec)
  ];
};
