import { MutationTree } from 'vuex';

import { AuthState, User } from './types';
import { CONST } from './constants';

export const mutations: MutationTree<AuthState> = {
  [CONST.SET_TOKEN](state: AuthState, payload: string | null) {
    payload
      ? localStorage.setItem('auth.token', JSON.stringify(payload))
      : localStorage.removeItem('auth.token');

    state.token = payload;
  },
  [CONST.SET_REFRESH_TOKEN](state: AuthState, payload: string | null) {
    payload
      ? localStorage.setItem('auth.refreshToken', JSON.stringify(payload))
      : localStorage.removeItem('auth.refreshToken');

    state.refreshToken = payload;
  },
  [CONST.SET_USER](state: AuthState, payload: User | null) {
    payload
      ? localStorage.setItem('auth.user', JSON.stringify(payload))
      : localStorage.removeItem('auth.user');

    state.user = payload;
  },
};
