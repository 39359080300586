import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { ChartDataState, INTERVAL_CONST } from '@/store/chart-data/chart-data.types';
import { mutations } from '@/store/chart-data/chart-data.mutations';
import { actions } from '@/store/chart-data/chart-data.actions';
import { getters } from '@/store/chart-data/chart-data.getters';
import { buildRange } from '@/utils/datetime/DateTimeCorrector';
import moment from 'moment';
import { MOMENT_QUERY_FORMAT } from '@/utils/datetime/DateTimeRangePresets';
import { CALC_NAMES } from '@/store/chart-data/chart-data.constants';
const d = new Date();
const range = buildRange(new Date(d.setHours(d.getHours() + 1)), -60 * 60);

const initialState: ChartDataState = {
  error: false,
  selectedPhase: undefined,
  data: [[], []],
  query: {
    interval: INTERVAL_CONST.minute,
    start: moment(range[1]).format(MOMENT_QUERY_FORMAT.dateToSec),
    end: moment(range[0]).format(MOMENT_QUERY_FORMAT.dateToSec),
    phase: 'A',
    calculatorName: CALC_NAMES.CALC_1,
    algorithm: '',
  },
  refs: [],
  mam: [],
};

export const chartData: Module<ChartDataState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
