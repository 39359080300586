export interface IStateKeyValue {
  key: string;
  header: string;
  alter?: string;
  alter1?: string;
  alter2?: string;
}

// state narrow
export const reactorKeys: Record<string, IStateKeyValue> = {
  reactor_on_off: { key: 'reactor_on_off', header: 'Подключение к сети' },
  time_ram: { key: 'time_ram', header: 'Время переключения' },
  state_r: { key: 'state_r', header: 'Работа', alter: 'state_r_ps', alter1: 'state_r_complex' },
  r_gas_result: {
    key: 'r_gas_result',
    alter: 'r_gas_result_ps',
    header: 'Горючие газы',
  },
  r_insulate_result: {
    key: 'r_insulate_result',
    header: 'Изоляция',
    alter: 'r_insulate_result_ps',
  },
  r_toil_result: {
    key: 'r_toil_result',
    alter: 'r_toil_result_ps',
    header: 'Температура масла',
  },
  r_params_result: {
    key: 'r_params_result',
    alter: 'r_params_result_ps',
    header: 'Схема замещения',
  },
  r_voltage_result: {
    key: 'r_voltage_result',
    header: 'Уровень напряжения',
  },
  r_ct_result: {
    key: 'r_ct_result',
    header: 'Встроенные ТТ',
    alter: 'r_ct_result_ps',
  },
  r_current_result: {
    key: 'r_current_result',
    header: 'Ток',
    alter: 'r_current_result_ps',
  },
  r_mois_result: {
    key: 'r_mois_result',
    alter: 'r_mois_result_ps',
    header: 'Влажность',
  },
};
