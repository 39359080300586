






























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { Getter } from 'vuex-class';

import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { AlgorithmScheme } from '@/store/algorithms/algorithms.types';
import PropertiesGroup from '@/components/basics/PropertiesGroup.vue';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';
import { QueryParamsService } from '@/utils';
import { DETAILS_CONST } from '@/store/details';
import { IEquipDetails } from '@/store/details/types';
import RamButton from '@/components/ram-info/RamButton.vue';
import { buildProp } from './equip-state.convertors';
import { addSubject, removeSubject } from '@/socket';
const { DICTIONARIES, TAB_REFS } = DICTIONARIES_CONST;
type QueryType = { tab?: string };

const rest = new RestApiService();

@Component({
  components: {
    'props-group': PropertiesGroup,
    'ram-button': RamButton,
  },
})
export default class EquipStateWT extends Vue {
  queryParams!: QueryParamsService<QueryType>;
  @Prop() scheme?: AlgorithmScheme;
  @Prop() phase?: number;

  subject = 'wavetrap_state';

  columnsData: Record<string | number, any> | null = null;

  @Getter(TAB_REFS, { namespace: DICTIONARIES })
  tabRefs?: ITabRef[];

  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  $equipmentDetails!: IEquipDetails;

  private unsubscribe() {
    this.$socket.client.off(this.subject);
    removeSubject(this.subject);
  }

  get keys(): string[] | null {
    return this.scheme?.moduleUid ? [this.scheme.moduleUid] : null;
  }

  get schemeAndPhase() {
    return { scheme: this.scheme, phase: this.phase };
  }

  @Watch('schemeAndPhase', { immediate: true }) async onChangeSchemeAndPhase(x: any) {
    if (!this.columnsData && x && x.scheme && x.phase) {
      const result = await rest.getParse(
        getApiUrl(
          TR_TYPE.HTTP,
          `/click-house/last-value?algorithm=${x.scheme.dbTableName}&calculator_uuid=${x.scheme.moduleUid}`
        )
      );

      if (result && result.length > 0 && !this.columnsData) {
        this.updateColumnsData(result[0]);
      }
    }
  }

  private updateColumnsData(data: any) {
    const dataBuf: Record<string | number, any> = {};
    const columnsData = this.getColumnsData(data);
    columnsData.forEach((x) => {
      x.key && (dataBuf[x.key] = x);
    });
    this.columnsData = dataBuf;
  }

  private subscribe() {
    if (this.keys) {
      addSubject({
        theme: this.subject,
        keys: [...this.keys],
      });
    }
    this.$socket.client.on(this.subject, (x: any) => {
      const data = x;
      if (data.phase === this.phase) {
        this.updateColumnsData(data);
      }
    });
  }

  private getColumnsData(data: any) {
    return [
      ...(this.scheme?.columns
        .filter((x) => x.mandatory)
        .map((column) => ({
          key: column.key,
          name: column.header,
          order: column.order,
          value: column.property && buildProp(data[column.property], this.tabRefs || [], column),
          tabLink: column.tabLink,
        })) || []),
      {
        key: 'ts',
        name: 'Последнее изменение',
        order: 0,
        value: buildProp(data.ts, this.tabRefs || [], {
          order: 1,
          Type: 'DateTime',
          Format: 'iso',
        }),
      },
    ];
  }

  hasValue(field: string) {
    return (
      this.columnsData &&
      this.columnsData[field] &&
      this.columnsData[field].value !== undefined &&
      this.columnsData[field].value.length !== 0
    );
  }

  getValue(field: string, suffix?: string) {
    return this.columnsData &&
      this.columnsData[field] &&
      this.columnsData[field].value !== undefined
      ? `${this.columnsData[field].value}${suffix ? ` ${suffix}` : ''}`
      : '';
  }

  getTagClass(field: string) {
    return this.columnsData &&
      this.columnsData[field] &&
      this.columnsData[field].value !== undefined
      ? this.columnsData[field].value === 'выход за норму' ||
        this.columnsData[field].value === 'Отклонение' ||
        this.columnsData[field].value === 'Меньше нормы' ||
        this.columnsData[field].value === 'нарушение'
        ? { 'tag-bad': true }
        : this.columnsData[field].value === 'внимание' ||
          this.columnsData[field].value === 'Внимание'
        ? { 'tag-bad': true }
        : { 'tag-norm': true }
      : { 'tag-hidden': true };
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      tab: this.$route.query.tab,
    }));
  }

  mounted() {
    this.subscribe();
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
