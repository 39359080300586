import { SOCKET_END_POINTS } from '@/socket/subject/subject.const';
import { ISubject } from '@/socket/subject/subject.types';

const { REMOVE_SUBJECT, ADD_SUBJECT, HANDSHAKE, ACK } = SOCKET_END_POINTS;

export interface ISubjectManager {
  currentSubject: ISubject | null | undefined;
  addSubject: (subject: ISubject, socket: SocketIOClient.Socket) => void;
  removeSubject: (theme: string, socket: SocketIOClient.Socket) => void;
}

export class SubjectManager implements ISubjectManager {
  private _currentSubject?: ISubject | null;

  get currentSubject(): ISubject | null | undefined {
    return this._currentSubject;
  }

  private subjectAction(endPoint: string, payload: ISubject, socket: SocketIOClient.Socket) {
    try {
      const token = `${endPoint}_${new Date().getTime().toString()}`;
      socket.on(HANDSHAKE, (msg: any) => {
        if (token === msg.token) {
          if (Number(msg.flag) === 1) {
            console.log(`Subscribed on ${msg.theme} successfully.`);
          } else {
            console.log(`Unsubscribed from ${msg.theme} successfully.`);
          }
          // socket.off(HANDSHAKE);
        }
      });
      socket.emit(endPoint, {
        event: endPoint,
        ...payload,
        token,
      });
      if (!payload.keys?.length) {
        this._currentSubject = null;
      } else {
        this._currentSubject = payload;
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  public addSubject(subject: ISubject, socket: SocketIOClient.Socket) {
    const { theme, keys } = subject;
    this.subjectAction(
      ADD_SUBJECT,
      {
        theme,
        keys,
      } as ISubject,
      socket
    );
  }
  public removeSubject(theme: string, socket: SocketIOClient.Socket) {
    this.subjectAction(
      REMOVE_SUBJECT,
      {
        theme,
      } as ISubject,
      socket
    );
  }
}

export class SubjectSingleton {
  private static _instance: ISubjectManager;
  public static instance = (): ISubjectManager => {
    if (!SubjectSingleton._instance) {
      SubjectSingleton._instance = new SubjectManager();
    }
    return SubjectSingleton._instance;
  };
}
