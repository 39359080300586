import { RestApiService } from '../../services';
import { getApiUrl, TR_TYPE } from '../tools';
import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { TabParamsQuery, TabParamsState } from './tab-params.types';
import { buildUrlQueries, debugLog, transformData } from './tab-params.extensions';
import { TAB_PARAMS_CONST } from './tab-params.constants';

const rest = new RestApiService();

const path = getApiUrl(TR_TYPE.HTTP, `/algorithms-config/tabs`);

export const actions: ActionTree<TabParamsState, RootState> = {
  [TAB_PARAMS_CONST.UPDATE_DETAILS]: async (context, payload: any) => {
    await rest.post(getApiUrl(TR_TYPE.HTTP, '/ram-info/update'), payload);
  },
  [TAB_PARAMS_CONST.GET_DETAILS]: async (context, payload: TabParamsQuery) => {
    try {
      const { equip, phase } = payload;
      if (!equip || !phase) return;
      const answer = await rest.get(await buildUrlQueries(path, equip, phase));
      const data = transformData(answer.status, answer.data ?? []);
      context.commit(TAB_PARAMS_CONST.DATA_LOADED, data);
    } catch (e) {
      context.commit(TAB_PARAMS_CONST.DATA_ERROR, e);
    }
  },
  [TAB_PARAMS_CONST.QUERY_UPDATED]: async (context, payload: TabParamsQuery) => {
    try {
      await context.commit(TAB_PARAMS_CONST.QUERY_UPDATED, payload);
      await context.dispatch(TAB_PARAMS_CONST.GET_DETAILS, context.state.query);
    } catch (e) {
      await context.commit(TAB_PARAMS_CONST.QUERY_ERROR);
    }
  },
  [TAB_PARAMS_CONST.CHANGE_PHASE]: async (context, payload) => {
    try {
      await context.commit(TAB_PARAMS_CONST.PHASE_CHANGED, payload);
    } catch (e) {
      await context.commit(TAB_PARAMS_CONST.PHASE_ERROR);
    }
  },
};
