import { Module } from 'vuex';
import {
  HDQueryType,
  HistoryDataState,
  IChartArea,
  IChartPropsQuery,
  IChartScheme,
  IPropertyScheme,
  MaxAvgMin,
} from '@/store/history-data/history-data.types';
import { RootState } from '@/store/types';
import { HIST_DATA_CONST } from '@/store/history-data/history-data.const';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { QueryBuilder } from '@/utils/QueryBuilder';
import { HistoryDataManager, IHistoryDataManager } from '@/store/history-data/history-data.manager';
import store from '@/store';
import { ROOT_STORE_CONST } from '@/store/root.constants';
import { ChartManagerSingleton } from '@/store/history-data/chart-manager';
import { ILiveChartScheme } from '@/components/basics/charts/chart.types';
import {
  Preloader,
  preloaderActions,
  preloaderGetters,
  preloaderInitialState,
  preloaderMutations,
  PRELOADER_DICT,
} from '@/store/abstract/preloader';
const _dataManager: IHistoryDataManager = new HistoryDataManager();
const {
  GET_HISTORY_DATA,
  GET_HISTORY_DATA_ERROR,
  GET_HISTORY_DATA_SUCCESS,
  HISTORY_DATA_SET,
  CHANGE_CHART_SCHEME,
  CHANGE_CHART_SCHEME_ERROR,
  CHANGE_CHART_SCHEME_SUCCESS,
  REFRESH_HISTORY_DATA,
  UPDATE_PROPERTIES_SCHEME,
  UPDATE_PROPERTIES_SCHEME_SUCCESS,
  UPDATE_PROPERTIES_SCHEME_ERROR,
  UPD_COLORS,
  UPD_COLORS_SUCCESS,
  UPD_COLORS_ERROR,
  COLOR_SCHEME,
  UPD_LEGEND_DATA,
  LEGEND_DATA,
  UPD_LEGEND_DATA_ERROR,
  UPD_LEGEND_DATA_SUCCESS,
  LIVE_DATA_BUFFER,
  GET_LIVE_DATA_BUFFER,
  GET_LIVE_DATA_BUFFER_SUCCESS,
  GET_LIVE_DATA_BUFFER_ERROR,
  PROPS_SCHEME
} = HIST_DATA_CONST;
const path = getApiUrl(TR_TYPE.HTTP, '/click-house/history-data?');
//let _dataMap: Map<string, any[]> = new Map<string, any[]>();
const cn = 'calculator_name';
export const historyData: Module<HistoryDataState, RootState> = {
  namespaced: true,
  state: {
    ...preloaderInitialState,
    dataError: false,
    data: [],
    legendData: new Map<string, MaxAvgMin>(),
    chartScheme: ChartManagerSingleton.instance().chartScheme,
    propertiesSchemes: [],
  },
  actions: {
    ...preloaderActions,

    [UPD_LEGEND_DATA]: async (context) => {
      try {
        const lData = await _dataManager.legendData;
        context.commit(UPD_LEGEND_DATA_SUCCESS, lData);
      } catch (e) {
        context.commit(UPD_LEGEND_DATA_ERROR, e);
      }
    },
    [UPD_COLORS]: (context, payload: number) => {
      try {
        //
      } catch (e) {
        context.commit(UPD_COLORS_ERROR, e);
      }
    },
    [UPDATE_PROPERTIES_SCHEME]: async (context, payload: IChartPropsQuery) => {
      try {
        const r = store.getters[ROOT_STORE_CONST.BUSY_INDICATOR];
        if (!r.isBusy) {
          await store.dispatch(ROOT_STORE_CONST.START_BUSY_INDICATOR);
        }
        const { phase, equipId } = payload;
        await ChartManagerSingleton.instance().updatePropertiesScheme(phase, equipId);
        const props = ChartManagerSingleton.instance().propertiesScheme;
        context.commit(UPDATE_PROPERTIES_SCHEME_SUCCESS, props);
      } catch (e) {
        context.commit(UPDATE_PROPERTIES_SCHEME_ERROR, e);
      } finally {
        await store.dispatch(ROOT_STORE_CONST.STOP_BUSY_INDICATOR);
      }
    },
    [REFRESH_HISTORY_DATA]: async (context, payload) => {
      try {
        _dataManager.resetDataMap();
        const r = store.getters[ROOT_STORE_CONST.BUSY_INDICATOR];
        if (!r.isBusy) {
          await store.dispatch(ROOT_STORE_CONST.START_BUSY_INDICATOR);
        }
        const { areas } = context?.state?.chartScheme;
        const { start, end, interval } = payload;
        if (!areas?.length) return;
        for (const area of areas) {
          const values = area.series.map((s) => s.value);
          if (!area?.propertyScheme) {
            return;
          }
          const { select, algorithm, calculatorName } = area?.propertyScheme as any;
          const query: HDQueryType = {
            select,
            algorithm,
            start,
            end,
            interval: interval.value,
            [cn]: calculatorName,
          };
          await context.dispatch(GET_HISTORY_DATA, [query, values, area.propertyScheme]);
        }
      } catch (e) {
        console.log(e);
      } finally {
        await store.dispatch(ROOT_STORE_CONST.STOP_BUSY_INDICATOR);
      }
    },
    [GET_HISTORY_DATA]: async (context, payload: [HDQueryType, string[], IPropertyScheme]) => {
      try {
        const r = store.getters[ROOT_STORE_CONST.BUSY_INDICATOR];
        if (!r.isBusy) {
          await store.dispatch(ROOT_STORE_CONST.START_BUSY_INDICATOR);
        }
        let query = QueryBuilder.build(payload[0]);
        const quality = `&qd_${payload[0].select}=0`;
        query += quality;
        const url = `${path}${query}`;
        const d = await _dataManager.add(
          payload[1],
          url,
          payload[0].interval,
          payload[2]?.scale,
          payload[2]?.axisScale
        );
        context.commit(GET_HISTORY_DATA_SUCCESS, d);
        const leg = _dataManager.legendData;
        context.commit(UPD_LEGEND_DATA_SUCCESS, leg);
      } catch (e) {
        console.log(e.message);
        context.commit(GET_HISTORY_DATA_ERROR, e);
      } finally {
        await store.dispatch(ROOT_STORE_CONST.STOP_BUSY_INDICATOR);
      }
    },
    [CHANGE_CHART_SCHEME]: (context, payload: [IChartArea, IPropertyScheme]) => {
      try {
        const { chartScheme } = context.state;
        const area = chartScheme.areas.find((a) => a.name === payload[0].name);
        if (!area) return;
        area.propertyScheme = payload[1];
      } catch (e) {
        context.commit(CHANGE_CHART_SCHEME_ERROR, e);
      }
    },
  },
  mutations: {
    ...preloaderMutations,
    [GET_LIVE_DATA_BUFFER_SUCCESS]: (state, payload) => {
      state.liveDataBuffer = payload;
      state.dataError = false;
    },
    [GET_LIVE_DATA_BUFFER_ERROR]: (state, payload) => {
      state.liveDataBuffer = [];
      state.dataError = payload;
    },
    [UPD_LEGEND_DATA_SUCCESS]: (state, payload) => {
      state.legendData = new Map<string, MaxAvgMin>(payload);
    },
    [UPD_LEGEND_DATA_ERROR]: (state, payload: Error) => {
      console.log(payload.message);
    },
    [UPDATE_PROPERTIES_SCHEME_SUCCESS]: (state, payload) => {
      state.propertiesSchemes = payload;
    },
    [UPDATE_PROPERTIES_SCHEME_ERROR]: (state, payload) => {
      state.propertiesSchemes = [];
    },
    [CHANGE_CHART_SCHEME_SUCCESS]: (state, payload: IChartScheme) => {
      state.chartScheme = payload;
    },
    [CHANGE_CHART_SCHEME_ERROR]: (state, payload) => {
      console.log('err::', payload?.message);
    },
    [GET_HISTORY_DATA_SUCCESS]: (state, payload) => {
      state.data = payload;
      state.dataError = false;
    },
    [GET_HISTORY_DATA_ERROR]: (state, payload) => {
      state.data = [];
      state.dataError = payload;
    },
  },
  getters: {
    ...preloaderGetters,
    [HISTORY_DATA_SET]: ({ data }) => data,
    [LEGEND_DATA]: ({ legendData }) => legendData,
    [LIVE_DATA_BUFFER]: ({ liveDataBuffer }) => liveDataBuffer,
  },
};
