













import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import RamInfo from '@/components/ram-info/RamInfo.vue';

@Component({
  components: {
    'ram-info': RamInfo,
  },
})
export default class RamButton extends Vue {
  @Prop() equipId?: number;
  @Prop() phase?: string | number;
  @Prop() tableName: any;

  regDialogVisible = false;
}
