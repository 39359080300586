export enum DATA_CONST {
  // data
  GET_DATA = 'getData',
  DATA_LOADED = 'dataLoaded',
  DATA_ERROR = 'dataError',
  DATA = 'data',
  STATE_DATA = 'stateData',

  // query
  QUERY = 'query',
  QUERY_UPDATED = 'queryUpdated',
  QUERY_ERROR = 'queryError',
  UPDATE_QUERY = 'updateQuery',

  // phase
  CHANGE_PHASE = 'changePhase',
  PHASE_CHANGED = 'phaseChanged',
  PHASE_ERROR = 'phaseError',
  PHASE = 'phase',

  // refs
  UPDATE_REFS = 'updateRefs',
  REFS_UPDATED = 'refsUpdated',
  UPDATE_REFS_ERROR = 'updateRefsError',
  GET_REFS = 'getRefs',

  // mmm
  UPDATE_MAM = 'updateMam',
  MAM_UPDATED = 'mamUpdated',
  MAM_ERROR = 'mamError',
  GET_MAM = 'getMam'
}

export const CALC_NAMES = {
  CALC_1: 'voltageparams1',
  CALC_2: 'voltageparams2'
};

export const END_POINTS = {
  FREQUENCY: 'frequency',
  HARMONIC: 'harmonic'
};
