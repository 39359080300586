import io from 'socket.io-client';
import { Subject, Observable } from 'rxjs';
import { share, filter } from 'rxjs/operators';
import { InputTypes } from './input-types';
import { ISubject } from '@/socket/subject/subject.types';
import { SubjectSingleton } from '@/socket/subject/subject.manager';
import { ENV_CONST, EnvManager } from '@/env';
const port = EnvManager.getValue(ENV_CONST.SOCKET_PORT);
const ip = EnvManager.getValue(ENV_CONST.SOCKET_HOST);
const socketUrl = `${ip ?? ''}${ port ?  ':' + port : '' }`;
export const socketClient =  io( socketUrl, {
  transports: ['websocket'],
  query: {
    token: new Date().getTime().toString(),
  },
});

// eslint-disable-next-line @typescript-eslint/no-var-requires
const path = require('socketio-wildcard')(io.Manager);
path(socketClient);

const socketMessages$ = new Subject<InputTypes>();

socketClient.on('*', (message: any) => {
  const data = message.data && message.data[1];
  // console.log('data::',data);
  data && socketMessages$.next(data);
});

export const socket$ = socketMessages$.pipe(share());

export const addSubject = (subject: ISubject) => {
  SubjectSingleton.instance().addSubject(subject, socketClient);
};

export const removeSubject = (theme: string) => {
  SubjectSingleton.instance().removeSubject(theme, socketClient);
};

export const get = <S extends any>(socket: S) => (
  obs$: Observable<InputTypes>
): Observable<InputTypes & { socket: S }> => {
  return obs$.pipe(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter((x: any) => x.socket === socket),
    share()
  );
};
