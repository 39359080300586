const prefix = 'preloader/';
export const PRELOADER_STATE = `${prefix}state`;
export const PRELOADER_CHANGE_STATE = `${prefix}changeState`;
export const PRELOADER_CHANGE_STATE_SUCCESS = `${prefix}changeStateSuccess`;
export const PRELOADER_CHANGE_STATE_ERROR = `${prefix}changeStateError`;

export const PRELOADER_DICT = {
  LOADING: 'Загрузка...',
  DATA_LOADING: 'Загрузка данных...',
  DATA_SEARCH: 'Поиск данных...',
  SCHEME_LOADING: 'Загрузка схемы...',
};
