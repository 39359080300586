import { Module } from 'vuex';

import { RootState } from '@/store/types';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { RestApiService } from '@/services';

import { ITabRef, ReferenceState, IEquipmentType } from './dictionaries.types';
import { DICTIONARIES_CONST } from './dictionaries.const';
import { DataConverter } from '@/components/basics/table/converters/DataConverter';

const {
  GET_TAB_REFS,
  GET_TAB_REFS_ERROR,
  GET_TAB_REFS_SUCCESS,
  TAB_REFS,
  GET_EQUIPMENT_TYPES,
  GET_EQUIPMENT_TYPES_ERROR,
  GET_EQUIPMENT_TYPES_SUCCESS,
  EQUIPMENT_TYPES,
} = DICTIONARIES_CONST;

const enumsPath = getApiUrl(TR_TYPE.HTTP, '/algorithms-config/enums');
const equipmentTypesPath = getApiUrl(TR_TYPE.HTTP, '/equipment-types-dictionary');

const rest = new RestApiService();

const initialState = (): ReferenceState => {
  return {
    tabRefError: false,
    equipmentTypesError: false,
    tabRefs: [],
    equipmentTypes: [],
  };
};

export const dictionaries: Module<ReferenceState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    [GET_TAB_REFS]: async (context) => {
      try {
        if ((context.state.tabRefs?.length ?? 0) === 0) {
          const tabsRefs = await rest.get(enumsPath);
          const refs = tabsRefs?.data?.map((d: any) => {
            const { enum_uid, ...rest } = d;
            return {
              enumUid: enum_uid,
              ...rest,
            };
          });
          context.commit(GET_TAB_REFS_SUCCESS, refs);
        }
      } catch (e) {
        context.commit(GET_TAB_REFS_ERROR, e);
      }
    },
    [GET_EQUIPMENT_TYPES]: async (context) => {
      try {
        const equipmentTypes = await rest.get(equipmentTypesPath);
        context.commit(GET_EQUIPMENT_TYPES_SUCCESS, equipmentTypes.data);
      } catch (e) {
        context.commit(GET_EQUIPMENT_TYPES_ERROR, e);
      }
    },
  },
  mutations: {
    [GET_TAB_REFS_SUCCESS]: (state, payload: ITabRef[]) => {
      //for cell data converter
      DataConverter.createEnumConverter(payload);
      state.tabRefs = payload;
      state.tabRefError = false;
    },
    [GET_TAB_REFS_ERROR]: (state, payload: Error) => {
      state.tabRefError = payload;
      state.tabRefs = [];
    },
    [GET_EQUIPMENT_TYPES_SUCCESS]: (state, payload: IEquipmentType[]) => {
      state.equipmentTypes = payload;
      state.equipmentTypesError = false;
    },
    [GET_EQUIPMENT_TYPES_ERROR]: (state, payload: Error) => {
      state.equipmentTypesError = payload;
      state.equipmentTypes = [];
    },
  },
  getters: {
    [TAB_REFS]: ({ tabRefs }) => tabRefs,
    [EQUIPMENT_TYPES]: ({ equipmentTypes }) => equipmentTypes,
  },
};
