import { IEquipmentGroupType, IReportColumn, IReportTab } from '@/store/report-data/types';
import DetailsReport from '@/components/details-report/index.vue';
import AlgorithmView from '@/components/items-tab/panes/algorithms/algorithm.view.vue';
import { buildScheme } from '@/store/tabs/tabs.extensions';

export const getPropsOfChildren = (equipment: any[], children: any[]) => {
  const result = [];
  for (const child of children) {
    const find = equipment.find((e) => e.equip_uuid === child.uid);
    if (!find) continue;
    result.push(find);
  }
  return result;
};

export const extractUniquePhases = (equipment: any[], children: any[]) => {
  const __children = getPropsOfChildren(equipment, children);
  return [...new Set(__children.map((e) => Number(e.phase)))];
};

export const getTypeMap = async (
  allTypes: IEquipmentGroupType[],
  equipmentList: any[]
): Promise<{}> => {
  return await new Promise((resolve, reject) => {
    try {
      let equipmentNodeTypeMap = {};
      for (const type of allTypes) {
        const { equip_id } = type;
        const find = equipmentList.find((e) => e.equip_id === equip_id);
        if (!find) {
          equipmentNodeTypeMap = { ...equipmentNodeTypeMap, [equip_id]: 'container' };
        } else {
          equipmentNodeTypeMap = { ...equipmentNodeTypeMap, [equip_id]: 'equipment' };
        }
      }
      resolve(equipmentNodeTypeMap);
    } catch (e) {
      reject(e);
    }
  });
};

const addItem = (item: any, prop: string, col: any[]) => {
  if (item[prop]) {
    col = [...col, item[prop]];
  } else {
    col = [item[prop]];
  }
  return col;
};
// asset_id: "501"
// asset_uid: "1e848087-605e-4803-b421-0e5b05c3091e"
// equip_id: "246"
// flags: "32"
// key: "topology"
// module_props: Object
// module_uid: "PS_750_kV_Gribovo:V-1_AT-2:topostatus:A"
// monitored: true
// notes: "Состояние топологии"
// order: "277"
// page_name: "Изменения топологии"
// phase: "A"
// props: Array(17)
// table_name: "breakerswitching"
// voltage: "500"
// weight: 1
export const buildAlgoTab = (
  data: any,
  children: any[],
  all: any[],
  isTopology = false
): IReportTab => {
  const { page_name, equip_id } = data;
  const match = [];
  for (const child of children) {
    const find = all.filter((i) => !i.embed).find((e) => e.equip_uuid === child.uid);
    if (!find) continue;
    match.push(find);
  }
  return {
    key: `${equip_id}`,
    name: page_name,
    component: AlgorithmView,
    type: 'datatable',
    scheme: buildScheme(data),
    order: 1,
    uuids: match?.length ? match.map((i) => i.asset_uuid) : [],
    isTopology,
  };
};

export const buildTabs = async (
  children: any[],
  all: any[],
  types: IEquipmentGroupType[]
): Promise<IReportTab[]> => {
  return await new Promise((resolve, reject) => {
    try {
      const tabs = new Map<string, IReportTab>();
      for (const equip of all.filter((i) => !i.embed)) {
        const find = children.find((i) => i.uid === equip.equip_uuid);
        if (!find) continue;
        const type = types.find((t) => t.equip_id === equip.equip_id);
        const exist = tabs.get(`${equip.eq_order}`);
        let phases;

        if (exist?.phases) {
          phases = [...exist.phases, equip.phase];
        } else {
          phases = [equip.phase];
        }
        let ids;
        if (exist?.ids) {
          ids = [...exist.ids, equip.equip_id];
        } else {
          ids = [equip.equip_id];
        }
        let uuids;
        if (exist?.uuids) {
          uuids = [...exist.uuids, equip.asset_uuid];
        } else {
          uuids = [equip.asset_uuid];
        }
        tabs.set(`${equip.eq_order}`, {
          key: `${equip.eq_order}`,
          name: equip.type_name,
          component: DetailsReport,
          type: 'datatable',
          type_uid: type?.type_uid,
          phases: [...new Set(phases)],
          ids: [...new Set(ids)],
          uuids: [...new Set(uuids)],
          order: equip.eq_order,
        });
      }
      resolve(
        [...tabs.values()].sort((a, b) => {
          if (!a?.order || !b?.order) return -1;
          return a.order - b.order;
        })
      );
    } catch (e) {
      reject(e);
    }
  });
};

export const transformColumns = async (config: any[]): Promise<IReportColumn[]> => {
  return await new Promise<IReportColumn[]>((resolve, reject) => {
    try {
      const state = config.find((i) => i.table_name.includes('state'));
      if (!state?.props?.length) resolve([]);
      const sorted = state?.props
        ?.filter((i: any) => Number(i.Order) !== 0 && i.Mandatory)
        .sort((a: any, b: any) => a.Order - b.Order);
      const unique = new Map<string, IReportColumn>();
      const tableName = state?.table_name;
      for (const x of sorted) {
        const { Notes, Name, Type, Flags, Format, Mandatory, Order, Sort, Uid } = x;
        unique.set(Notes, {
          header: Notes,
          key: Notes,
          property: Name,
          type: Type,
          flags: Flags,
          format: Format,
          mandatory: Mandatory,
          order: Order,
          sort: Sort,
          uid: Uid,
          tableName,
        });
      }
      resolve([...unique.values()]);
    } catch (e) {
      reject(e);
    }
  });
};
