import { IMatrixColumn } from '@/components/row-popup-matrix2/types';

const scaleValueX2 = (v: any, scale: any) => {
  if (v === '-' || v === '?') return v;
  const result = v * scale;
  if (Math.abs(result) >= 1000) return result.toFixed(0);
  return result.toFixed(3);
};

export const buildX2Matrix = (
  { matrix, n_prm_1 }: { matrix: number[][]; n_prm_1: number[] },
  scale: number
) => {
  const summaryMatrix: number[][] = [];
  for (let u = 0; u < n_prm_1.length; u++) {
    // add row
    summaryMatrix.push([]);
    // fill row
    summaryMatrix[u] = [Math.round(n_prm_1[u]), ...matrix[u].map((m) => scaleValueX2(m, scale))];
  }
  return summaryMatrix;
};

export const buildMatrixColumns = (n_t: number[], n1: string, n2: string): IMatrixColumn[] => {
  if (!n_t?.length) return [];
  return [
    { key: 0, value: `${n1} / ${n2}`, fixed: 'left', width: '95px' },
    ...n_t?.map((t, i) => ({ key: i + 1, value: `${t}` })),
  ];
};

export const buildMatrixRows = (m2X?: number[][], columns?: IMatrixColumn[]) => {
  if (!m2X?.length || !columns?.length) return [];
  // console.log('m2X:::',m2X);
  const rows: any = [];
  for (const row of m2X) {
    let item = {};
    let i = -1;
    for (const m of row) {
      item = { ...item, [columns[++i].value]: m };
    }
    rows.push({ ...item });
  }
  return rows;
};
