import { ActionTree } from 'vuex';
import { RestApiService } from '@/services';
import { RootState } from '@/store/types';
import { DataQuery, DataState, INTERVAL_CONST } from '@/store/data/data.types';
import { CALC_NAMES, DATA_CONST, END_POINTS } from '@/store/data/data.constants';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import {
  buildUrlQueries,
  checkCritical,
  getMam,
  getMamMS,
  mergeDataArrays,
  transformData,
} from '@/store/data/data.extensions';
import store from '@/store';
import { ROOT_STORE_CONST } from '@/store/root.constants';

const rest = new RestApiService();
// we need start three queries
const { HARMONIC, FREQUENCY } = END_POINTS;
const harPath = getApiUrl(TR_TYPE.HTTP, `/click-house/${HARMONIC}?`);
const freqPath = getApiUrl(TR_TYPE.HTTP, `/click-house/${FREQUENCY}?`);

export const actions: ActionTree<DataState, RootState> = {
  getData: async (context, payload: DataQuery) => {
    try {
      // start busy indicator
      await store.dispatch(ROOT_STORE_CONST.START_BUSY_INDICATOR);

      if (!checkCritical(payload)) {
        console.log('The chosen range is not possible!');
        context.commit(DATA_CONST.DATA_LOADED, context.state.data);
        await store.dispatch(ROOT_STORE_CONST.STOP_BUSY_INDICATOR);
        return;
      }

      const har1 = await rest.fetchData(await buildUrlQueries(payload, harPath, CALC_NAMES.CALC_1));
      const har2 = await rest.fetchData(await buildUrlQueries(payload, harPath, CALC_NAMES.CALC_2));
      const freq = await rest.fetchData(await buildUrlQueries(payload, freqPath));
      const data = mergeDataArrays(
        transformData(har1, payload.interval.value),
        transformData(har2, payload.interval.value),
        transformData(freq, payload.interval.value),
        payload.interval.value
      );
      context.commit(DATA_CONST.DATA_LOADED, data);
      const mam = [];
      if (payload.interval.value === 'ms') {
        mam.push(getMamMS(har1), getMamMS(har2), getMamMS(freq));
      } else {
        mam.push(getMam(har1), getMam(har2), getMam(freq));
      }
      await context.dispatch(DATA_CONST.UPDATE_MAM, mam);
      await store.dispatch(ROOT_STORE_CONST.STOP_BUSY_INDICATOR);
    } catch (e) {
      context.commit(DATA_CONST.DATA_ERROR, e);
      await store.dispatch(ROOT_STORE_CONST.STOP_BUSY_INDICATOR);
    }
  },
  updateQuery: async (context, payload: DataQuery) => {
    try {
      await context.commit(DATA_CONST.QUERY_UPDATED, payload);
      await context.dispatch(DATA_CONST.GET_DATA, context.state.query);
    } catch (e) {
      await context.commit(DATA_CONST.QUERY_ERROR);
    }
  },
  updateRefs: async (context, payload) => {
    try {
      await context.commit(DATA_CONST.REFS_UPDATED, payload);
    } catch (e) {
      await context.commit(DATA_CONST.UPDATE_REFS_ERROR, e);
    }
  },
  changePhase: async (context, payload) => {
    try {
      await context.commit(DATA_CONST.PHASE_CHANGED, payload);
    } catch (e) {
      await context.commit(DATA_CONST.PHASE_ERROR);
    }
  },
  updateMam: async (context, payload) => {
    try {
      await context.commit(DATA_CONST.MAM_UPDATED, payload);
    } catch (e) {
      await context.commit(DATA_CONST.MAM_ERROR, e);
    }
  },
};
