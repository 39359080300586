///PROP
// Flags: 0
// Format: "text"
// Mandatory: true
// Max: "2"
// Min: "1"
// Name: "type"
// Notes: "Сообщение"
// Order: 2
// Type: "Enum"
// Uid: "d1a8f072-e724-4b43-a4cc-6a2793036c3e"

/// REF
// "enumUid": "d1a8f072-e724-4b43-a4cc-6a2793036c3e",
//   "order": 2,
//   "name": "HighVoltage_Stop",
//   "notes": "Снижение напряжения ниже уровня "

import { ColType } from '@/components/basics/table/basic-table.types';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';
import moment from 'moment';
import { MOMENT_FORMAT } from '@/utils/datetime/DateTimeRangePresets';
import { DataConverter } from '@/components/basics/table/converters/DataConverter';
import { ValueFormat } from '@/components/basics/table/converters/types';

export const DATETIME_UNKOEF = 1_000_000;

const replace = (i: any) => {
  // let j = i.toString();
  // try {
  //   j = j.replace('.', ',');
  // } catch (e) {
  //   //
  // }
  return i;
};

export const format = (col: any, value: number): string => {
  if (!value) return `${value}`;
  switch (col.Format as ValueFormat) {
    case '.':
    case '*':
      return `${replace(value.toFixed(0))}`;
    case '.*':
      return replace(value.toFixed(1));
    case '.**':
      return replace(value.toFixed(2));
    case '.***':
      return replace(value.toFixed(3));
    case '.ms':
      return `${replace(value)}`;
    case 'mm:ss.ms':
      return `${replace(value)}`;
    case 'hh:mm:ss.ms':
      return `${replace(value)}`;
    case 'text':
      return `${replace(value)}`;
    default:
      return `${value}`;
  }
};

export const convertEnum = (
  value: any,
  refs: ITabRef[],
  col: any,
  commentsMap?: Map<string, string>
): string => {
  const ref = refs.find((i) => `${i.enumUid}` === `${col.Uid}` && `${i.order}` === `${value}`);
  if (ref?.props?.Comment) {
    commentsMap?.set(col?.dbProps, ref?.props?.Comment);
  }

  if (value > col.Max) return '';
  if (col.Format === 'text' && ref) {
    return ref.notes;
  }
  return !ref && (value === 0 || value === '0') ? '' : `${replace(value)}`;
};

export const convertGuid = (value: any, col: any, guidTablesHash?: Record<string, any>): string => {
  const columnData = guidTablesHash && guidTablesHash[col.Name];
  if (columnData) {
    const row = columnData.items.find((x: any) => x.uuid === value);
    return row?.[columnData.property] ?? '';
  } else {
    return value;
  }
};

export const convertDateTime = (value: any, col: any): string => {
  if (col.Format === '.ms') {
    return replace(moment(new Date(value / 1000000)).format(MOMENT_FORMAT.dateToMsss));
  } else if (col.Format === 'mm:ss.ms') {
    const dt = new Date(value / 1_000_000);
    return replace(moment(dt).format(MOMENT_FORMAT.minSecMss));
  } else if (col.Format === 'hh:mm:ss.ms') {
    const dt = new Date(value / 1_000_000);
    return replace(moment(dt).format(MOMENT_FORMAT.hourMinSecMss));
  }
  return `${replace(value)}`;
};

export const convertAsset = (value: any, equipment: any[]): string => {
  const find = equipment?.find((e) => `${e.asset_uuid}` === `${value}`);
  return find?.name ?? value;
};

export const getSecFormat = (value: number) => {
  if (value === 0) {
    return '0';
  }
  if (value <= 60.99) {
    return replace(value.toFixed(2));
  }
  return replace(value.toFixed(0));
};

export const convertFloat = (value: any, col: any): string => {
  if (value <= col.Min) return '0';
  if (col.Format === '.ms') {
    return replace(getSecFormat(moment.duration(value / DATETIME_UNKOEF).asSeconds()));
  } else if (col.Format === 'mm:ss.ms') {
    const dt = new Date(value / DATETIME_UNKOEF);
    return replace(moment(dt).format(MOMENT_FORMAT.minSecMss));
  } else if (col.Format === 'hh:mm:ss.ms') {
    const dt = new Date(value / DATETIME_UNKOEF);
    return replace(moment(dt).format(MOMENT_FORMAT.hourMinSecMss));
  }
  const scale = col?.Scale;
  if (scale) value = value * scale;
  let r = format(col, value);
  if (r === '-0.00') r = '0.00';
  if (r === '-0.000') r = '0.000';
  if (r === '-0.0') r = '0.0';
  return replace(r);
};

export const convertInteger = (value: any, col: any): string => {
  if (value > col.Max) return '';
  if (value <= col.Min) return '0';
  const scale = col?.Scale;
  if (scale) value = value * scale;
  return `${replace(value)}`;
};

const getColor = (col: any, value: any, refs: ITabRef[]): string | null => {
  const ref = refs.find((i) => `${i.enumUid}` === `${col.Uid}` && `${i.order}` === `${value}`);
  return ref?.props?.Color ?? null;
};

const tryInjectColor = (
  col: any,
  value: any,
  refs: ITabRef[],
  colorMap?: Map<string, string>
): Map<string, any> => {
  if (!colorMap) {
    colorMap = new Map<string, any>();
  }
  if (!col?.ColorEnum) return colorMap;
  colorMap.set(col?.key, getColor(col, value, refs) ?? col.ColorEnum);
  return colorMap;
};

const tryInjectFormat = (
  col: any,
  value: any,
  refs: ITabRef[],
  formatMap?: Map<string, string>
): Map<string, any> => {
  if (!formatMap) {
    formatMap = new Map<string, any>();
  }
  if (!col?.ScaleEnum) return formatMap;
  formatMap.set(col?.key, col.ScaleEnum);
  return formatMap;
};

export const buildProp = (
  value: any,
  refs: ITabRef[],
  col: any,
  guidTablesHash?: Record<string, any>,
  equipment?: any[],
  colorMap?: Map<string, any>,
  enumFormatMap?: Map<string, any>,
  commentsMap?: Map<string, any>
) => {
  // if (!col?.order || (col?.order && col.order < 0)) return;
  if (value === null) return;
  tryInjectColor(col, value, refs, colorMap);
  tryInjectFormat(col, value, refs, enumFormatMap);
  const { Type } = col;
  switch (Type as ColType) {
    // todo tmpl solution
    case ColType.Integer:
    case ColType.Float:
    case ColType.DateTime:
      return DataConverter.convert(value, col);
    case ColType.Enum:
      return convertEnum(value, refs, col, commentsMap);
    case ColType.Guid:
      return convertGuid(value, col, guidTablesHash);
    case ColType.string:
    case ColType.String:
      return value;
    case ColType.Asset:
      return convertAsset(value, equipment ?? []);
  }
};
