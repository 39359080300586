























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
  HDQueryType,
  IChartArea,
  IPropertyScheme,
  MaxAvgMin,
} from '@/store/history-data/history-data.types';
import { Action, Getter } from 'vuex-class';
import { HIST_DATA_CONST } from '@/store/history-data/history-data.const';
import { Dictionary } from 'vue-router/types/router';
import { timeFrames, TimeInterval } from '@/store/data/data.types';
import { ChartManagerSingleton } from '@/store/history-data/chart-manager';
import { PhaseConverter } from '@/utils/PhaseConverter';
const { CHANGE_CHART_SCHEME, HISTORY_DATA, GET_HISTORY_DATA, LEGEND_DATA } = HIST_DATA_CONST;

@Component
export default class WindingLabel extends Vue {
  @Prop() header?: string;
  @Prop() area?: IChartArea;
  selectedProperty = '';

  // @Watch('area.propertyScheme') onPropSchemeChanged(val?: IPropertyScheme, old?: IPropertyScheme) {
  //   if (val === old || !val) return;
  //   this.selectedProperty = val?.name ?? '';
  // }

  get unit(): string {
    return this.area?.propertyScheme?.unit ?? '';
  }

  get props() {
    return ChartManagerSingleton.instance().propertiesScheme;
  }

  @Getter(LEGEND_DATA, { namespace: HISTORY_DATA })
  legendData!: Map<string, MaxAvgMin>;

  get currentLegendData(): MaxAvgMin | undefined {
    const values = this.area?.series.map((s) => s.value);
    if (!values?.length) return undefined;
    const key = values.join('-');
    return this.legendData.size > 0 ? this.legendData.get(key) : undefined;
  }

  format(x: any) {
    const n = Number(x);
    if (!n || isNaN(n)) return 0;
    return n.toFixed(2);
  }

  get max() {
    const r = this.currentLegendData?.max ?? 0;
    return this.format(r);
  }

  get min() {
    const r = this.currentLegendData?.min ?? 0;
    return this.format(r);
  }

  get avg() {
    const r = this.currentLegendData?.avg ?? 0;
    return this.format(r);
  }

  @Watch('propsSchemes') OnPropsChanged(val?: IPropertyScheme[], old?: IPropertyScheme[]) {
    if (val === old || !val?.length) return;
    if (this.area?.propertyScheme?.name) this.selectedProperty = this.area?.propertyScheme?.name;
  }

  @Action(CHANGE_CHART_SCHEME, { namespace: HISTORY_DATA })
  changeChartScheme!: (payload: [IChartArea, IPropertyScheme]) => void;

  @Action(GET_HISTORY_DATA, { namespace: HISTORY_DATA })
  getHistoryData!: (payload: [HDQueryType, string[], IPropertyScheme]) => void;

  @Watch('selectedProperty') onSelectedChanged(val?: string, old?: string) {
    if (val === old) return;

    // update props scheme
    const pScheme = this.props?.find((j) => j.name === val);
    if (!pScheme || !this.area) return;
    this.changeChartScheme([this.area, pScheme]);

    // get history data
    const query = {
      select: pScheme.select,
      ...this.partialQuery,
      algorithm: pScheme.algorithm,
      // eslint-disable-next-line @typescript-eslint/camelcase
      calculator_name: pScheme.calculatorName,
    } as HDQueryType;
    const values = this.area.series.map((i) => i.value);
    this.getHistoryData([query, values, pScheme]);
  }

  get interval() {
    const { interval } = this.$route.query as Dictionary<string | (string | null)>;
    return timeFrames.find((x: TimeInterval) => x.value === interval);
  }

  get partialQuery() {
    const { start, end } = this.$route.query as Dictionary<string | (string | null)>;
    if (start && end && this.interval) {
      return {
        start,
        end,
        interval: this.interval.value,
      };
    } else {
      return null;
    }
  }

  get phase(): null | number {
    const { phase } = this.$route.query;
    if (!phase) return null;
    const sPhase = PhaseConverter.getPhase(phase as string);
    if (!sPhase || isNaN(Number(sPhase))) return null;
    return Number(sPhase);
  }

  get equipId(): null | number {
    const { id } = this.$route.params;
    if (!id || isNaN(Number(id))) return null;
    return Number(id);
  }

  async mounted(): Promise<void> {
    if (!this.phase || !this.equipId) {
      console.log(`Error form getting phase: ${this.phase} or equipId: ${this.equipId}`);
      return;
    }
    // if (!ChartManagerSingleton.instance().propertiesScheme?.length) {
    //   // console.log('New schemes will be generated!');
    //   await ChartManagerSingleton.instance().updatePropertiesScheme(this.phase, this.equipId);
    // }
    // console.log('propertiesScheme:::', ChartManagerSingleton.instance().propertiesScheme);
    // console.log('area?.propertyScheme:::', this.area?.propertyScheme);

    await ChartManagerSingleton.instance().updateAreaPropertyScheme(this.area);

    this.selectedProperty = this.area?.propertyScheme?.name ?? '';
  }
}
