import { AlgorithmScheme } from '@/store/algorithms/algorithms.types';
import { Route } from 'vue-router';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';
import {
  buildProp,
  convertDateTime,
  format,
} from '@/components/items-tab/panes/algorithms/algorithm.convertors';
import { PhaseConverter } from '@/utils/PhaseConverter';
import { TableCol } from '@/components/basics/table/basic-table.types';

const sortData = (data: any[], { sorter }: AlgorithmScheme) => {
  if (!sorter) {
    return data;
  }
  const { property, type, dir } = sorter;
  const dc = dir === 'ASC' ? 1 : -1;
  switch (type) {
    case 'date':
      return data.sort(
        (a, b) => dc * new Date(a[property]).getTime() - dc * new Date(b[property]).getTime()
      );
    case 'number':
      return;
  }
};

const scalingEnum = 'ScaleEnum';
const extractScaler = (cols: any[], enums: any[], rec: any) => {
  let results = {};
  const uniqueEnums = cols.filter((c: any) => c.Type === 'Enum');
  const fEnums = uniqueEnums?.flatMap((e) =>
    enums.filter((i) => i.enumUid === e.Uid && i?.props?.Scale)
  );
  for (const col of cols) {
    const scale = col[scalingEnum];
    const order = rec[scale];
    const fScale = fEnums.find((i) => `${i.order}` === `${order}`);
    if (scale) {
      results = { ...results, [col.key as string]: { scale: fScale?.props?.Scale } };
    }
  }
  return results;
};

const tryAddRowConfig = (col: any, value: any, enums: any[]) => {
  if (col.Type !== 'Enum' || col.Type !== 'StateEnum') return null;
  const ref = enums.find((i) => `${i.enumUid}` === `${col.Uid}` && `${i.order}` === `${value}`);
  if (!ref?.props) return null;
  return { ...ref.props };
};

const fixColorSettings = (colorMap: Map<string, string>) => {
  for (const [key, value] of colorMap) {
    const color = colorMap.get(value);
    if (!color) continue;
    colorMap.set(key, color);
  }
  return colorMap;
};

const restoreEnumFormatValue = (
  enumFormatMap: Map<string, string>,
  columns: TableCol[],
  enums: ITabRef[]
) => {
  for (const [key, value] of enumFormatMap) {
    const col = columns.find((c) => c.property === value);
    if (!col) continue;
    const _enum = enums.find((e) => `${e.enumUid}` === `${(col as any).Uid}`);
    if (!_enum) continue;
    enumFormatMap.set(key, _enum?.props?.Format);
  }
  return enumFormatMap;
};

const tsNano = 'ts_nano';
const transformMessage = (
  rec: any,
  { columns }: AlgorithmScheme,
  tabsRefs?: ITabRef[],
  guidTablesHash?: Record<string, any>,
  equipment?: any[],
  isPipe = false
) => {
  if (!tabsRefs) return;
  let msg: any = {};
  const scaleKeys = new Set<string>();
  const colorKeys = new Set<string>();
  const enumScale = extractScaler(columns, tabsRefs, rec);

  // col color mapping
  let colorMap = new Map<string, string>();

  // format map from enum
  let enumFormatMap = new Map<string, string>();

  // commentsMap
  const commentsMap = new Map<string, string>();

  // row props cycle
  for (const [key, value] of Object.entries(rec)) {
    const col = columns.find((i) => i.dbProps === key);
    if (col) {
      let res = buildProp(
        value,
        tabsRefs,
        col,
        guidTablesHash,
        equipment,
        colorMap,
        enumFormatMap,
        commentsMap
      );
      const scaleKey = (col as any)?.ScaleEnum;

      if (scaleKey) {
        scaleKeys.add(scaleKey);
      }
      const colorKey = (col as any)?.ColorEnum;
      if (colorKey && col?.property) {
        colorKeys.add(col.property);
      }

      const eScale = (enumScale as any)[key as string];
      if (eScale?.scale) {
        res = format(col, res * eScale.scale);
      }
      // todo tmp pipe flag
      if (isPipe) {
        let res1 = value;
        // todo make pipe
        if (eScale?.scale && !Number.isNaN(res1)) {
          res1 = Number(res1) * eScale.scale;
        }
        msg = { ...msg, [key]: res1 };
      } else {
        if (res) {
          msg = { ...msg, [key]: res };
        }
      }

      const config = tryAddRowConfig(col, value, tabsRefs);
      if (config) {
        msg.config = { ...msg.config, ...config };
      }
    }

    if (scaleKeys?.size > 0) {
      msg.config = { ...msg.config, scaleKeys };
    }
    if (colorKeys?.size > 0) {
      msg.config = { ...msg.config, colorKeys };
    }
  }

  if (colorMap?.size) {
    colorMap = fixColorSettings(colorMap);
    msg = { ...msg, colorMap };
  }

  if (enumFormatMap?.size) {
    enumFormatMap = restoreEnumFormatValue(enumFormatMap, columns, tabsRefs);
    msg = { ...msg, enumFormatMap };
  }

  if (commentsMap?.size) {
    msg = { ...msg, commentsMap };
  }

  if (rec?.ts_nano && !isNaN(rec.ts_nano)) {
    msg = {
      ...msg,
      [tsNano]: convertDateTime(rec.ts_nano, { Format: '.ms' }),
      ts: Number(rec.ts_nano),
      true_asset_uuid: rec.asset_uuid,
    };
  }
  if (enumScale) {
    msg = { ...msg, enumScale };
  }

  return msg;
};

export const sortByTsNano = (a: any, b: any): number => {
  if (!a?.ts || !b?.ts) return -1;
  return b - a;
};

export const transformData = (
  data: any[],
  scheme: AlgorithmScheme,
  tabsRefs?: ITabRef[],
  guidTablesHash?: Record<string, any>,
  equipment?: any[],
  isPipe = false
) => {
  return data.map((i) => transformMessage(i, scheme, tabsRefs, guidTablesHash, equipment, isPipe));
};
const cn = 'calculator_name';
export const buildDBQuery = (route: Route, scheme: AlgorithmScheme) => {
  const sorter = scheme.columns.find((x) => x.sort);
  const sort = sorter && `${sorter.dbProps}:${sorter.sort?.toUpperCase()}`;
  const { phase } = route?.query;
  return {
    sort,
    ...route.query,
    algorithm: scheme.dbTableName,
    [cn]: scheme.moduleUid,
    phase: phase ? PhaseConverter.getPhase(phase as string) : scheme.phaseValue,
  };
};
