










import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { AlgorithmScheme } from '@/store/algorithms/algorithms.types';
import { QueryParamsService } from '@/utils';
import EquipBaseState from '@/components/items-tab/panes/equip-state/EquipBaseState.vue';
type QueryType = { tab?: string };

@Component({
  components: {
    'equip-base-state': EquipBaseState,
  },
})
export default class EquipStateOPN extends Vue {
  queryParams!: QueryParamsService<QueryType>;
  @Prop() scheme?: AlgorithmScheme;
  @Prop() phase?: number;
  @Prop() currentEquipment: any;
  sampleKeys: string[] = [
    'i_leak',
    'i3_leak',
    'i3_i1_leak',
    'opn_over',
    'lifetime_opn',
    'time_pulse',
  ];
}
