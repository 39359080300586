
















import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { Getter } from 'vuex-class';

import { ROOT_STORE_CONST } from '@/store/root.constants';
import { BusyIndicator } from '@/store/types';
import { QueryParamsService } from '@/utils';

type QueryType = {
  offset?: string;
  limit?: string;
};

@Component({})
export default class TablePagination extends Vue {
  queryParams!: QueryParamsService<QueryType>;

  @Prop() noSizes?: boolean;
  @Prop() disabled?: boolean;
  @Prop() page?: any[];
  @Prop() total?: number;

  @Getter(ROOT_STORE_CONST.BUSY_INDICATOR)
  busyIndicator?: BusyIndicator;

  get pageIndex() {
    const { offset, limit } = this.queryParams.get();
    if (offset && limit && this.page?.length) {
      return Number(offset) / Number(limit) + 1;
    } else {
      return 1;
    }
  }

  get pageSize() {
    return Number(this.queryParams.get().limit);
  }

  get pageCount() {
    return this.total && Math.ceil(this.total / this.pageSize);
  }

  onPagination(p: number) {
    if (this.pageSize) {
      this.queryParams.update({ offset: (this.pageSize * (p - 1)).toString() });
    }
  }

  onChangeSize(size: number) {
    this.queryParams.update({
      limit: size.toString(),
      offset: (size * (this.pageIndex - 1)).toString(),
    });
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      offset: this.$route.query.offset,
      limit: this.$route.query.limit,
    }));
  }

  mounted() {
    this.queryParams.initialise({
      limit: '10',
      offset: '0',
    });
  }
}
