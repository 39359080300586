













































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { IQueryPopupChartParams } from '@/store/popup-charts/popup.charts.types';
import {
  buildMatrix4Rows,
  buildMatrixColumns,
  buildValuesMatrix,
} from '@/components/row-popup-matrix4/extensions';
import { Getter } from 'vuex-class';
import { PRELOADER_STATE } from '@/store/abstract/preloader';
import { IPreloader } from '@/store/abstract/preloader/types';
import { POPUP_CHART_CONST } from '@/store/popup-charts/popup.charts.const';

@Component
export default class RowPopupMatrix4 extends Vue {
  @Prop() data!: any[];
  @Prop() names!: any[] | null;
  @Prop() queryParams!: IQueryPopupChartParams[];
  @Prop() title?: string;
  @Prop() selectedRow?: any;

  @Getter(PRELOADER_STATE, { namespace: POPUP_CHART_CONST.POPUP_CHART })
  preloader!: IPreloader;

  baseCellStyle = { padding: '0', height: '20px', textAlign: 'center' };
  valueCellStyle({ row, column, rowIndex, columnIndex }: any) {
    const cellValue = row[column?.label];
    if (columnIndex > 0 && cellValue && cellValue !== '-')
      return { background: '#f6f8ff', color: '#2E5BFF', ...this.baseCellStyle };
    return { ...this.baseCellStyle };
  }

  rowClassName() {
    return 'matrix-row';
  }

  matrix: any = [];
  uIndex = 0;
  iIndex = 0;
  buildMatrix() {
    if (!this.data?.length) return;
    const i = this.uPanel.indexOf(this.uIndex);
    const j = this.iPanel.indexOf(this.iIndex);
    this.matrix = buildValuesMatrix(this.data[0].matrix[i][j], this.rowScale);
    // console.log('matrix:::', this.matrix);
  }
  get uPanel() {
    if (!this.data?.length || !this.data[0]) return [];
    const { selectors } = this.data[0];
    if (!selectors?.length || !selectors[0]?.length) return [];
    return selectors[0].map((i: any) => i);
  }

  get rowScale(): number {
    // console.log('selectedRow:::',this.selectedRow);
    return !this.selectedRow?.config?.Scale ? 1 : this.selectedRow?.config?.Scale;
  }

  selectorName(name: string): string {
    if (!this.data?.length || !name) return '';
    const order = this.data[0][name];
    return this.names?.find((e) => Number(e.order) === order)?.notes ?? '';
  }

  @Watch('data') onDataChange(val: any[], old: any[]) {
    if (val === old || !val?.length) return;
    if (!val?.length || !val[0]) return [];
    const { selectors } = val[0];
    if (!selectors?.length || selectors.length < 2) return;
    this.uIndex = selectors[0][0];
    this.iIndex = selectors[1][0];
  }

  @Watch('uIndex') onSelectedU(val: number, old: number) {
    if (val === old ) return;
    this.buildMatrix();
  }

  @Watch('iIndex') onSelectedI(val: number, old: number) {
    if (val === old ) return;
    this.buildMatrix();
  }

  get columns() {
    if (
      !this.data?.length ||
      !this.data[0].selectors?.length ||
      this.data[0]?.selectors?.length < 4
    )
      return [];
    const p = this.data[0]?.selectors[3];
    return buildMatrixColumns(p, this.selectorName('type_prm_3'), this.selectorName('type_prm_4'));
  }

  get rows() {
    if (!this.matrix?.length || !this.columns?.length) return [];
    const t2X = this.data[0]?.selectors[2];
    return buildMatrix4Rows(this.matrix, t2X, this.columns);
  }

  get iPanel() {
    if (!this.data?.length || !this.data[0]) return [];
    const { selectors } = this.data[0];
    if (!Array.isArray(selectors)) return [];
    if (selectors?.length < 2) return [];
    if (!selectors[1]?.length) return [];
    return selectors[1].map((i: any) => i);
  }
}
