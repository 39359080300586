import { MutationTree } from 'vuex';

import { LayoutState } from './types';
import { LAYOUT } from './constants';

export const mutations: MutationTree<LayoutState> = {
  [LAYOUT.SET_DRAWER_VISIBLE](state, payload: boolean) {
    localStorage.setItem('layout.drawerVisible', JSON.stringify(payload));
    state.drawerVisible = payload;
  },
  [LAYOUT.SET_DRAWER_WIDTH](state, payload: number) {
    localStorage.setItem('layout.drawerWidth', JSON.stringify(payload));
    state.drawerWidth = payload;
  },
};
