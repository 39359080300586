













import { Component, Prop } from 'vue-property-decorator';
import { TimeInterval, timeFrames, TimeFrameType } from '@/store/data/data.types';

import Vue from 'vue';
@Component
export default class TimeFrameSelector extends Vue {
  @Prop() interval!: TimeFrameType;
  @Prop() disabled!: boolean;
  @Prop() onChange?: (value: any) => void;

  get tfItems(): TimeInterval[] {
    return timeFrames;
  }

  get intervalFinder() {
    return timeFrames.find((x) => x.value === this.interval);
  }
  handleChange(val: string) {
    if (!this.onChange) return;
    this.onChange(val);
  }
}
