



















import { Component, Prop } from 'vue-property-decorator';
import { durationPreset, DurationPresetType, tryFitOnDurationPreset } from './extensions';
import { IDatetimeRange } from '@/store/abstract/range-getter/types';
import Vue from 'vue';

@Component
export default class PresetSelector extends Vue {
  @Prop() range!: IDatetimeRange;
  @Prop() disabled?: boolean;
  @Prop() onChange?: (range: string) => void;
  @Prop() defaultPreset?: DurationPresetType;

  get selectedPreset() {
    if (!this.range) return durationPreset['hour'];
    return tryFitOnDurationPreset(this.range);
  }

  get presets() {
    return Object.entries(durationPreset).map((v: any) => ({ key: v[0], value: v[1] }));
  }

  // mounted() {
  //   if (this.defaultPreset && !this.$route?.query?.interval) {
  //     this.onChange && this.onChange(
  //       durationPreset[this.defaultPreset] || durationPreset['hour']
  //     );
  //   }
  // }
}
