import router from '@/router';

type UpdateParams = {
  removeExcept?: string[];
};

export class QueryParamsService<T> {
  constructor(private querySelector: () => any) { }

  public get() {
    return this.querySelector();
  }

  public update(x: T, params: UpdateParams | null = null, writeHistory = true) {
    const val = this.querySelector();
    const isNotEqual = Object.entries(x).some(([key, value]) => val[key] !== value);

    if (isNotEqual || params?.removeExcept) {
      let newQuery = { ...router.currentRoute.query, ...x } as any;
      if (params?.removeExcept) {
        const keys = [...Object.keys(x), ...params.removeExcept];

        newQuery = Object.fromEntries(
          Object.entries(newQuery).filter(([key]) => keys.some((x) => x === key))
        );
      }
      if (writeHistory)
        router.push({ query: newQuery }).catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      else
        router.replace({ query: newQuery }).catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
    }
  }

  public initialise(x: T) {
    const val = this.querySelector();
    if (
      Object.entries({ ...val }).some(
        ([key, value]) => value === undefined && (x as any)[key] !== undefined
      )
    ) {
      // вызываем через таймаут чтобы избежать гонки между вызовами и некорректного current query
      setTimeout(() => {
        router.replace({ query: { ...router.currentRoute.query, ...x } });
      }, 0);
    }
  }
}
