import { EChartOption, EChartTitleOption } from 'echarts';
import { Builder } from '@/components/basics/charts/echart/builder';
import Format = echarts.EChartOption.Tooltip.Format;
import moment from 'moment';
import _ from 'lodash';
import {
  colorByPhase,
  phaseColor,
} from '@/components/basics/charts/history-chart/e-charts.color-manager';
import { ShockPoint } from '@/components/basics/charts/short-circuit-chart/types';
import { ScaleManager } from '@/utils/scale/ScaleManager';

export function getOption<T>(item: unknown, pack: T): T | undefined {
  if (!item) {
    return;
  }
  if (item === true) {
    return pack;
  }
  return item as T;
}

export const buildSimpleSeries = (
  item: any,
  areaIndex: number,
  showSymbol = false,
  phase: number | null,
  zzPoint: Record<ShockPoint, number>,
  yAxisPoint?: number
): EChartOption.Series => {
  const { name, data } = item;
  return {
    data,
    type: 'line',
    smooth: false,
    animation: false,
    showSymbol: showSymbol,
    showAllSymbol: showSymbol,
    xAxisIndex: areaIndex,
    yAxisIndex: areaIndex,
    symbol: name,
    name,
    itemStyle: {
      color: colorByPhase(phase),
    },
    lineStyle: {
      width: 1,
      color: colorByPhase(phase),
    },
    markLine: {
      symbol: 'none',
      data: [
        {
          symbol: 'none',
          xAxis: zzPoint.xShock,
          lineStyle: {
            color: '#b1b0b0',
            type: 'dotted',
            width: 1,
            opacity: 0.55,
          },
          label: {
            formatter: '',
          },
        },
        {
          symbol: 'none',
          yAxis: yAxisPoint,
          lineStyle: {
            color: '#b1b0b0',
            type: 'dotted',
            width: 0.5,
            opacity: 0.35,
          },
          label: {
            formatter: '',
          },
        },
      ],
    },
  };
};

const buildSimpleSeries2 = (
  item: any,
  areaIndex: number,
  phase?: number | null
): EChartOption.SeriesLine => {
  const { name, data } = item;
  return {
    data: data.map((i: any) => [moment(new Date(i.x)).format('YYYY-MM-DD HH:mm:ss.S'), i.y]),
    type: 'line',
    smooth: false,
    animation: false,
    showSymbol: false,
    xAxisIndex: areaIndex,
    yAxisIndex: areaIndex,
    symbol: name,
    name,
    lineStyle: {
      width: 1,
      color: colorByPhase(phase),
    },
  };
};

const buildTitles = (areas: string[], space = 150, phase?: number | null): EChartTitleOption[] => {
  return areas.map((t, i) => ({
    show: true,
    text: t,
    textAlign: 'center',
    left: '50%',
    top: space * i + 17,
    textStyle: {
      fontSize: 13,
      fontWeight: 'normal',
      color: colorByPhase(phase),
    },
  }));
};

export const buildPopupChartOptions = (
  _data: any[],
  builder: Builder,
  phase?: number | null
): EChartOption | null => {
  if (!_data || !Array.isArray(_data) || !_data.length) return null;
  const data = _.uniqBy(_data, 'name');
  const areas = data.map((i) => i.name);
  const space = 30;
  const mrg = 50;
  const height = 300 / areas.length - space / 2;
  return {
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          title: '.png',
          name: `${new Date().toISOString()}`,
        },
      },
    },
    // tooltip: builder.buildToolTip(),
    title: buildTitles(areas, 300 / areas.length + 21, phase),
    xAxis: builder.buildXAxis(areas, 'category', 'HH:mm:ss.S'),
    yAxis: builder.buildYAxis(areas, 'value', data.map(i => i.info.axisScale)),
    grid: builder.buildGrid(areas, { height }, mrg, space),
    series: data.map((j, i) => buildSimpleSeries2(j, i, phase)),
  };
};

export const buildShortCircuitChartOptions = (
  data: any[],
  builder: Builder,
  phase: number | null
): EChartOption | null => {
  if (!data || !Array.isArray(data) || !data.length) return null;
  const areas = data.map((i) => i.name);
  const space = 30;
  const mrg = 40;
  const height = 300 / areas.length - space / 2;
  const zz = data[0].shockPoint as Record<ShockPoint, number>;
  const dzOptions = {
    show: true,
    realtime: true,
    start: zz.prcShock - 5,
    end: zz.prcShock + 5,
    labelFormatter: (index: number) => '',
  } as EChartOption.DataZoom;
  const xAxisOptions = {
    type: 'value',
    boundaryGap: false,
  };
  const yAxisTitles = data.map((d) => (d.scale === 0 ? null : `x${d.scale}`));
  const yAxis = builder.buildYAxis(areas, 'value', [], true, yAxisTitles); // FIXME откуда брать масштаб?
  return {
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        try {
          const fResult = ScaleManager.format(params[0].value[1], {
            key: 'format',
            scheme: { format: '.***' },
          });
          const ts = `<i class="las la-clock"></i> ${moment(params[0].value[0]).format('s.SSS')}`;
          const value = `${params[0].marker} ${fResult}`;
          return [ts, value].join(`<br/>`);
        } catch {
          return '';
        }
      },
    },
    toolbox: {
      show: true,
      feature: {
        dataZoom: {
          title: {
            zoom: '+/-',
            back: 'Назад',
          },
          yAxisIndex: 'none',
        },
        saveAsImage: {
          title: '.png',
          name: `${new Date().toISOString()}`,
        },
      },
    },
    title: buildTitles(areas, 300 / areas.length + 21, phase),
    xAxis: [
      {
        ...xAxisOptions,
        scale: true,
        gridIndex: 0,
        axisLabel: {
          show: false,
          formatter: (value: number) => moment(value).format('s.SSS'),
        },
      },
      {
        ...xAxisOptions,
        scale: true,
        gridIndex: 1,
        axisLabel: {
          show: true,
          formatter: (value: number) => moment(value).format('s.SSS'),
        },
      },
    ].slice(0, yAxis.length) as echarts.EChartOption.XAxis[],
    yAxis,
    grid: builder.buildGrid(areas, { height }, mrg, space),
    series: data.map((j, i) => buildSimpleSeries(j, i, false, phase, zz, 0)),
    dataZoom: [
      { ...dzOptions, xAxisIndex: [0, 1].slice(0, yAxis.length), zoomOnMouseWheel: false },
      { ...dzOptions, xAxisIndex: [0, 1].slice(0, yAxis.length), zoomOnMouseWheel: false, type: 'inside' },
    ],
  };
};

export const buildPopupChartOptionsAsync = async (
  data: any[],
  builder: Builder,
  phase?: number | null
): Promise<EChartOption | null> => {
  return await new Promise<EChartOption | null>((resolve, reject) => {
    try {
      const r = buildPopupChartOptions(data, builder, phase);
      resolve(r);
    } catch (e) {
      reject(e);
    }
  });
};
