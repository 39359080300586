import { ITabRef } from '@/store/dictionaries/dictionaries.types';

export const getAssetProps = async (data: any[]): Promise<{}> => {
  return await new Promise<{}>((resolve, reject) => {
    try {
      let r = {};
      data.forEach((i) => {
        r = { ...r, [i.asset_uid]: i.props };
      });
      resolve(r);
    } catch (e) {
      reject(e);
    }
  });
};

export const findAssetUid = async (tabs: any[], uid: string): Promise<string | null> => {
  return await new Promise<string | null>((resolve, reject) => {
    try {
      for (const tab of tabs) {
        for (const item of tab.items) {
          if (item.uid === uid) {
            resolve(tab.assetUid);
          }
        }
      }
      resolve(null);
    } catch (e) {
      reject(e);
    }
  });
};

const getEnumValues = (uid: string, tabRefs: ITabRef[]): string[] => {
  return (tabRefs ?? []).filter((v) => `${v.enumUid}` === `${uid}`).map((v) => v.notes);
};

export const resetModel = (val: any, tabRefs: ITabRef[]): Record<string, any> => {
  const values = val.flatMap((x: any) =>
    x.items.filter(
      (x: any) =>
        x.min !== x.max ||
        ((x.min === null || x.min === undefined) && (x.max === null || x.max === undefined))
    )
  );

  const numberValues = values.filter((x: any) => x.type === 'Float' || x.type === 'Integer');
  const enumValues = values.filter((x: any) => x.type === 'Enum');
  const dateTimeValues = values.filter((x: any) => x.type === 'DateTime');
  const objectTableValues = values.filter(
    (x: any) => x.type === 'NormTable' && Array.isArray(x.value)
  );

  return Object.fromEntries([
    ...dateTimeValues.map((i: any) => [
      i.key,
      { value: (new Date(i.value).getTime() - (new Date().getTimezoneOffset() * 60000)), type: i.type },
    ]),
    ...numberValues.map((x: any) => [
      x.key,
      {
        value: x.value,
        min: x.min,
        max: x.max,
        type: x.type,
      },
    ]),
    ...enumValues.map((x: any) => [
      x.key,
      {
        value: x.value,
        type: x.type,
        options: getEnumValues(x.uid, tabRefs),
      },
    ]),
    ...objectTableValues.map((x: any) => [
      x.key,
      {
        value: x.value.map((x: any) => ({ ...x })),
        type: 'ObjectTable',
      },
    ]),
  ]);
};

export const getEnumOrder = async (
  records: Record<string, any>,
  key: string,
  value: string
): Promise<number | null> => {
  return await new Promise<number | null>((resolve, reject) => {
    try {
      const rec = records[key];
      if (!rec || rec.type !== 'Enum' || !rec.options?.length) resolve(null);
      const { options } = rec;
      const order = options.indexOf(value);
      resolve(order);
    } catch (e) {
      reject(e);
    }
  });
};

export const getEnumValue = (
  uid: string,
  order: number,
  tabsRefs: ITabRef[]
): string | undefined => {
  return tabsRefs?.find((v) => {
    return `${v.enumUid}` === `${uid}` && `${v.order}` === `${order}`;
  })?.notes;
};

export const getTabItems = (tab: any, tabsRefs: ITabRef[]) => {
  const items = tab?.items?.map((item: any) => {
    if (item?.value)
      return {
        ...item,
        value:
          item?.value &&
          item?.type &&
          (item.type === 'Float' && item.value.search('\\.') !== -1
            ? Math.round(Number(item.value) * 1000) / 1000
            : item.type === 'Enum'
            ? getEnumValue(item.uid, item.order, tabsRefs)
            : item.type === 'NormTable'
            ? JSON.parse(item.value)
            : item.value),
        measure: item?.value && item?.type && (item.unit ? ` ${item.unit}` : ''),
      };
  });
  return items.filter((i: any) => i);
};

export const getDelta = (rec: any): number => {
  if (rec?.type === 'Float') return 0.01;
  if (rec?.type === 'Integer') return 1;
  return 0;
};
