import { ActionTree } from 'vuex';
import { DetailsState, IEquipDetails, StateAndPhase } from '@/store/details/types';
import { RootState } from '@/store/types';
import {  DETAILS_CONST, UPDATE_EQUIPMENT_PHASES } from '@/store/details/index';
import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { PhaseConverter } from '@/utils/PhaseConverter';

const rest = new RestApiService();
//const path = getApiUrl(TR_TYPE.HTTP, '/details?equipId=');
const path = getApiUrl(TR_TYPE.HTTP, '/details/');

export const actions: ActionTree<DetailsState, RootState> = {
  getDetails : async (context, payload) => {
    try {
      const url = `${path}${payload}`;
      const response = await rest.get(url);
      context.commit(DETAILS_CONST.DETAILS_LOADED, response?.data);
    } catch (e) {
      context.commit(DETAILS_CONST.DETAILS_ERROR)
    }
  },
  [UPDATE_EQUIPMENT_PHASES]: (context, payload: number[]) => {
    try {
      const { equipmentDetails } = context.state;
      const __details  = { ...equipmentDetails } as IEquipDetails;
      __details.statesAndPhases = payload.map(p => ({
        phaseLabel: PhaseConverter.getLabel(p),
        phaseValue:p,
        stateId:1
      } as StateAndPhase));
      context.commit(DETAILS_CONST.DETAILS_LOADED, __details);
    } catch (e) {
      context.commit(DETAILS_CONST.SELECTED_PHASE_ERROR, e);
    }
  },
};
