export interface RootState {
  version: string;
  busyIndicator: BusyIndicator;
}

export interface BusyIndicator {
  isBusy: boolean;
  defaultMessage?: string;
  message: string;
  data?: any;
}

export const BUSY_INDICATOR_MSG = {
  GETTING_SERVER_DATA: 'Загрузка данных...',
};
