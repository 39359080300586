

































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FiltersMap, FiltersState } from '../filter.types';
import FilterFooter from '../FilterFooter.vue';

@Component({
  components: {
    'filter-footer': FilterFooter,
  },
})
export default class NumberFilter extends Vue {
  @Prop() filtersMap!: FiltersMap;
  @Prop() filtersState!: FiltersState;
  @Prop() column!: any;

  get maxValue(): number {
    return this.filtersState?.filterMax[this.column?.dbProps] ?? 0;
  }
  get minValue(): number {
    return this.filtersState?.filterMin[this.column?.dbProps] ?? 0;
  }

  get isValid(): boolean {
    return this.maxValue >= this.minValue;
  }

  get canReset(): boolean {
    return this.isValid;
  }
}
