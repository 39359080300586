
export enum TimeFrameInterval {
  MS = 'ms',
  SECOND = 'second',
  MINUTE = 'minute',
  HOUR = 'hour',
}

export interface IZoomQuery {
  start?: string;
  end?: string;
  interval?: TimeFrameInterval;
}

export interface IZoomHistory{
  info?: string;
  query?: IZoomQuery;
}

export interface IHistoryDataQueryState {
  zoomHistory?: IZoomHistory[];
  zoomOutQuery?: IZoomHistory | null;
  homeQuery?: IZoomHistory | null;
  zoomInQuery?: IZoomHistory | null;
  error: Error | boolean;
}