import { GetterTree } from 'vuex';

import { RootState } from '@/store/types';

import { BREADCRUMBS } from './constants';
import { BreadcrumbsState } from './types';

export const getters: GetterTree<BreadcrumbsState, RootState> = {
  [BREADCRUMBS.GET_BREADCRUMBS](state): string[] {
    return state.breadcrumbs;
  },
};
