import { DEBUG_LOGS } from './tab-params.constants';
import { TabParamsItem, TabParamsResponse } from './tab-params.types';

export const debugLog = (...params: any[]) => {
  if (DEBUG_LOGS) {
    console.debug('STORE.TAB-PARAMS:', ...params);
  }
};

export const buildUrlQueries = async (
  path: string,
  equipId: string | number | undefined,
  phase: string | number | undefined
): Promise<string> => {
  return await new Promise((resolve, reject) => {
    try {
      let url = path;
      url += '?equipId=' + equipId;
      url += '&phase=' + phase;
      resolve(url);
    } catch (e) {
      reject(e);
    }
  });
};

export const transformData = (status: number, data: any): TabParamsResponse => {
  const result = {
    items: data as TabParamsItem[],
    status: status == 200,
  };
  return result;
};
