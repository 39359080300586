import { Module } from 'vuex';

import { RootState } from '@/store/types';

import { SensorsState } from './types';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';

const initialState: SensorsState = {
  sensors: null,
  equipments: null,
  references: null,
  sensorsStates: null,
  treeExpanded: new Set(JSON.parse(localStorage.getItem('sensorsTree.expanded') ?? '[]')),
};

export const sensors: Module<SensorsState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
