import { IBookmarkNode } from '@/store/bookmarks/types';

const namesDict = {
  equipment: 'Мониторинг',
  sensors: 'Источники данных',
  'update-journals': 'Журнал обновлений',
  admin: 'Пользователи и роли',
  'system-logs': 'Протокол работы системы',
  'new-folder': 'Новая папка',
};

export const generateFolderName = (url: string) => {
  const parts = url.split('/');
  return parts?.length < 2
    ? namesDict['new-folder']
    : (namesDict as any)[parts[1]] + '_' + namesDict['new-folder'];
};

export const generatePath = (tree: IBookmarkNode[]) => {
  const path = Math.max(...tree.map((i) => Number(i.mPath?.split('.')[0])));
  return path ? `${path}` : '1';
};

const findMaxPath = (nodes: IBookmarkNode[], parentPath?: string): string => {
  const p = parentPath?.split('.');
  let max = 1;
  for (const { mPath } of nodes) {
    const pathParts = mPath?.split('.');
    if (!Array.isArray(pathParts) || !pathParts.length) continue;
    const last = pathParts[p?.length ?? 0];
    if (Number(last) >= max) {
      max = Number(last) + 1;
    }
  }
  return `${max}`;
};

export const generateChildPath = ({ children, mPath }: IBookmarkNode) =>
  !children?.length ? `${mPath ? mPath + '.1' : '1'}` : findMaxPath(children, mPath);
