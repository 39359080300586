


















import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

import { QueryParamsService } from '@/utils';
import { RestApiService } from '@/services';
import { buildProp } from '@/components/items-tab/panes/algorithms/algorithm.convertors';
import { TableCol } from '@/components/basics/table/basic-table.types';

import { FiltersState, FiltersMap, AllFilter } from './filter.types';

type QueryType = {
  filter?: string;
  offset?: string;
};

@Component({})
export default class FilterFooter extends Vue {
  rest = new RestApiService();

  queryParams!: QueryParamsService<QueryType>;
  buildProp = buildProp;

  @Prop() filtersMap!: FiltersMap;
  @Prop() filtersState!: FiltersState;
  @Prop() column!: TableCol;
  @Prop() isValid?: boolean;
  @Prop() canReset?: boolean;

  acceptFilter(filter: AllFilter) {
    if (filter.type === 'Number') {
      filter.min = this.filtersState.filterMin[filter.name];
      filter.max = this.filtersState.filterMax[filter.name];
      filter.active = true;
    } else if (filter.type === 'Enum') {
      filter.options = this.filtersState.filterOptions.map((x) => ({ ...x }));
      filter.active = filter.options.some((x) => x.active);
    } else if (filter.type === 'EnumQuery') {
      filter.options = this.filtersState.filterQueryOptions.map((x) => ({ ...x }));
      filter.active = filter.options.some((x) => x.active);
    } else if (filter.type === 'Guid') {
      filter.options = this.filtersState.filterGuidOptions.map((x) => ({ ...x }));
      filter.active = filter.options.some((x) => x.active);
    } else if (filter.type === 'NumberEnum') {
      filter.options = this.filtersState.numberFilterOptions.map((x) => ({ ...x }));
      filter.active = filter.options.some((x) => x.active);
    } else if (filter.type === 'StringEnum') {
      filter.options = this.filtersState.stringFilterOptions.map((x) => ({ ...x }));
      filter.active = filter.options.some((x) => x.active);
    }

    this.cancelFilter(filter);
    this.updateFilterQuery();
  }

  cancelFilter(filter: AllFilter) {
    filter.visible = false;
  }

  resetFilter(filter: AllFilter) {
    if (filter.type === 'Number') {
      filter.min = null;
      filter.max = null;
    } else if (
      filter.type === 'Enum' ||
      filter.type === 'NumberEnum' ||
      filter.type === 'StringEnum' ||
      filter.type === 'EnumQuery' ||
      filter.type === 'Guid'
    ) {
      filter.options.forEach((x: any) => {
        x.active = false;
      });
    }

    filter.active = false;
    this.cancelFilter(filter);
    this.updateFilterQuery();
  }

  updateFilterQuery() {
    const filterList = Object.entries(this.filtersMap)
      .filter(([key, value]) => value && value.active && [key, value])
      .map(
        ([key, value]) =>
          value &&
          (value.type === 'Number'
            ? {
                type: 'Number' as 'Number',
                name: key,
                min: value.min !== null && value.scale ? value.min / value.scale : value.min,
                max: value.max !== null && value.scale ? value.max / value.scale : value.max,
              }
            : value.type === 'EnumQuery'
            ? {
                type: 'EnumQuery' as 'EnumQuery',
                name: key,
                options: value.options.filter((x) => x.active).map((x) => x.value),
              }
            : value.type === 'Guid'
            ? {
                type: 'Guid' as 'Guid',
                name: key,
                options: value.options.filter((x) => x.active).map((x) => x.value),
              }
            : value.type === 'Enum'
            ? {
                type: 'Enum' as 'Enum',
                name: key,
                options: value.options.filter((x) => x.active).map((x) => x.value),
              }
            : value.type === 'NumberEnum'
            ? {
                type: 'NumberEnum' as 'NumberEnum',
                name: key,
                options: value.options.filter((x) => x.active).map((x) => x.value),
              }
            : value.type === 'StringEnum'
            ? {
                type: 'StringEnum' as 'StringEnum',
                name: key,
                options: value.options.filter((x) => x.active).map((x) => x.value),
              }
            : undefined)
      );

    if (filterList.length > 0) {
      this.queryParams.update({
        offset: '0',
        filter: encodeURIComponent(JSON.stringify(filterList)),
      });
    } else {
      this.queryParams.update({ offset: '0', filter: undefined });
    }
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      offset: this.$route.query.offset,
      filter: this.$route.query.filter,
    }));
  }
}
