





















import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

import { QueryParamsService } from '@/utils';
import { TableCol } from '@/components/basics/table/basic-table.types';

type QueryType = {
  sort?: string;
};

@Component({})
export default class TableSorter extends Vue {
  queryParams!: QueryParamsService<QueryType>;

  @Prop() column!: TableCol;

  get sorting() {
    const { sort } = this.queryParams.get();
    const [field, direction] = (sort || '').split(':');

    return sort ? { field, direction } : {};
  }

  onChangeSort(column: string) {
    const { field, direction } = this.sorting;

    this.queryParams.update(
      direction === 'ASC' && field === column
        ? { sort: undefined }
        : direction === 'DESC' && field === column
        ? { sort: `${column}:ASC` }
        : { sort: `${column}:DESC` }
    );
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      sort: this.$route.query.sort,
    }));
  }
}
