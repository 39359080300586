




























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import DynamicChartLegend from '@/components/basics/charts/dynamic-history-chart/dynamic.chart.legend.vue';
import DateTimeSelector from '@/components/basics/selectors/DateTimeSelector.vue';
import { Action, Getter } from 'vuex-class';
import { DETAILS_CONST } from '@/store/details';
import { IEquipDetails } from '@/store/details/types';
import { HIST_DATA_CONST } from '@/store/history-data/history-data.const';
import { timeFrames, TimeFrameType, TimeInterval } from '@/store/data/data.types';
import { Dictionary } from 'vue-router/types/router';
import { IChartPropsQuery } from '@/store/history-data/history-data.types';
import HistoryChart from '@/components/basics/charts/history-chart/HistoryChart.vue';
import EChartSample from '@/components/basics/charts/history-chart/e-chart-sample.vue';
import { PRELOADER_STATE } from '@/store/abstract/preloader';
import { IPreloader } from '@/store/abstract/preloader/types';
import {
  HISTORY_CHARTS,
  HISTORY_CHARTS_BUILD_SCHEME,
  RESET_EXTRA_DATA,
} from '@/store/history-charts/constants';
import { IHistoryChartsQuery } from '@/store/history-charts/types';
import LiveUpdate from '@/components/basics/charts/dynamic-history-chart/live-update/LiveUpdate.vue';
import { CHART_PROPS } from '@/store/abstract/props-scheme/constants';
import { IChartProperty } from '@/store/abstract/props-scheme/types';

const {
  HISTORY_DATA,
  REFRESH_HISTORY_DATA,
  UPDATE_PROPERTIES_SCHEME,
  UPD_COLORS,
} = HIST_DATA_CONST;
export type QueryType = {
  phase: number;
  start: string;
  end: string;
  interval: TimeInterval;
};
@Component({
  components: {
    'dynamic-legend': DynamicChartLegend,
    'datetime-selector': DateTimeSelector,
    'history-chart': HistoryChart,
    'live-update': LiveUpdate,
    sample: EChartSample,
  },
})
export default class DynamicChartView extends Vue {
  refreshState = false;
  refreshStateHandle(state: boolean) {
    this.refreshState = state;
  }

  selectedInterval?: TimeInterval;
  onInterval(interval: TimeInterval) {
    this.selectedInterval = interval;
  }

  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  equipmentDetails?: IEquipDetails;
  $route: any;

  get selectedPhase() {
    const { phase } = this.$route.query;
    return this.equipmentDetails?.statesAndPhases?.find((x) => x.phaseLabel === phase);
  }

  get phase(): number | null {
    return !this.selectedPhase?.phaseValue || Number.isNaN(this.selectedPhase.phaseValue)
      ? null
      : this.selectedPhase?.phaseValue;
  }

  get interval() {
    const { interval } = this.$route.query as Dictionary<string | (string | null)>;
    return timeFrames.find((x: TimeInterval) => x.value === interval);
  }

  @Action(RESET_EXTRA_DATA, { namespace: HISTORY_CHARTS })
  resetData!: () => void;
  @Action(HISTORY_CHARTS_BUILD_SCHEME, { namespace: HISTORY_CHARTS })
  buildScheme!: (query: IHistoryChartsQuery) => void;

  @Getter(CHART_PROPS, { namespace: HISTORY_CHARTS })
  chartProps?: IChartProperty[];

  get query(): QueryType | null {
    // reset data maxMinAvg
    if (!this.refreshState) this.resetData();
    const { start, end, phase } = this.$route.query as Dictionary<string | (string | null)>;
    const p = this.equipmentDetails?.statesAndPhases?.find((i) => i.phaseLabel === phase);
    if (start && end && p && this.interval) {
      return {
        start,
        end,
        phase: p.phaseValue,
        interval: this.interval,
      };
    } else {
      return null;
    }
  }

  @Getter(PRELOADER_STATE, { namespace: HISTORY_CHARTS })
  preloader?: IPreloader;

  get preloaderMessage(): string {
    return this.preloader?.message ?? '';
  }

  get preloaderIsBusy(): boolean {
    return this.preloader?.isBusy ?? false;
  }

  @Action(REFRESH_HISTORY_DATA, { namespace: HISTORY_DATA })
  refreshHistoryData!: (payload: QueryType) => void;

  @Action(UPDATE_PROPERTIES_SCHEME, { namespace: HISTORY_DATA })
  updatePropertiesScheme!: (payload: IChartPropsQuery) => void;

  @Action(UPD_COLORS, { namespace: HISTORY_DATA })
  updColors!: (payload: number) => void;

  get equipId(): number {
    const { id } = this.$route.params;
    return id as number;
  }

  get assetUid(): string | null {
    if (!this.equipmentDetails) return null;
    const { assetUids } = this.equipmentDetails as any;
    if (!assetUids) return null;
    const index = this.selectedPhase?.phaseValue;
    if (!index) return null;
    return assetUids[index];
  }
  get algorithm(): string | null {
    if (!Array.isArray(this.chartProps) || this.chartProps?.length <= 0) return null;
    return this.chartProps[0].algorithm;
  }

  mounted() {
    const phase = this.selectedPhase?.phaseValue;
    if (phase && !isNaN(phase)) {
      this.updColors(phase as number);
    }
    this.updatePropertiesScheme({ phase: Number(phase), equipId: Number(this.equipId) });
  }
}
