import { MutationTree } from 'vuex';

import { SystemLogsState } from './types';
import { SYSTEM_LOGS_STORE_CONST } from './constants';

export const mutations: MutationTree<SystemLogsState> = {
  [SYSTEM_LOGS_STORE_CONST.TREE_ITEMS_EXPAND](
    state: SystemLogsState,
    payload: Set<number | string>
  ) {
    state.treeExpanded = new Set([...state.treeExpanded, ...payload]);
    localStorage.setItem('systemLogsTree.expanded', JSON.stringify([...state.treeExpanded]));
  },
  [SYSTEM_LOGS_STORE_CONST.TREE_ITEMS_COLLAPSE](
    state: SystemLogsState,
    payload: Set<number | string>
  ) {
    state.treeExpanded = new Set([...state.treeExpanded].filter((x) => !payload.has(x)));
    localStorage.setItem('systemLogsTree.expanded', JSON.stringify([...state.treeExpanded]));
  },
  [SYSTEM_LOGS_STORE_CONST.TREE_ITEMS_COLLAPSE_ALL](state: SystemLogsState) {
    state.treeExpanded = new Set();
    localStorage.setItem('systemLogsTree.expanded', JSON.stringify([...state.treeExpanded]));
  },
};
