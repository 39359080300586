import { MutationTree } from 'vuex';

import { BreadcrumbsState } from './types';
import { BREADCRUMBS } from './constants';

export const mutations: MutationTree<BreadcrumbsState> = {
  [BREADCRUMBS.SET_BREADCRUMBS](state, payload: string[]) {
    state.breadcrumbs = payload;
  },
};
