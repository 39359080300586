export const getOffset = (selected: number, size: number): number => {
  return Math.max(0, selected - 1) * size;
};
export const updateRow = async (page: any[], row: any): Promise<any[] | null> => {
  return await new Promise<any>((resolve, reject) => {
    try {
      const find = page.find((i) => i.asset_uuid === row.asset_uuid);
      if (!find) resolve(null);
      const index = page.indexOf(find);
      for (const [key, value] of Object.entries(row)) {
        find[key] = value;
      }
      page[index] = find;
      resolve(page);
    } catch (e) {
      reject(e);
    }
  });
};
