export const popupChartMockData = [
  {
    name: 'Напряжение 1',
    data: [
      ['2021-02-26 16:56:07.0', 429938.1326361553],
      ['2021-02-26 16:56:07.1', 429951.58885121933],
      ['2021-02-26 16:56:07.2', 430149.54593519185],
      ['2021-02-26 16:56:07.3', 430010.67998030054],
      ['2021-02-26 16:56:07.4', 430075.19607994106],
      ['2021-02-26 16:56:07.5', 430048.9203782615],
      ['2021-02-26 16:56:07.6', 430575.0253717161],
      ['2021-02-26 16:56:07.7', 430430.89479055506],
      ['2021-02-26 16:56:07.8', 430033.27479388064],
      ['2021-02-26 16:56:07.9', 430107.2524579695],
    ],
  },
  {
    name: 'Напряжение 2',
    data: [
      ['2021-02-26 16:56:07.0', 938.1326361553],
      ['2021-02-26 16:56:07.1', 951.58885121933],
      ['2021-02-26 16:56:07.2', 149.54593519185],
      ['2021-02-26 16:56:07.3', 10.67998030054],
      ['2021-02-26 16:56:07.4', 75.19607994106],
      ['2021-02-26 16:56:07.5', 48.9203782615],
      ['2021-02-26 16:56:07.6', 575.0253717161],
      ['2021-02-26 16:56:07.7', 430.89479055506],
      ['2021-02-26 16:56:07.8', 33.27479388064],
      ['2021-02-26 16:56:07.9', 107.2524579695],
    ],
  },
  {
    name: 'Напряжение 3',
    data: [
      ['2021-02-26 16:56:07.0', 429938.1326361553],
      ['2021-02-26 16:56:07.1', 429951.58885121933],
      ['2021-02-26 16:56:07.2', 430149.54593519185],
      ['2021-02-26 16:56:07.3', 430010.67998030054],
      ['2021-02-26 16:56:07.4', 430075.19607994106],
      ['2021-02-26 16:56:07.5', 430048.9203782615],
      ['2021-02-26 16:56:07.6', 430575.0253717161],
      ['2021-02-26 16:56:07.7', 430430.89479055506],
      ['2021-02-26 16:56:07.8', 430033.27479388064],
      ['2021-02-26 16:56:07.9', 430107.2524579695],
    ],
  },
];
