






































import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { ALGORITHM_CONST } from '@/store/algorithms';

import { Action, Getter } from 'vuex-class';
import { ALGORITHM_DATA_CONST } from '@/store/abstract/algorithm-data/constants';
import { AlgorithmScheme, IAlgorithmQuery } from '@/store/algorithms/algorithms.types';
import { DETAILS_CONST } from '@/store/details';
import { IEquipDetails } from '@/store/details/types';
import {
  buildDBQuery,
  transformData,
} from '@/components/items-tab/panes/algorithms/algorithms.extensions';
import AutoQuerySwitch from '@/components/basics/AutoQuerySwitch.vue';
import DateTimeSelector from '@/components/basics/selectors/DateTimeSelector.vue';
import BasicTable from '@/components/basics/table/BasicTable.vue';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';
import RamButton from '@/components/ram-info/RamButton.vue';
import { GET_QUERY_PARAMS, POPUP_CHART_CONST } from '@/store/popup-charts/popup.charts.const';
import { EventAggregator } from '@/events';
import { EVENTS_RAM_DATA_STATE } from '@/components/ram-info/constants';
import { IReportTab } from '@/store/report-data/types';
import { GET_DATETIME_RANGE } from '@/store/abstract/range-getter/constants';
import { HISTORY_CHARTS } from '@/store/history-charts/constants';
import { IDatetimeRangeQuery } from '@/store/abstract/range-getter/types';
import { buildDatetimeRangeQuery } from '@/components/items-tab/panes/algorithms/algorithms.queries';

const { ALGORITHMS } = ALGORITHM_CONST;
const { DICTIONARIES, TAB_REFS } = DICTIONARIES_CONST;
const { POPUP_CHART } = POPUP_CHART_CONST;
@Component({
  components: {
    'basic-table': BasicTable,
    'datetime-selector': DateTimeSelector,
    'auto-query-switch': AutoQuerySwitch,
    'ram-button': RamButton,
  },
})
export default class AlgorithmView extends Vue {
  @Prop() scheme?: AlgorithmScheme;
  @Prop() stateTab?: IReportTab;
  @Prop() phase?: number;

  // state report
  @Prop() isState!: boolean;
  @Prop() equipment?: any[];
  // hyb
  @Prop() isTopology?: boolean | null;

  @Getter(TAB_REFS, { namespace: DICTIONARIES })
  tabRefs?: ITabRef[];

  @Getter(ALGORITHM_DATA_CONST.DATA, { namespace: ALGORITHMS })
  $pageData?: any;

  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  $equipmentDetails!: IEquipDetails;

  @Action(ALGORITHM_DATA_CONST.LOAD_DATA, { namespace: ALGORITHMS })
  $getData!: (payload: IAlgorithmQuery) => void;

  //todo decompose
  @Action(GET_DATETIME_RANGE, { namespace: HISTORY_CHARTS })
  getDatetimeRange!: (query: IDatetimeRangeQuery) => void;

  @Action(ALGORITHM_DATA_CONST.RESET_DATA, { namespace: ALGORITHM_CONST.ALGORITHMS })
  resetLastAlgoData!: () => void;

  @Watch('query', { immediate: true }) onQueryChange(
    val: IAlgorithmQuery | null,
    old: IAlgorithmQuery | null
  ) {
    if (val === old || !val) return;
    this.resetLastAlgoData();
    if (this.stateTab && val?.start && val?.end) {
      this.$getData({
        ...val,
        uuids: this.stateTab.uuids?.join(';'),
        isTopology: this.isTopology ?? undefined,
        isState: this.isState ?? undefined,
      });
      return;
    }

    if (val.start && !this.isTopology) {
      const { limit, offset } = val;
      if (!limit || !offset) return;
      this.$getData(val);
    } else if (!this.isTopology) {
      if (!this.stateTab) return;
      const { uuids } = this.stateTab;
      const q = { ...val, uuids: uuids?.join(';'), isState: this.isState };
      this.$getData(q);
    }
  }
  get query(): any {
    if (!this.scheme) return null;
    return buildDBQuery(this.$route, this.scheme);
  }

  // range (last data in db)
  get algorithm(): string {
    return this.scheme?.dbTableName ?? '';
  }
  get assetUid(): string {
    return this.scheme?.assetUid ?? '';
  }

  get references() {
    return this.tabRefs ?? [];
  }

  get columns() {
    const filtered = this.scheme?.columns
      ?.filter((c: any) => c.order > 0)
      .sort((a: any, b: any) => a.order - b.order);

    return filtered ?? [];
  }

  get socketSubject() {
    return this.scheme?.dbTableName ?? '';
  }

  get socketKeys(): string[] {
    return this.scheme?.moduleUid ? [this.scheme?.moduleUid] : [];
  }

  get total() {
    return this.$pageData?.count || 0;
  }

  get page() {
    if (this.$pageData?.count <= 0 || !this.scheme || !this.$pageData?.selectedPage?.length)
      return [];
    const p = transformData(
      this.$pageData.selectedPage,
      this.scheme,
      this.references,
      undefined,
      this.equipment,
      true
    );
    return p ?? [];
  }

  reQuery() {
    this.query && this.$getData({ ...this.query, noSpinner: true });
  }

  @Action(GET_QUERY_PARAMS, { namespace: POPUP_CHART })
  getPopupQueryParams!: (query: any) => void;

  // preparations for feature queries to get popup chart data
  getPopupChartQueryParams() {
    const { id } = this.$route?.params;
    const { phase, algorithm } = this.query;
    if (!phase || !algorithm) return;
    this.getPopupQueryParams({ phase: Number(phase), algorithm, equipId: Number(id) });
  }
  isRamShown = false;

  onPresetChanged({ interval, count }: any) {
    this.getValidDatetimeRange({ ...this.query, interval, count });
  }

  getValidDatetimeRange(query: any) {
    if (this.isTopology && this.stateTab) {
      // hyb case
      const rangeQuery = buildDatetimeRangeQuery({
        ...query,
        isTopology: true,
        uuids: this.stateTab.uuids,
      });
      if (!rangeQuery) return;
      this.getDatetimeRange(rangeQuery);
    }
  }

  mounted() {
    this.getValidDatetimeRange(this.query);
    // unique fore each algorithm-data
    if (!this.isState) this.getPopupChartQueryParams();
    EventAggregator.register(EVENTS_RAM_DATA_STATE)?.subscribe((m) => {
      this.isRamShown = m as boolean;
    });
  }
}
