import { IDataConverter } from '@/components/basics/table/converters/types';

export class IntegerConverter implements IDataConverter {
  convert(value: any, opts: any): any {
    if (value > opts.Max) return '';
    if (value <= opts.Min) return '0';
    const scale = opts?.Scale;
    if (scale) value = value * scale;
    return `${value}`;
  }
}
