import { resetTimeOffset } from '@/utils/datetime/DateTimeCorrector';
import moment from 'moment';
import { MOMENT_QUERY_FORMAT } from '@/utils/datetime/DateTimeRangePresets';

export const goUTC = (datetime: string) => {
  const d = resetTimeOffset(new Date(datetime));
  return moment(d).format(MOMENT_QUERY_FORMAT.dateToSec);
};

const nonEmptyValuesObject = (data: object): object => {
  const obj = {};
  for (const [key, value] of Object.entries(data)) {
    if (value === '' || value === null || value === undefined) {
      continue;
    }
    Object.assign(obj, { [key]: value });
  }
  return obj;
};

export class QueryBuilder {
  public static build(data: any): string | null {
    if (!data) {
      return null;
    }
    const __data = nonEmptyValuesObject(data);
    let query = '';
    const keys = Object.keys(__data);
    const length = keys.length;
    for (const [key, value] of Object.entries(__data)) {
      const mValue = key === 'start' || key === 'end' ? goUTC(`${value}`) : value;
      const i = keys.indexOf(key);
      query += `${key}=${mValue}`;
      query += i < length - 1 ? '&' : '';
    }
    return query;
  }
}
