import _ from 'lodash';
import { IBookmark, IBookmarkNode } from '@/store/bookmarks/types';

const findRoot = (tree: IBookmarkNode[], bookmark: IBookmark): IBookmarkNode | null => {
  if (!bookmark?.mPath) return null;
  const fullPath = bookmark.mPath.split('.');
  if (!Array.isArray(fullPath) || !fullPath?.length) return null;
  let currentPath = '';
  let root: IBookmarkNode | null = null;
  for (const path of fullPath) {
    currentPath += path;
    root = tree.find((i) => i.mPath === currentPath) as IBookmarkNode;
    if (!root) continue;
    break;
  }
  return root;
};

const moveIntoFolder = (rootNode: IBookmarkNode, bookmark: IBookmark) => {
  //
};

const findNode = (node: IBookmarkNode, uuid: string): IBookmarkNode | undefined => {
  if (node.uuid === uuid) return node;
  return node?.children?.find((n) => findNode(n, uuid));
};

const updateNode = (node: IBookmarkNode, upd: IBookmarkNode): IBookmarkNode => {
  if (node?.children?.length) {
    node.children = node.children.map((n) => updateNode(n, upd));
  }
  return node.uuid === upd.uuid ? { ...node, ...upd } : node;
};

export const deleteNode = (node: IBookmarkNode, { uuid }: IBookmarkNode): IBookmarkNode | null => {
  if (node?.children?.length) {
    node.children = node.children.filter((n) => deleteNode(n, { uuid } as IBookmarkNode));
  }
  return node.uuid === uuid ? null : node;
};

export const updateTree = (node: IBookmarkNode, tree: IBookmarkNode[]): IBookmarkNode[] =>
  tree.map((n) => updateNode(n, node));

const findParentNode = (
  node: IBookmarkNode,
  bookmark: IBookmark
): IBookmarkNode | undefined | null =>
  node.mPath && bookmark.mPath?.includes(node.mPath)
    ? node?.children?.find((c) => findParentNode(c, bookmark)) ?? node
    : null;

export const addTreeNode = (tree: IBookmarkNode[], bookmark: IBookmark) => {
  const tmpTree = [...tree];
  tmpTree.unshift(bookmark);
  return tmpTree;
};

export const buildBookMarksTree = (bookmarks: IBookmark[]): IBookmarkNode[] => {
  const tree: IBookmarkNode[] = [];
  const sortedBookmarks = _.orderBy(bookmarks, ['mPath']);
  for (const bookmark of sortedBookmarks) {
    const path = bookmark?.mPath?.split('.');
    if (!Array.isArray(path) || !path?.length) continue;
    const rootNode = findRoot(tree, bookmark);
    if (!rootNode) {
      tree.push({
        ...bookmark,
      });
    }
  }
  return tree;
};
