

export enum ROOT_STORE_CONST {
  START_BUSY_INDICATOR = 'startBusyIndicator',
  STOP_BUSY_INDICATOR = 'stopBusyIndicator',
  BUSY_INDICATOR = 'busyIndicator',
  INDICATOR_START = 'indicatorStart',
  INDICATOR_STOP = 'indicatorStop',

  ROOT = 'store'
}
