import { MOMENT_QUERY_FORMAT, TIME_RANGE_PRESETS } from '@/utils/datetime/DateTimeRangePresets';
import { buildRange, resetTimeOffset, updateTimeOffset } from '@/utils/datetime/DateTimeCorrector';
import { TimeFrameType, TimeInterval } from '@/store/data/data.types';
import moment from 'moment';
import { IDatetimeRange } from '@/store/abstract/range-getter/types';

const hourInSec = 60 * 60;
const dayInSec = hourInSec * 24;
const weekInSec = dayInSec * 7;
// ~ 10 records per second
const recordsPerSecond = 10;

export const getRangeFilter = (val: string): [Date, Date] => {
  const { hour, day, week } = TIME_RANGE_PRESETS;
  let cf = 0;
  switch (val) {
    case hour.label:
      cf = 60 * 60;
      break;
    case day.label:
      cf = 60 * 60 * 24;
      break;
    case week.label:
      cf = 60 * 60 * 24 * 7;
      break;
    default:
      return [new Date(), new Date()];
  }
  const d = new Date();
  const range = buildRange(new Date(d.setHours(d.getHours() + 1)), -cf);
  return [range[1], range[0]];
};

export type DurationPresetType = 'hour' | 'day' | 'week';
export const durationPreset = {
  hour: 'Час',
  day: 'День',
  week: 'Неделя',
};

export const getMsCount = (tf: TimeFrameType): number => {
  switch (tf) {
    case 'ms':
      return recordsPerSecond * 60;
    case 'second':
      return recordsPerSecond * 60 * 10; // 10 minute
    case 'minute':
      return recordsPerSecond * hourInSec; // 1 hour
    case 'hour':
      return recordsPerSecond * dayInSec; // 1 day (24 hours)
    case 'day':
      return recordsPerSecond * dayInSec * 7; // 1 week (7 days)
    default:
      return 100;
  }
};

export const getSecondsCount = (tf: TimeFrameType): number => {
  return getMsCount(tf) / recordsPerSecond;
};

export const durationKeyFinder = (value: string): DurationPresetType | null => {
  const find = Object.entries(durationPreset).find(([k, v]) => v === value);
  if (!Array.isArray(find) || find.length <= 0) return null;
  return find[0] as DurationPresetType;
};

export const durationToTimeFrame = (value: string): TimeFrameType | null => {
  const key = durationKeyFinder(value);
  if (!key) return null;
  switch (key) {
    case 'hour':
      return 'minute';
    case 'day':
      return 'hour';
    case 'week':
      return 'day';
    default:
      return null;
  }
};

export const durationToTimeFrameAsync = async (value: string): Promise<TimeFrameType | null> => {
  return await new Promise((resolve, reject) => {
    try {
      resolve(durationToTimeFrame(value));
    } catch (e) {
      reject(e);
    }
  });
};

const limit = 1440;
const getMsFromRange = (range: [Date | null, Date | null] | null) => {
  if (!Array.isArray(range) || range.length < 2 || !range[0] || !range[1]) return null;
  return Math.abs(range[0].getTime() - range[1].getTime());
};
export const protectFromFool = (range: [Date | null, Date | null] | null): TimeFrameType | null => {
  const count = getMsFromRange(range);
  if (!count) return null;
  let x = (count / 1000) * recordsPerSecond;
  if (x <= limit) return 'ms';
  x /= recordsPerSecond;
  if (x <= limit) return 'second';
  x /= 60;
  if (x <= limit) return 'minute';
  return 'hour';
};

export const getMaxEndDate = (start: Date, end: Date, tf: TimeFrameType):Date => {
  const end2 = new Date(start);
  switch (tf) {
    case 'ms':
      end2.setMilliseconds(end2.getMilliseconds() + limit*100);
      break;
    case 'second':
      end2.setSeconds(end2.getSeconds() + limit);
      break;
    case 'minute':
      end2.setMinutes(end2.getMinutes() + limit);
      break;
    case 'hour':
      end2.setHours(end2.getHours() + limit);
      break;
  }
  const n = resetTimeOffset(new Date())
  if(end2 > n) return n
  return end2
}

export const getSecFromPreset = (value: string): number | null => {
  const key = durationKeyFinder(value);
  if (!key) return null;
  switch (key) {
    case 'hour':
      return getSecondsCount('minute');
    case 'day':
      return getSecondsCount('hour');
    case 'week':
      return getSecondsCount('hour') * 7;
    default:
      return null;
  }
};

export const getSecFromPresetAsync = async (value: string): Promise<number | null> => {
  return await new Promise<number | null>((resolve, reject) => {
    try {
      resolve(getSecFromPreset(value));
    } catch (e) {
      reject(e);
    }
  });
};

const getPercent = (value: number, prc: number) => {
  return (value / 100) * prc;
};

const valueInRange = (x: number, value: number, prc = 20) => {
  return x <= value + getPercent(value, prc) && x >= value - getPercent(value, prc);
};

export const setDefaultRange = (
  preset: DurationPresetType = 'hour',
  end = new Date()
): IDatetimeRange => {
  let seconds: number;
  switch (preset) {
    default:
    case 'hour':
      seconds = 60 * 60;
      break;
    case 'day':
      seconds = 60 * 60 * 24;
      break;
    case 'week':
      seconds = 60 * 60 * 24 * 7;
      break;
  }
  const range = buildRange(end, seconds*(-1));
  return {
    start: moment(resetTimeOffset(range[1])).format(MOMENT_QUERY_FORMAT.dateToSec),
    end: moment(resetTimeOffset(range[0])).format(MOMENT_QUERY_FORMAT.dateToSec),
  };
};

// this try to fit preset on datetime range
export const tryFitOnDurationPreset = ({ start, end }: IDatetimeRange) => {
  if (!start || !end) return '';
  const dif = Math.abs(new Date(start).getTime() - new Date(end).getTime());
  const sec = moment.duration(dif).asSeconds();
  if (valueInRange(sec / weekInSec, 1)) {
    return durationPreset['week'];
  }
  if (valueInRange(sec / dayInSec, 1)) {
    return durationPreset['day'];
  }
  if (valueInRange(sec / hourInSec, 1)) {
    return durationPreset['hour'];
  }
  return '';
};
