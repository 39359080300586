






















import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { Getter } from 'vuex-class';

import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { AlgorithmScheme } from '@/store/algorithms/algorithms.types';
import PropertiesGroup from '@/components/basics/PropertiesGroup.vue';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';
import { QueryParamsService } from '@/utils';
import { addSubject, removeSubject } from '@/socket';
import { DETAILS_CONST } from '@/store/details';
import { IEquipDetails } from '@/store/details/types';
import RamButton from '@/components/ram-info/RamButton.vue';
import { tableFilters } from '@/components/basics/table/filters';
const { DICTIONARIES, TAB_REFS } = DICTIONARIES_CONST;
type QueryType = { tab?: string };
const rest = new RestApiService();

@Component({
  filters: {
    ...tableFilters,
  },
  data: function() {
    return { socketData: {}, blocks: [] };
  },
  components: {
    'props-group': PropertiesGroup,
    'ram-button': RamButton,
  },
})
export default class EquipBaseState extends Vue {
  queryParams!: QueryParamsService<QueryType>;
  @Prop() scheme?: AlgorithmScheme;
  @Prop() phase?: number;
  @Prop() currentEquipment: any;
  @Prop() sampleKeys?: string[];
  @Prop() subject!: string;

  @Getter(TAB_REFS, { namespace: DICTIONARIES })
  tabRefs?: ITabRef[];
  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  $equipmentDetails!: IEquipDetails;
  private unsubscribe() {
    this.$socket.client.off(this.subject);
    removeSubject(this.subject);
  }
  get keys(): string[] | null {
    return this.scheme?.moduleUid ? [this.scheme.moduleUid] : null;
  }
  get schemeAndPhase() {
    return { scheme: this.scheme, phase: this.phase };
  }
  @Watch('schemeAndPhase', { immediate: true }) async onChangeSchemeAndPhase(x: any) {
    if (x && x.scheme && x.phase) {
      const result = await rest.getParse(
        getApiUrl(
          TR_TYPE.HTTP,
          `/click-house/last-value?algorithm=${x.scheme.dbTableName}&calculator_uuid=${x.scheme.moduleUid}`
        )
      );
      if (!result?.length) return;
      this.$set(this.$data, 'socketData', { ...result[0] });
    }
  }
  private subscribe() {
    if (this.keys) {
      addSubject({
        theme: this.subject,
        keys: [...this.keys],
      });
    }
    this.$socket.client.on(this.subject, (x: any) => {
      if (x.phase === this.phase) {
        this.$set(this.$data, 'socketData', { ...x });
      }
    });
  }
  //
  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      tab: this.$route.query.tab,
    }));
  }
  mounted() {
    this.sampleKeys?.forEach((key) => {
      const column = this.scheme?.columns.find((c) => c.key === key);
      if (column) {
        this.$set(this.$data, 'blocks', [...this.$data.blocks, { ...column }]);
      }
    });
    this.subscribe();
  }
  beforeDestroy() {
    this.unsubscribe();
  }
}
