






import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { CronManager, CronPresets } from '@/services/cron/CronManager';
import { INTERVAL_CONST } from '@/store/data/data.types';

@Component
export default class LiveUpdate extends Vue {
  cron = new CronManager();
  @Prop() on?: string;
  @Prop() off?: string;
  @Prop() interval!: string;
  active?: boolean = false;
  @Watch('active') onActiveChanged(val: boolean, old: boolean) {
    if (val === old) return;
    this.tryStartTimer();
    this.$emit('active', val);
  }
  timer: any;
  tryStartTimer() {
    try {
      this.cron.removeSchedule();
      if (this.active) {
        const schedule = this.defineSchedule(this.interval);
        console.log(`New cron schedule: '${schedule}' created.`)
        this.cron.createSchedule(schedule).subscribe((datetime) => {
          this.$emit('tick', datetime);
        });
      } else {
        console.log(`Cron task stopped.`)
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  defineSchedule(interval: string): string {
   // console.log('defineSchedule::',interval);
    switch (interval) {
      case INTERVAL_CONST.ms.value:
        return CronPresets.EveryTwoSeconds;
      case INTERVAL_CONST.second.value:
        return CronPresets.EveryFiveSeconds;
      default:
      case INTERVAL_CONST.minute.value:
        return CronPresets.EveryMinute;
      case INTERVAL_CONST.hour.value:
        return CronPresets.EveryHour;
    }
  }

  mounted() {
    // better keep it off
    this.active = false;
  }
  beforeDestroy() {
    // better keep it off
    this.active = false;
  }
}
