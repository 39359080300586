import { Module } from 'vuex';
import { IConfigUpdaterState } from '@/store/abstract/config-updater/types';
import { RootState } from '@/store/types';
import {
  CHANGED_ITEMS,
  PICKUP_CHANGED_VALUES,
  SAVE_CHANGED_VALUES,
  SAVE_CHANGED_VALUES_FAIL,
} from '@/store/abstract/config-updater/constants';
import {
  Preloader,
  PRELOADER_DICT,
  preloaderActions,
  preloaderGetters,
  preloaderInitialState,
  preloaderMutations,
} from '@/store/abstract/preloader';
import { RestApiService } from '@/services';
import store from '@/store';
import { REG_INFO_CONST } from '@/store/reg-info/reg-info.const';
import { RegInfoQuery } from '@/store/reg-info/reg-info.types';
import { dirty, initialDirtyState } from '@/store/abstract/dirty';
import { RESET_DIRTY } from '@/store/abstract/dirty/constants';
const rest = new RestApiService();

export const configUpdateInitial: IConfigUpdaterState = {
  items: [],
  updError: false,
  ...initialDirtyState,
  ...preloaderInitialState,
};

export const configUpdater: Module<IConfigUpdaterState, RootState> = {
  state: configUpdateInitial,
  actions: {
    ...preloaderActions,
    ...dirty.actions,
    [SAVE_CHANGED_VALUES]: async (
      context,
      payload: {
        url: string;
        phase: number;
        configName: string;
      }
    ) => {
      try {
        await Preloader.start(context, PRELOADER_DICT.LOADING);
        const { url, phase, configName } = payload;
        const { state } = context;
        const { changes } = state;

        const newVals =
          changes &&
          Object.fromEntries(
            Object.entries(changes).map(([key, value]) => [
              key,
              Object.fromEntries(
                Object.entries(value).map(([innerKey, innerValue]) => [
                  innerKey,
                  Array.isArray(innerValue) ? JSON.stringify(innerValue) : innerValue,
                ])
              ),
            ])
          );

        const response = await rest.post(url, {
          config: { name: configName },
          updates: newVals,
        });

        // current equip id from getter
        const equipId = store.getters['details/equipmentDetails'].equipId;

        // update nsi value on the fly
        await store.dispatch(`${REG_INFO_CONST.REG_INFO}/${REG_INFO_CONST.GET_REG_INFO_TABS}`, {
          equipId,
          phase,
        } as RegInfoQuery);
        // reset
        context.commit(RESET_DIRTY);
        // returns data if ok
        return response.data;
      } catch (e) {
        context.commit(SAVE_CHANGED_VALUES_FAIL, e);
      } finally {
        await Preloader.stop(context);
      }
    },
  },
  mutations: {
    ...dirty.mutations,
    ...preloaderMutations,
    [PICKUP_CHANGED_VALUES]: (state, payload) => {
      state.items = [...payload];
    },
    [SAVE_CHANGED_VALUES_FAIL]: (state, payload) => {
      state.updError = payload;
    },
  },
  getters: {
    ...dirty.getters,
    ...preloaderGetters,
    [CHANGED_ITEMS]: ({ items }) => items,
  },
};
