import {
  IChartArea,
  IChartScheme,
  IChartSeries,
  IPropertyScheme,
} from '@/store/history-data/history-data.types';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { RestApiService } from '@/services';
import { ILiveChartScheme } from '@/components/basics/charts/chart.types';

const path = getApiUrl(TR_TYPE.HTTP, '/chart-props');
const rest = new RestApiService();

export interface IChartManager {
  extractFromScheme: (live: ILiveChartScheme[], phase: number) => IPropertyScheme[];
  updateAreaPropertyScheme: (area?: IChartArea) => Promise<void>;
  propertiesScheme?: IPropertyScheme[];
  chartScheme: IChartScheme;
  updatePropertiesScheme: (phase: number, equipId: number, typeId?: number) => Promise<void>;
  selectFromScheme: (
    selector: string[],
    phase: number,
    scheme: IPropertyScheme[]
  ) => IPropertyScheme[] | null;
}



export class ChartManager implements IChartManager {
  propertiesScheme: IPropertyScheme[] = [];
  areas!: IChartArea[];
  constructor() {
    if (!this.areas) {
      this.areas = this.generateAreasScheme();
    }
  }
  get chartScheme(): IChartScheme {
    return { areas: this.areas };
  }

  private static generateSeriesForArea(count: number, startIndex: number): IChartSeries[] {
    const series: IChartSeries[] = [];
    for (let i = 0; i < count; i++) {
      const j = startIndex + i;
      series.push({
        chartIndex: j,
        category: 'time',
        value: `value${j}`,
      });
    }
    return series;
  }
  private generateAreasScheme(areasAndSeries = [3, 3, 3]): IChartArea[] {
    const areas: IChartArea[] = [];
    let j = -1;
    for (const a of areasAndSeries) {
      j++;
      const area = {
        index: j,
        name: `Area${j + 1}`,
        series: ChartManager.generateSeriesForArea(a, a * j + 1),
        propertyScheme: this.propertiesScheme[j],
      };
      areas.push(area);
    }
    return areas;
  }



  async updateAreaPropertyScheme(area?: IChartArea): Promise<void> {
    try {
      if (!area) {
        // console.log(`Area is undefined`);
        return;
      }
      const _area = this.chartScheme.areas.find((a) => a === area);
      if (!_area) {
        // console.log(`Area ${area.name} not found!`);
        return;
      }

      if (!_area.propertyScheme) {
        // console.log(
        //   `propertyScheme is undefined, new scheme will be attached! By index ${_area.index}`
        // );
        _area.propertyScheme = this.propertiesScheme[_area.index];
        return;
      }

      const sKey = _area.propertyScheme.similarityKey;

      const nPropScheme = this.propertiesScheme.find((k) => k.similarityKey === sKey);

      if (!nPropScheme) {
        // console.log(
        //   `propertyScheme not found, new scheme will be attached! By index ${_area.index}`
        // );
        _area.propertyScheme = this.propertiesScheme[_area.index];
      }
    } catch (e) {
      console.log(e.message);
    }
  }



  private static getLabel(phaseValue: number) {
    if (phaseValue === 32) return 'A';
    if (phaseValue === 64) return 'B';
    if (phaseValue === 128) return 'C';
    return null;
  }

  extractFromScheme(live: ILiveChartScheme[], phase: number): IPropertyScheme[] {
    if (!live?.length) {
      return [];
    }
    const pScheme: IPropertyScheme[] = [];
    live.forEach((l) => {
      const name = `${l.selectorPrefix}${
        l.hasPhase ? '_' + ChartManager.getLabel(phase)?.toLowerCase() : ''
      }`;
      const find = this.propertiesScheme.find((i) => {
        if (i.select === name) {
          if (l.hasPhase) {
            if (l.calculatorName === i.calculatorName) {
              return true;
            }
          } else {
            return true;
          }
        }
        return false;
      });
      if (find) pScheme.push(find);
    });
    return pScheme;
  }

  selectFromScheme(
    selector: string[],
    phase: number,
    scheme: IPropertyScheme[]
  ): IPropertyScheme[] | null {
    if (!selector?.length) return null;
    let result: IPropertyScheme[] = [];
    selector.forEach((s) => {
      let fnd;
      if (s.includes('_')) {
        fnd = scheme.filter(
          (i) => i?.select === `${s}${ChartManager.getLabel(phase)?.toLowerCase()}`
        );
      } else {
        fnd = scheme.filter((i) => i?.select === s);
      }
      if (fnd) {
        result = [...result, ...fnd];
      }
    });
    return result;
  }

  async generatePropertiesScheme(phase: number, equipId: number, typeId?: number): Promise<any> {
    try {
      // const tId = typeId ? `&typeId=${typeId}` : '';
      // const url = `${path}?equipId=${equipId}&phase=${phase}${tId}`;
      // const response = await rest.get(url);
      // return response.data;
      return null;
    } catch (e) {
      //
    }
  }

  async updatePropertiesScheme(phase: number, equipId: number, typeId?: number): Promise<void> {
    this.propertiesScheme = await this.generatePropertiesScheme(phase, equipId);
    this.areas.forEach((area, jj) => {
      const f = this.propertiesScheme.find(
        (i) => i.similarityKey === area.propertyScheme?.similarityKey
      );
      if (f) {
        area.propertyScheme = f;
      } else {
        if (
          !area?.propertyScheme &&
          this.propertiesScheme?.length &&
          this.propertiesScheme.length >= jj - 1
        ) {
          area.propertyScheme = this.propertiesScheme[jj];
        }
      }
    });

    // console.log('_propertiesScheme:::', this.propertiesScheme);
  }
}

export class ChartManagerSingleton {
  private static _instance: IChartManager;
  public static instance = (): IChartManager => {
    if (!ChartManagerSingleton._instance) {
      ChartManagerSingleton._instance = new ChartManager();
    }
    return ChartManagerSingleton._instance;
  };
}
