const prefix = 'report-data/';

export const REPORT_DATA = `reportData`;
export const REPORT_DATA_COLUMNS = `${prefix}ReportDataColumns`;
export const SUBSTATION_EQUIPMENT = `${prefix}SubstationEquipment`;
export const EQUIPMENT_CHILDREN = `${prefix}GetEquipmentChildren`;
export const EQUIPMENT_TYPES = `${prefix}EquipmentTypes`;
export const NODE_TYPE_MAP = `${prefix}NodeTypeMap`;
export const REPORT_TABS = `${prefix}ReportTabs`;
export const EQUIPMENT_TYPE_TABLE_CONFIG = `${prefix}EquipmentTypeTableConfig`;
export const ALL_EQUIPMENT_PHASES = `${prefix}AllEquipmentPhases`;
export const EQUIPMENT_STATE = `${prefix}EquipmentState`;
export const EQUIPMENT_STATE_DATA = `${prefix}EquipmentStateData`;
export const EXACT_EQUIP_STATE = `${prefix}ExactEquipState`;
export const CLEAR_TAB_DATA = `${prefix}ClearTabData`;
export const CALCULATOR_UUIDS = `${prefix}CalculatorUuids`;

export const SELECTED_STATE_TAB = `${prefix}SelectedStateTab`;
export const REPORT_TOPOLOGY_TAB = `${prefix}ReportTopologyTab`;
