import { Module } from 'vuex';
import { ITab, ITabsQuery, TabsState } from '@/store/tabs/tabs.types';
import { RootState } from '@/store/types';
import { TABS_CONST } from '@/store/tabs/tabs.const';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { RestApiService } from '@/services';
import { extractTabs } from '@/store/tabs/tabs.extensions';
import DynamicChartView from '@/components/basics/charts/dynamic-history-chart/dynamic.chart.view.vue';
import _ from 'lodash';
import { equipmentStates } from '@/utils';

const { GET_TABS, GET_TABS_ERROR, GET_TABS_SUCCESS, SELECTED_TAB, TABS, ALL_TABS } = TABS_CONST;
const path = getApiUrl(TR_TYPE.HTTP, '/algorithms-config/tabs');
const rest = new RestApiService();

const defaultTabs = (): ITab[] => [
  {
    key: 'rejime',
    name: 'Параметры режимов',
    component: DynamicChartView,
    type: 'chart',
    isDefault: true,
  },
];
const initialState = (): TabsState => {
  return {
    tabsError: false,
    selectedTab: defaultTabs()[0],
    tabs: [...defaultTabs()],
    query: {
      equipId: -1,
      phase: -1,
      equipmentType: '',
    },
    tabsRefs: [],
    tabsRefsError: false,
  };
};

export const tabs: Module<TabsState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    [GET_TABS]: async (context, payload: ITabsQuery) => {
      try {
        context.commit(GET_TABS_SUCCESS, []);
        const { equipId, phase } = payload;
        if (!equipId || !phase) return;
        const _tabs = await rest.get(`${path}?equipId=${equipId}&phase=${phase}`);
        const rr = extractTabs(_tabs.data as any[], payload.equipmentType);
        const { eqOrder } = payload;
        //todo hardcode on eqOrder
        const def = eqOrder && eqOrder > 0 && eqOrder < 10 ? [...defaultTabs()] : [];

        const states = rr.filter((t) => equipmentStates.some((x) => x === t?.type));
        const tables = rr.filter((t) => t?.type === 'datatable');
        const tabs = [...states, ...def, ..._.orderBy(tables, 'order', ['asc'])];
        context.commit(GET_TABS_SUCCESS, tabs);
      } catch (e) {
        context.commit(GET_TABS_ERROR, e);
      }
    },
  },
  mutations: {
    [GET_TABS_SUCCESS]: (state, payload: ITab[]) => {
      state.tabs = payload;
      state.tabsError = false;
      state.selectedTab = payload[0];
    },
    [GET_TABS_ERROR]: (state, payload: boolean | Error) => {
      state.tabs = [];
      state.tabsError = payload;
    },
  },
  getters: {
    [ALL_TABS]: ({ tabs }) => tabs,
    [SELECTED_TAB]: ({ selectedTab }) => selectedTab,
  },
};
