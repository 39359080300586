import { ActionTree } from 'vuex';
import { TreeState } from '@/store/equipment/types';
import { RootState } from '@/store/types';
import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { CONST as AUTH } from '@/store/auth/constants';

import { EQUIP_STORE_CONST } from './constants';
import {
  injectStates,
  transformTree,
  transformStates,
  updateStatesAsync,
} from '@/store/equipment/extensions';

const rest = new RestApiService();
const userTreePath = getApiUrl(TR_TYPE.HTTP, '/equipment/userTree?');
const substationStatePath = getApiUrl(TR_TYPE.HTTP, '/click-house/substation-state?');
const reportPath = getApiUrl(TR_TYPE.HTTP, '/report-data');
export const actions: ActionTree<TreeState, RootState> = {
  [EQUIP_STORE_CONST.SUBSTATION_CALC_UIDS]: (context, payload) => {
    try {
      context.commit(EQUIP_STORE_CONST.SUBSTATION_CALC_UIDS, payload);
    } catch (e) {
      console.log(e.message);
    }
  },
  [EQUIP_STORE_CONST.SELECTED_TREE_NODE]: (context, payload) => {
    try {
      context.commit(EQUIP_STORE_CONST.SELECTED_TREE_NODE, payload);
    } catch (e) {
      context.commit(EQUIP_STORE_CONST.SELECTED_TREE_NODE, null);
    }
  },
  [EQUIP_STORE_CONST.UPDATE_SUBSTATION_STATES]: async (context, payload) => {
    try {
      const treeStateMap = await transformStates(payload?.data);
      const { tree } = context.state;
      if (!tree) return;
      // const uid = "f34e921d-d197-42fc-98df-7fdacc6fb1bd"
      // treeStateMap[uid] = {state: 0, quality:1}
      await updateStatesAsync(tree, treeStateMap);
      context.commit(EQUIP_STORE_CONST.TREE_LOADED, tree);
    } catch (e) {
      // console.log(e.message);
    }
  },
  [EQUIP_STORE_CONST.GET_SUBSTATION_STATES]: async (context, payload) => {
    try {
      const response = await Promise.all(
        payload.map((x: any) => rest.get(`${substationStatePath}substationUid=${x.uid}`))
      );
      const data = response.map((x: any) => x?.data[0]);

      const treeStateMap = transformStates(data);

      context.commit(
        EQUIP_STORE_CONST.SUBSTATION_CALC_UIDS,
        Object.fromEntries(
          data.filter((x) => !!x).map((x) => [x.substation_uuid, x.calculator_uuid])
        )
      );
      context.commit(EQUIP_STORE_CONST.TREE_STATE_MAP, treeStateMap);
      const x = injectStates(payload, treeStateMap);
      context.commit(EQUIP_STORE_CONST.TREE_LOADED, x);
    } catch (e) {
      console.log(e.message);
      context.commit(EQUIP_STORE_CONST.TREE_STATE_MAP_ERR);
    }
  },
  [EQUIP_STORE_CONST.GET_TREE]: async (context) => {
    try {
      const user = context.rootGetters[`${AUTH.AUTH}/${AUTH.GET_USER}`];
      context.commit(EQUIP_STORE_CONST.TREE_LOADING_SET, true);
      const [resp, types] = await Promise.all([
        rest.get(`${userTreePath}userId=${user.id}`),
        rest.get(`${reportPath}/types-list`),
      ]);

      if (types.status === 200) {
        const typesHash: Record<string, any> = {};
        types.data.forEach((x: any) => {
          typesHash[x.uid] = x;
        });

        context.commit(EQUIP_STORE_CONST.SET_TYPES, typesHash);
      }

      const data = transformTree(resp.data, 0);
      await context.dispatch(EQUIP_STORE_CONST.GET_SUBSTATION_STATES, data);
      // context.commit(EQUIP_STORE_CONST.TREE_LOADED, data);
    } catch (e) {
      context.commit(EQUIP_STORE_CONST.TREE_ERROR);
    } finally {
      context.commit(EQUIP_STORE_CONST.TREE_LOADING_SET, false);
    }
  },
  [EQUIP_STORE_CONST.APPLY_FILTER](context, payload) {
    try {
      // console.log(payload);
    } catch (e) {
      context.commit(EQUIP_STORE_CONST.TREE_ERROR);
    }
  },
};
