import { Module } from 'vuex';

import { RootState } from '@/store/types';

import { AuthState } from './types';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';

const initialState: AuthState = {
  token: JSON.parse(localStorage.getItem('auth.token') ?? 'null'),
  refreshToken: JSON.parse(localStorage.getItem('auth.refreshToken') ?? 'null'),
  user: JSON.parse(localStorage.getItem('auth.user') ?? 'null'),
};

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
