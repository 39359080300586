import { GetterTree } from 'vuex';
import { TabParamsQuery, TabParamsResponse, TabParamsState } from './tab-params.types';
import { RootState } from '../types';
import { TAB_PARAMS_CONST } from './tab-params.constants';

export const getters: GetterTree<TabParamsState, RootState> = {
  [TAB_PARAMS_CONST.EQUIPMENT_DETAILS](state): TabParamsResponse | undefined {
    if (!state) return undefined;

    return state.data;
  },
  [TAB_PARAMS_CONST.QUERY](state): TabParamsQuery | undefined {
    const { query } = state;
    return query;
  },
};
