export const DEBUG_LOGS = false;

export enum TAB_PARAMS_CONST {
  // data
  GET_DETAILS = 'getDetails',
  UPDATE_DETAILS = 'updateDetails',
  DATA_LOADED = 'dataLoaded',
  DATA_ERROR = 'dataError',
  NAMESPACE = 'tabParams',
  EQUIPMENT_DETAILS = 'equipmentDetails',
  CLEAR_DETAILS = 'clearDetails',

  // query
  QUERY = 'query',
  QUERY_UPDATED = 'queryUpdated',
  QUERY_ERROR = 'queryError',
  UPDATE_QUERY = 'updateQuery',

  // phase
  CHANGE_PHASE = 'changePhase',
  PHASE_CHANGED = 'phaseChanged',
  PHASE_ERROR = 'phaseError',
  PHASE = 'phase',
}
