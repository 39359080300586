const prefix = 'bookmarks/';

export const BOOKMARKS = `${prefix}`;
export const BOOKMARKS_TREE = `${prefix}BookmarksTree`;
export const LOAD_BOOKMARKS_TREE = `${prefix}LoadBookmarksTree`;
export const SAVE_BOOKMARKS_TREE = `${prefix}SaveBookmarksTree`;
export const GENERATE_LINK = `${prefix}GenerateLink`;
export const ADD_TREE_NODE = `${prefix}AddBookmark`;
export const GENERATE_FOLDER = `${prefix}GenerateFolder`;
export const UPDATE_NODE = `${prefix}UpdateNode`;
export const DELETE_NODE = `${prefix}DeleteNode`;
export const BOOKMARKS_QUERY = `${prefix}BookmarksQuery`;
