































































































































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

import { AlgorithmScheme } from '@/store/algorithms/algorithms.types';
import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { buildProp } from '@/components/items-tab/panes/algorithms/algorithm.convertors';
import { QueryBuilder } from '@/utils/QueryBuilder';

import { FiltersState, FiltersMap, AllFilter } from './filter.types';
import FilterFooter from './FilterFooter.vue';
import NumberFilter from '@/components/basics/table/filters/number-filter.vue';

@Component({
  components: {
    NumberFilter,
    'filter-footer': FilterFooter,
  },
})
export default class TableFilter extends Vue {
  rest = new RestApiService();

  buildProp = buildProp;

  @Prop() filtersMap!: FiltersMap;
  @Prop() filtersState!: FiltersState;
  @Prop() column!: any;
  @Prop() scheme?: AlgorithmScheme;
  @Prop() query: any;
  @Prop() enums!: any[] | null;
  @Prop() guidTablesHash!: Record<string, any> | null;

  async initialiseFilter(filter: AllFilter) {
    if (filter.type === 'Number') {
      this.filtersState.filterMin[filter.name] = filter.min;
      this.filtersState.filterMax[filter.name] = filter.max;
    } else if (filter.type === 'Enum') {
      this.filtersState.filterOptions = filter.options.map((x) => ({ ...x }));
    } else if (filter.type === 'EnumQuery') {
      const data = await this.rest.getParse(
        getApiUrl(
          TR_TYPE.HTTP,
          `/click-house/query-enum-values?field=${filter.name}${this.query &&
            `&${QueryBuilder.build({
              ...this.query,
              limit: undefined,
              offset: undefined,
              filter: undefined,
            })}`}`
        )
      );

      if (data) {
        const newOptions = data
          .map((x) => x[filter.name])
          .filter(
            (x) => !filter.options.some((option) => option.active && option.value === x.toString())
          );

        const newEnumOptions =
          this.enums?.filter(
            (x) =>
              x.enumUid === this.column?.Uid && newOptions.some((y) => x.order === y.toString())
          ) || [];

        filter.options = [
          ...newEnumOptions.map((x) => ({ notes: x.notes, value: x.order, active: false })),
          ...filter.options.filter((x) => x.active),
        ].sort((x: any, y: any) => (x.notes < y.notes ? -1 : 1));

        filter.optionsLoaded = true;
      }

      this.filtersState.filterQueryOptions = filter.options.map((x) => ({ ...x }));
    } else if (filter.type === 'Guid') {
      const data = await this.rest.getParse(
        getApiUrl(
          TR_TYPE.HTTP,
          `/click-house/query-enum-values?field=${filter.name}${this.query &&
            `&${QueryBuilder.build({
              ...this.query,
              limit: undefined,
              offset: undefined,
              filter: undefined,
            })}`}`
        )
      );

      if (data) {
        console.log(filter.name, data?.length);
        const newOptions = data
          .map((x) => x[filter.name])
          .filter(
            (x) => !filter.options.some((option) => option.active && option.value === x.toString())
          );

        const hashItem = this.guidTablesHash?.[filter.name];

        const newEnumOptions =
          hashItem?.items.filter((x: any) => newOptions.some((y) => x.uuid === y)) || [];

        const optionsHash: any = {};
        newEnumOptions?.forEach((x: any) => {
          const key = x[hashItem?.property];
          if (optionsHash[key]) {
            optionsHash[key] = [...optionsHash[key], x.uuid];
          } else {
            optionsHash[key] = [x.uuid];
          }
        });

        const optionsNames = Object.keys(optionsHash);

        const oldFilters = filter.options.filter((x) => x.active);
        const reloadedOldFilters = oldFilters.map((x) => {
          const key = hashItem?.property;
          const value = JSON.parse(x.value)[0];

          return {
            ...x,
            notes: hashItem?.items.find((y: any) => y.uuid === value)?.[key],
          };
        });

        filter.options = [
          ...optionsNames.map((x: any) => ({
            notes: x,
            value: JSON.stringify(optionsHash[x]),
            active: false,
          })),
          ...reloadedOldFilters,
        ].sort((x: any, y: any) => (x.notes < y.notes ? -1 : 1));

        filter.optionsLoaded = true;
      }

      this.filtersState.filterGuidOptions = filter.options.map((x) => ({ ...x }));
    } else if (filter.type === 'NumberEnum') {
      if (!filter.optionsLoaded) {
        const data = await this.rest.getParse(
          getApiUrl(
            TR_TYPE.HTTP,
            `/click-house/enum-values?field=${filter.name}&algorithm=${this.scheme?.dbTableName}`
          )
        );

        if (data) {
          const newOptions = data
            .map((x) => x[filter.name])
            .filter((x) => !filter.options.some((option) => option.value === x));

          filter.options = [
            ...newOptions.map((x) => ({ value: x, active: false })),
            ...filter.options,
          ].sort((x, y) => x.value - y.value);

          filter.optionsLoaded = true;
        }

        this.filtersState.numberFilterOptions = filter.options.map((x) => ({ ...x }));
      }
    } else if (filter.type === 'StringEnum') {
      const data = await this.rest.getParse(
        getApiUrl(
          TR_TYPE.HTTP,
          `/click-house/query-enum-values?field=${filter.name}${this.query &&
            `&${QueryBuilder.build({
              ...this.query,
              limit: undefined,
              offset: undefined,
              filter: undefined,
            })}`}`
        )
      );

      if (data) {
        const newOptions = data
          .map((x) => x[filter.name])
          .filter((x) => !filter.options.some((option) => option.active && option.value === x));

        filter.options = [
          ...newOptions.map((x) => ({ value: x, active: false })),
          ...filter.options.filter((x) => x.active),
        ].sort((x, y) => (x.value < y.value ? -1 : 1));

        filter.optionsLoaded = true;
      }

      this.filtersState.stringFilterOptions = filter.options.map((x) => ({ ...x }));
    }
  }

  async filterToggle(filter: AllFilter) {
    const oldFilterState = filter.visible;
    this.filtersHide();
    if (!oldFilterState) {
      await this.initialiseFilter(filter);
    }
    filter.visible = !oldFilterState;
  }

  filtersHide() {
    Object.entries(this.filtersMap).forEach(([, value]) => value && (value.visible = false));
  }
}
