import { ActionContext } from 'vuex';
import { RootState } from '@/store/types';
import { PRELOADER_CHANGE_STATE, PRELOADER_DICT } from '@/store/abstract/preloader/constants';
import { IPreloader } from '@/store/abstract/preloader/types';

export class Preloader {
  public static normalState: IPreloader = {
    isBusy: false,
    message: '',
  };
  public static isBusyState: IPreloader = {
    isBusy: true,
    message: PRELOADER_DICT.LOADING,
  };
  public static async start<T>(ctx: ActionContext<T, RootState>, message = PRELOADER_DICT.LOADING) {
    await ctx.dispatch(PRELOADER_CHANGE_STATE, { isBusy: true, message });
  }
  public static async stop<T>(ctx: ActionContext<T, RootState>) {
    await ctx.dispatch(PRELOADER_CHANGE_STATE, { isBusy: false, message: '' });
  }
}
