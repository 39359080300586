export type PhaseName = 'phase_a' | 'phase_b' | 'phase_c';

export const defaultColors = {
  YELLOW: '#fcd105',
  GREEN: '#7ef644',
  RED: '#f64045',
  BLUE: '#14a7f9',
};

export const phaseColor = (phase: PhaseName): string => {
  switch (phase) {
    case 'phase_a':
      return defaultColors.YELLOW;
    case 'phase_b':
      return defaultColors.GREEN;
    case 'phase_c':
      return defaultColors.RED;
    default:
      return defaultColors.BLUE;
  }
};

export const colorByPhase = (phase?: number | null): string => {
  switch (phase?.toString()) {
    case '32':
      return defaultColors.YELLOW;
    case '64':
      return defaultColors.GREEN;
    case '128':
      return defaultColors.RED;
    default:
      return defaultColors.BLUE;
  }
};

function hexToRgbA(hex: string, opacity = 1): string {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${opacity})`;
  }
  throw new Error('Bad Hex');
}

const striped = (flag: boolean, color: string): string => {
  if (flag) {
    return color;
  }
  return hexToRgbA(color, 0.3);
};

export const buildColors = (
  color = defaultColors.BLUE,
  count = 1,
  group = 3,
  isStriped = false
) => {
  const colors: string[] = [];
  let mem = 1;
  for (let i = 0; i < count; i++) {
    const clr = !isStriped ? color : striped(mem === i, color);
    if (i === mem) {
      mem += count / group;
    }
    colors.push(clr);
  }
  return colors;
};
