import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import {
  PRELOADER_CHANGE_STATE,
  PRELOADER_CHANGE_STATE_ERROR,
  PRELOADER_CHANGE_STATE_SUCCESS,
} from '@/store/abstract/preloader/constants';
import { IPreloader, IPreloaderState } from '@/store/abstract/preloader/types';

const actions: ActionTree<IPreloaderState, RootState> = {
  [PRELOADER_CHANGE_STATE]: async (context, payload: IPreloader) => {
    try {
      await context.commit(PRELOADER_CHANGE_STATE_SUCCESS, payload);
    } catch (e) {
      await context.commit(PRELOADER_CHANGE_STATE_ERROR, e);
    }
  },
};

export default actions;
