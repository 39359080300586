import { Module } from 'vuex';
import { IPopupChartsState, PopupFlagsType } from '@/store/popup-charts/popup.charts.types';
import { RootState } from '@/store/types';
import {
  CLEAN_POPUP_DATA,
  GET_POPUP_CHART_DATA,
  GET_POPUP_CHART_DATA_FAIL,
  GET_QUERY_PARAMS,
  GET_QUERY_PARAMS_FAIL,
  GET_QUERY_PARAMS_OK,
  POPUP_CHART_CONST,
  POPUP_CHART_DATA,
  POPUP_CHART_MOCK_DATA,
  POPUP_CHART_QUERY_PARAMS,
  POPUP_FLAG,
  POPUP_SCALE_CONFIG,
} from './popup.charts.const';
import { QueryBuilder } from '@/utils/QueryBuilder';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { RestApiService } from '@/services';
import { splitChunks } from '@/store/helpers';
import moment from 'moment';
import _ from 'lodash';
import { TableCol } from '@/components/basics/table/basic-table.types';
import { resetTimeOffset } from '@/utils/datetime/DateTimeCorrector';
import { popupChartMockData } from '@/store/popup-charts/popup.charts.mock';
import {
  Preloader,
  PRELOADER_DICT,
  preloaderActions,
  preloaderGetters,
  preloaderInitialState,
  preloaderMutations,
} from '@/store/abstract/preloader';
import {
  baseValueFilter,
  buildClickHouseQueryAsync,
  definePopupTypeAsync,
  getEnumValue,
} from '@/store/popup-charts/extensions';

const { GET_DATA, DATA, GET_DATA_ERROR, GET_DATA_SUCCESS } = POPUP_CHART_CONST;
const path = getApiUrl(TR_TYPE.HTTP, '/click-house/history-data?');
const path2 = getApiUrl(TR_TYPE.HTTP, '/click-house/popup-history?');
const pathQueryParams = getApiUrl(TR_TYPE.HTTP, '/popup-chart/query-params?');
const rest = new RestApiService();

const initialState: IPopupChartsState = {
  scaleConfig: null,
  mockData: popupChartMockData,
  popupData: [],
  data: [],
  dataError: false,
  ...preloaderInitialState,
  popupChartQueryParams: [],
  popupFlag: 'none',
};

export const popupChart: Module<IPopupChartsState, RootState> = {
  namespaced: true,
  state: initialState,
  actions: {
    ...preloaderActions,
    [GET_QUERY_PARAMS]: async (context, payload) => {
      try {
        const query = QueryBuilder.build(payload);
        const response = await rest.get(pathQueryParams + query);
        const { data } = response;
        if (!Array.isArray(data)) {
          context.commit(GET_QUERY_PARAMS_FAIL, new Error(data));
          return;
        }
        context.commit(GET_QUERY_PARAMS_OK, data);
      } catch (e) {
        context.commit(GET_QUERY_PARAMS_FAIL, e);
      }
    },
    [POPUP_SCALE_CONFIG]: async (context, payload) => {
      try {
        let config = {};
        const { row, params } = payload;
        if (!row?.config?.Scale) {
          return;
        }
        for (const param of params) {
          const e = getEnumValue(row, param);
          if (`${e}` === `${param.base_value}`) {
            const { data_field } = param;
            config = { ...config, [data_field]: { scale: row?.config?.Scale } };
          }
        }
        context.commit(POPUP_SCALE_CONFIG, config);
      } catch (e) {
        console.log(e.message);
      }
    },
    [GET_POPUP_CHART_DATA]: async (context, payload: any) => {
      try {
        await Preloader.start(context, PRELOADER_DICT.DATA_LOADING);
        const { popupChartQueryParams } = context?.state;
        const trueParams = popupChartQueryParams.filter(
          (p) => p.flags && !Number.isNaN(p.flags) && Number(p.flags) !== 0
        );
        await context.dispatch(POPUP_SCALE_CONFIG, { row: payload, params: trueParams });
        const queries = await buildClickHouseQueryAsync(payload, trueParams);
        if (!Array.isArray(queries) || !queries?.length) {
          context.commit(GET_QUERY_PARAMS_FAIL, new Error(`Query error:\nquery: ${queries}`));
          context.commit(POPUP_FLAG, 'none');
          return;
        }

        // define Popup Type
        await context.dispatch(POPUP_FLAG, queries);

        const data = [];
        for await (const query of queries) {
          const response = await rest.get(path2 + QueryBuilder.build({ ...query }));
          if (response.status === 200) data.push(response.data);
        }

        context.commit(GET_POPUP_CHART_DATA, data);
      } catch (e) {
        context.commit(GET_POPUP_CHART_DATA_FAIL, e);
      } finally {
        await Preloader.stop(context);
      }
    },
    [POPUP_FLAG]: async (context, payload) => {
      try {
        const flag = await definePopupTypeAsync(payload);
        context.commit(POPUP_FLAG, flag);
      } catch (e) {
        context.commit(POPUP_FLAG, 'none');
      }
    },
    [GET_DATA]: async (context, payload: [{}, TableCol[]]) => {
      try {
        const { unit } = payload[1][0];
        const { start, end, calculator_name, ...props } = payload[0] as any;
        const response = await rest.post(path, {
          ...props,
          start: moment(resetTimeOffset(new Date(start))).format('YYYY-MM-DD HH:mm:ss'),
          end: moment(resetTimeOffset(new Date(end))).format('YYYY-MM-DD HH:mm:ss'),
        });
        const spl = splitChunks(response.data);
        let data = spl.map((i) => {
          return {
            time: new Date(i.ts / 1000000),
            value1: unit ? i.v : i.v / 1000,
          };
        });
        data = _.orderBy(data, 'time', ['asc']);
        context.commit(GET_DATA_SUCCESS, data);
      } catch (e) {
        context.commit(GET_DATA_ERROR, e);
      }
    },
  },
  mutations: {
    ...preloaderMutations,
    [CLEAN_POPUP_DATA]: (state) => {
      state.popupData = [];
    },
    [POPUP_SCALE_CONFIG]: (state, payload) => {
      state.scaleConfig = payload;
    },
    [POPUP_FLAG]: (state, payload) => {
      state.popupFlag = payload;
    },
    [GET_QUERY_PARAMS_FAIL]: (state, payload) => {
      state.popupChartQueryParams = [];
    },
    [GET_QUERY_PARAMS_OK]: (state, payload) => {
      state.popupChartQueryParams = payload;
    },
    [GET_POPUP_CHART_DATA]: (state, payload) => {
      state.popupData = payload;
      state.dataError = false;
    },
    [GET_POPUP_CHART_DATA_FAIL]: (state, payload) => {
      state.popupData = [];
      state.dataError = payload;
    },
    [GET_DATA_SUCCESS]: (state, payload) => {
      state.data = payload;
      state.dataError = false;
    },
    [GET_DATA_ERROR]: (state, payload) => {
      state.data = [];
      state.dataError = payload;
    },
  },
  getters: {
    ...preloaderGetters,
    [DATA]: ({ data }) => data,
    [POPUP_FLAG]: ({ popupFlag }) => popupFlag,
    [POPUP_CHART_DATA]: ({ popupData }) => popupData,
    [POPUP_CHART_MOCK_DATA]: ({ mockData }) => mockData,
    [POPUP_CHART_QUERY_PARAMS]: ({ popupChartQueryParams }) => popupChartQueryParams,
    [POPUP_SCALE_CONFIG]: ({ scaleConfig }) => scaleConfig,
  },
};
