const prefix = 'abstractAlgorithmData/';

export const ALGORITHM_DATA_CONST = {
  RESET_DATA: `${prefix}resetData`,
  LOAD_DATA: `${prefix}loadData`,
  DATA_LOADED: `${prefix}dataLoaded`,
  DATA_ERROR: `${prefix}dataError`,
  DATA: `${prefix}pageData`,
  MOCK: `${prefix}mock`,
  UPDATE_STATE_DATA: `${prefix}UpdateStateData`,
  LAST_UPDATE: `${prefix}LastUpdate`,
};
