







import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { TableCol } from '@/components/basics/table/basic-table.types';
import { tableFilters } from '@/components/basics/table/filters';
@Component({
  filters: {
    ...tableFilters
  }
})
export default class EquipmentDynamicStateItem extends Vue {
  @Prop() prop!: TableCol;
  @Prop() state?: any;
}
