import { MutationTree } from 'vuex';
import { TabParamsItem, TabParamsState } from './tab-params.types';
import { debugLog } from './tab-params.extensions';
import { TAB_PARAMS_CONST } from './tab-params.constants';

export const mutations: MutationTree<TabParamsState> = {
  [TAB_PARAMS_CONST.DATA_LOADED](state: TabParamsState, payload: any) {
    state.error = false;
    state.data = payload;
  },

  [TAB_PARAMS_CONST.DATA_ERROR](state: TabParamsState, error: Error) {
    state.data = undefined;
    state.error = true;
    debugLog('TabParams.data error: ' + error);
  },

  [TAB_PARAMS_CONST.QUERY_ERROR](state: TabParamsState) {
    state.query = undefined;
  },

  [TAB_PARAMS_CONST.UPDATE_QUERY](state: TabParamsState, payload) {
    state.query = payload;
    debugLog(`TabParams.mutations query: `, state.query);
  },

  [TAB_PARAMS_CONST.CLEAR_DETAILS](state: TabParamsState) {
    state.data = undefined;
  },
};
