














import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { Builder } from '@/components/basics/charts/echart/builder';
import echarts from 'echarts';
import phaseA from '@/assets/themes/echarts/phase_a.json';
import { ILevel } from '@/components/basics/TableRowChartManager';
import { Getter } from 'vuex-class';
import { POPUP_CHART_CONST, POPUP_CHART_MOCK_DATA } from '@/store/popup-charts/popup.charts.const';
import { buildPopupChartOptionsAsync } from '@/components/basics/charts/echart/extensions';
import { PRELOADER_STATE } from '@/store/abstract/preloader';
import { IPreloader } from '@/store/abstract/preloader/types';
import { scaleChartData } from '@/components/basics/charts/levels/levels-chart.extensions';

import { HISTORY_CHARTS } from '@/store/history-charts/constants';
import { IChartProperty } from '@/store/abstract/props-scheme/types';
import { CHART_PROPS } from '@/store/abstract/props-scheme/constants';
import { ChartDataVector } from './levels-chart.types';

echarts.registerTheme('phase_a', phaseA);
const { POPUP_CHART } = POPUP_CHART_CONST;

@Component
export default class LevelsEChart extends Vue {
  emptyMessage = 'Нет данных!';
  get isEmpty(): boolean {
    return (
      !Array.isArray(this.popupData) ||
      !this.popupData?.length ||
      this.popupData[0]?.data?.length <= 0
    );
  }

  @Ref('levels-chart') chartDiv: any;
  chartBuilder = new Builder();

  @Prop() popupData?: any[];
  @Prop() phase?: number;
  @Prop() levels?: ILevel[];
  @Prop() title?: string;
  @Prop() scaleConfig?: object | null;

  // props
  @Getter(CHART_PROPS, { namespace: HISTORY_CHARTS })
  chartProps?: IChartProperty[];

  @Getter(POPUP_CHART_MOCK_DATA, { namespace: POPUP_CHART })
  mockData!: any[];

  @Getter(PRELOADER_STATE, { namespace: POPUP_CHART })
  preloader!: IPreloader;

  @Watch('popupData') onPopupDataChange(val: ChartDataVector[], old: ChartDataVector[]) {
    if (val === old || !Array.isArray(val) || !val?.length) return;
    const tData = [];
    for (const v of val) {
      const info =  this.chartProps?.find((i) => i.select == v.select);
      const scaled = scaleChartData( v.data, info );
      tData.push({
        name: info ? `${info?.note}` : `${v.select}`,
        data: [...scaled],
        info,
      });
    }
    this.renderChart(tData);
  }

  async renderChart(data: any[]) {
    this.chartBuilder.disposeChart();
    this.chartBuilder.initChart(this.chartDiv, 'phase_a');
    const opt = await buildPopupChartOptionsAsync(data, this.chartBuilder, this.phase);
    if (!opt) return;
    this.chartBuilder.setChartOptions(opt);
  }

  beforeDestroy(): void {
    this.chartBuilder.disposeChart();
  }
}
