export const REG_INFO_CONST = {
  EQUIP_TYPE_UUID: 'equipTypeUuid',
  CLEAR_REG_INFO_TABS: 'clearRegInfoTabs',
  GET_REG_INFO_TABS: 'getRegInfoTabs',
  GET_REG_INFO_TABS_SUCCESS: 'getRegInfoTabsSuccess',
  GET_REG_INFO_TABS_ERROR: 'getRegInfoTabsError',
  REG_INFO_TABS: 'regInfoTabs',
  REG_INFO: 'regInfo',
};

export const PROP_KEY_ASSET_ID = 'propKeyAssetId';
export const ASSET_PROPS = 'regInfo/assetProps';
