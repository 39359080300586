import { Module } from 'vuex';
import {
  IMaxAvgMin,
  IHistoryChartsQuery,
  IHistoryChartsState,
  IHistoryDataSet,
  ISeries,
  IAreasDataState,
} from '@/store/history-charts/types';
import { RootState } from '@/store/types';
import {
  SET_HISTORY_DATA,
  SET_HISTORY_DATA_FAIL,
  SET_HISTORY_DATA_SUCCESS,
  EXTRA_CALC_DATA,
  EXTRA_CALCULATED_FAIL,
  EXTRA_CALCULATED_SUCCESS,
  HISTORY_CHARTS_BUILD_SCHEME,
  HISTORY_CHARTS_BUILD_SCHEME_ERROR,
  HISTORY_CHARTS_BUILD_SCHEME_SUCCESS,
  HISTORY_CHARTS_DATA,
  REQUEST_DATA,
  RESET_EXTRA_DATA,
  RESET_EXTRA_DATA_FAIL,
  RESET_EXTRA_DATA_SUCCESS,
  AREAS_DATA_STATE,
  AREAS_DATA_STATE_FAIL,
  CLEAR_HISTORY_DATA,
} from '@/store/history-charts/constants';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { dataGetter, dataGetterInitialState } from '@/store/abstract/data-getter';
import { chartProps, chartPropsInitialState } from '@/store/abstract/props-scheme';
import { getSchemeBySketch, updateHistoryDataMap } from '@/store/history-charts/extensions';
import { GET_DATA } from '@/store/abstract/data-getter/constants';
import { IPropertyScheme } from '@/store/history-data/history-data.types';
import { dateTimeRange } from '@/store/abstract/range-getter';
import { IRangeGetterState } from '@/store/abstract/range-getter/types';
const path = getApiUrl(TR_TYPE.HTTP, '/click-house/history-data?');

const initial: IHistoryChartsState = {
  path,
  maxAvgMin: new Map<string, IMaxAvgMin>(),
  historyDataError: false,
  historyDataMap: new Map<ISeries, IHistoryDataSet>(),
  areasDataState: {
    area1: false,
    area2: false,
    area3: false,
  },
  ...dataGetterInitialState,
  ...chartPropsInitialState,
  ...(dateTimeRange.state as IRangeGetterState),
};

export const historyCharts: Module<IHistoryChartsState, RootState> = {
  namespaced: true,
  state: initial,
  actions: {
    ...dateTimeRange.actions,
    ...dataGetter.actions,
    ...chartProps.actions,
    [RESET_EXTRA_DATA]: async (context, payload?: string) => {
      try {
        if (!payload) {
          context.commit(EXTRA_CALCULATED_SUCCESS, new Map<string, IMaxAvgMin>());
        }
      } catch (e) {
        context.commit(RESET_EXTRA_DATA_FAIL, e);
      }
    },
    // query
    // area
    // prop scheme
    [REQUEST_DATA]: async (context, payload: [IHistoryChartsQuery, string, IPropertyScheme]) => {
      try {
        await context.dispatch(GET_DATA, payload[0]);
        // build scheme point
        const { data, historyDataMap, maxAvgMin } = context.state;
        if (historyDataMap) {
          const dataMap = updateHistoryDataMap(
            payload[0],
            payload[1],
            payload[2],
            data,
            // area + series keys
            historyDataMap,
            maxAvgMin
          );
          if (dataMap) {
            context.commit(HISTORY_CHARTS_BUILD_SCHEME_SUCCESS, dataMap[0]);
            context.commit(
              EXTRA_CALCULATED_SUCCESS,
              new Map(dataMap[1] as Map<string, IMaxAvgMin>)
            );
          }
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    [AREAS_DATA_STATE]: async (context, payload: Map<ISeries, IHistoryDataSet>) => {
      const ads: IAreasDataState = { area1: false, area2: false, area3: false };
      if (!payload?.size || payload.size === 0) {
        context.commit(AREAS_DATA_STATE, ads);
        return;
      }
      const areasData = [...payload.values()].map((v) => v.data);
      if (areasData?.length === 3) {
        ads.area1 = areasData[0]?.length > 0 ?? false;
        ads.area2 = areasData[1]?.length > 0 ?? false;
        ads.area3 = areasData[2]?.length > 0 ?? false;
      }
      if (areasData?.length === 9) {
        ads.area1 =
          areasData[0]?.length > 0 ?? areasData[1]?.length > 0 ?? areasData[2]?.length > 0 ?? false;
        ads.area2 =
          areasData[3]?.length > 0 ?? areasData[4]?.length > 0 ?? areasData[5]?.length > 0 ?? false;
        ads.area3 =
          areasData[6]?.length > 0 ?? areasData[7]?.length > 0 ?? areasData[8]?.length > 0 ?? false;
      }
      context.commit(AREAS_DATA_STATE, ads);
    },
    [HISTORY_CHARTS_BUILD_SCHEME]: async (context, payload: IHistoryChartsQuery) => {
      try {
        const dataMap = await getSchemeBySketch(payload);
        context.commit(HISTORY_CHARTS_BUILD_SCHEME_SUCCESS, dataMap);
      } catch (e) {
        context.commit(HISTORY_CHARTS_BUILD_SCHEME_ERROR, e);
      }
    },
    [SET_HISTORY_DATA]: async (context, payload) => {
      try {
        context.commit(SET_HISTORY_DATA_SUCCESS, payload);
      } catch (e) {
        context.commit(SET_HISTORY_DATA_FAIL, e);
        console.log(e.message);
      }
    },
  },
  mutations: {
    ...dateTimeRange.mutations,
    ...dataGetter.mutations,
    ...chartProps.mutations,
    [AREAS_DATA_STATE]: (state, payload: IAreasDataState) => {
      state.areasDataState = payload;
    },
    [AREAS_DATA_STATE_FAIL]: (state) => {
      state.areasDataState = { area1: false, area2: false, area3: false };
    },
    [SET_HISTORY_DATA_SUCCESS]: (state, payload) => {
      state.historyDataMap = payload;
    },
    [SET_HISTORY_DATA_FAIL]: (state, payload) => {
      state.historyDataMap = new Map<ISeries, IHistoryDataSet>();
    },
    [RESET_EXTRA_DATA_SUCCESS]: (state, payload) => {
      state.maxAvgMin = payload;
    },
    [RESET_EXTRA_DATA_FAIL]: (state, payload) => {
      console.log(`${RESET_EXTRA_DATA_FAIL}::`, payload.message);
    },
    [HISTORY_CHARTS_BUILD_SCHEME_SUCCESS]: (state, payload) => {
      state.historyDataMap = payload;
      state.historyDataError = false;
    },
    [HISTORY_CHARTS_BUILD_SCHEME_ERROR]: (state, payload) => {
      state.historyDataMap = new Map<ISeries, IHistoryDataSet>();
      state.historyDataError = payload;
    },
    [EXTRA_CALCULATED_SUCCESS]: (state, payload) => {
      state.maxAvgMin = payload;
    },
    [EXTRA_CALCULATED_FAIL]: (state, payload) => {
      state.maxAvgMin = new Map<string, IMaxAvgMin>();
    },
  },
  getters: {
    ...dateTimeRange.getters,
    ...dataGetter.getters,
    ...chartProps.getters,
    [AREAS_DATA_STATE]: ({ areasDataState }) => areasDataState,
    [HISTORY_CHARTS_DATA]: ({ historyDataMap }) => historyDataMap,
    [EXTRA_CALC_DATA]: ({ maxAvgMin }) => maxAvgMin,
  },
};
