













import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BasicTable from '@/components/basics/table/BasicTable.vue';
import { Action, Getter, Mutation } from 'vuex-class';
import {
  SUBSTATION_EQUIPMENT,
  REPORT_DATA,
  REPORT_DATA_COLUMNS,
  EQUIPMENT_TYPE_TABLE_CONFIG,
  ALL_EQUIPMENT_PHASES,
  EQUIPMENT_STATE_DATA,
  CLEAR_TAB_DATA,
  CALCULATOR_UUIDS,
} from '@/store/report-data/constants';
import {
  ICalculatorUuid,
  IReportColumn,
  IReportTab,
  ITableConfigQuery,
} from '@/store/report-data/types';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';
import AlgorithmView from '@/components/items-tab/panes/algorithms/algorithm.view.vue';
import { AlgorithmScheme } from '@/store/algorithms/algorithms.types';
import { buildScheme, setColumnsWidth } from '@/store/tabs/tabs.extensions';
import { stateSubjectByOrder, StateSubscriber } from '@/components/details-report/state-subscriber';
import { ALGORITHM_DATA_CONST } from '@/store/abstract/algorithm-data/constants';
import { ALGORITHM_CONST } from '@/store/algorithms';

@Component({
  components: { BasicTable, 'algorithm-view': AlgorithmView },
})
export default class DetailsReport extends Vue {
  @Prop() selected: any;
  @Prop() item!: any;

  @Action(ALGORITHM_DATA_CONST.UPDATE_STATE_DATA, { namespace: ALGORITHM_CONST.ALGORITHMS })
  updateStateData!: (payload: any) => void;
  __subject: string | null = null;
  get selectedTab() {
    const { order } = this.selected;
    this.__subject = stateSubjectByOrder[order];
    if (this.__subject && this.keys)
      StateSubscriber.start(this.__subject, this.keys, this.$socket.client).subscribe(
        async (data) => {
          await this.updateStateData(data);
        }
      );
    return this.selected;
  }

  get keys(): string[] | null {
    if (!this.calculatorUuids?.length) return null;
    return this.calculatorUuids?.map((i) => i.uuid);
  }

  @Getter(REPORT_DATA_COLUMNS, { namespace: REPORT_DATA })
  reportDataColumns!: IReportColumn[];
  @Getter(EQUIPMENT_STATE_DATA, { namespace: REPORT_DATA })
  stateData!: any[];
  @Getter(SUBSTATION_EQUIPMENT, { namespace: REPORT_DATA })
  equipmentList!: any[];

  @Mutation(CLEAR_TAB_DATA, { namespace: REPORT_DATA })
  clearData!: () => void;

  @Getter(DICTIONARIES_CONST.TAB_REFS, { namespace: DICTIONARIES_CONST.DICTIONARIES })
  enums?: ITabRef[];
  @Getter(EQUIPMENT_TYPE_TABLE_CONFIG, { namespace: REPORT_DATA })
  config?: any[];
  @Getter(CALCULATOR_UUIDS, { namespace: REPORT_DATA })
  calculatorUuids?: ICalculatorUuid[];

  @Action(EQUIPMENT_TYPE_TABLE_CONFIG, { namespace: REPORT_DATA })
  getTypeTableConfig!: (payload: ITableConfigQuery) => Promise<void>;
  @Action(ALL_EQUIPMENT_PHASES, { namespace: REPORT_DATA })
  setAllPhases!: (payload: any[]) => Promise<void>;
  @Action(EQUIPMENT_STATE_DATA, { namespace: REPORT_DATA })
  getStateData!: (payload: IReportTab) => Promise<void>;

  get equipment() {
    return (
      this.equipmentList?.filter(
        (x) =>
          x.substation_id === this.item?.substation_id ||
          x.substation_id === this.item?.substationId
      ) || []
    );
  }

  get scheme(): AlgorithmScheme | null {
    if (!Array.isArray(this.config) || this.config.length <= 0) return null;
    const s = buildScheme(this.config[this.config.length - 1]);
    setColumnsWidth(s);
    return s;
  }

  get limit(): number {
    const { query } = this.$route;
    return query?.limit ? Number(query?.limit) : 10;
  }

  get offset(): number {
    const { query } = this.$route;
    return query?.offset ? Number(query?.offset) : 0;
  }

  get columns(): IReportColumn[] {
    return this.reportDataColumns ?? [];
  }
  get total(): number {
    return this.stateData?.length ?? 0;
  }

  async mounted() {
    const { phases, ids } = this.selected as IReportTab;
    if (phases?.length && ids?.length) {
      await this.setAllPhases(phases);
      await this.getTypeTableConfig({
        phase: phases[0],
        equipId: ids[0],
      });
      if (this.reportDataColumns?.length > 0) {
        await this.getStateData(this.selected);
      }
    }
  }

  beforeDestroy() {
    this.clearData();
    if (this.__subject) StateSubscriber.stop(this.$socket.client, this.__subject);
  }
}
