const prefix = 'socketSub/';

export const SOCKET_SUB_CONST = {
  SUBJECT: `${prefix}subject`,
  SOCKET_SUB: 'socketSub',

  ADD_SUBJECT: `${prefix}AddSubject`,
  REMOVE_SUBJECT: `${prefix}AddSubjectRemove`,

  UPD_SUBJECT_SUCCESS: `${prefix}AddSubjectSuccess`,
  UPD_SUBJECT_ERROR: `${prefix}AddSubjectError`,
};

export const SOCKET_END_POINTS = {
  ADD_SUBJECT: 'addSubject',
  REMOVE_SUBJECT: 'removeSubject',
  HANDSHAKE: 'handshake',
  ACK: 'ack',
};
