import { buildRange, updateTimeOffset } from '@/utils/datetime/DateTimeCorrector';

export interface TimeInterval {
  duration: number;
  value: string;
  label: string;
}
export const INTERVAL_CONST = {
  ms: {
    value: 'ms',
    duration: 1,
    label: '1 мс',
  } as TimeInterval,
  second: {
    value: 'second',
    duration: 1000,
    label: '1 сек',
  } as TimeInterval,

  minute: {
    value: 'minute',
    duration: 1000 * 60,
    label: '1 мин',
  } as TimeInterval,
  hour: {
    value: 'hour',
    duration: 1000 * 60 * 60,
    label: '1 час',
  } as TimeInterval,
};

export enum AlgorithmType {
  stkPowerMeasurementCalculator = 'stk_power_measurement_calculator',
}

const range = buildRange(
  updateTimeOffset(new Date('2020-04-27 00:00:00')),
  60 * 60 * 24 * 3
);

export interface Reference {
  id: number;
  uuid: string;
  note: string;
}

export interface ChartDataState {
  error: boolean | Error;
  selectedPhase?: number;
  data?: [ChartDataItem[], ChartDataItem[]];
  query?: ChartDataQuery;
  refs: Reference[];
  mam?: MinAvgMax[];
}

export interface MinAvgMax {
  min: number;
  avg: number;
  max: number;
}

export interface PhasesData {
  u_a: MinAvgMax;
  u_b: MinAvgMax;
  u_c: MinAvgMax;
}

export interface ChartDataItem {
  ts: string;
  data: PhasesData;
}

export interface ChartDataQuery {
  interval: TimeInterval;
  start: string;
  end: string;
  algorithm: string;
  calculatorName: string;
  phase: string;
}
