export const equipmentStates = [
  'tt_state',
  'pt_state',
  'reactor_state',
  'at_state',
  'input_hv_state',
  'input_mv_state',
  'input_lv_state',
  'breaker_state',
  'wavetrap_state',
  'rpn_state',
  'dc_general_state_result',
  'ccvt_state',
  'opn_state',
];
