import { IPreloaderState } from '@/store/abstract/preloader/types';

export const preloaderInitialState: IPreloaderState = {
  preloader: {
    isBusy: false,
    message: '',
  },
  preloaderError: false,
};

export { default as preloaderActions } from './actions';
export { default as preloaderMutations } from './mutations';
export { default as preloaderGetters } from './getters';
export { Preloader } from './preloader';
export {
  PRELOADER_DICT,
  PRELOADER_CHANGE_STATE,
  PRELOADER_STATE,
  PRELOADER_CHANGE_STATE_ERROR,
  PRELOADER_CHANGE_STATE_SUCCESS,
} from './constants';
