import { GetterTree } from 'vuex';

import { TreeState, INode } from '@/store/equipment/types';
import { RootState } from '@/store/types';

import { EQUIP_STORE_CONST } from './constants';

export const getters: GetterTree<TreeState, RootState> = {
  [EQUIP_STORE_CONST.TREE](state): INode[] | undefined {
    const { tree } = state;
    return tree;
  },
  [EQUIP_STORE_CONST.TREE_LOADING](state): boolean {
    const { isLoading } = state;

    return isLoading;
  },
  [EQUIP_STORE_CONST.EXPANDED_ITEMS](state): Set<number> {
    const { expanded } = state;
    return expanded;
  },
  [EQUIP_STORE_CONST.TREE_STATE_MAP]: ({ treeStateMap }) => treeStateMap,
  [EQUIP_STORE_CONST.SUBSTATION_CALC_UIDS]: ({ substationCalcUids }) => substationCalcUids,
  [EQUIP_STORE_CONST.SELECTED_TREE_NODE]: ({ selectedTreeNode }) => selectedTreeNode,
  [EQUIP_STORE_CONST.GET_TYPES]: ({ equipmentTypes }) => equipmentTypes,
};
