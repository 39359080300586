import { Module } from 'vuex';
import { TabParamsState } from '@/store/tab-params/tab-params.types';
import { RootState } from '@/store/types';
import { mutations } from '@/store/tab-params/tab-params.mutations';
import { getters } from '@/store/tab-params/tab-params.getters';
import { actions } from '@/store/tab-params/tab-params.actions';

const initialState: TabParamsState = {
  error: false,
  data: { items: [], status: true },
  query: {
    phase: undefined,
    equip: undefined,
  },
};

export const tabParams: Module<TabParamsState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
