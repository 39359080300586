import { ColType } from '@/components/basics/table/basic-table.types';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';
import moment from 'moment';
import { MOMENT_FORMAT } from '@/utils/datetime/DateTimeRangePresets';
import { ValueFormat } from '@/components/basics/table/converters/types';

export const DATETIME_UNKOEF = 1_000_000;

const replace = (i: any) => {
  // let j = i.toString();
  // try {
  //   j = j.replace('.', ',');
  // } catch (e) {
  //   //
  // }
  return i;
};

export const format = (col: any, value: number): string => {
  if (value == null || !col) return '';
  switch (col.Format as ValueFormat) {
    case '.':
    case '*':
      return `${replace(value.toFixed(0))}`;
    case '.*':
      return replace(value?.toFixed(1));
    case '.**':
      return replace(value?.toFixed(2));
    case '.***':
      return replace(value?.toFixed(3));
    case '.ms':
      return `${replace(value)}`;
    case 'mm:ss.ms':
      return `${replace(value)}`;
    case 'hh:mm:ss.ms':
      return `${replace(value)}`;
    case 'text':
      return `${replace(value)}`;
    default:
      return `${replace(value).toFixed(2)}`;
  }
};

export const convertEnum = (value: any, refs: ITabRef[], col: any): string => {
  const ref = refs.find(
    (i) => `${i.enumUid}` === `${col.Uid}` && Number(i.order) === Number(value)
  );
  if (value > col.Max) return '';
  if (ref) {
    return ref.notes;
  }
  return value !== undefined ? `${replace(value)}` : '';
};

export const convertDateTime = (value: any, col: any): string => {
  if (!value || (!isNaN(value) && Number(value) < 0)) {
    return '';
  }

  if (col.Format === '.ms') {
    return replace(moment(new Date(value / 1000000)).format(MOMENT_FORMAT.dateToMs));
  } else if (col.Format === 'mm:ss.ms') {
    const dt = new Date(value / DATETIME_UNKOEF);
    return replace(moment(dt).format(MOMENT_FORMAT.minSecMss));
  } else if (col.Format === 'hh:mm:ss.ms') {
    const dt = new Date(value / DATETIME_UNKOEF);
    return replace(moment(dt).format(MOMENT_FORMAT.hourMinSecMss));
  } else if (col.Format === 'iso') {
    return moment(value).format(MOMENT_FORMAT.dateToMs);
  }
  return `${replace(value)}`;
};

export const getSecFormat = (value: number) => {
  if (value === 0) {
    return '0';
  }
  if (value <= 60.99) {
    return replace(value.toFixed(2));
  }
  return replace(value.toFixed(0));
};

export const convertFloat = (value: any, col: any): string => {
  if (value <= col.Min) return '0';
  if (col.Format === '.ms') {
    const seconds = getSecFormat(moment.duration(value / DATETIME_UNKOEF).asSeconds());
    return isNaN(seconds) ? '' : replace(seconds);
  } else if (col.Format === 'mm:ss.ms') {
    const dt = new Date(value / DATETIME_UNKOEF);
    return replace(moment(dt).format(MOMENT_FORMAT.minSecMss));
  } else if (col.Format === 'hh:mm:ss.ms') {
    const dt = new Date(value / DATETIME_UNKOEF);
    return replace(moment(dt).format(MOMENT_FORMAT.hourMinSecMss));
  }
  const scale = col?.Scale;
  if (scale) value = value * scale;
  let r = format(col, value);
  if (r === '-0.00') r = '0.00';
  if (r === '-0.000') r = '0.000';
  if (r === '-0.0') r = '0.0';
  return replace(r);
};

export const convertInteger = (value: any, col: any): string => {
  if (value > col.Max) return '';
  if (value <= col.Min) return '0';
  const scale = col?.Scale;
  if (scale) value = value * scale;
  return `${replace(value)}`;
};

export const convertStateEnum = (value: any, opts: any): any => {
  const num = Number(value);

  if (value === undefined || value === null || isNaN(num)) {
    return '';
  }
  const prc = num * 100;
  return prc === 0 ? 'Норма' : prc === 100 ? 'Отклонение' : `Внимание ${prc.toFixed(2)}%`;
};

export const buildProp = (value: any, refs: ITabRef[], col: any) => {
  if (value === null) return;

  switch (col.Type as ColType) {
    case ColType.DateTime:
      return convertDateTime(value, col);
    case ColType.DateTimes:
      return value?.map((x: any) => convertDateTime(x, col));
    case ColType.Enum:
      return convertEnum(value, refs, col);
    case ColType.Float:
      return convertFloat(value, col);
    case ColType.Integer:
      return convertInteger(value, col);
    case ColType.StateEnum:
      return convertStateEnum(value, col);
    case ColType.StateEnums:
      return value?.map((x: any) => convertStateEnum(x, col));
    case ColType.String:
      return value;
  }
};
