export enum EQUIP_STORE_CONST {
  GET_TREE = 'getTree',
  APPLY_FILTER = 'applyFilter',
  TREE_LOADED = 'treeLoaded',
  TREE_LOADING = 'treeLoading',
  TREE_LOADING_SET = 'treeLoadingSet',
  TREE_ERROR = 'treeError',
  TREE_ITEMS_EXPAND = 'treeItemExpand',
  TREE_ITEMS_COLLAPSE = 'treeItemsCollapse',
  TREE_ITEMS_COLLAPSE_ALL = 'treeItemsCollapseAll',
  SET_TYPES = 'treeSetTypes',
  GET_TYPES = 'treeGetTypes',
  TREE = 'tree',

  SUBSTATION_CALC_UIDS = 'substationCalcUids',
  GET_SUBSTATION_STATES = 'getSubstationStates',
  UPDATE_SUBSTATION_STATES = 'updateSubstationStates',
  TREE_STATE_MAP = 'treeStateMap',
  TREE_STATE_MAP_ERR = 'treeStateMapError',

  SELECTED_TREE_NODE = 'selectedTreeNode',

  // selected children
  EXPANDED_ITEMS = 'expanded',
}
