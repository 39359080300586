export const TABS_CONST = {
  GET_TABS: 'getTabs',
  GET_TABS_SUCCESS: 'getTabsSuccess',
  GET_TABS_ERROR: 'getTabsError',

  MODULE_UID: 'moduleUid',

  SELECTED_TAB: 'selectedTab',
  ALL_TABS: 'allTabs',
  TABS: 'tabs',
};
