export const splitChunks = (data: any) => {
  if (Array.isArray(data)) {
    return data;
  }

  if (!data?.length) {
    return [];
  }

  const arr = data.split('*');
  if (!arr?.length) {
    return [];
  }

  const result: any[] = [];
  arr.map((i: any) => {
    let d;
    try {
      d = JSON.parse(i);
      if (d) {
        result.push(d);
      }
    } catch (e) {
      // ignore
    }
  });
  return result;
};
