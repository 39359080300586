export const equipmentTypes = {
  VoltageLevel: '0245e8b4-c7e4-4949-84f1-32b206388074',
  Bay: '109b00b0-1035-4ad4-82d0-6e9be6d5e700',
  BusbarSection: '48e4d29d-0b89-41ba-92b2-8dcebcbaf976',
  Disconnector: 'e05c9d41-bd68-4ad6-80b5-bc3bec8581c1',
  DisconnectorV2: '5c3592ad-dcab-4f2a-9a88-f1b3a332b48b',
  GroundDisconnector: 'e6ec5817-37c2-45c7-9669-cab64e9ccbf8',
  Line: '4fa0051f-208c-4c6e-a7c3-8fe781710363',
  Breaker: 'c472cf10-fe61-4186-8788-75539b515de2',
  BreakerV2: 'df48059c-f42f-4205-a593-1cea91644702',
  WaveTrap: 'de3d300b-e2e8-4deb-ae3c-97909bd431f8',
  ATrGround: 'b550410f-6d50-4668-bcd5-f5016ec60bcc',
  Reactor_X2: '2c8fa276-f3ea-4106-98cf-bda86ce7932f',
  PTccWindingPrim: '248b034f-567c-4b4e-9fbf-ab79c209a409',
  CouplingCapacitor: 'e44f6503-1905-4261-a3d1-63e977ba4828',
  SurgeArrester: 'c515569d-328e-4063-98c2-b40f1a1603a2',
  ATrWindingHV: '44706418-9f3a-4df4-b923-bbd9d5517ba1', // ВН
  ATrWindingMV: 'ea86e035-ff74-45b0-ab3a-a419377c2451', // СН
  ATrWindingLV: '403be76c-008b-4031-917c-149001527c4f', // НН
  RatioTapChanger: '1fcfa1d2-e812-476d-aa1e-48d2e9ac79aa',
  Reactor: 'ca1c1363-f6ff-41b7-8b7b-d4bd85474702',
  Reactor_HV: 'e051a2cd-b3e1-4264-9ebd-afe55573d30d',
  Reactor_X1: 'e9b2236f-5ad7-474c-ae2b-e77ab3b31f52',
  CurrentTransformer: 'd36507e8-67e0-48b1-bb5f-e54dc32cfca4',
  CurrentTransformerEmbed: '32af7aac-d7cc-46e6-bdb1-ef62d2211ace',
  CTWindingPrim: 'bf5be9bc-a3b6-4330-a364-1ca658d41dcc',
  CTWindingPrimEmbed: 'b47b8cd2-7a27-4d81-b4eb-e61e6b77dac6',
  CTWindingSec: '194e1acf-6c29-4a9d-a8cf-1677ce9b027f',
  CTWindingSecEmbed: 'ecefd017-fec0-4d21-ab83-2078689644ab',
  PotentialTransformerCC: '84562c7f-af9d-4911-b80f-927ee58c2577',
  PotentialTransformerEM: '46106367-a179-4635-b8d5-ceb572ae8974',
  PTemWindingPrim: 'f71db015-0f17-4497-bc10-f64fc5bf3b35',
  PTccWindingSec: 'b5faf9ea-a0a1-471f-a733-6f5764f72676',
  PTemWindingSec: 'e4b20fe1-7d62-4171-8987-9b3693b8f481',
  PowerTransformer: 'f92a596e-fb93-4944-bd78-4d4456ba78b0',
  OPN: 'c515569d-328e-4063-98c2-b40f1a1603a2',
};

export const equipmentNames: Record<string, string> = {
  '0245e8b4-c7e4-4949-84f1-32b206388074': 'Распределительное устройство',
  '109b00b0-1035-4ad4-82d0-6e9be6d5e700': 'Ячейка присоединения',
  '48e4d29d-0b89-41ba-92b2-8dcebcbaf976': 'Секция шин',
  'e05c9d41-bd68-4ad6-80b5-bc3bec8581c1': 'Разъединитель',
  '5c3592ad-dcab-4f2a-9a88-f1b3a332b48b': 'Разъединитель',
  'e6ec5817-37c2-45c7-9669-cab64e9ccbf8': 'Заземляющий нож',
  '4fa0051f-208c-4c6e-a7c3-8fe781710363': 'Линия электропередачи',
  'c472cf10-fe61-4186-8788-75539b515de2': 'Выключатель',
  'df48059c-f42f-4205-a593-1cea91644702': 'Выключатель',
  'de3d300b-e2e8-4deb-ae3c-97909bd431f8': 'Высокочастотный заградитель',
  'b550410f-6d50-4668-bcd5-f5016ec60bcc': 'Нейтраль',
  '2c8fa276-f3ea-4106-98cf-bda86ce7932f': 'Нейтраль 2 ШР',
  '248b034f-567c-4b4e-9fbf-ab79c209a409': 'Первичная обмотка емкостного ТН',
  'e44f6503-1905-4261-a3d1-63e977ba4828': 'Конденсатор связи',
  'c515569d-328e-4063-98c2-b40f1a1603a2': 'Ограничитель перенапряжения',
  '44706418-9f3a-4df4-b923-bbd9d5517ba1': 'Обмотка ВН',
  'ea86e035-ff74-45b0-ab3a-a419377c2451': 'Обмотка СН',
  '403be76c-008b-4031-917c-149001527c4f': 'Обмотка НН',
  '1fcfa1d2-e812-476d-aa1e-48d2e9ac79aa': 'РПН',
  'ca1c1363-f6ff-41b7-8b7b-d4bd85474702': 'Шунтирующий реактор',
  'e051a2cd-b3e1-4264-9ebd-afe55573d30d': 'Высоковольтный ввод ШР',
  'e9b2236f-5ad7-474c-ae2b-e77ab3b31f52': 'Нейтраль 1 ШР',
  'd36507e8-67e0-48b1-bb5f-e54dc32cfca4': 'Трансформатор тока',
  '32af7aac-d7cc-46e6-bdb1-ef62d2211ace': 'Трансформатор тока встроенный',
  'bf5be9bc-a3b6-4330-a364-1ca658d41dcc': 'Первичная обмотка ТТ',
  'b47b8cd2-7a27-4d81-b4eb-e61e6b77dac6': 'Первичная обмотка встроенного ТТ',
  '194e1acf-6c29-4a9d-a8cf-1677ce9b027f': 'Вторичная обмотка ТТ',
  'ecefd017-fec0-4d21-ab83-2078689644ab': 'Вторичная обмотка встроенного ТТ',
  '84562c7f-af9d-4911-b80f-927ee58c2577': 'Емкостной трансформатор напряжения',
  '46106367-a179-4635-b8d5-ceb572ae8974': 'Электромагнитный трансформатор напряжения',
  'f71db015-0f17-4497-bc10-f64fc5bf3b35': 'Первичная обмотка э/м ТН',
  'b5faf9ea-a0a1-471f-a733-6f5764f72676': 'Вторичная обмотка емкостного ТН',
  'e4b20fe1-7d62-4171-8987-9b3693b8f481': 'Вторичная обмотка э/м ТН',
  'f92a596e-fb93-4944-bd78-4d4456ba78b0': 'Автотрансформатор',
};
