import { INode } from '@/store/equipment/types';
import { defineTopSlot } from '@/components/details-manager/types';

export const hideOffServiceItems = (data: any): any => {
  const { children, inService } = data;
  if (!inService) {
    return;
  }
  if (children?.length) {
    const c = [];
    for (const child of children) {
      const res = hideOffServiceItems(child);
      if (res) {
        c.push(res);
      }
    }
    data.children = c;
  }
  return data;
};

export const updateStates = (nodes: any[], statesMap: any) => {
  if (!Array.isArray(nodes) || !nodes?.length || !statesMap) return;
  nodes.forEach((i) => {
    const st = statesMap[i.uid];
    if (st) {
      const { state, quality } = i.state;
      if (st.state !== state || st.quality !== quality) {
        i.state = { state: st.state, quality: st.quality };
      }
    }
    updateStates(i.children, statesMap);
  });
};

export const updateStatesAsync = async (nodes: any[], statesMap: any): Promise<boolean> => {
  return await new Promise<boolean>((resolve) => {
    try {
      updateStates(nodes, statesMap);
      resolve(true);
    } catch (e) {
      resolve(false);
    }
  });
};

export const injectStates = (nodes: any[], statesMap: any) => {
  return nodes.map((i: any) => {
    if (!statesMap) {
      i.state = { quality: 1 };
    } else {
      const st = statesMap[i.uid];
      if (!st) {
        i.state = { quality: 1 };
      } else {
        i.state = { ...st };
      }
    }
    i.children = [...injectStates(i.children, statesMap)];
    return i;
  });
};

export const transformStates = (payload: any[]) => {
  const r: Record<string, any> = {};

  payload.forEach((data) => {
    if (!data) return null;

    const { equipment_uuid, quality, state } = data;

    if (!equipment_uuid || !quality || !state) {
      return null;
    }

    const equip = equipment_uuid.split(';');

    if (!Array.isArray(equip) || equip?.length <= 0) {
      return null;
    }

    equip?.forEach((u: any, i: any) => {
      r[u] = { quality: quality[i], state: state[i] };
    });
  });

  return r;
};

export const transformTree = (items: any[], level: number): INode[] => {
  items?.forEach((x: any) => {
    x.level = level;
    x.topSlot = defineTopSlot(x);
    transformTree(x.children, level + 1);
  });
  return items;
};
