

















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import WindingLabel from '@/components/basics/WindingLabel.vue';
import { Action, Getter } from 'vuex-class';
import { CHART_PROPS, GET_CHARTS_PROPS } from '@/store/abstract/props-scheme/constants';
import {
  EXTRA_CALC_DATA,
  HISTORY_CHARTS,
  HISTORY_CHARTS_DATA,
} from '@/store/history-charts/constants';
import { IChartProperty, IChartPropsQuery } from '@/store/abstract/props-scheme/types';
import { PhaseConverter } from '@/utils/PhaseConverter';
import { IHistoryChartsQuery, IMaxAvgMin, ISeries } from '@/store/history-charts/types';
import LegendItem from '@/components/basics/charts/history-chart/legend-item/LegendItem.vue';
import { getAreas } from '@/store/history-charts/extensions';
import { DETAILS_CONST } from '@/store/details';
import { IEquipDetails } from '@/store/details/types';
import { resetTimeOffset } from '@/utils/datetime/DateTimeCorrector';
import { Subject } from 'rxjs';

@Component({
  components: {
    'winding-label': WindingLabel,
    'legend-item': LegendItem,
  },
})
export default class DynamicChartLegend extends Vue {
  @Prop() query?: any;
  @Action(GET_CHARTS_PROPS, { namespace: HISTORY_CHARTS })
  getChartsProps!: (query: IChartPropsQuery) => void;
  @Getter(CHART_PROPS, { namespace: HISTORY_CHARTS })
  chartProps?: IChartProperty[];
  @Getter(HISTORY_CHARTS_DATA, { namespace: HISTORY_CHARTS })
  chartData?: Map<ISeries, number[]>;
  @Getter(EXTRA_CALC_DATA, { namespace: HISTORY_CHARTS })
  mam?: Map<string, IMaxAvgMin>;

  refreshQuery: Subject<any> = new Subject<any>();

  refreshAllSelectors(query: IHistoryChartsQuery) {
    this.refreshQuery.next(query);
  }

  maxAvgMin(key: string) {
    const x = this.mam?.get(key);
    return x ?? {};
  }

  get areas() {
    let _areas;
    if (this.chartData) {
      _areas = getAreas([...this.chartData?.keys()]);
    }
    return _areas ?? [];
  }

  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  $equipmentDetails!: IEquipDetails;

  get equipDetails(): IEquipDetails | null {
    return this.$equipmentDetails ?? null;
  }

  get typeId(): number {
    return this.$equipmentDetails?.typeId ?? -1;
  }

  get typeUid(): string {
    return this.$equipmentDetails?.typeUid ?? '';
  }

  get propsQuery(): IChartPropsQuery | null {
    const { id } = this.$route?.params;
    if (!id || isNaN(Number(id))) return null;
    const { phase } = this.$route?.query;
    if (!phase) return null;
    const _phase = PhaseConverter.getPhase(`${phase}`);
    if (!_phase) return null;
    return { equipId: Number(id), phase: _phase, typeId: this.typeId, typeUid: this.typeUid };
  }

  mounted(): void {
    if (this.propsQuery) {
      this.getChartsProps(this.propsQuery);
    }
  }
}
