import { Module } from 'vuex';
import {
  IBookmark,
  IBookmarkNode,
  IBookmarksQuery,
  IBookmarksState,
} from '@/store/bookmarks/types';
import { RootState } from '@/store/types';
import {
  ADD_TREE_NODE,
  BOOKMARKS_TREE,
  DELETE_NODE,
  GENERATE_FOLDER,
  GENERATE_LINK,
  LOAD_BOOKMARKS_TREE,
  SAVE_BOOKMARKS_TREE,
  BOOKMARKS_QUERY,
  UPDATE_NODE,
} from '@/store/bookmarks/constants';
import { BookmarksDto } from '@/store/bookmarks/bookmarks-dto';
import { addTreeNode, deleteNode, updateTree } from '@/store/bookmarks/extensions';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { RestApiService } from '@/services';
const userBookmarksPath = getApiUrl(TR_TYPE.HTTP, '/user-bookmarks');
const rest = new RestApiService();

const defaultState: IBookmarksState = {
  error: false,
  bookmarksTree: [],
  bookmarksQuery: { userId: undefined },
};

export const bookmarks: Module<IBookmarksState, RootState> = {
  state: defaultState,
  namespaced: true,
  actions: {
    [GENERATE_LINK]: (ctx, { book, query }: { book: IBookmark; query: any }) => {
      try {
        const url =
          book.url +
          '?' +
          Object.entries(query)
            .map(([k, v]) => `${k}=${v}`)
            .filter((j) => !j.includes('start') && !j.includes('end'))
            .join('&');
        book = { ...book, url };
        let bookmark: IBookmark | null = null;
        bookmark = BookmarksDto.GenerateLink(book);
        ctx.dispatch(ADD_TREE_NODE, bookmark);
      } catch (e) {
        console.log(e.message);
      }
    },
    [GENERATE_FOLDER]: (ctx, { book }: { book: IBookmark }) => {
      try {
        let bookmark: IBookmark | null = null;
        bookmark = BookmarksDto.GenerateFolder(book);
        ctx.dispatch(ADD_TREE_NODE, bookmark);
      } catch (e) {
        console.log(e.message);
      }
    },
    [ADD_TREE_NODE]: (ctx, payload: IBookmark) => {
      try {
        const bookmarksTree = ctx.getters[BOOKMARKS_TREE];
        if (!bookmarksTree) return;
        const tree = addTreeNode(bookmarksTree, payload);
        ctx.commit(BOOKMARKS_TREE, tree);
        ctx.dispatch(SAVE_BOOKMARKS_TREE);
      } catch (e) {
        console.log(e.message);
      }
    },
    [DELETE_NODE]: (ctx, { uuid }: { uuid: string }) => {
      try {
        const bookmarksTree = ctx.getters[BOOKMARKS_TREE] as IBookmarkNode[];
        if (!bookmarksTree) return;
        ctx.commit(
          BOOKMARKS_TREE,
          bookmarksTree.filter((n) => deleteNode(n, { uuid } as IBookmarkNode))
        );
        ctx.dispatch(SAVE_BOOKMARKS_TREE);
      } catch (e) {
        console.log(e.message);
      }
    },
    [UPDATE_NODE]: (ctx, { node, alias }: { node: IBookmarkNode; alias: string }) => {
      try {
        const bookmarksTree = ctx.getters[BOOKMARKS_TREE];
        if (!bookmarksTree) return;
        node = { ...node, alias };
        const uTree = updateTree(node, bookmarksTree);
        ctx.commit(BOOKMARKS_TREE, uTree);
        ctx.dispatch(SAVE_BOOKMARKS_TREE);
      } catch (e) {
        console.log(`${UPDATE_NODE} err:::`, e.message);
      }
    },
    [BOOKMARKS_QUERY]: (ctx, payload: IBookmarksQuery) => {
      try {
        ctx.commit(BOOKMARKS_QUERY, payload);
        const { userId } = ctx.getters[BOOKMARKS_QUERY] as IBookmarksQuery;
        if (!userId) return;
        ctx.dispatch(LOAD_BOOKMARKS_TREE, userId);
      } catch (e) {
        ctx.commit(BOOKMARKS_QUERY, {});
        console.log(`${BOOKMARKS_QUERY} ERR:::`, e.message);
      }
    },
    [BOOKMARKS_TREE]: (ctx, payload) => {
      try {
        ctx.commit(BOOKMARKS_TREE, payload);
      } catch (e) {
        ctx.commit(BOOKMARKS_TREE, e);
      }
    },
    [SAVE_BOOKMARKS_TREE]: async (ctx) => {
      try {
        const bookmarksTree = ctx.getters[BOOKMARKS_TREE];
        const { userId } = ctx.getters[BOOKMARKS_QUERY] as IBookmarksQuery;
        const response = await rest.post(userBookmarksPath + `/${userId}`, {
          json: JSON.stringify(bookmarksTree),
        });
      } catch (e) {
        console.log(`${SAVE_BOOKMARKS_TREE} err:::`, e.message);
      }
    },
    [LOAD_BOOKMARKS_TREE]: async (ctx, userId: number) => {
      try {
        const response = await rest.get(userBookmarksPath + `/${userId}`);
        const tree = JSON.parse(response.data[0].content);
        ctx.commit(BOOKMARKS_TREE, tree);
      } catch (e) {
        console.log('LOAD_BOOKMARKS_TREE err:', e.message);
      }
    },
  },
  mutations: {
    [BOOKMARKS_QUERY]: (state, payload: IBookmarksQuery) => {
      state.bookmarksQuery = payload;
    },
    [BOOKMARKS_TREE]: (state, payload?: IBookmarkNode[] | Error) => {
      if (payload instanceof Error) {
        state.error = payload as Error;
        return;
      }
      state.bookmarksTree = [...(payload as IBookmarkNode[])];
    },
  },
  getters: {
    [BOOKMARKS_QUERY]: ({ bookmarksQuery }) => bookmarksQuery,
    [BOOKMARKS_TREE]: ({ bookmarksTree }) => bookmarksTree,
  },
};
