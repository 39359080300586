import moment from 'moment';
import { MOMENT_QUERY_FORMAT } from '@/utils/datetime/DateTimeRangePresets';

export const slideRange = (direction: string, start: string, end: string, lag = 0.5) => {
  const sDate = new Date(start);
  const eDate = new Date(end);
  const range = Math.abs(sDate.getTime() - eDate.getTime());
  const laggedRange = range * lag;
  let dir = 0;
  if (direction === 'right') {
    dir = 1;
  }
  if (direction === 'left') {
    dir = -1;
  }
  const nsDate = new Date(sDate.getTime() + dir * laggedRange);
  const neDate = new Date(eDate.getTime() + dir * laggedRange);
  return [
    moment(nsDate).format(MOMENT_QUERY_FORMAT.dateToSec),
    moment(neDate).format(MOMENT_QUERY_FORMAT.dateToSec),
  ];
};

export const updateTimeOffset = (date: Date): Date => {
  const offset = date.getTimezoneOffset();
  return new Date(date.getTime() - offset * 60 * 1000);
};
export const updateTimeOffsetMinCeil = (date: Date): Date => {
  const dt = updateTimeOffset(date);
  if (date.getSeconds() === 0) return dt;
  const minutes = dt.getMinutes();
  return new Date(dt.setMinutes(minutes + 1));
};

export const resetTimeOffset = (date: Date): Date => {
  const offset = date.getTimezoneOffset();
  return new Date(date.getTime() + offset * 60 * 1000);
};

export const roundHour = (date: Date): Date => {
  const minutes = date.getMinutes();
  let rDate: Date;
  if (minutes <= 0) {
    rDate = date;
  } else {
    // ceil hour round
    rDate = new Date(date.setHours(date.getHours() + 1));
  }
  const m = moment(rDate).format('YYYY-MM-DD HH');
  return new Date(`${m}:00:00`);
};

export const buildRange = (start: Date, secondsDuration: number): [Date, Date] => {
  const sr = roundHour(start);
  const e = new Date(sr.getTime() + secondsDuration * 1000);
  return [sr, e];
};

export const buildDate = (date: string | Date, hour = 0, minute = 0, second = 0, ms = 0) => {
  const d = new Date(date);
  const h = new Date(d.setHours(d.getHours() + hour));
  const m = new Date(h.setMinutes(h.getMinutes() + minute));
  const s = new Date(m.setSeconds(m.getSeconds() + second));
  return resetTimeOffset(new Date(s.setMilliseconds(m.getMilliseconds() + ms)));
};
