const prefix = 'abstract/dirty/';
// getters
export const IS_DIRTY = `${prefix}isDirty`;
export const DIRTY_ITEMS = `${prefix}dirtyItems`;
export const CLEAR_ITEMS = `${prefix}clearItems`;

// actions + mutations
export const UPDATE_DIRTY = `${prefix}updateDirty`;
export const ADD_DIRTY_ITEM = `${prefix}addDirtyItem`;
export const REMOVE_DIRTY_ITEM = `${prefix}removeDirtyItem`;
export const SET_CLEAR_ITEMS = `${prefix}setClearItems`;
export const RESET_DIRTY = `${prefix}resetDirty`;
// action fail
export const DIRTY_FAIL = `${prefix}dirtyFail`;
// success
export const DIRTY_SUCCESS = `${prefix}dirtySuccess`;
