import { Module } from 'vuex';
import {
  RegInfoItem,
  RegInfoQuery,
  RegInfoState,
  RegInfoTab,
} from '@/store/reg-info/reg-info.types';
import { RootState } from '@/store/types';
import { ASSET_PROPS, PROP_KEY_ASSET_ID, REG_INFO_CONST } from '@/store/reg-info/reg-info.const';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { RestApiService } from '@/services';
import { RegInfoMapper } from '@/store/reg-info/reg-info.mapper';
import { configUpdater, configUpdateInitial } from '../abstract/config-updater';
import { DataConverter } from '@/components/basics/table/converters/DataConverter';
import { getAssetProps } from '@/components/reg-info/extensions';

const path = getApiUrl(TR_TYPE.HTTP, '/reg-info');
const rest = new RestApiService();

const regInfoInitialSate: RegInfoState = {
  tabs: [],
  tabsError: false,
  propKeysAssetIds: {},
  assetProps: {},
  ...configUpdateInitial,
};

const {
  REG_INFO,
  EQUIP_TYPE_UUID,
  REG_INFO_TABS,
  GET_REG_INFO_TABS,
  GET_REG_INFO_TABS_ERROR,
  GET_REG_INFO_TABS_SUCCESS,
  CLEAR_REG_INFO_TABS,
} = REG_INFO_CONST;

export const regInfo: Module<RegInfoState, RootState> = {
  state: regInfoInitialSate,
  namespaced: true,
  actions: {
    ...configUpdater.actions,
    [PROP_KEY_ASSET_ID]: async (context, payload: RegInfoTab[]) => {
      try {
        if (!payload?.length) return;
        const map = payload
          .flatMap((i) => {
            return i.items?.map((p: RegInfoItem) => ({ key: p.key, value: i.assetUid }));
          })
          .reduce((acc: any, i) => {
            if (acc == null) acc = new Map();
            if (i) {
              acc.set(i.key, i.value);
            }
            return acc;
          }, null);
        context.commit(PROP_KEY_ASSET_ID, Object.fromEntries(map));
      } catch (e) {
        console.log(e.message);
      }
    },
    [GET_REG_INFO_TABS]: async (context, payload: RegInfoQuery) => {
      try {
        const response = await rest.get(
          `${path}?equipId=${payload.equipId}&phase=${payload.phase}`
        );
        context.commit(ASSET_PROPS, await getAssetProps(response.data));
        const ri = RegInfoMapper.transform(response?.data);
        if (!ri?.length) {
          context.commit(GET_REG_INFO_TABS_ERROR, new Error('Empty data context!'));
          return;
        }
        await context.dispatch(PROP_KEY_ASSET_ID, ri);
        context.commit(GET_REG_INFO_TABS_SUCCESS, ri);
      } catch (e) {
        context.commit(GET_REG_INFO_TABS_ERROR, e);
      }
    },
  },
  mutations: {
    ...configUpdater.mutations,
    [ASSET_PROPS]: (state, payload) => {
      state.assetProps = payload;
      DataConverter.createWindingConverter(state.assetProps);
    },
    [PROP_KEY_ASSET_ID]: (state, payload) => {
      state.propKeysAssetIds = payload;
    },
    [GET_REG_INFO_TABS_SUCCESS]: (state, payload) => {
      state.tabs = payload;
      state.tabsError = false;
    },
    [GET_REG_INFO_TABS_ERROR]: (state, payload) => {
      state.tabs = [];
      state.tabsError = payload;
    },
    [CLEAR_REG_INFO_TABS]: (state) => {
      state.tabs = [];
    },
  },
  getters: {
    ...configUpdater.getters,
    [REG_INFO_TABS]: ({ tabs }) => tabs,
    [ASSET_PROPS]: ({ assetProps }) => assetProps,
    [EQUIP_TYPE_UUID]: ({ tabs }) => {
      if (!tabs?.length) return null;
      return tabs[0].typeUid;
    },
    [PROP_KEY_ASSET_ID]: ({ propKeysAssetIds }) => propKeysAssetIds,
  },
};
