import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { IAlgorithmState } from '@/store/algorithms/algorithms.types';
import { abstractAlgorithm, abstractAlgorithmInitialState } from '@/store/abstract/algorithm-data';

export const ALGORITHM_CONST = {
  UPDATE_TABLE_NAME: 'updateTableName',
  TABLE_NAME: 'tableName',
  TABLE_NAME_UPDATED: 'tableNameUpdated',
  TABLE_NAME_ERROR: 'tableNameError',
  ALGORITHMS: 'algorithms'
};

const {
  UPDATE_TABLE_NAME,
  TABLE_NAME,
  TABLE_NAME_UPDATED,
  TABLE_NAME_ERROR,
  ALGORITHMS,
} = ALGORITHM_CONST;

const initialState: IAlgorithmState = {
  type: 'measurements',
  dbTableName: '',
  ...abstractAlgorithmInitialState
}

export const algorithms: Module<IAlgorithmState, RootState> = {
  namespaced: true,
  state: initialState,
  actions: {
    ...abstractAlgorithm.actions,
    [UPDATE_TABLE_NAME]: (context, payload) => {
      if (payload) context.commit(TABLE_NAME_UPDATED, payload);
      else context.commit(TABLE_NAME_ERROR);
    },
  },
  mutations: {
    ...abstractAlgorithm.mutations,
    [TABLE_NAME_UPDATED]: (state, payload: string) => {
      state.dbTableName = payload;
    },
    [TABLE_NAME_ERROR]: (state) => {
      state.dbTableName = '';
    },
  },
  getters: {
    ...abstractAlgorithm.getters,
    [TABLE_NAME]: ({ dbTableName }) => dbTableName,
  },
};
