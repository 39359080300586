import { ColType } from '@/components/basics/table/basic-table.types';
import { DateTimeConverter } from '@/components/basics/table/converters/DateTimeConverter';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';
import { EnumConverter } from '@/components/basics/table/converters/EnumConverter';
import { FloatConverter } from '@/components/basics/table/converters/FloatConverter';
import { IntegerConverter } from '@/components/basics/table/converters/IntegerConverter';
import { GuidConverter } from '@/components/basics/table/converters/GuidConverter';
import { AssetConverter } from '@/components/basics/table/converters/AssetConverter';
import { WindingConverter } from '@/components/basics/table/converters/WIndingConverter';
import { StateEnumConverter } from '@/components/basics/table/converters/StateEnumConverter';
import { NumberConverter } from '@/components/basics/table/converters/NumberConverter';

export class DataConverter {
  // converters
  private static _dateTimeConverter = new DateTimeConverter();
  private static _enumConverter: EnumConverter;
  private static _integerConverter = new IntegerConverter();
  private static _floatConverter = new FloatConverter();
  private static _guidConverter = new GuidConverter();
  private static _stateEnumConverter = new StateEnumConverter();
  private static _assetConverter: AssetConverter;
  private static _windingConverter: WindingConverter;
  private static _numberConverter: NumberConverter = new NumberConverter();
  // factory
  public static createEnumConverter(enums: ITabRef[]) {
    this._enumConverter = new EnumConverter(enums);
  }
  public static createAssetConverter(equipment: any[]) {
    this._assetConverter = new AssetConverter(equipment);
  }
  public static createWindingConverter(assetProps: any) {
    this._windingConverter = new WindingConverter(assetProps);
  }

  private static __converters = {
    [ColType.DateTime]: (val: any, opts: any) =>
      DataConverter._dateTimeConverter.convert(val, opts),
    [ColType.DateTimes]: (val: any, opts: any) =>
      val?.map((x: any) => DataConverter._dateTimeConverter.convert(x, opts)),
    [ColType.Enum]: (val: any, opts: any) => DataConverter._enumConverter.convert(val, opts),
    [ColType.Integer]: (val: any, opts: any) => DataConverter._integerConverter.convert(val, opts),
    [ColType.Float]: (val: any, opts: any) => DataConverter._floatConverter.convert(val, opts),
    [ColType.String]: (value: any) => value,
    [ColType.string]: (value: any) => value,
    [ColType.Guid]: (val: any, opts: any) => DataConverter._guidConverter.convert(val, opts),
    [ColType.Asset]: (val: any, opts: any) => DataConverter._assetConverter.convert(val, opts),
    [ColType.Winding]: (val: any, opts: any) => DataConverter._windingConverter.convert(val, opts),
    [ColType.StateEnum]: (val: any, opts: any) =>
      DataConverter._stateEnumConverter.convert(val, opts),
    [ColType.StateEnums]: (val: any, opts: any) =>
      val?.map((x: any) => DataConverter._stateEnumConverter.convert(x, opts)),
  };
  public static convert(value: any, col: any) {
    const { Type } = col;
    const type = Type as ColType;
    try {
      return DataConverter.__converters[type](value, col);
    } catch {
      return value;
    }
  }
}
