export class PhaseConverter {
  public static getPhase(label: string): number | null {
    if (label === 'A' || label === 'a') return 32;
    if (label === 'B' || label === 'b') return 64;
    if (label === 'C' || label === 'c') return 128;
    return null;
  }
  public static getLabel(value: number): string | null {
    if (value === 32) return 'A';
    if (value === 64) return 'B';
    if (value === 128) return 'C';
    return null;
  }
}
