import { IDataConverter } from '@/components/basics/table/converters/types';

export class AssetConverter implements IDataConverter {
  private __equipment!: any[];
  constructor(equipment: any[]) {
    this.__equipment = equipment;
  }
  convert(value: any, opts: any): any {
    const find = this.__equipment?.find((e) => `${e.asset_uuid}` === `${value}`);
    return find?.name ?? value;
  }
}
