import { IUsageConfigItem, IUsageMessage } from '@/store/usage-info';
import { IUsageChartSettings, UsageItemType } from '@/services';

export const getScale = (config: IUsageConfigItem[], key: string) => {
  if (!config?.length) return 1;
  const scale = config.find((c) => c.name === key)?.scale ?? 1;
  return Number(scale);
};

export const getLimit = (config: IUsageConfigItem[], data: IUsageMessage, key: string) => {
  const scale = config.find((c) => c.name === key)?.scale ?? 1;
  if (!data) return 1;
  const value = (data as any)[key] ?? 1;
  return Number(value) * Number(scale);
};

export const getSome = (config: IUsageConfigItem[], name: string, key: string) => {
  const prop = config.find((c) => c.name === name);
  if (!prop) return '';
  return (prop as any)[key];
};

export const buildBasicChartMap = (
  config: IUsageConfigItem[],
  data: IUsageMessage
): IUsageChartSettings[] => {
  return [
    {
      type: UsageItemType.CPU,
      limit: 100,
      title: getSome(config, 'cpuPercents', 'notes'),
      unit: getSome(config, 'cpuPercents', 'unit'),
      scale: getScale(config, ''),
    },
    {
      type: UsageItemType.HARD_DRIVE,
      limit: getLimit(config, data, 'diskTotal'),
      title: getSome(config, 'diskUsed', 'notes'),
      unit: getSome(config, 'diskUsed', 'unit'),
      scale: getScale(config, 'diskTotal'),
    },
    {
      type: UsageItemType.MEMORY,
      limit: getLimit(config, data, 'memTotal'),
      title: getSome(config, 'memUsed', 'notes'),
      unit: getSome(config, 'memUsed', 'unit'),
      scale: getScale(config, 'memTotal'),
    },
  ];
};
