
export type TopSlotType = 'report' | 'equipment'

// todo uid + hard code
export const defineTopSlot = ({type_uid}: any): TopSlotType => {
  // substation
  if(type_uid ===  '1000134e-0000-0000-c000-0000006d746c')
    return 'report';
  return 'equipment';
}

