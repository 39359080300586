const RANGE_GETTER_PREFIX = 'rangeGetter';

// external change
export const GET_DATETIME_RANGE = `${RANGE_GETTER_PREFIX}getDatetimeRange`;
export const GET_DATETIME_RANGE_FAIL = `${RANGE_GETTER_PREFIX}getDatetimeRangeFail`;

//internal change
export const UPDATE_DATETIME_RANGE = `${RANGE_GETTER_PREFIX}updateDatetimeRange`;
export const UPDATE_DATETIME_RANGE_FAIL = `${RANGE_GETTER_PREFIX}updateDatetimeRangeFail`;

//getter
export const DATETIME_RANGE = `${RANGE_GETTER_PREFIX}datetimeRange`;
export const DATETIME_RANGE_ERROR = `${RANGE_GETTER_PREFIX}datetimeRangeError`;
