import { IHistoryDataSet, ISeries } from '@/store/history-charts/types';

export function updateMap<T>(key: string, map: Map<string, T>, item: T): Map<string, T> {
  let v = map.get(key);
  if (!v) {
    map.set(key, item);
  } else {
    v = item;
  }
  return map;
}

export function createMap<T>(): Map<string, T> {
  return new Map<string, T>();
}

export function getByKey<T>(key: string, map: Map<string, T>): T | undefined {
  return map.get(key);
}

export function setData<T>(
  key: string,
  map: Map<string, T>,
  data: number[] | string[] | undefined
): number[] | string[] | undefined {
  const itm = getByKey<T>(key, map);
  if (itm) {
    (itm as any).data = data;
    return data;
  }
}
