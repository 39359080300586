import { AlgorithmScheme, IChartQueryBase } from '@/store/algorithms/algorithms.types';

export default class AlgorithmChartsExt {
  private static checkPhase(col: any, phaseLabel?: string): boolean {
    const colValue = col?.Value;
    if (col?.Flags && !isNaN(col.Flags) && col.Flags < 32) return true;
    const nSplit = colValue?.split('_');
    if (!nSplit?.length) return false;
    const phase = nSplit[nSplit.length - 1].toUpperCase();
    return phase === phaseLabel;
  }

  // TODO KILL HARD CODE
  private static hardCodeCalculatorName(col: any): string {
    if (col?.Flags && !isNaN(col.Flags) && col.Flags < 32) return '';
    return 'voltageparams1';
  }

  public static seriesName: string;
  private static cn = 'calculator_name';
  public static chartBaseQuery(scheme?: AlgorithmScheme): IChartQueryBase[] | undefined {
    const queryBase: IChartQueryBase[] = [];
    AlgorithmChartsExt.seriesName = 'Нет данных.';
    if (!scheme?.columns?.length || !scheme?.phaseValue || isNaN(scheme?.phaseValue)) return;
    const tableNames = scheme?.columns?.filter((c: any) => c.order === 0 && c.Flags === 0);
    if (!tableNames?.length) return;
    for (const tableName of tableNames) {
      // "charttable_1"

      const name = tableName.key;
      const cSplit = name?.toString().split('_');
      if (!cSplit?.length) continue;
      const chartNumber = Number(cSplit[1]);
      if (isNaN(chartNumber)) continue;
      scheme?.columns?.forEach((c: any) => {
        if (c.order > 0) return;
        if (!AlgorithmChartsExt.checkPhase(c, scheme.phaseLabel)) {
          return;
        }
        // "chartcolum_1_1"
        AlgorithmChartsExt.seriesName = `${c.notes ?? ''}, ${c.unit ?? ''}`;
        const lSplit = c.key.split('_');
        if (lSplit?.length !== 3) return;
        const clNumber = Number(lSplit[1]);
        if (isNaN(clNumber) || clNumber !== chartNumber) return;
        const levelNumber = lSplit[2];
        if (isNaN(levelNumber)) return;
        const qb: IChartQueryBase = {
          select: c.Value,
          algorithm: (tableName as any).Value,
          [AlgorithmChartsExt.cn]: AlgorithmChartsExt.hardCodeCalculatorName(c),
        };
        queryBase.push(qb);
      });
    }
    return queryBase;
  }

  public static getLevelsRef(scheme?: AlgorithmScheme) {
    if (!scheme?.columns?.length) return;
    return scheme?.columns.filter((c) => c.level);
  }
}
