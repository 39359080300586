import { IEquipDetails } from '@/store/details/types';
import { CHART_LEVELS_MAX_NOMINAL_U_KV } from '@/components/basics/charts/levels/levels-chart.consts';
import { AlgorithmScheme } from '@/store/algorithms/algorithms.types';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';
import { ChartDataItem } from './levels-chart.types';
import { IChartProperty } from '@/store/abstract/props-scheme/types';

export function getMaxUValueFromDetails(equipmentDetails: IEquipDetails): number {
  const element: any = (equipmentDetails.make[0] as any)?.props?.find(
    (el: any) => el.Name === 'MaxU'
  );
  const value = element?.Value?.toString();

  return parseInt(String(value ?? CHART_LEVELS_MAX_NOMINAL_U_KV), 10);
}

/**
 * Возвращает уровень по формуле
 * @param maxU
 */
export function calculateNorm(maxU: number): number {
  if (maxU > 10000) {
    const maxUkV = maxU / 1000;
    return maxUkV / Math.sqrt(3);
  }
  return maxU / Math.sqrt(3);
}

export const scaleChartData = (data: ChartDataItem[], config: IChartProperty | undefined) => {
  console.log(data, config);
  if(!config) return data;
  const sc = Number(config.scale);
  return data.map(d => ({ x: d.x, y: d.y * sc }));
};

export const getEnumOrder = (
  scheme: AlgorithmScheme,
  refs: ITabRef[],
  name: string,
  value: string
): number | null => {
  if (!scheme?.columns?.length) return null;
  if (!refs?.length) return null;
  const column = scheme.columns.find((c) => c.key === name);
  if (!column) return null;
  const enums = refs.filter((i) => i.enumUid === (column as any).Uid);
  if (!enums?.length) return null;
  const result = enums.find((i) => i.notes === value);
  if (!result) return null;
  return result.order;
};
