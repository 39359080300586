import { IDataConverter } from '@/components/basics/table/converters/types';

export class StateEnumConverter implements IDataConverter {
  convert(value: any, opts: any): any {
    const num = Number(value);

    if (value === undefined || value === null || isNaN(num)) {
      return '';
    }
    const prc = num * 100;
    return prc === 0 ? 'Норма' : prc === 100 ? 'Отклонение' : `Внимание ${prc.toFixed(2)}%`;
  }
}
