import cron, { ScheduledTask } from 'node-cron';
import { Subject } from 'rxjs';

/*
 # ┌────────────── second (optional)
 # │ ┌──────────── minute
 # │ │ ┌────────── hour
 # │ │ │ ┌──────── day of month
 # │ │ │ │ ┌────── month
 # │ │ │ │ │ ┌──── day of week
 # │ │ │ │ │ │
 # │ │ │ │ │ │
 # * * * * * *
*/

export enum CronPresets {
  EveryTwoSeconds = '*/2 * * * * *',
  EveryFiveSeconds = '*/5 * * * * *',
  EverySecond = '* * * * * *',
  EveryMinute = '* * * * *',
  EveryHour = '* * * *',
}

export class CronManager {
  private task?: ScheduledTask;
  private subject?: Subject<Date>;
  constructor() {
    this.subject = new Subject<Date>();
  }
  removeSchedule() {
    try {
      this.subject?.unsubscribe();
      this.task?.stop();
      this.task?.destroy();
    } catch (e) {
      console.log(e.message);
    }
  }

  public createSchedule(scheduleExpression: string): Subject<Date> {
    this.subject = new Subject<Date>();
    this.task = cron.schedule(scheduleExpression, () => {
      try {
        this.subject?.next(new Date());
      } catch (e) {
        console.log(e.message);
      }
    }) as ScheduledTask;
    return this.subject;
  }
}
