import {
  AbstractState,
  IAbstractAlgorithmState,
  IAbstractQuery,
} from '@/store/abstract/algorithm-data/types';
import { TableCol } from '@/components/basics/table/basic-table.types';

export interface IChartQueryBase {
  select: string;
  algorithm: string;
  calculator_name?: string;
}

export interface IAlgorithmQuery extends IAbstractQuery {
  // ++
  uuids?: string;
  isState?: boolean;
  isTopology?: boolean;
}

export const COMMON_QUERY_CONST = {
  ALGORITHM: 'algorithm',
  START: 'start',
  END: 'end',
  OFFSET: 'offset',
  LIMIT: 'limit',
  PHASE: 'phase',
  CALCULATOR_NAME: 'calculatorName',
  FILTER: 'filter',
  NO_SPINNER: 'noSpinner',
};

const { ALGORITHM, START, END, LIMIT, OFFSET, FILTER } = COMMON_QUERY_CONST;
export const commonQueryProps = [ALGORITHM, START, END, OFFSET, LIMIT, FILTER];

export type SortDirection = 'ASC' | 'DESC';
export type PropertyType = 'date' | 'number';
export interface ISort {
  dir: SortDirection;
  property: string;
  type: PropertyType;
}

export type ValueType = 'Enum' | 'Integer' | 'Float';

export interface AlgorithmScheme {
  moduleUid?: string;
  assetUid?: string;
  phaseValue?: number;
  phaseLabel?: string;
  columns: TableCol[];
  dbTableName: string;
  name: string;
  socket: string;
  sorter?: ISort;
  queryProps: string[];
  inputProps?: any[];
}

export type AType = 'chart' | 'measurements' | 'data' | undefined;

export interface IAlgorithmMessage {
  name: string;
}

export interface IAlgorithmState extends IAbstractAlgorithmState {
  dbTableName?: string;
  type?: AType;
}
