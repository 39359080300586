import { IPresetItem } from '@/store/abstract/presets-service/types';
import { LocalStorageManager } from '@/store/abstract/presets-service/local-storage-manager';
import { IChartProperty } from '@/store/abstract/props-scheme/types';
const LEGEND_ITEM_GROUP_KEY = 'legendItemGroup';

export const getSelectedProperty = async (
  key: string,
  props: IChartProperty[]
): Promise<string | null> => {
  const item = await LocalStorageManager.load({ key, groupKey: LEGEND_ITEM_GROUP_KEY });
  if (!item) {
    return null;
  }
  const property = props.find((i) => i.name === (item.value as string));
  return property?.name ?? null;
};
export const saveSelectedProperty = async ({ name }: IChartProperty, key: string) => {
  const preset: IPresetItem = {
    value: name,
    groupKey: LEGEND_ITEM_GROUP_KEY,
    key,
  };
 await LocalStorageManager.save(preset);
};
