import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';

import { AuthState, User, Roles } from './types';
import { CONST } from './constants';

export const getters: GetterTree<AuthState, RootState> = {
  [CONST.GET_TOKEN](state): string | null {
    const { token } = state;

    return token;
  },
  [CONST.GET_REFRESH_TOKEN](state): string | null {
    const { refreshToken } = state;

    return refreshToken;
  },
  [CONST.GET_USER](state): User | null {
    const { user } = state;

    return user;
  },
  [CONST.GET_ROLES](state): Roles {
    const { user } = state;

    const roles = (user?.roles ?? []).map((x) => x.role.rights).reduce((x, y) => x | y, 0);

    return {
      roleView: !!(roles & 0b1),
      roleEdit: !!(roles & 0b10),
      summary: !!(roles & 0b100),
      operational: !!(roles & 0b1000),
      history: !!(roles & 0b10000),
      normativeView: !!(roles & 0b100000),
      normativeEdit: !!(roles & 0b1000000),
      normativeSettingsEdit: !!(roles & 0b10000000),
      sensorsEdit: !!(roles & 0b100000000),
      logsView: !!(roles & 0b1000000000),
      logsEdit: !!(roles & 0b10000000000),
    };
  },
};
