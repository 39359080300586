import { Module } from 'vuex';
import {
  IExactStateQuery,
  IReportDataState,
  IReportQuery,
  IReportTab,
} from '@/store/report-data/types';
import { RootState } from '@/store/types';
import {
  EQUIPMENT_CHILDREN,
  EQUIPMENT_TYPES,
  SUBSTATION_EQUIPMENT,
  NODE_TYPE_MAP,
  REPORT_DATA_COLUMNS,
  REPORT_TABS,
  EQUIPMENT_TYPE_TABLE_CONFIG,
  ALL_EQUIPMENT_PHASES,
  EQUIPMENT_STATE_DATA,
  CLEAR_TAB_DATA,
  SELECTED_STATE_TAB,
  CALCULATOR_UUIDS,
  REPORT_TOPOLOGY_TAB,
  EXACT_EQUIP_STATE,
  EQUIPMENT_STATE,
} from '@/store/report-data/constants';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { RestApiService } from '@/services';
import {
  buildAlgoTab,
  buildTabs,
  getTypeMap,
  transformColumns,
} from '@/store/report-data/extensions';
import { QueryBuilder } from '@/utils/QueryBuilder';
import { DataConverter } from '@/components/basics/table/converters/DataConverter';

const path = getApiUrl(TR_TYPE.HTTP, '/report-data');
const path2 = getApiUrl(TR_TYPE.HTTP, '/algorithms-config');
const path3 = getApiUrl(TR_TYPE.HTTP, '/click-house');
const path4 = getApiUrl(TR_TYPE.HTTP, '/algorithms-config/tabs/topology');
const exactStatePath = getApiUrl(TR_TYPE.HTTP, '/click-house/last-value?');

const rest = new RestApiService();

const initialState: IReportDataState = {
  equipmentList: [],
  equipmentChildren: [],
  reportDataError: false,
  columns: [],
  allTypes: [],
  nodeTypeMap: {},
  reportTabs: [],
  typeTableConfig: [],
  allPhases: [],
  stateData: [],
  selectedStateTab: null,
  calculatorUuids: [],
};

export const reportData: Module<IReportDataState, RootState> = {
  namespaced: true,
  state: initialState,
  actions: {
    [REPORT_TOPOLOGY_TAB]: async (context, payload) => {
      try {
        //
      } catch (e) {
        //
      }
    },
    [CALCULATOR_UUIDS]: async (context, payload) => {
      try {
        const resp2 = await rest.get(path + `/calculator-names?ids=${payload?.join(';')}`);
        context.commit(CALCULATOR_UUIDS, resp2.data);
      } catch (e) {
        context.commit(CALCULATOR_UUIDS, []);
      }
    },
    [SELECTED_STATE_TAB]: (context, payload: IReportTab) => {
      try {
        context.commit(SELECTED_STATE_TAB, payload);
      } catch (e) {
        context.commit(SELECTED_STATE_TAB, null);
      }
    },
    [EXACT_EQUIP_STATE]: async (context, payload: IExactStateQuery) => {
      try {
        const url =
          exactStatePath +
          Object.entries(payload)
            .map(([k, v]) => `${k}=${v}`)
            .join('&');
        const resp = await rest.getParse(url);
        const eState = (resp as any)[0];
        context.commit(EXACT_EQUIP_STATE, eState);
      } catch (e) {
        console.log(e.message);
      }
    },
    [EQUIPMENT_STATE_DATA]: async (context, payload: IReportTab) => {
      try {
        const { uuids, ids } = payload;
        const { columns } = context?.state;
        const table = columns[0].tableName;
        const query = QueryBuilder.build({ uuids: uuids?.join(';'), table });
        await context.dispatch(CALCULATOR_UUIDS, ids);
        const resp = await rest.get(path3 + '/summary-state-data?' + query);
        context.commit(EQUIPMENT_STATE_DATA, resp.data);
      } catch (e) {
        context.commit(EQUIPMENT_STATE_DATA, []);
      }
    },
    [ALL_EQUIPMENT_PHASES]: async (context, payload) => {
      try {
        const phases = payload?.map((i: any) => Number(i));
        context.commit(ALL_EQUIPMENT_PHASES, phases);
      } catch (e) {
        context.commit(ALL_EQUIPMENT_PHASES, []);
      }
    },
    // todo narrowed query as means the same config for all three phases
    [EQUIPMENT_TYPE_TABLE_CONFIG]: async (context, payload) => {
      try {
        context.commit(REPORT_DATA_COLUMNS, []);
        const query = QueryBuilder.build(payload);
        const resp = await rest.get(path2 + '/equip-type-table-config?' + query);
        context.commit(EQUIPMENT_TYPE_TABLE_CONFIG, resp.data);
        const { typeTableConfig } = context.state;
        const columns = await transformColumns(typeTableConfig);
        context.commit(REPORT_DATA_COLUMNS, columns);
      } catch (e) {
        context.commit(EQUIPMENT_TYPE_TABLE_CONFIG, []);
      }
    },
    [EQUIPMENT_TYPES]: async (context) => {
      try {
        const resp = await rest.get(path + '/all-types');
        context.commit(EQUIPMENT_TYPES, resp.data);
      } catch (e) {
        context.commit(EQUIPMENT_TYPES, []);
      }
    },
    [EQUIPMENT_CHILDREN]: async (context, { uid, equipId }: IReportQuery) => {
      try {
        const resp = await rest.get(path + '/children' + `?uid=${uid}`);
        context.commit(EQUIPMENT_CHILDREN, resp.data);
        const { equipmentList, equipmentChildren, allTypes } = context.state;
        const tabs = await buildTabs(equipmentChildren, equipmentList, allTypes);
        // todo + topology tab
        const algorithm = 'breakerswitching';
        const tResp = await rest.get(`${path4}?equipId=${equipId}&algorithm=${algorithm}`);
        const topology = tResp?.data?.length ? tResp?.data[0] : null;
        const tSummary = topology
          ? [buildAlgoTab(topology, equipmentChildren, equipmentList, true), ...tabs]
          : [...tabs];

        context.commit(REPORT_TABS, tSummary);
      } catch (e) {
        await context.commit(EQUIPMENT_CHILDREN, []);
      }
    },
    [SUBSTATION_EQUIPMENT]: async (context, { uids }: any) => {
      try {
        await context.dispatch(EQUIPMENT_TYPES);
        const resp = await rest.post(path + '/equipment', { uids });
        context.commit(SUBSTATION_EQUIPMENT, resp.data);
        const { allTypes, equipmentList } = context.state;
        context.commit(NODE_TYPE_MAP, await getTypeMap(allTypes, equipmentList));
      } catch (e) {
        await context.commit(SUBSTATION_EQUIPMENT, []);
      }
    },
  },
  mutations: {
    [EXACT_EQUIP_STATE]: (state, payload) => {
      state.exactEquipState = { ...payload };
    },
    [CALCULATOR_UUIDS]: (state, payload) => {
      state.calculatorUuids = payload;
    },
    [SELECTED_STATE_TAB]: (state, payload) => {
      state.selectedStateTab = payload;
    },
    [EQUIPMENT_STATE_DATA]: (state, payload) => {
      state.stateData = payload;
    },
    [ALL_EQUIPMENT_PHASES]: (state, payload) => {
      state.allPhases = payload;
    },
    [EQUIPMENT_TYPE_TABLE_CONFIG]: (state, payload) => {
      state.typeTableConfig = payload;
    },
    [REPORT_TABS]: (state, payload) => {
      state.reportTabs = payload;
    },
    [NODE_TYPE_MAP]: (state, payload) => {
      state.nodeTypeMap = { ...payload };
    },
    [EQUIPMENT_TYPES]: (state, payload) => {
      state.allTypes = payload;
    },
    [EQUIPMENT_CHILDREN]: (state, payload) => {
      state.equipmentChildren = payload;
    },
    [SUBSTATION_EQUIPMENT]: (state, payload) => {
      DataConverter.createAssetConverter(payload);
      state.equipmentList = payload;
    },
    [REPORT_DATA_COLUMNS]: (state, payload) => {
      state.columns = payload;
    },
    [CLEAR_TAB_DATA]: (state) => {
      state.stateData = [];
      state.allPhases = [];
      state.columns = [];
      state.typeTableConfig = [];
    },
  },
  getters: {
    [EXACT_EQUIP_STATE]: ({ exactEquipState }) => exactEquipState,
    [CALCULATOR_UUIDS]: ({ calculatorUuids }) => calculatorUuids,
    [REPORT_DATA_COLUMNS]: ({ columns }) => columns,
    [SUBSTATION_EQUIPMENT]: ({ equipmentList }) => equipmentList,
    [EQUIPMENT_CHILDREN]: ({ equipmentChildren }) => equipmentChildren,
    [EQUIPMENT_TYPES]: ({ allTypes }) => allTypes,
    [NODE_TYPE_MAP]: ({ nodeTypeMap }) => nodeTypeMap,
    [REPORT_TABS]: ({ reportTabs }) => reportTabs,
    [EQUIPMENT_TYPE_TABLE_CONFIG]: ({ typeTableConfig }) => typeTableConfig,
    [ALL_EQUIPMENT_PHASES]: ({ allPhases }) => allPhases,
    [EQUIPMENT_STATE_DATA]: ({ stateData }) => stateData,
    [SELECTED_STATE_TAB]: ({ selectedStateTab }) => selectedStateTab,
  },
};
