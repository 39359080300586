import { Module } from 'vuex';
import { IAbstractAlgorithmState } from '@/store/abstract/algorithm-data/types';
import { RootState } from '@/store/types';
import { ALGORITHM_DATA_CONST } from '@/store/abstract/algorithm-data/constants';
import store from '@/store';
import { QueryBuilder } from '@/utils/QueryBuilder';
import { splitChunks } from '@/store/helpers';
import { logError } from '@/services/sentry.service';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { RestApiService } from '@/services';
import {
  Preloader,
  PRELOADER_DICT,
  preloaderActions,
  preloaderGetters,
  preloaderInitialState,
  preloaderMutations,
} from '@/store/abstract/preloader';
import { updateRow } from '@/store/abstract/algorithm-data/extension';
const rest = new RestApiService();

const path1 = getApiUrl(TR_TYPE.HTTP, '/click-house/paginator/totalCount?');
const path2 = getApiUrl(TR_TYPE.HTTP, '/click-house/paginator/selectedPage?');

export const abstractAlgorithmInitialState: IAbstractAlgorithmState = {
  lastUpdate: null,
  selectedPage: [],
  count: 0,
  ...preloaderInitialState,
};

export const abstractAlgorithm: Module<IAbstractAlgorithmState, RootState> = {
  state: abstractAlgorithmInitialState,
  actions: {
    ...preloaderActions,
    [ALGORITHM_DATA_CONST.RESET_DATA]: (context) => {
      try {
        context.commit(ALGORITHM_DATA_CONST.DATA_LOADED, { count: 0, data: 0 });
      } catch (e) {
        context.commit(ALGORITHM_DATA_CONST.DATA_ERROR, e);
      }
    },
    [ALGORITHM_DATA_CONST.UPDATE_STATE_DATA]: async (context, payload) => {
      try {
        const { selectedPage } = context.state;
        await updateRow(selectedPage, payload);
        context.commit(ALGORITHM_DATA_CONST.LAST_UPDATE, new Date());
      } catch (e) {
        // console.log('UPDATE_STATE_DATA:::', e.message);
      }
    },
    [ALGORITHM_DATA_CONST.LOAD_DATA]: async (context, payload) => {
      try {
        await Preloader.start(context, PRELOADER_DICT.DATA_LOADING);
        //cleanup page
        context.commit(ALGORITHM_DATA_CONST.DATA_LOADED, { count: 0, data: [] });
        const query = QueryBuilder.build({ ...payload });
        const [res1, res2] = await Promise.all([rest.get(path1 + query), rest.get(path2 + query)]);
        const [spl1, spl2] = [splitChunks(res1.data), splitChunks(res2.data)];
        if (spl1 && spl2 && spl1.length) {
          context.commit(ALGORITHM_DATA_CONST.DATA_LOADED, { count: spl1[0].total, data: spl2 });
        }
        if (!spl2?.length) {
          context.commit(ALGORITHM_DATA_CONST.DATA_ERROR, new Error('Empty data'));
        }
      } catch (e) {
        context.commit(ALGORITHM_DATA_CONST.DATA_ERROR, e);
      } finally {
        await Preloader.stop(context);
      }
    },
  },
  mutations: {
    ...preloaderMutations,
    [ALGORITHM_DATA_CONST.LAST_UPDATE]: (state, payload) => {
      state.lastUpdate = payload;
    },
    [ALGORITHM_DATA_CONST.DATA_LOADED]: async (state, payload) => {
      state.selectedPage = payload.data;
      state.count = payload.count;
    },
    [ALGORITHM_DATA_CONST.DATA_ERROR]: async (state, payload: Error) => {
      state.selectedPage = [];
      state.count = 0;
      state.pageError = payload;
    },
  },
  getters: {
    ...preloaderGetters,
    [ALGORITHM_DATA_CONST.LAST_UPDATE]: ({ lastUpdate }) => lastUpdate,
    [ALGORITHM_DATA_CONST.DATA]: ({ selectedPage, count }) => ({
      selectedPage,
      count,
    }),
  },
};
