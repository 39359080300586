export enum RangeTypes {
  Start = 'start',
  End = 'end',
}

export type RangeType = RangeTypes.Start | RangeTypes.End;

export const buildSharpRange = (ts = new Date(), delayMs = 1000): Record<RangeTypes, Date> => {
  const ndt = new Date(ts.getTime() + delayMs);
  const start = ts > ndt ? ndt : ts;
  const end = ts < ndt ? ndt : ts;
  return { [RangeTypes.Start]: start, [RangeTypes.End]: end };
};
