










































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  buildMatrixColumns,
  buildMatrixRows,
  buildX2Matrix,
} from '@/components/row-popup-matrix2/extensions';
import { Getter } from 'vuex-class';
import { PRELOADER_STATE } from '@/store/abstract/preloader';
import { POPUP_CHART_CONST } from '@/store/popup-charts/popup.charts.const';
import { IPreloader } from '@/store/abstract/preloader/types';
import { tableFilters } from '@/components/basics/table/filters';

@Component({
  filters:{
    ...tableFilters
  }
})
export default class RowPopupMatrix2 extends Vue {
  @Prop() title?: string;
  @Prop() data!: any[];
  @Prop() names!: any[] | null;
  @Prop() selectedRow?: any;

  @Getter(PRELOADER_STATE, { namespace: POPUP_CHART_CONST.POPUP_CHART })
  preloader!: IPreloader;

  baseCellStyle = { padding: '0', height: '20px', textAlign: 'center' };
  valueCellStyle({ row, column, rowIndex, columnIndex }: any) {
    const cellValue = row[column?.label];
    if (columnIndex > 0 && cellValue && cellValue !== '-')
      return { background: '#f6f8ff', color: '#2E5BFF', ...this.baseCellStyle };
    return { ...this.baseCellStyle };
  }

  selectorName(name: string): string {
    if (!this.data?.length || !name) return '';
    const order = this.data[0][name];
    return this.names?.find((e) => Number(e.order) === order)?.notes ?? '';
  }

  get columns() {
    return !Array.isArray(this.data) || !this.data?.length
      ? []
      : buildMatrixColumns(
          this.data[0].n_prm_2,
          this.selectorName('type_prm_1'),
          this.selectorName('type_prm_2')
        );
  }

  get rowScale(): number {
    return !this.selectedRow?.config?.Scale ? 1 : this.selectedRow?.config?.Scale;
  }

  get matrix() {
    const cols = this.columns;
    if (!cols?.length) return [];
    const matrix = buildX2Matrix(this.data[0], this.rowScale);
    if (!matrix?.length) return [];
    return buildMatrixRows(matrix, cols);
  }
}
