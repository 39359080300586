export const DICTIONARIES_CONST = {
  GET_TAB_REFS: 'getTabRefs',
  GET_TAB_REFS_SUCCESS: 'getTabRefsSuccess',
  GET_TAB_REFS_ERROR: 'getTabRefsError',

  GET_EQUIPMENT_TYPES: 'getEquipmentTypes',
  GET_EQUIPMENT_TYPES_SUCCESS: 'getEquipmentTypesSuccess',
  GET_EQUIPMENT_TYPES_ERROR: 'getEquipmentTypesError',

  TAB_REFS: 'tabRefs',
  DICTIONARIES: 'dictionaries',
  EQUIPMENT_TYPES: 'equipmentTypes',
};
