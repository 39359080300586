import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';

import { SystemLogsState } from './types';
import { SYSTEM_LOGS_STORE_CONST } from './constants';

export const getters: GetterTree<SystemLogsState, RootState> = {
  [SYSTEM_LOGS_STORE_CONST.EXPANDED_ITEMS](state): SystemLogsState['treeExpanded'] {
    const { treeExpanded } = state;
    return treeExpanded;
  },
};
